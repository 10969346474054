<template>
  <div class="presale-start-countdown">
    <p class="announcement">
      {{ pageData.presale_announcement }}
    </p>
    <p
      v-html="countdown"
      v-resize-text="{ ratio: 0.6, maxFontSize: '60px', delay: 50 }"
      class="countdown"
    />
    <p class="presale-start-date">
      {{ presaleStartDateFormatted }} Uhr
    </p>
  </div>
</template>

<script>

const UNITS = {
  second: { duration: 1000, text: 'SEKUNDEN' },
  minute: { duration: 60000, text: 'MINUTEN' },
  hour: { duration: 3600000, text: 'STUNDEN' },
  day: { duration: 86400000, text: 'TAGEN' },
};

export default {
  name: 'PresaleStartCountdown',
  props: {
    presaleStart: {
      type: Date,
    },
  },
  data() {
    return {
      timeDifference: null,
      jsInterval: null,
    };
  },
  computed: {
    unit() {
      if (this.timeDifference >= (49 * UNITS.hour.duration)) {
        return UNITS.day;
      }
      if (this.timeDifference >= (99 * UNITS.minute.duration)) {
        return UNITS.hour;
      }
      if (this.timeDifference >= (99 * UNITS.second.duration)) {
        return UNITS.minute;
      }
      return UNITS.second;
    },
    value() {
      const val = Math.ceil(this.timeDifference / this.unit.duration);
      return Math.max(val, 0);
    },
    countdown() {
      this.$store.commit('setTicketsCountdown', `${this.value} ${this.unit.text}`);
      return `${this.value}&nbsp;${this.unit.text}`;
    },
    presaleStartDateFormatted() {
      const options = {
        day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric',
      };
      return new Intl.DateTimeFormat('de-DE', options).format(this.presaleStart);
    },
  },
  methods: {
    updateTimeDifference() {
      const now = new Date();
      this.timeDifference = this.presaleStart.getTime() - now.getTime();
    },
  },
  mounted() {
    this.updateTimeDifference();
    this.jsInterval = setInterval(this.updateTimeDifference, 500);
  },
  beforeDestroy() {
    clearInterval(this.jsInterval);
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";

.presale-start-countdown {
  font-family: "OnAirBold";
  color: $white;
  background-color: $blue-02;
  padding: 25px;
  margin: -25px;
  margin-bottom: 20px;
}
.announcement {
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  margin: 20px 0;
}
.countdown {
  font-size: 60px;
}
.presale-start-date {
  font-family: "OnAirLight";
  font-size: 15px;
}
</style>
