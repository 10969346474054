<template>
  <div data-view>
    <div>
      Privacy page <br>
      Title : {{ pageData.title }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'Privacy',
  data: () => ({}),
};
</script>
