<template>
  <div class="highlight-journeys">
    <div class="first-row">
      <WelcomeBlock class="welcome" />
      <HighlightJourney
        v-if="firstHighlight"
        :highlight-id="firstHighlight.id"
        :highlight-journey="firstHighlight.value"
        class="first-highlight"
        button-type="primary"
      />
    </div>
    <div class="second-row">
      <HighlightJourney
        v-for="highlight in allOtherHighlights"
        :key="highlight.id"
        :highlight-id="highlight.id"
        :highlight-journey="highlight.value"
        button-type="secondary"
      />
    </div>
  </div>
</template>

<script>
import WelcomeBlock from './welcome-block.vue';
import HighlightJourney from './HighlightJourney.vue';

export default {
  name: 'highlight-journeys',
  components: {
    WelcomeBlock,
    HighlightJourney,
  },
  props: {
    journeysData: {
      type: Array,
    },
  },
  data() {
    return {
      isOnTablet: window.innerWidth <= 992,
    };
  },
  computed: {
    highlights() {
      const highlights = [];

      this.journeysData.forEach((obj, index) => {
        let highlight = obj;

        if (this.isOnTablet) { // on a tablet iterate images between left and right
          const isEven = index % 2 === 0;
          const imagePosition = isEven ? 'image-left' : 'image-right';
          const highlightValue = { ...highlight.value, image_position: imagePosition };
          highlight = { ...highlight, value: highlightValue };
        }

        highlights.push(highlight);
      });

      return highlights;
    },
    firstHighlight() {
      return this.highlights.length > 0 ? this.highlights[0] : null;
    },
    allOtherHighlights() {
      return this.highlights.slice(1);
    },
  },
  methods: {
    getJourneyImage(order) {
      return this.getResponsiveImage(this.journeysData[order].value.cover);
    },
    onClickJourney(path) {
      if (path) {
        this.$router.push({ path });
      }
    },
    updateIsOnTablet() {
      this.isOnTablet = window.innerWidth <= 992;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateIsOnTablet, false);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateIsOnTablet, false);
  },
};
</script>

<style lang="scss" scoped>
.highlight-journeys {
  margin-bottom: 200px;

  ::v-deep h2 {
    line-height: 1;
  }

  @include lg {
    margin-bottom: 0;
  }
}
.first-row {
  display: flex;
  align-items: center;

  @include lg {
    display: block;
  }
  .welcome {
    flex: 0 0 25%;
    align-self: flex-start;
  }
  .first-highlight {
    flex: 0 0 75%;
    padding-top: 20px;
    padding-bottom: 100px;
    @include xl {
      padding-bottom: 80px;
    }
    @include lg {
      padding-bottom: 40px;
    }
    @include sm {
      padding-bottom: 20px;
    }
  }
}
.second-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 40px;

  & > * {
    flex: 0 0 50%;
    margin-bottom: 80px;
  }
  @include lg {
    display: block;
  }
  @include sm {
    padding-top: 60px;
  }
}
</style>
