<template>
  <div class="content-wrapper" data-view>
    <GridLines />
    <div
      class="content-item image-row image-left"
      :style="{ 'background-image': 'url(' + images.imageLeft + ')' }" />
    <div class="content-item content-row">
      <div class="mobile-image"
        :style="{ 'background-image': 'url(' + images.imageTop + ')' }" />
      <div class="inner-wrapper">
        <div
          class="inner-content"
          :class="{ 'inner-content-additional': !pageData.back_button_enabled }"
        >
          <back-button v-if="pageData.back_button_enabled"/>
          <h1 v-html="pageData.header_title"></h1>
          <template v-for="item in pageData.content">
            <simple-paragraph
              v-if="item.type === 'simple_paragraph'"
              :key="item.id"
              :copy="item.value" />
            <div
              v-if="item.type === 'hybrid_navigation_link'"
              :key="item.id"
              class="button-wrapper"
            >
              <button-component
                :label="item.value.button_text"
                :button-link="{
                    internal: item.value.is_internal,
                    to: item.value.button_link
                  }"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="content-item image-row image-right"
      :style="{ 'background-image': 'url(' + images.imageRight + ')' }" />
  </div>
</template>
<script>
import BackButton from '@/components/button/BackButton.vue';
import GridLines from '@/components/misc/GridLines.vue';
import SimpleParagraph from '@/views/content-page/components/SimpleParagraph.vue';

export default {
  name: 'ContentPage',
  components: {
    BackButton,
    GridLines,
    SimpleParagraph,
  },
  computed: {
    images() {
      const { left_image, right_image, landscape_image } = this.pageData;
      const imageLeft = left_image ? left_image.desktop.url : '';
      const imageRight = right_image ? right_image.desktop.url : '';
      const imageTop = landscape_image ? landscape_image.desktop.url : '';
      return { imageLeft, imageRight, imageTop };
    },
  },
};
</script>
<style lang="scss" scoped>
.button-wrapper {
  padding-top: 30px;
  text-align: center;
}
.content-wrapper {
  display: flex;
  width: 100%;
  background-color: #fff;

  @include lg {
    background-color: transparent;
  }

  .mobile-image {
    height: 334px;
    display: none;
    background-size: cover;
    @include lg {
      display: block;
    }
    @include sm {
      height: 310px;
    }
  }
  .content-item {
    &.content-row {
      color: $blue-01;
      font-family: "OnAirLight";
      flex-grow: 0;
      max-width: 762px;

      @include lg {
        width: 100%;
        max-width: 100%;
      }

      .inner-wrapper {
        background: #fff;
        padding: 0 123px 0 59px;

        @include xl {
          padding: 0 35px;
        }
        @include sm {
          padding: 0 20px;
        }

        .inner-content {
          width: auto;
          display: block;
          padding-bottom: 50px;

          &-additional {
            padding-top: 40px;
          }

          @include lg {
            border: 1px solid $very-light-grey;
            border-top: none;
            border-bottom: none;
            padding-left: 50px;
          }

          @include sm {
            padding-left: 0;
          }
        }
      }

      h1 {
        font-size: 70px;
        font-weight: 300;
        line-height: 1.11;
        margin: 0;
        max-width: 553px;
        padding-bottom: 25px;

        @include xl {
          font-size: 62px;
          line-height: 1.13;
          max-width: 451px;
        }

        @include lg {
          max-width: 754px;
        }

        @include sm {
          max-width: auto;
          font-size: 50px;
          line-height: 1.2;
          padding-bottom: 10px;
        }
      }
    }

    &.image-row {
      flex-grow: 1;
      background-color: $blue-01;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: left top;
      padding: 95px 0 0 30px;
      @include xl-1680 {
        padding: 90px 0 0 30px;
        background-size: unset;
      }
      @include xl {
        padding: 90px 0 0 10px;
      }
      @include lg {
        display: none;
      }

    }
  }
}
</style>
