<template>
  <div class="presale-strip">
    <PresaleEndCountdown
      v-if="isPresaleRunning && ticketsAvailable"
      :presale-end="presaleEndDate"
      class="end-countdown"
    />
    <PresaleSoldOut
      v-if="isPresaleRunning && !ticketsAvailable"
      :presale-start="presaleStartDate"
    />
    <PresaleEndMessage
      v-if="presaleHasEnded && ticketsState === 'SALE'"
      class="end-message"
    />
    <PresaleLoginAlert
      v-if="(isPresaleRunning === true && isLoggedIn === false && ticketsAvailable) || isLoggedIn === false && ticketsAvailable && ticketsState === 'SALE'"
      :event-name="eventName"
    />
    <PresalePriorityAlert v-if="(isPresaleRunning === true && isLoggedIn === true && hasPriorityConditionsAccepted === false && ticketsAvailable) || isLoggedIn === true && hasPriorityConditionsAccepted === false && ticketsAvailable && ticketsState === 'SALE'" />
    <PresaleAuthorizedAlert v-if="(isPresaleRunning === true && isLoggedIn === true && hasPriorityConditionsAccepted === true && ticketsAvailable) || (isLoggedIn === true && hasPriorityConditionsAccepted === true && ticketsAvailable && ticketsState === 'SALE')" />
    <PresaleDetails
      v-if="(!presaleHasEnded && ticketsAvailable) || (ticketsState === 'SALE' && ticketsAvailable) || ticketsState === 'EXCLUSIVE'"
      :event-series="eventSeries"
      :presale-start="presaleStartDate"
      :is-presale-running="isPresaleRunning"
      :ticketsState="ticketsState"
      :event-name="eventName"
      :is-logged-in="isLoggedIn === true"
      ref="presaledetails"
      :on-show-popup-error="onShowPopupError"
    />
    <PresaleTicketAlarm
      v-if="!isPresaleRunning && ticketsState !== 'SALE'"
      class="presale-ticket-alarm"
      :event-series="eventSeries"
    />
    <SimilarArtists
      v-if="similarEventSeries.length > 0"
      :similarEventSeries="similarEventSeries"
      class="presale-similar-artists"
    />
  </div>
</template>

<script>
import PresaleDetails from '@/views/journey/components/presale/PresaleDetails.vue';
import PresaleLoginAlert from '@/views/journey/components/presale/PresaleLoginAlert.vue';
import PresalePriorityAlert from '@/views/journey/components/presale/PresalePriorityAlert.vue';
import PresaleAuthorizedAlert from '@/views/journey/components/presale/PresaleAuthorizedAlert.vue';
import PresaleTicketAlarm from '@/views/journey/components/presale/PresaleTicketAlarm.vue';
import PresaleEndCountdown from '@/views/journey/components/presale/PresaleEndCountdown.vue';
import PresaleSoldOut from '@/views/journey/components/presale/PresaleSoldOut.vue';
import PresaleEndMessage from '@/views/journey/components/presale/PresaleEndMessage.vue';
import SimilarArtists from '@/components/misc/SimilarArtists.vue';
import AuthService from '@/services/AuthService';

const auth = new AuthService();

export default {
  name: 'PresaleStrip',
  components: {
    PresaleDetails,
    PresaleTicketAlarm,
    PresalePriorityAlert,
    PresaleAuthorizedAlert,
    PresaleEndCountdown,
    PresaleSoldOut,
    PresaleLoginAlert,
    PresaleEndMessage,
    SimilarArtists,
  },
  props: {
    eventSeries: {
      type: Object,
    },
    similarEventSeries: {
      type: Array,
      default: () => [],
    },
    presaleStartDate: {
      type: Date,
      required: true,
    },
    presaleEndDate: {
      type: Date,
      required: true,
    },
    isPresaleRunning: {
      type: Boolean,
      required: true,
    },
    presaleHasEnded: {
      type: Boolean,
      required: true,
    },
    ticketsAvailable: {
      type: Boolean,
      required: true,
    },
    ticketsState: {
      type: String,
      default: '',
    },
    onShowPopupError: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoggedIn: null,
      hasPriorityConditionsAccepted: null,
      arePresaleDetailsOverflowing: false,
      arePresaleDetailsScrolled: false,
    };
  },
  computed: {
    isOverflowFadeDisplayed() {
      return this.arePresaleDetailsOverflowing && !this.arePresaleDetailsScrolled;
    },
    eventName() {
      if (this.eventSeries.artists && this.eventSeries.artists.length) {
        return this.eventSeries.artists[0];
      }
      return this.title ? this.title : this.eventSeries.eventserie;
    },
  },
  methods: {
    isElementOverflowing(element) {
      return element.scrollHeight > element.clientHeight;
    },
    hideOverflowFade(event) {
      const element = event.target;
      this.arePresaleDetailsScrolled = true;
      element.removeEventListener('scroll', this.hideOverflowFade);
    },
    addOverflowFadeIfNecessary() {
      if (this.$refs.presaledetails) {
        const element = this.$refs.presaledetails.$el;
        this.arePresaleDetailsOverflowing = this.isElementOverflowing(element);
        if (this.arePresaleDetailsOverflowing) {
          element.addEventListener('scroll', this.hideOverflowFade);
        }
      }
    },
    updateAuthStatus() {
      auth.isLoggedIn().then((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });
      auth.hasPriorityConditionsAccepted().then((val) => {
        this.hasPriorityConditionsAccepted = val;
      });
    },
  },
  mounted() {
    this.updateAuthStatus();
    this.addOverflowFadeIfNecessary();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/base.scss";
@import "src/assets/styles/variables.scss";

.presale-strip {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 3px;
  height: auto;
  min-height: 800px;

  @include lg {
    height: calc(100vh - #{$mobile-navigation-height});
    min-height: 750px;
  }

  @include sm {
    height: auto;
    min-height: auto;
  }
}
.overflow-fade-container {
  position: relative;
  pointer-events: none;
  opacity: 1;

  @include sm {
    display: none;
  }

  &.hide {
    opacity: 0;
    transition: opacity 100ms linear;
  }

  .overflow-fade {
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0), $white);
    width: calc(100% - 15px);
    height: 60px;
    line-height: 60px;
    text-align: center;
    pointer-events: none;

    img {
      vertical-align: bottom;
      margin-bottom: 8px;
      pointer-events: none;
    }
  }
}
.end-countdown, .end-message {
  @include sm {
    display: none;
  }
}
.presale-ticket-alarm {
  margin: 3px -3px -3px -3px;
}
</style>
