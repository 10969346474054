<template>
  <div class="input-wrapper" :class="classObject">
    <div class="field">
      <label v-if="field.label" :class="{'error': error}">{{ field.label }}</label>
      <select
        :class="{'error': error}"
        :name="field.name"
        @input="$emit('input', $event.target.value), validate(field, $event.target.value)">
        <option selected="true">Bitte wählen...</option>
        <option
          v-for="(option, index) in field.choices"
          :key="index"
          :value="option.value"
          class="option">
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectMenu',
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: String || Number,
      required: false,
    },
    classObject: {
      type: Object,
      required: false,
    },
    error: {
      type: Boolean,
      required: false,
    },
    validate: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";
.input-wrapper {
  margin: 12px 0;
  color: $blue-01;
  font-family: "OnAirLight";

  .field {
    position: relative;
  }

  label {
    display: block;
    font-size: 14px;
    font-family: "OnAirRoman";
    line-height: 1.71;
    background: #fff;
    position: absolute;
    padding: 0 3px;
    margin-left: 8px;
    bottom: 40px;
    color: $blue-01;
    &.error {
      color: $red;
    }
  }

  select {
    width: 100%;
    border: 1px solid #b9b9b9;
    border-radius: 0;
    background-color: $white;
    padding: 14px 18px;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.4;
    color: $blue-01;
    font-family: "OnAirLight";
    box-sizing: border-box;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    &.error {
      border-color: $red;
    }
  }

  select:focus {
    border-color: $blue-01;
    &.error {
      border-color: $red;
    }
  }
}
</style>
