<template>
  <div class="presale-end-countdown">
    <p class="call-to-action" v-custom-html="pageData.presale_running" />
    <div class="countdown-timer">
      <div class="time">
        {{ hours }}
      </div>
      <div class="colon"><p class="unit">Std.</p></div>
      <div class="time">
        {{ minutes }}
      </div>
      <div class="colon"><p class="unit">Min.</p></div>
      <div class="time">
        {{ seconds }}
      </div>
      <div class="colon"><p class="unit">Sek.</p></div>
    </div>
    <div class="countdown-date">
      Vor-Vorverkauf läuft bis {{ getTicketDate(presaleEnd) }}, {{ getTime(presaleEnd) }}
    </div>
    <div class="countdown-info" >
      NUR SOLANGE DER VORRAT REICHT
    </div>
  </div>
</template>

<script>

const UNITS = {
  second: { duration: 1000 },
  minute: { duration: 60000 },
  hour: { duration: 3600000 },
  day: { duration: 86400000 },
};

export default {
  name: 'PresaleEndCountdown',
  props: {
    presaleEnd: {
      type: Date,
    },
  },
  data() {
    return {
      jsInterval: null,
      hours: null,
      minutes: null,
      seconds: null,
    };
  },
  methods: {
    updateCountdown() {
      const now = new Date();
      const timeDifference = this.presaleEnd.getTime() - now.getTime();

      const secondsInt = Math.floor((timeDifference / UNITS.second.duration) % 60);
      this.seconds = secondsInt.toString().padStart(2, '0');

      const minutesInt = Math.floor((timeDifference / UNITS.minute.duration) % 60);
      this.minutes = minutesInt.toString().padStart(2, '0');

      const hoursInt = Math.floor((timeDifference / UNITS.hour.duration));
      this.hours = hoursInt.toString().padStart(2, '0');

      this.$store.commit('setTicketsCountdown', `${this.hours}:${this.minutes}:${this.seconds}`);
    },
    getTicketDate(dateData) {
      const day = `0${dateData.getDate()}`.slice(-2);
      const month = `0${dateData.getMonth() + 1}`.slice(-2);
      const year = dateData.getFullYear();
      return `${day}.${month}.${year}`;
    },
    getTime(date) {
      if (!date) return '';
      return new Date(date).toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit' });
    },
  },
  mounted() {
    this.updateCountdown();
    this.jsInterval = setInterval(this.updateCountdown, 100);
  },
  beforeDestroy() {
    clearInterval(this.jsInterval);
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/base.scss";
@import "src/assets/styles/variables.scss";

.presale-end-countdown {
  margin: -3px -3px 3px -3px;
  padding: 50px 25px;
  padding-bottom: 46px;
  background-color: $blue-02;
  color: $white;
  font-family: "OnAirBold";
  position: relative;
  padding-bottom: 0;
}
.call-to-action {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 17px;
}
.countdown-timer {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .time {
    font-size: 60px;
    width: 30%;
    text-align: center;

    @include xl-1440 {
      font-size: 50px;
    }

    @include xl {
      font-size: 40px;
    }

    @include lg {
      font-size: 40px;
    }
    @include sm {
      font-size: 60px;
    }
  }
  .colon {
    transform: rotate(-90deg) translate(24px, -5px);
    align-self: flex-end;

    @include xl {
      transform: rotate(-90deg) translate(17px, -5px);
    }
    @include lg {
      transform: rotate(-90deg) translate(17px, -5px);
    }
    @include sm {
      transform: rotate(-90deg) translate(20px, -5px);
    }
  }
  .unit {
    font-family: "OnAirRoman";
    font-size: 17px;
  }
}
.countdown-date {
  font-family: 'OnAirLight';
  font-size: 12px;
  line-height: 1.33;
  padding-top: 10px;
}
.countdown-info {
  background-color: $white;
  color: $blue-02;
  text-align: center;
  min-height: 26px;
  line-height: 26px;
  font-size: 14px;
  margin-left: -25px;
  margin-right: -25px;
  margin-top: 20px;
  border-bottom: solid 1px $very-light-grey;
}
</style>
