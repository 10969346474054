<template>
  <div
    class="block block--default"
    :class="[(isNavigationActive && !openUpMenu) ? 'open' : '']"
  >
    <div class="link-upmenu" @click="$emit('toggle-main')">
      O<sub>2</sub> Hauptmenü
    </div>
    <router-link
      v-for="navigationItem in leftNavigation"
      v-on:click.native="$emit('on-click')"
      :key="navigationItem.key"
      class="links-nav"
      rel="follow"
      exact
      :to="navigationItem.url"
    >
      <span
        :class="[isHtml(navigationItem.header || navigationItem.title) ? 'html' : '']"
        v-html="navigationItem.header || navigationItem.title"
      />
    </router-link>
    <div class="links-nav social-wrapper">
      <div>{{ leftNavigationSocialHeader }}</div>
      <div
        v-if="leftNavigationSocial.length > 0"
        class="social-block-links"
      >
        <div
          v-for="item in leftNavigationSocial"
          :key="item.platform"
          class="social-block-link"
        >
          <a :href="item.url" target="_blank" rel="noopener">
            <span :class="`glyphicon glyphicon-${item.platform}`" />
          </a>
        </div>
      </div>
    </div>
    <div
      v-if="!openUpMenu"
      class="default-menu-feedback"
      @click="$emit('on-feedback')"
    >
      <img
        src="@/assets/images/video-overlay-feedback/feedback-teaser.svg"
        alt="default-menu-feedback"
      />
    </div>
    <nav class="navigation__meta">
      <ul class="navigation__meta-portal">
        <li class="navigation__meta-item">
          <a target="_blank" href="https://www.o2online.de/" rel="noopener">
            PRIVATKUNDEN
          </a>
        </li>
        <li class="navigation__meta-item">
          <a target="_blank" href="https://www.o2online.de/business/" rel="noopener">
            GESCHÄFTSKUNDEN
          </a>
        </li>
      </ul>
      <div class="navigation__meta-link-container">
        <ul class="navigation__meta-links">
          <li class="navigation__meta-item">
            <a target="_blank" href="https://www.o2online.de/kontakt/" rel="noopener">
              <span class="glyphicon glyphicon-phone" />
              <span>Bestellhotline <br class="sm:hidden">089 78 79 79 409</span>
            </a>
          </li>
          <li class="navigation__meta-item">
            <a target="_blank" href="https://www.o2online.de/shops" rel="noopener">
              <span class="glyphicon glyphicon-retail" />
              <span>O<sub>2</sub> Shops</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    isNavigationActive: Boolean,
    openUpMenu: Boolean,
  },
  computed: {
    leftNavigation() {
      return this.$store.state.siteSettings.leftNavigation;
    },
    leftNavigationSocialHeader() {
      return this.$store.state.siteSettings.leftNavigationSocialHeader || [];
    },
    leftNavigationSocial() {
      return this.$store.state.siteSettings.leftNavigationSocial || 'JETZT FAN WERDEN:';
    },
  },
  methods: {
    isHtml(val) {
      return /<\/?[a-z][\s\S]*>/i.test(val);
    },
  },
};
</script>
