<template>
  <div class="input-wrapper" :class="{'disabled': disabled, ...classObject }">
    <label>
      <span class="text" v-html="field.label"></span>
      <input
        :name="field.name"
        :id="field.name"
        class="input"
        type="checkbox"
        :checked="value"
        :disabled="disabled"
        :required="field.required"
        @change="$emit('input', !value)">
      <span class="checkmark" :class="{'error': error}"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classObject: {
      type: Object,
      required: false,
    },
    error: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";
.input-wrapper ::v-deep a {
  text-decoration: underline;
  color: $blue-01;
}
.footer ::v-deep a {
  text-decoration: underline;
  color: $white;
}
.input-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 1.67;
  color: $blue-01;
  font-family: "OnAirRoman";
  user-select: none;
  z-index: 10;

  &.disabled {
    color: #b9b9b9;
  }

  span.text {
    display: block;
    padding-top: 2px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $blue-02;
      border-color: $blue-02;
    }

    &:hover ~ .checkmark {
      border-color: $blue-01;
    }

    &:disabled ~ .checkmark {
      border-color: #b9b9b9;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #b9b9b9;

    &.error {
      border-color: $red;
    }

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 3px;
      width: 5px;
      height: 14px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}
</style>
