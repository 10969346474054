var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"youtube-video-item-wrapper"},[_c('div',{staticClass:"youtube-video-item",class:{ 'no-margins': _vm.noMargins }},[(_vm.expirationData.expiration && _vm.expirationData.expiration_warning)?_c('div',{staticClass:"expiration-warning",domProps:{"innerHTML":_vm._s(_vm.expirationData.expiration_warning)}}):_vm._e(),_c('div',{staticClass:"thumbnail",class:[{
        'thumbnail-active' : _vm.showMask && _vm.isYoutube,
        'mouseover': _vm.isYoutube || _vm.mouseOver,
        'is-clickable': _vm.isInternalLink || _vm.isExternalLink,
      }],on:{"click":function($event){return _vm.onClickThumbnail()}}},[(_vm.isYoutube)?_c('div',{staticClass:"play-button"}):_vm._e(),_c('img',{attrs:{"src":_vm.thumbnail,"alt":_vm.header}}),(_vm.isYoutube || _vm.mouseOver)?_c('div',{staticClass:"content-wrapper-mask"},[_c('div',{staticClass:"mask-content"},[_c('div',{staticClass:"mask-text"},[_vm._v(" "+_vm._s(_vm.maskText)+" ")]),(_vm.isYoutube)?_c('div',{staticClass:"mask-button"},[_c('button-component',{attrs:{"button-type":"secondary","background-color":"white","label":"Jetzt ansehen","icon-props":{
                enabled: true,
                name: 'play-button',
                position: 'left'
              }},on:{"change":_vm.onClickPlayButton}})],1):_vm._e()])]):_vm._e()]),(_vm.header || _vm.description || _vm.extraContent)?_c('div',{staticClass:"copy"},[_c('h3',{directives:[{name:"custom-html",rawName:"v-custom-html",value:(_vm.header),expression:"header"}]}),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.description)}}),_c('span',{staticClass:"extra-content"},[_vm._v(_vm._s(_vm.extraContent))])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }