<template>
  <div v-if="data.length" class="presale-teaser">
    <swiper
      ref="swiperRef"
      :options="swiperOption"
      @ready="handleSwiperReady"
      @slideChangeTransitionEnd="slideChangeTransitionEnd"
      @slideChangeTransitionStart="slideChangeTransitionStart"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="slide in slides"
        :key="slide.id"
        :data-swiper-autoplay="getDisplayTime(slide)"
      >
        <div
          class="slide-item-wrapper"
          :class="[ isActionSlide(slide) ? 'slide-item-wrapper--action' : '' ]"
          :style="{ 'background-image': backgroundSlideImage(slide) }"
          @click.prevent="onClickSlide($event, slide)"
        >
          <presale-teaser-slide-item
            class="slide-item-teaser-content"
            :id="slide.id"
            :data="slide"
          />
          <div
            v-if="(slide.value.background_video && slide.value.background_video.url && !isMobile && !isTablet) || (slide.value.background_video_mobile && slide.value.background_video_mobile.url && (isMobile || isTablet))"
            class="slide-item-video"
          >
            <video
              v-if="slide.value.background_video && !isMobile && !isTablet"
              class="slide-item-video-desktop"
              muted
              autoplay
              loop
              preload="auto"
              playsinline
            >
              <source
                :src="slide.value.background_video.url"
                :type="slide.value.background_video.type"
              >
            </video>
            <video
              v-if="slide.value.background_video_mobile && (isMobile || isTablet)"
              class="slide-item-video-mobile"
              muted
              autoplay
              loop
              preload="auto"
              playsinline
            >
              <source
                :src="slide.value.background_video_mobile.url"
                :type="slide.value.background_video.type"
              >
            </video>
          </div>
        </div>
      </swiper-slide>
      <div v-if="slides.length > 1" class="swiper-pagination" slot="pagination" />
    </swiper>
    <div v-if="slides.length > 1" class="swiper-nav-buttons">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>
<script>
import PresaleTeaserSlideItem from './presale-teaser-slide-item.vue';

export default {
  name: 'PresaleTeaser',
  components: {
    PresaleTeaserSlideItem,
  },
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    swiperOption: {
      pagination: {
        el: '.swiper-pagination',
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      simulateTouch: false,
      simulateSwipe: false,
      loop: true,
      effect: 'fade',
      fadeEffect: { crossFade: true },
      allowTouchMove: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    },
    swiper: null,
    sliderTimout: null,
    sliderRealIndex: 0,
    sliderTiming: 0,
    timingDifferent: 0,
    initialSlideIndex: -1,
  }),
  computed: {
    slides() {
      return this.data.map((slide, idx) => {
        if (slide.type === 'takeover_serie_teaser' && slide.value) {
          slide.value.button_link = {
            type: 'internal_link',
            value: slide.value ? slide.value.event_link : '',
          };
          slide.value.button_text = slide.value.buy_button;
          slide.value.event_logo = slide.value.local_image;
          slide.value.event_logo_alternative = slide.value.eventserie;
          slide.value.live_flag = slide.value.in_stock;
        }
        if (!!this.initialSlideSlug && this.initialSlideSlug === slide.value.slug) {
          this.initialSlideIndex = idx;
        }
        return slide;
      });
    },
    /* works with query ?slide=xyz and #xyz */
    initialSlideSlug() {
      const { hash } = this.$route;
      return hash ? hash.replace('#', '') : this.$route.query.slide;
    },
  },
  methods: {
    getDisplayTime(item) {
      if (!item) return 5000;
      return item.value.display_time ? item.value.display_time * 1000 : 5000;
    },
    backgroundSlideImage(item) {
      if (item.value && item.value.background_mobile && (this.isTablet || this.isMobile)) {
        return `url(${this.getResponsiveImage(item.value.background_mobile)})`;
      }
      if (item.value && item.value.background) {
        return `url(${this.getResponsiveImage(item.value.background)})`;
      }
      return `url(${require('@/assets/images/presale-teaser/bg-img-takeover.jpg')})`;
    },
    onSlideChange() {
      if (this.swiper.realIndex === 0 && this.swiper.previousIndex === this.slides.length) {
        this.swiper.slideTo(1);
      }
      if (this.swiper.previousIndex === 1 && this.swiper.realIndex === this.slides.length - 1) {
        this.swiper.slideTo(this.slides.length);
      }
    },
    onMouseOverSlide() {
      clearTimeout(this.sliderTimout);
      const pagination = this.swiper.pagination ? this.swiper.pagination.el : null;
      if (!pagination) {
        return;
      }
      const activeBullet = pagination.querySelector('.slide-page-active-wrapper');
      if (activeBullet && activeBullet.className.indexOf('slide-page-active-wrapper-hold') < 0) {
        activeBullet.classList.add('slide-page-active-wrapper-hold');
        this.swiper.autoplay.stop();
        const timingDifferent = new Date().valueOf() - this.sliderTiming;
        this.timingDifferent += timingDifferent;
      }
    },
    onMouseOutSlide() {
      clearTimeout(this.sliderTimout);
      const pagination = this.swiper.pagination ? this.swiper.pagination.el : null;
      if (!pagination) {
        return;
      }
      const activeBullet = pagination.querySelector('.slide-page-active-wrapper');
      if (activeBullet && activeBullet.className.indexOf('slide-page-active-wrapper-hold') > -1) {
        this.sliderTimout = setTimeout(() => {
          this.swiper.params.autoplay.delay = 5300 - this.timingDifferent;
          this.$nextTick(() => {
            this.swiper.autoplay.start();
            activeBullet.classList.remove('slide-page-active-wrapper-hold');
            this.sliderTiming = new Date().valueOf();
          });
        }, 200);
      }
    },
    runSliderBulletWrapper() {
      if (!this.swiper.pagination.bullets) {
        return;
      }
      const oldActiveBullet = this.swiper.pagination.el.querySelector('.slide-page-active-wrapper');
      if (oldActiveBullet) {
        oldActiveBullet.parentNode.removeChild(oldActiveBullet);
      }
      setTimeout(() => {
        const pageBullet = this.swiper.pagination.bullets[this.sliderRealIndex];
        const bulletWrapper = document.createElement('div');
        bulletWrapper.classList.add('slide-page-active-wrapper');
        pageBullet.appendChild(bulletWrapper);
      }, 0);
    },
    slideChangeTransitionStart() {
      const pagination = this.swiper.pagination ? this.swiper.pagination.el : null;
      if (!pagination) {
        return;
      }
      const element = pagination.querySelector('.slide-page-active-wrapper');
      if (element) {
        element.parentNode.removeChild(element);
      }
      this.swiper.params.autoplay.delay = 5000;
      this.timingDifferent = 0;
      this.sliderTiming = new Date().valueOf();
    },
    slideChangeTransitionEnd() {
      this.sliderRealIndex = this.swiper.realIndex;
      this.runSliderBulletWrapper();
    },
    addSlidesEvents() {
      const slideItems = Array.from(this.swiper.slides);
      slideItems.map((element) => {
        const slideItem = element.firstChild.firstChild;
        slideItem.addEventListener('mouseover', this.onMouseOverSlide);
        slideItem.addEventListener('mouseout', this.onMouseOutSlide);
        return element;
      });
    },
    handleSwiperReady(swiper) {
      this.swiper = swiper;
      if (this.initialSlideIndex > -1) {
        this.swiper.slideTo(this.initialSlideIndex + 1);
      }
      this.addSlidesEvents();
      this.runSliderBulletWrapper();
      this.sliderTiming = new Date().valueOf();
    },
    onClickSlide($event, slide) {
      const path = $event.composedPath();
      const { button_link, button_text = {} } = slide.value;

      if (path[2].classList.contains('calendar-link')) {
        this.trackActionEvent({
          eventName: 'customInteractionClick',
          eCat: 'analysis',
          eAct: `click - iCal in header ${button_text}`,
          eLab: button_text,
          nonInteraction: false,
        });
        window.open(
          slide.value.calendar_link.calendar_file.download_url,
          `_newtab${Date.now()}`,
        );
        return;
      }

      if (path[2].classList.contains('cta-link') || this.isActionSlide(slide)) {
        this.trackActionEvent({
          eventName: 'customInteractionClick',
          eCat: 'analysis',
          eAct: `click - CTA in header ${button_text}`,
          eLab: button_link.value,
          nonInteraction: false,
        });

        if (typeof slide.value.button_link === 'undefined') return;

        const { type: buttonType, value: buttonValue } = slide.value.button_link;
        if (buttonType === 'external_link' && this.isYoutubeLink(buttonValue)) {
          this.openYoutubeInNewWindow(buttonValue);
        } else if (buttonType === 'external_link') {
          window.open(buttonValue, '_blank');
        } else {
          this.$router.push({ path: buttonValue });
        }
      }
    },
    isActionSlide(slide) {
      const { type, value: { button_hidden, button_link } } = slide;
      return type === 'takeover_livestream_teaser'
        && button_hidden
        && typeof button_link === 'object';
    },
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
  @keyframes bulletMove {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  .swiper-slide {
    height: auto;
  }
  .presale-teaser {
    height: calc(100vh - #{$navigation-height});
    min-height: calc(850px - #{$navigation-height});
    position: relative;
    background-color: $white;

    @include lg {
      height: 600px;
      min-height: 600px;
    }
    @include sm {
      height: 100%;
      min-height: 100%;
    }
  }
  .swiper-container {
    height: 100%;
  }
  .swiper-wrapper {
    width: 100%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: $white;

      @include sm {
        align-items: flex-start;
        height: auto;
      }
    }
  }
  .slide-item-wrapper {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    @include sm {
      align-items: flex-start;
      height: 100%;
    }

    &--action {
      cursor: pointer;
    }
  }
  .slide-item-teaser-content {
    z-index: 1;
  }
  .slide-item-video {
    position: fixed;
    z-index: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 1100px;
    @include lg {
      min-height: auto;
    }
  }
  .slide-item-video-desktop {
    position: absolute;
    width: auto;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .slide-item-video-mobile {
    position: absolute;
    width: 100%;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .swiper-container::v-deep {
    .swiper-pagination {
      bottom: 40px;

      @include lg {
        bottom: 20px;
      }
      @include sm {
        bottom: 24px;
      }
    }
  }
  .swiper-pagination::v-deep {
    @include sm {
      left: 14px !important;
      width: auto !important;
      margin-right: auto;
    }
    .swiper-pagination-bullet {
      border-radius: 4px;
      transition: width 0.18s linear;
      position: relative;
      overflow: hidden;
      background-color: transparent;
      opacity: 1;
      &::before {
        content: "";
        background-color: $white;
        opacity: 0.5;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 4px;
        position: absolute;
      }

      @include sm {
        width: 6px;
        height: 6px;
        margin: 0 6px !important;
      }
    }
    .swiper-pagination-bullet-active {
      width: 70px;
      border-radius: 4px;
      overflow: hidden;
      touch-action: none;
      -ms-touch-action: none;

      @include sm {
        width: 56px;
        height: 6px;
      }
    }
    .slide-page-active-wrapper {
      width: 70px;
      height: 8px;
      border-radius: 4px;
      background-color: $white;
      animation: bulletMove 4.95s linear;

      @include sm {
        width: 56px;
        height: 6px;
      }

      &.slide-page-active-wrapper-hold {
        animation-play-state: paused;
      }
    }
  }
  .swiper-nav-buttons {
    position: absolute;
    display: flex;
    width: 1344px;
    max-width: calc(100% - 200px);
    left: 0;
    right: 0;
    margin: 0 auto;
    justify-content: flex-end;
    bottom: 35px;

    @include xl {
      max-width: calc(100% - 110px);
    }
    @include lg {
      max-width: calc(100% - 50px);
    }
    @include sm {
      max-width: calc(100% - 40px);
      bottom: 21px;
    }

    .swiper-button-prev {
      cursor: pointer;
      left: 0;
      right: 0;
      display: inline-block;
      position: relative;
      background-image: url('~@/assets/images/presale-teaser/arrow-active.svg');
      background-size: cover;
      width: 50px;
      height: 35px;
      transform: rotate(180deg);
      &::after {
        content: "";
        display: none;
      }

      @include sm {
        width: 40px;
        height: 28px;
      }
    }
    .swiper-button-next {
      cursor: pointer;
      left: 0;
      right: 0;
      display: inline-block;
      margin-left: 15px;
      position: relative;
      background-image: url('~@/assets/images/presale-teaser/arrow-active.svg');
      background-size: cover;
      width: 50px;
      height: 35px;
      &::after {
        content: "";
        display: none;
      }

      @include sm {
        width: 40px;
        height: 28px;
      }
    }
  }
  .slide-item-wrapper {
    // background-image: url('~@/assets/images/presale-teaser/bg-img-takeover.png')
    background-color: $blue-02;
  }
</style>
