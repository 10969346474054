<template>
  <div
    class="teaser-wrapper"
    :class="{ 'teaser-wrapper-video': videoTablet && videoDefault }"
    :style="cssVars"
  >
    <div class="teaser">
      <div class="teaser-item">
        <img
          v-if="image"
          :src="image"
          :alt="title"
        >
        <h1 v-if="title" v-custom-html="title" />
        <div
          v-if="videoTablet && videoDefault"
          class="teaser-video-background"
        >
          <video
            ref="videoElement"
            :src="videoSrc"
            :poster="backgroundImage"
            muted
            preload="none"
            playsinline
            @ended="onTimeEnded()"
            @canplay="onVideoCanPlay()"
          />
        </div>
      </div>
      <div class="copy">
        <h2 v-if="subtitle" v-html="subtitle" />
        <div
          class="description"
          v-html="description"
        />
        <div
          v-if="specialMediaContent"
          class="special-media-content"
          @click="onOpenVideo(specialMediaContent)"
        >
          <span class="special-media-content-icon" />
          Jetzt Highlights ansehen
        </div>
      </div>
      <slot name="button"></slot>
      <slot name="additional-info"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TeaserPriority',
  props: {
    onOpenVideo: {
      type: Function,
    },
    specialMediaContent: {
      type: [Object, Boolean],
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    backgroundImage: {
      type: String,
      required: false,
      default: '',
    },
    backgroundImageLandscape: {
      type: String,
      required: false,
    },
    videoDefault: {
      type: Object,
      default: null,
    },
    videoTablet: {
      type: Object,
      default: null,
    },
    videoCurrentTimes: {
      type: Object,
      default: () => ({
        desktop: 2,
        tablet: 2,
      }),
    },
  },
  data() {
    return {
      videoPlayed: 0,
    };
  },
  computed: {
    cssVars() {
      return {
        '--background-image': `url(${this.backgroundImage})`,
        '--backgroud-image-landscape': `url(${this.backgroundImageLandscape})`,
      };
    },
    videoSrc() {
      return this.isTablet ? this.videoTablet.url : this.videoDefault.url;
    },
  },
  methods: {
    onTimeEnded() {
      if (this.videoPlayed > 3) return;
      this.videoPlayed += 1;
      const video = this.$refs.videoElement;
      video.currentTime = this.isTablet
        ? this.videoCurrentTimes.tablet : this.videoCurrentTimes.desktop;
      video.pause();
      video.currentTime = this.isTablet
        ? this.videoCurrentTimes.tablet : this.videoCurrentTimes.desktop;
      setTimeout(() => {
        if (video.paused) {
          video.play();
        }
      }, 100);
    },
    onVideoCanPlay() {
      const video = this.$refs.videoElement;
      video.play();
    },
  },
  mounted() {
    if (this.$refs.videoElement) {
      this.$refs.videoElement.load();
    }
  },
};
</script>

<style lang="scss" scoped>
.special-media-content {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background-color: $white;
  color: $blue-01;
  font-size: 14px;
  font-family: 'OnAirBold';
  height: 44px;
  line-height: 44px;
  text-align: center;
  padding-left: 44px;
  margin-top: 34px;
  margin-bottom: 15px;

  &-icon {
    position: absolute;
    height: 44px;
    width: 44px;
    left: 0;
    top: 0;
    background-image: url('~@/assets/icons/play-blue.svg');
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: center;
    border-right: dashed 1px $blue-01;
  }
}
.teaser-video-background {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1;

  video {
    position: absolute;
    width: auto;
    height: 100%;
    left: 0;
  }

  @include lg {
    left: -80px;
    width: calc(100% + 80px);
    // bottom: 85px;

    video {
      width: 1072px;
      object-fit: unset;
      height: auto;
    }
  }
  @include sm {
    left: -15px;
    width: calc(100% + 15px);
    top: -50px;

    video {
      width: 576px;
      object-fit: unset;
      height: auto;
    }
  }
}
.teaser-wrapper {
  background-image: var(--background-image);
  color: $white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  width: 100%;
  position: relative;
  overflow: hidden;
  @include lg {
    background-image: var(--backgroud-image-landscape);
  }
  @include sm {
    background-image: var(--background-image);
  }

  .teaser {
    padding: 71px 20px 71px 60px;

    @include xl-1440 {
      padding: 50px 15px 50px 45px;
    }
    @include xl {
      padding: 50px 10px 50px 35px;
    }
    @include lg {
      display: flex;
      flex-direction: row;
      padding: 40px 0;
    }
    @include sm {
      display: block;
      padding: 35px 20px 35px 55px;
    }

    .teaser-item {

      ::v-deep {
        img {
          width: 90%;
          @include lg {
            width: 200px;
          }
          @include sm {
            width: 100%;
          }
        }
      }
      img {
        width: 90%;
        @include lg {
          width: 200px;
        }
        @include sm {
          width: 100%;
        }
      }
      @include lg {
        width: 55%;
        padding: 0 auto;
        text-align: center;
      }
      @include sm {
        width: unset;
        max-width: 290px;
        text-align: unset;
        margin: unset;
      }
    }

    h1 {
      font-family: 'OnAirLight';
      font-size: 84px;
      line-height: 1.07;
      font-weight: 300;
      letter-spacing: -1px;
      margin: 22px 0 0 0;
      display: block;
      z-index: 2;
      position: relative;

      @include xl-1680 {
        font-size: 65px;
        letter-spacing: -0.3px;
        margin: 16px 0 0 0;
      }

      @include xl-1440 {
        font-size: 50px;
        letter-spacing: -1.5px;
        margin: 14px 0 0 0;
      }
      @include xl {
        font-size: 57px;
        letter-spacing: -0.3px;
      }
      @include lg {
        font-size: 52px;
        width: 200px;
        text-align: left;
        margin-left: 52px;
      }
      @include sm {
        margin: 16px 0 0 0;
        font-size: 76px;
        letter-spacing: -1.5px;
        line-height: 1.08;
        width: unset;
      }
    }

    .copy {
      margin: 40px 28px 0 0;
      z-index: 2;
      position: relative;

      @include xl {
        margin: 35px 28px 0 0;
      }
      @include lg {
        width: 45%;
        margin: 14px 80px 35px 60px;
      }
      @include sm {
        min-width: 240px;
        margin: 14px 28px 0 0;
      }

      h2 {
        font-family: 'OnAirBold';
        margin: 0;
      }
      .description {
        font-family: 'OnAirLight';
        padding-top: 12px;

        &::v-deep a {
          text-decoration: underline;
        }
      }
      .description ::v-deep h1, .description ::v-deep h2 {
        margin: 0;
        font-size: 15px;
        line-height: 1.6;
        font-weight: normal;
        display: block;
        @include xl {
          font-size: 14px;
          line-height: 1.57;
        }
        @include lg {
          max-width: 275px;
          margin: 0 auto;
        }
      }
      h2, .description {
        font-size: 15px;
        line-height: 1.6;
        font-weight: normal;
        display: block;
        @include xl {
          font-size: 14px;
          line-height: 1.57;
        }
        @include lg {
          max-width: 275px;
          margin: 0 auto;
        }
      }
    }
  }
  &.teaser-wrapper-video {
    .teaser {
      padding: 71px 20px 71px 38px !important;

      @include xl-1440 {
        padding: 50px 15px 50px 38px !important;
      }
      @include xl {
        padding: 50px 10px 50px 38px !important;
      }
      @include lg {
        display: flex;
        flex-direction: row;
        padding: 38px 0 !important;
      }
      @include sm {
        display: block;
        padding: 35px 20px 35px 38px !important;
      }
    }
  }
}
</style>
