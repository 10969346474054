<template>
  <div data-view class="journey-page">
    <LivestreamTicker
      v-if="hasLiveStreamTicker"
      :text="pageData.livestream_ticker.text"
      :link="pageData.livestream_ticker.link"
    />
    <GridLines :classList="{'disable-sm': true}" />
    <div class="journey-page-content">
      <Component
        v-for="(module, $index) in modules"
        :key="module.id"
        :index="$index"
        :is="moduleComponents[module.type]"
        v-bind="module.value"
        :class="
          module.value && module.value.anchor_slug ? `anchor-${module.value.anchor_slug}` : ''
        "
      />
      <Presale
        v-if="pageData.event_series"
        class="presale journey-presale"
      />
      <legal-notification
        v-if="pageData.pre_footer_enabled &&
          pageData.pre_footer_description && pageData.pre_footer_button_text &&
          pageData.pre_footer_button_link"
        class="journey-legal-notification"
        :pre-footer-description="pageData.pre_footer_description"
        :pre-footer-button-text="pageData.pre_footer_button_text"
        :pre-footer-button-link="pageData.pre_footer_button_link"
      />
    </div>
  </div>
</template>

<script>
import GridLines from '@/components/misc/GridLines.vue';
import Presale from '@/views/journey/components/presale/Presale.vue';
import Content from '@/views/journey/components/modules/Content.vue';
import SocialMediaWall from '@/views/journey/components/modules/SocialMediaWall.vue';
import TeaserExternal from '@/views/journey/components/modules/TeaserExternal.vue';
import Intro from '@/views/journey/components/modules/Intro.vue';
import RowContentModule from '@/views/journey/components/modules/RowContentModule.vue';
import Livestream from '@/views/journey/components/modules/Livestream.vue';
import MediaModule from '@/views/journey/components/modules/MediaModule.vue';
import Faq from '@/views/journey/components/modules/Faq.vue';
import ImageModule from '@/views/journey/components/modules/ImageModule.vue';
import ICalendarModule from '@/views/journey/components/modules/ICalendarModule.vue';
import PresaleModule from '@/views/journey/components/modules/PresaleModule.vue';
import TakeOverTeaserModule from '@/views/journey/components/modules/TakeOverTeaserModule.vue';
import LegalNotification from '@/components/misc/LegalNotification.vue';
import Ticker from '@/views/common/components/ticker/Ticker.vue';
import TickerModule from '@/views/common/components/ticker/TickerModule.vue';
import LivestreamTicker from '@/views/common/components/ticker/LivestreamTicker.vue';

export default {
  name: 'JourneyPage',
  components: {
    GridLines,
    Presale,
    Content,
    LegalNotification,
    LivestreamTicker,
  },
  data() {
    return {
      moduleComponents: {
        content_module: Content,
        social_module: SocialMediaWall,
        external_navigation_module: TeaserExternal,
        intro_module: Intro,
        row_content_module: RowContentModule,
        livestream_module: Livestream,
        faq_module: Faq,
        image_module: ImageModule,
        calendar_teaser_module: ICalendarModule,
        presale_module: PresaleModule,
        takeover_teaser_module: TakeOverTeaserModule,
        mediathek_module: MediaModule,
        ticker_module: Ticker,
        ticker_newsletter_module: TickerModule,
      },
    };
  },
  watch: {
    $route() {
      const { currentRoute } = this.$router;
      const { hash } = currentRoute;
      this.$nextTick(() => {
        this.onRunAnchor(hash);
      });
    },
  },
  methods: {
    onRunAnchor(hash) {
      if (hash) {
        const elementClass = hash.replace('#', '.anchor-');
        const element = document.querySelector(elementClass);
        if (!element) return;
        setTimeout(() => {
          const topPos = element.getBoundingClientRect().top;
          element.scrollIntoView(true);
          window.scrollBy(0, -200);
          const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
          setTimeout(() => {
            if (!supportsNativeSmoothScroll) {
              element.scrollIntoView(true);
            } else {
              window.scrollTo({
                top: topPos,
                behavior: 'smooth',
              });
            }
          }, 100);
        }, 300);
      }
    },
  },
  mounted() {
    const { hash } = this.$route;
    this.onRunAnchor(hash);
  },
  computed: {
    modules() {
      return this.pageData.content;
    },
    hasLiveStreamTicker() {
      const { livestream_ticker } = this.pageData;
      return (livestream_ticker && livestream_ticker.isLive) || false;
    },
  },
};
</script>

<style lang="scss" scoped>
.journey-page-content {
  > * {
    margin-bottom: 120px;

    &.content-module:last-child,
    &.image-module:last-child,
    &.presale:last-child {
      margin-bottom: 0;
    }

    @include lg {
      margin-bottom: 60px;
    }
  }
}
.journey-legal-notification, .journey-presale {
  margin-bottom: 0;
}
</style>
