<template>
  <div data-view class="raffle-page">
    <div
      class="content-item image-row image-left"
      :style="{ 'background-image': 'url(' + images.imageLeft + ')' }">
      <div class="inner">
        <img
          src="@/assets/images/raffle/intro_img_raffle.png"
          alt="o2 Priority"
        />
      </div>
    </div>
    <div class="content-item content-row">
      <div class="mobile-image"
        :style="{ 'background-image': 'url(' + images.imageTop + ')' }">
        <div class="inner">
          <img
            src="@/assets/images/raffle/intro_img_raffle.png"
            alt="o2 Priority"
          />
        </div>
      </div>
      <raffle-content />
    </div>
    <div class="content-item image-row image-right"
      :style="{ 'background-image': 'url(' + images.imageRight + ')' }">
      <div class="inner" />
    </div>
  </div>
</template>
<script>
import RaffleContent from '@/views/raffle/components/RaffleContent.vue';

export default {
  name: 'Raffle',
  components: {
    RaffleContent,
  },
  computed: {
    images() {
      const { left_image, right_image, landscape_image } = this.pageData;
      const imageLeft = left_image ? left_image.desktop.url : '';
      const imageRight = right_image ? right_image.desktop.url : '';
      const imageTop = landscape_image ? landscape_image.desktop.url : '';
      return { imageLeft, imageRight, imageTop };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/styles/base.scss";
@import "src/assets/styles/variables.scss";

.raffle-page {
  display: flex;
  width: 100%;
  background-color: #fff;

  .inner {
    width: 292px;
    img {
      width: 100%;
    }
    @include xl-1680 {
      width: 268px;
    }
    @include xl {
      width: 242px;
    }
  }

  .mobile-image {
    height: 334px;
    display: none;
    background-size: cover;
    -moz-background-size: cover;
    @include lg {
      display: block;
    }
    @include sm {
      height: 310px;
    }
    .inner {
      padding: 50px 0 0 65px;
      @include sm {
        padding: 40px 0 0 5px;
      }
    }
  }

  .content-item {
    &.content-row {
      flex-grow: 0;
      max-width: 762px;

      @include xl {
        max-width: 592px;
      }
      @include lg {
        width: 100%;
        max-width: unset;
      }
    }

    &.image-row {
      flex-grow: 1;
      background-color: $blue-01;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: left top;
      padding: 95px 0 0 30px;
      @include xl-1680 {
        padding: 90px 0 0 30px;
        background-size: unset;
      }
      @include xl {
        padding: 90px 0 0 10px;
      }
      @include lg {
        display: none;
      }

    }
  }
}
</style>
