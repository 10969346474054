<template>
  <div class="livestream-ticker" :class="{ 'is-clickable': hasLink }" @click="onClick">
    <div class="livestream-ticker__inner">
      <span
        v-html="`+++ ${transformLive(text, true)} `"
        v-for="index in 25"
        :key="index"
      />
      +++
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'LIVE Stream <b>Ticker Text</b>',
    },
    link: {
      type: Object,
      required: false,
    },
  },
  computed: {
    hasLink() {
      return !!this.link;
    },
    isInternalLink() {
      return !!this.link && this.link.type === 'internal_link';
    },
    isExternalLink() {
      return !!this.link && this.link.type === 'external_link';
    },
    isExternalYoutubeLink() {
      return this.isExternalLink && this.isYoutubeLink(this.link.value);
    },
  },
  methods: {
    onClick() {
      if (this.hasLink) {
        const { value: link } = this.link;
        if (this.isInternalLink) {
          this.$router.push(link);
        } else if (this.isExternalLink && !this.isExternalYoutubeLink) {
          window.open(link, '_blank');
        } else if (this.isExternalYoutubeLink) {
          this.openYoutubeInNewWindow(link);
        }
      }
    },
  },
};
</script>

<!--
  Since the text comes from the CMS wrapped in a <p>
  we cannot use scoped here as it would not apply
  any styles to the text inside the spans
-->
<style lang="scss">

.livestream-ticker {
  width: 100%;
  height: 40px;
  color: $white;
  font-size: 16px;
  font-family: 'OnAirLight';
  background-color: $blue-02;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;

  @include lg {
    top: 64px;
  }

  &.is-clickable {
    cursor: pointer;
  }

  &__inner {
    @include marquee-text;
    will-change: transform;
    animation: marquee 100s linear infinite;

    & > span {
      top: 0px;
      margin: 9px 3px;
      position: relative;
      display: inline-block;
      & > p {
        display: inline-block;
        & > .live::before {
          margin-left: 3px;
        }
      }
    }
  }

  .live {
    font-weight: bold;
  }
}
body.open-nav .livestream-ticker {
  display: none;
}
</style>
