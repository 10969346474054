import { render, staticRenderFns } from "./TickerModule.vue?vue&type=template&id=286d0cdd&scoped=true&"
import script from "./TickerModule.vue?vue&type=script&lang=js&"
export * from "./TickerModule.vue?vue&type=script&lang=js&"
import style0 from "./TickerModule.vue?vue&type=style&index=0&id=286d0cdd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286d0cdd",
  null
  
)

export default component.exports