<template>
  <div class="video">
    <video
      ref="videoElement"
      :src="`${videoLink}#t=0.1`"
      :poster="videoImage"
      loop
      preload="auto"
      playsinline
      @click="onClickVideo()"
    />
    <div
      v-if="!isPlaying"
      class="play-button"
      @click="onPlay()"
    />
    <div
      v-if="isPlaying"
      class="mute-button"
      :class="[ !isMuted ? 'active-mute' : '' ]"
      @click="onMute()"
    />
  </div>
</template>
<script>
export default {
  name: 'SocialMediaWallItemVideo',
  props: {
    videoLink: {
      type: String,
      required: '',
    },
    videoImage: {
      type: String,
      default: '',
    },
    videoId: {
      type: String,
      default: '',
    },
    activeVideo: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isPlaying: false,
    isMuted: false,
  }),
  methods: {
    onPlay() {
      this.$root.$emit('onTriggerPlayVideo', this.videoId);
    },
    onClickVideo() {
      if (!this.isPlaying) return;
      this.$root.$emit('onTriggerPlayVideo', '-1');
    },
    onMute() {
      this.isMuted = !this.isMuted;
      if (this.isMuted) {
        this.$refs.videoElement.muted = true;
      } else {
        this.$refs.videoElement.muted = false;
      }
    },
  },
  watch: {
    activeVideo(val) {
      if (val === this.videoId) {
        this.$nextTick(() => {
          this.$refs.videoElement.play();
          this.isPlaying = true;
        });
      } else {
        this.$refs.videoElement.pause();
        this.isPlaying = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .play-button {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/icons/social-wall/play.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60px;
  }
  .mute-button {
    position: absolute;
    margin: auto;
    padding: 15px;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-image: url('~@/assets/icons/social-wall/sound_off.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;

    &.active-mute {
      background-image: url('~@/assets/icons/social-wall/sound_on.svg');
    }
  }
</style>
