<template>
  <div class="" v-if="canShare">
    <img
      src="@/assets/icons/share.svg"
      v-if="canShare && type === 'white'"
      @click.stop="buttonClicked"
      :style="{ width: width, height: height, cursor: 'pointer' }"
    />
    <img
      src="@/assets/icons/share-blue.svg"
      v-if="canShare && type === 'blue'"
      @click.stop="buttonClicked"
      :style="{ width: width, height: height, cursor: 'pointer' }"
    />
  </div>
</template>

<script>

export default {
  name: 'ShareButton',
  props: {
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    url: {
      type: String,
      required: false,
    },
    width: {
      type: String,
      default: '40px',
    },
    height: {
      type: String,
      default: '40px',
    },
    type: {
      type: String,
      default: 'white',
    },
  },
  data() {
    return {
      shareData: {},
    };
  },
  methods: {
    buttonClicked() {
      const shareData = this.getShareData();
      if (this.canShare) {
        navigator.share({
          text: shareData.text,
          title: shareData.title,
          url: shareData.url,
        });
      }
    },
    getShareData() {
      this.shareData = {
        title: this.decodeHtmlToText(this.title),
        text: this.decodeHtmlToText(this.text),
        url: this.url,
      };
      return this.shareData;
    },
  },
  computed: {
    canShare() {
      // navigator.share is only available in certain browsers and only on https
      return !!navigator && typeof navigator.share === 'function';
    },
  },
};
</script>

<style lang="scss" scoped></style>
