var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket-shop",attrs:{"data-view":""}},[_c('GridLines'),(_vm.pageData.teaser)?_c('exclusive-tickets-sales',{attrs:{"exclusive-tickets-module":_vm.teaserEvents}}):_vm._e(),_c('div',{staticClass:"filtering-wrapper"},[_c('filtering',{staticClass:"filter-block",attrs:{"filter-list":_vm.filterList,"filterHeader":_vm.pageData.filter_header},on:{"change":_vm.onChangeFiltering}})],1),(
      _vm.content.exclusive_tickets_module.value
      && _vm.content.exclusive_tickets_module.value.events.length
      && !_vm.isItemsFiltered
    )?_c('tickets-on-sales',{attrs:{"on-sale-tickets-module":_vm.content.exclusive_tickets_module}}):_vm._e(),(
      _vm.content.on_sale_tickets_module.value
      && _vm.content.on_sale_tickets_module.value.events.length
      && !_vm.isItemsFiltered
    )?_c('tickets-on-sales',{attrs:{"on-sale-tickets-module":_vm.content.on_sale_tickets_module}}):_vm._e(),(
      _vm.content.past_tickets_module.value
      && _vm.content.past_tickets_module.value.events.length
      && !_vm.isItemsFiltered
    )?_c('tickets-on-sales',{attrs:{"on-sale-tickets-module":_vm.content.past_tickets_module}}):_vm._e(),(_vm.isItemsFiltered)?_c('tickets-on-sales',{attrs:{"on-sale-tickets-module":_vm.filteredData}}):_vm._e(),_c('legal-notification',{attrs:{"pre-footer-description":_vm.pageData.pre_footer_description,"pre-footer-button-text":_vm.pageData.pre_footer_button_text,"pre-footer-button-link":_vm.pageData.pre_footer_button_link}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }