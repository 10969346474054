<template>
  <div class="spotify" v-if="spotify_uri" :class="{ 'is-player': isPlayer }" :style="gridStyles">
    <template v-if="element === 'follow'">
      <iframe
        :src="`https://open.spotify.com/follow/1/?uri=${spotify_uri}&size=detail&theme=light`"
        height="100"
        scrolling="no"
        frameborder="0"
        style="border:none; overflow:hidden;"
        allowtransparency="true"
      ></iframe>
    </template>
    <template v-else>
      <iframe
        :src="`https://open.spotify.com/embed/${embedType}/${spotifyId}`"
        height="380"
        frameborder="0"
        allowtransparency="true"
        allow="encrypted-media"
      ></iframe>
    </template>
  </div>
</template>

<script>
import GridItemMixin from '@/mixins/grid-item-mixin';

export default {
  name: 'Spotify',
  mixins: [GridItemMixin],
  props: {
    element: {
      type: String,
      default: 'follow',
    },
    spotify_uri: {
      type: String,
      default: '',
    },
  },
  computed: {
    embedType() {
      return this.spotify_uri.substring(
        this.spotify_uri.indexOf(':') + 1,
        this.spotify_uri.lastIndexOf(':'),
      );
    },
    isPlayer() {
      return this.element === 'play';
    },
    spotifyId() {
      return this.spotify_uri.substring(this.spotify_uri.lastIndexOf(':') + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.spotify {
  padding: 0 40px;
  margin-bottom: 80px;

  @include sm {
    padding: 0;
  }

  iframe {
    width: 100%;
  }
}
.is-player {
  padding: 0 20px;

  // spotify player is not supported in ie11
  @include ie11 {
    display: none;
  }
}
</style>
