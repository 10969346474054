<template>
  <div class="exclusive-login-alert">
    <p v-html="loginText" />
    <div class="button-wrapper">
      <button-component
        button-type="tertiary"
        background-color="white"
        :label="loginButton"
        @change="login"
      />
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';

const auth = new AuthService();

export default {
  name: 'PresaleLoginAlert',
  props: {
    loginText: {
      type: String,
      default: '',
    },
    loginButton: {
      type: String,
      default: '',
    },
    eventName: {
      type: String,
      default: '',
    },
  },
  methods: {
    login() {
      const countdownTimer = this.$store.state.ticketsCountdown;
      auth.login();
      this.trackActionEvent({
        eventName: 'priorityRegistrationClick',
        eCat: 'Priority - Ticket Presale',
        eAct: 'click - Priority registration',
        eLab: `${this.eventName} | ${countdownTimer}`,
        nonInteraction: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";

.exclusive-login-alert {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: $blue-04;
  padding: 10px 20px 10px 25px;
  color: $white;
  font-family: "OnAirBold";
  font-size: 14px;
  line-height: 22px;
  margin: -20px -25px 15px;

  @include sm {
    margin: -25px -25px 15px;
  }

  p {
    width: 60%;
  }
  .button-wrapper {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
