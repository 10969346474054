<template>
  <div class="presale-login-alert" @click="onLogPriority()">
    <img src="@/assets/icons/notification.svg" />
    <p v-html="pageData.priority_alert" />
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';

const auth = new AuthService();

export default {
  name: 'PresalePriorityAlert',
  methods: {
    onLogPriority() {
      auth.toLogPriority();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";

.presale-login-alert {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: $red;
  margin: -3px -3px 3px -3px;
  padding: 20px 30px;
  color: $white;
  font-family: "OnAirBold";
  font-size: 14px;
  line-height: 22px;

  img {
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }
  &::v-deep {
    p {
      a {
        text-decoration: underline;
      }
    }
  }
}
</style>
