<template>
  <div
    ref="imageTrail"
    class="image-trail"
    @mouseover="imageTrailActive = true"
    @mouseleave="imageTrailActive = false"
    @mousemove="updateMousePos($event)"
  >
    <img
      v-for="(image, $index) in images"
      :key="$index"
      :ref="image.refName"
      :src="image.url"
      class="image"
    />
  </div>
</template>

<script>
import { gsap } from 'gsap';

// inspired by: https://github.com/codrops/ImageTrailEffects/

export default {
  name: 'ImageTrail',
  props: {
    imageList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pseudoAnimation: false,
      forceUpdate: false,
      animation: null,
      imageTrailActive: false,
      imageTrailActive2: false,
      imgPosition: 0, // upcoming image index
      zIndexVal: 1, // upcoming zIndex value
      threshold: 100, // mouse distance required to show the next image
      mousePos: { x: 0, y: 0 }, // current mouse position
      cacheMousePos: { x: 0, y: 0 }, // previous mouse position
      lastMousePos: { x: 0, y: 0 }, // mouse position at the time the last image was shown
    };
  },
  computed: {
    images() {
      const images = [];
      for (let i = 0; i < this.imageList.length; i += 1) {
        const image = this.imageList[i];
        images.push({
          refName: `image-${i}`,
          url: image.download_url,
        });
      }
      return images;
    },
  },
  methods: {
    randomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    renderImageTrail() {
      if (!this.imageTrailActive) {
        return;
      }
      this.threshold = this.forceUpdate ? 150 : 200;
      // cache previous mouse position
      this.cacheMousePos.x = this.lerp(
        (this.cacheMousePos.x || this.mousePos.x), this.mousePos.x, 0.1,
      );
      this.cacheMousePos.y = this.lerp(
        (this.cacheMousePos.y || this.mousePos.y), this.mousePos.y, 0.1,
      );
      // get distance between the current mouse position and the position of the previous image
      const distance = this.getMouseDistance();
      // if the mouse moved more than this.threshold show the next image
      if (distance > this.threshold) {
        this.showNextImage();
        this.zIndexVal += 1;
        this.imgPosition = this.imgPosition < this.images.length - 1 ? this.imgPosition + 1 : 0;
        this.lastMousePos = this.mousePos;
      }

      // check when mousemove stops and all images are inactive (not visible and not animating)
      let isIdle = true;
      for (let i = 0; i < this.images.length; i += 1) {
        const img = this.images[i];
        if (this.isImageActive(img.refName)) {
          isIdle = false;
          break;
        }
      }

      // reset z-index initial value
      if (isIdle && this.zIndexVal !== 1) {
        this.zIndexVal = 1;
      }

      // loop
      this.animation = requestAnimationFrame(() => this.renderImageTrail());
    },
    showNextImage() {
      // show image at position [this.imgPosition]
      const img = this.images[this.imgPosition];
      const imgEl = this.getImageElement(img.refName);
      const containerRect = this.$refs.imageTrail.getBoundingClientRect();

      // kill any tween on the image
      gsap.killTweensOf(imgEl);

      if (this.forceUpdate) {
        gsap.timeline()
          .set(imgEl, { // show the image
            startAt: { opacity: 0, scale: 1 },
            opacity: 0,
            scale: 1,
            zIndex: this.zIndexVal,
            x: this.cacheMousePos.x - (imgEl.width / 2) - containerRect.left,
            y: this.cacheMousePos.y - (imgEl.height / 2) - containerRect.top,
          }, 0)
          .to(imgEl, 1, { // smooth appear
            ease: 'power0.easeIn',
            opacity: 1,
          }, 0)
          .to(imgEl, 1, { // then make it disappear
            ease: 'power1.easeOut',
            opacity: 0,
          }, 0.75)
          .to(imgEl, 1, { // scale down the image
            ease: 'power4.easeOut',
            scale: 0.2,
          }, 0.75);
      } else {
        gsap.timeline()
          .set(imgEl, { // show the image
            startAt: { opacity: 0, scale: 1 },
            opacity: 1,
            scale: 1,
            zIndex: this.zIndexVal,
            x: this.cacheMousePos.x - (imgEl.width / 2) - containerRect.left,
            y: this.cacheMousePos.y - (imgEl.height / 2) - containerRect.top,
          }, 0)
          .to(imgEl, 0.9, { // animate position
            ease: 'expo.easeOut',
            duration: 0.25,
            x: this.mousePos.x - (imgEl.width / 2) - containerRect.left,
            y: this.mousePos.y - (imgEl.height / 2) - containerRect.top,
          }, 0)
          .to(imgEl, 1, { // then make it disappear
            ease: 'power1.easeOut',
            opacity: 0,
          }, 0.75)
          .to(imgEl, 1, { // scale down the image
            ease: 'power4.easeOut',
            scale: 0.2,
          }, 0.75);
      }
    },
    updateMousePos(e) {
      this.forceUpdate = false;
      const x = e.pageX - window.pageXOffset;
      const y = e.pageY - window.pageYOffset;
      this.mousePos = { x, y };
      this.imageTrailActive = window.innerWidth >= 992;
      clearInterval(this.pseudoAnimation);
      this.pseudoAnimation = null;
    },
    getMouseDistance() {
      return Math.hypot(
        (this.lastMousePos.x - this.mousePos.x),
        (this.lastMousePos.y - this.mousePos.y),
      );
    },
    lerp(a, b, n) { // linear interpolation
      return (1 - n) * a + n * b;
    },
    getImageElement(refName) {
      const listOfRefs = this.$refs[refName];
      const imgEl = listOfRefs ? listOfRefs[0] : null;
      return imgEl;
    },
    isImageActive(refName) {
      const imgEl = this.getImageElement(refName);
      if (!imgEl) return false;
      return gsap.isTweening(imgEl) || imgEl.style.opacity !== 0;
    },
    onShowPreview(imageTrailElement) {
      const halfElHeight = imageTrailElement.height * 0.3;
      const elOffsetTop = window.scrollY + imageTrailElement.y + halfElHeight;
      const scrolledPx = window.scrollY + window.window.innerHeight;
      if (scrolledPx > elOffsetTop) {
        if (!this.pseudoAnimation) {
          this.pseudoAnimation = setInterval(() => {
            this.forceUpdate = true;
            this.mousePos = {
              y: this.randomInteger(400, halfElHeight + 400),
              x: this.randomInteger(0, window.innerWidth),
            };
            this.imageTrailActive = window.innerWidth >= 992;
          }, 800);
        }
      } else {
        clearInterval(this.pseudoAnimation);
        this.pseudoAnimation = null;
      }
    },
    onWhell(e) {
      if (!this.$refs.imageTrail) return;
      this.forceUpdate = true;
      const imageTrailElement = this.$refs.imageTrail.getBoundingClientRect();
      const topHeight = imageTrailElement.top + window.scrollY;
      this.onShowPreview(imageTrailElement);
      if (topHeight < e.pageY && e.pageY < topHeight + imageTrailElement.height) {
        this.mousePos = { x: e.pageX, y: e.pageY - topHeight };
        clearInterval(this.pseudoAnimation);
        this.pseudoAnimation = null;
        this.imageTrailActive = window.innerWidth >= 992;
      }
    },
  },
  mounted() {
    document.addEventListener('mousewheel', this.onWhell);
  },
  watch: {
    imageTrailActive: {
      immediate: true,
      handler(imageTrailActive) {
        if (imageTrailActive === true) {
          this.renderImageTrail();
        } else {
          cancelAnimationFrame(this.animation);
        }
      },
    },
  },
  beforeDestroy() {
    this.imageTrailActive = false;
    cancelAnimationFrame(this.animation);
    document.removeEventListener('mousewheel', this.onWhell);
  },
};
</script>

<style lang="scss" scoped>
.image-trail {
  width: 100%;
  height: 100%;
}
.image {
	position: absolute;
	top: 0;
	left: 0;
  max-width: 250px;
  height: auto;
  opacity: 0;
  will-change: transform;
  pointer-events: none;
}
</style>
