export default {
  props: {
    grid_column: {
      type: String,
      default: 'unset',
    },
    grid_row: {
      type: String,
      default: 'unset',
    },
    place_self: {
      type: String,
      default: 'unset',
    },
  },
  computed: {
    gridStyles() {
      return {
        'grid-column': this.grid_column,
        'grid-row': this.grid_row,
        'place-self': this.place_self,
      };
    },
  },
};
