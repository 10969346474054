<template>
  <div class="generic-form">
    <div class="required-fields">
      <div v-if="formErrors.length > 0" class="errors">
        {{ requiredMissingText }}
      </div>
      * {{ requiredFieldsText }}
    </div>

    <form novalidate="novalidate">
      <div v-for="field in formFields" :key="field.name" class="generic-form-item">
        <text-input
          v-if="includes(textInputTypes, field.type)"
          :field="field"
          :validate="validateField"
          :error="includes(formErrors, field.name)"
          v-model="formData[field.name]" />
        <date-input
          v-else-if="field.type === 'date'"
          :field="field"
          :validate="validateField"
          :error="includes(formErrors, field.name)"
          v-model="formData[field.name]" />
        <select-menu
          v-else-if="field.type === 'select'"
          :field="field"
          :validate="validateField"
          :error="includes(formErrors, field.name)"
          v-model="formData[field.name]" />
        <checkbox
          v-else-if="field.type === 'checkbox'"
          :field="field"
          :error="includes(formErrors, field.name)"
          v-model="formData[field.name]" />
        <radio-group
          v-else-if="field.type === 'radio'"
          :field="field"
          :validate="validateField"
          :error="includes(formErrors, field.name)"
          v-model="formData[field.name]" />
      </div>
    </form>
    <div>
      <span @click="submitForm"><button-component :label="buttonText" /></span>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import RadioGroup from '@/components/form/RadioGroup.vue';
import SelectMenu from '@/components/form/SelectMenu.vue';

const TEXT_INPUT_TYPES = ['text', 'email', 'number', 'tel'];

export default {
  name: 'Form',
  props: {
    formFields: {
      type: Array,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    requiredMissingText: {
      type: String,
      required: true,
    },
    requiredFieldsText: {
      type: String,
      required: true,
    },
    onSubmitForm: {
      type: Function,
      required: true,
    },
    enableDataLayerEvent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextInput,
    DateInput,
    Checkbox,
    RadioGroup,
    SelectMenu,
  },
  data: () => ({
    textInputTypes: TEXT_INPUT_TYPES,
    formData: {},
    formErrors: [],
  }),
  methods: {
    includes(items, item) {
      return items.indexOf(item) >= 0;
    },
    addError(name) {
      this.formErrors = [...this.formErrors, name];
    },
    removeError(name) {
      this.formErrors = this.formErrors.filter((item) => item !== name);
    },
    isValidDate(field, value) {
      field.show_validation_error = false;
      if (field.minimum_age) {
        const birthDate = new Date(value);
        if (Number.isNaN(birthDate.getDate())) {
          return false;
        }
        const ageDifMs = Date.now() - birthDate;
        const ageDate = new Date(ageDifMs);
        if (Math.abs(ageDate.getUTCFullYear() - 1970) >= field.minimum_age) {
          return true;
        }
        field.show_validation_error = true;
        return false;
      }
      return !!value;
    },
    isValidEmail(value) {
      // eslint-disable-next-line no-useless-escape
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return emailRegex.test(value);
    },
    isValid(field, value) {
      switch (field.type) {
        case 'email':
          return this.isValidEmail(value);
        case 'date':
          return this.isValidDate(field, value);
        default:
          return !!value;
      }
    },
    validateField(field, value) {
      if (field.required && !this.isValid(field, value)) this.addError(field.name);
      else this.removeError(field.name);
    },
    validateForm() {
      this.formFields.forEach((field) => this.validateField(field, this.formData[field.name]));
    },
    submitForm() {
      this.validateForm();
      if (this.formErrors.length === 0) this.onSubmitForm(this.formData);
      else this.scrollToTop();
      if (this.formErrors.length) this.triggerDataLayerEvent(`failure: ${this.requiredMissingText}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";
.generic-form {
  position: relative;
  width: 100%;
  .required-fields {
    font-family: "OnAirRoman";
    font-size: 12px;
    line-height: 1.67;
    color: $blue-01;
    text-align: right;
    height: 40px;
    margin-top: 30px;
  }
  .errors {
    color: $red;
    font-size: 12px;
    font-style: italic;
    line-height: 1.33;
    font-family: "OnAirLight";
    font-style: italic;
    text-align: left;
  }
  .generic-form-item {
    width: 100%;
    display: block;
    min-height: 20px;
    margin: 35px 0;
  }
  padding-bottom: 100px;
}
</style>
