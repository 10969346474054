import { UserManager, WebStorageStateStore } from 'oidc-client';

// inspired by https://www.jerriepelser.com/blog/using-auth0-with-vue-oidc-client-js/
// and https://www.scottbrady91.com/Angular/Migrating-oidc-client-js-to-use-the-OpenID-Connect-Authorization-Code-Flow-and-PKCE

const AUTHORITY = 'https://dev-6i025b3n.eu.auth0.com/';
const CLIENT_ID = 'A5pk9pJenDvpVqZjzX3is7G8ml6VFz72';
const O2_CONNECTION_NAME = 'o2-prod'; // Name of the entreprise connection for o2's OIDC
const domain = `${window.location.protocol}//${window.location.hostname}`;
const port = window.location.port ? `:${window.location.port}` : '';
const REDIRECT_URL = `${domain}${port}/callback.html`;
const CUSTOM_CLAIM_NAMESPACE = 'https://music.o2online.de';

export default class AuthService {
  constructor() {
    const settings = {
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      authority: AUTHORITY,
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URL,
      response_type: 'code',
      scope: 'openid o2_music',
      post_logout_redirect_uri: REDIRECT_URL,
      filterProtocolClaims: true,
      automaticSilentRenew: true,
      extraQueryParams: {
        connection: O2_CONNECTION_NAME,
      },
    };
    this.priorityLink = 'https://www.o2online.de/priority/app/presale-registrierung';
    this.userManager = new UserManager(settings);
  }

  login() {
    return this.userManager.signinRedirect({ state: window.location.href });
  }

  logout() {
    return this.userManager.signoutRedirect();
  }

  getUser() {
    return this.userManager.getUser();
  }

  async signinSilent() {
    return this.userManager.signinSilent({ redirect_uri: `${REDIRECT_URL}?renew=true` });
  }

  toLogPriority() {
    if (window.location.href.indexOf('?priority-alert=true') > -1) {
      window.location.href = `${this.priorityLink}?target=${encodeURIComponent(`${domain}${port}/signin.html?target=${window.location.href}`)}`;
    } else {
      window.location.href = `${this.priorityLink}?target=${encodeURIComponent(`${domain}${port}/signin.html?target=${window.location.href}?priority-alert=true`)}`;
    }
  }

  async isLoggedIn() {
    const user = await this.getUser();
    return (user !== null && !user.expired);
  }

  async isO2Customer() {
    const user = await this.getUser();
    return (
      user !== null
      && !user.expired
      && user.profile !== null
      && user.profile[`${CUSTOM_CLAIM_NAMESPACE}/isO2Customer`]
    );
  }

  async hasPriorityConditionsAccepted() {
    const user = await this.getUser();
    return (
      user !== null
      && !user.expired
      && user.profile !== null
      && user.profile[`${CUSTOM_CLAIM_NAMESPACE}/hasPriorityConditionsAccepted`]
    );
  }

  async getAccessToken() {
    return this.getUser().then((data) => (data ? data.access_token : null));
  }

  async getIdToken() {
    return this.getUser().then((data) => (data ? data.id_token : null));
  }
}
