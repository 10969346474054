<template>
  <div
    ref="presaleLoginAlert"
    class="presale-login-alert"
    :class="!showItem ? 'presale-login-alert--inactive' :''"
  >
    <div class="presale-login-alert-content" ref="presaleContent">
      <img src="@/assets/icons/o2-signed.svg" />
      <p v-html="authorizedText" />
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';

const auth = new AuthService();

export default {
  name: 'PresaleLoginAlert',
  props: {
    loginSuccessText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showItem: true,
    };
  },
  methods: {
    login() {
      auth.login();
    },
    hideItem() {
      const { presaleLoginAlert, presaleContent } = this.$refs;
      const height = presaleContent.offsetHeight;
      this.$nextTick(() => {
        presaleLoginAlert.style.height = `${height}px`;
        presaleContent.classList.add('presale-login-alert-content--active');
      });
      setTimeout(() => {
        this.showItem = false;
      }, 6000);
    },
  },
  computed: {
    authorizedText() {
      return this.loginSuccessText || this.pageData.logged_alert || 'Logged';
    },
  },
  mounted() {
    this.hideItem();
  },
};
</script>

<style lang="scss" scoped>
.presale-login-alert {
  position: relative;
  transition: height .7s;
  display: flex;
  align-items: center;
  background-color: #91c90e;
  margin: -3px -3px 3px -3px;
  color: $white;
  font-family: "OnAirBold";
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
  flex: 0 0 auto;

  img {
    width: 30px;
    height: 20px;
    margin-right: 20px;
  }
}
.presale-login-alert-content {
  display: flex;
  align-items: center;
  padding: 16px 22px;

  ::v-deep {
    a {
      text-decoration: underline;
    }
  }

  &.presale-login-alert-content--active {
    position: absolute;
    bottom: 0;
  }
}
.presale-login-alert--inactive {
  height: 0 !important;
}
</style>
