<template>
  <div class="ticket-alarm-overlay">
    <div class="header" :style="{ 'background-image': `url(${headerImageUrl})` }">
      <PriorityLogo width="200px" />
    </div>
    <img src="@/assets/icons/close-btn.svg" @click.prevent="closeOverlay" class="close-button" />
    <div class="content">
      <h2 class="headline" v-html="headline" />
      <p class="copy" v-html="copy" />
      <GenericForm
        v-if="formIsNew"
        :formFields="formFields"
        :onSubmitForm="onSubmitForm"
        :buttonText="submitButtonText"
        :requiredMissingText="'Bitte alle Felder korrekt ausfüllen.'"
        :requiredFieldsText="'Pflichtfelder'"
        :enable-data-layer-event="true"
      />
      <span v-if="formSubmitSuccess" @click="closeOverlay">
        <button-component label="OK" class="form-button" />
      </span>
      <span v-if="formSubmitError" @click="resetForm">
        <button-component :label="ticketAlarmButtonError" class="form-button" />
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import GenericForm from '@/components/form/GenericForm.vue';
import PriorityLogo from '@/components/misc/PriorityLogo.vue';

const STATES = {
  new: 'new',
  success: 'success',
  serverError: 'serverError',
};

export default {
  name: 'TicketAlarmOverlay',
  components: {
    GenericForm,
    PriorityLogo,
  },
  data() {
    return {
      state: STATES.new,
      ticketAlarmError: null,
      headline2: 'O<sub>2</sub>',
    };
  },
  computed: {
    formIsNew() {
      return this.state === STATES.new;
    },
    formSubmitSuccess() {
      return this.state === STATES.success;
    },
    formSubmitError() {
      return this.state === STATES.serverError;
    },
    ticketAlarm() {
      return this.$store.state.siteSettings.ticketAlarm;
    },
    headerImageUrl() {
      return this.getResponsiveImage(this.ticketAlarm.headerImage);
    },
    ticketAlarmButtonError() {
      return this.ticketAlarm.errorButtonText;
    },
    headline() {
      if (this.formSubmitSuccess) {
        return this.ticketAlarm.successHeadline || '';
      }
      if (this.formSubmitError) {
        return this.ticketAlarm.errorHeadline || '';
      }
      return this.ticketAlarm.headline || '';
    },
    copy() {
      if (this.formSubmitSuccess) {
        return this.ticketAlarm.successCopy || '';
      }
      if (this.formSubmitError) {
        return this.ticketAlarmError || this.ticketAlarm.errorCopy;
      }
      return this.ticketAlarm.copy || '';
    },
    formFields() {
      return this.ticketAlarm.formFields || [];
    },
    submitButtonText() {
      return this.ticketAlarm.submitButtonText || 'Jetzt anmelden!';
    },
  },
  methods: {
    ...mapActions(['sendTicketAlarmForm']),
    closeOverlay() {
      this.$router.replace({ hash: '' }).catch(() => {});
    },
    resetForm() {
      this.state = STATES.new;
    },
    onSubmitForm(formData) {
      this.sendTicketAlarmForm({
        data: formData,
        alarmType: 'ticketalarm',
      }).then((response) => {
        if (response.status === 200) {
          this.state = STATES.success;
          this.triggerDataLayerEvent('success');
        } else {
          this.ticketAlarmError = response.data.detail || `${response.status} error`;
          this.triggerDataLayerEvent(`failure: ${this.ticketAlarmError}`);
          this.state = STATES.serverError;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-alarm-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  width: 25%;
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: auto;
  color: black;
  background-color: $white;
  border-left: 1px solid $very-light-grey;

  @include lg {
    width: 50%;
  }

  @include sm {
    width: 100%;
    border-left: 0;
    height: 100%;
    padding-bottom: 150px;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 240px;
  background-size: cover;
}
.close-button {
  cursor: pointer;
  width: 46px;
  height: 46px;
  margin-top: -23px;
  margin-left: 20px;
}
.content {
  padding: 20px;

  ::v-deep .required-fields {
    margin-top: 0;
    height: auto;
  }

  ::v-deep .errors {
    margin-top: 10px;
  }

  ::v-deep .generic-form {
    padding-bottom: 0;
  }

  ::v-deep form .generic-form-item:first-child {
    margin-top: 0;
  }
}
.headline {
  font-weight: normal;
  font-family: "OnAirBold";
  font-size: 26px;
  line-height: 30px;
  color: $blue-01;
}
.copy {
  font-family: "OnAirLight";
  font-size: 14px;
  line-height: 22px;
  color: $blue-01;
}
.form-button {
  margin-top: 20px;
}
</style>
