<template>
  <swiper :options="swiperOption" class="crawler-swiper">
    <swiper-slide class="crawler-swiper-slide" v-for="slide in slides" :key="slide.id">
      <div class="crawler-item-wrapper">
        <div class="crawler-item-images">
          <img class="horizontal-img" src="@/assets/images/presale-teaser/priority-tickets-blue-horizontal@3x.png" alt="priority-tickets">
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
export default {
  name: 'PresaleTeaserCrawlerHorizontal',
  props: {
    type: {
      type: String,
      default: 'blue',
    },
  },
  data: () => ({
    slides: Array(7).fill('').map((el, i) => ({ id: i })),
    swiperOption: {
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      speed: 13000,
      simulateTouch: false,
      simulateSwipe: false,
      loop: true,
      allowTouchMove: false,
      slidesPerView: 3,
      direction: 'horizontal',
    },
  }),
};
</script>
<style lang="scss" scoped>
  .crawler-swiper::v-deep {
    height: 63px;
    width: 560px;

    .swiper-wrapper {
      transition-timing-function: linear !important;
    }
  }
  .crawler-item-images {
    height: 63px;
    img {
      display: flex;
      height: 100%;
    }
  }
</style>
