<template>
  <div>
    <div v-if="showSpacer" class="spacer" />
    <div v-if="showTopLine" class="line" />
    <div class="faq-item">
      <h2 v-custom-html="headline" />
      <div
        class="copy"
        v-custom-html="text"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'FaqItem',
  props: {
    headline: {
      type: String,
    },
    text: {
      type: String,
    },
    showSpacer: {
      type: Boolean,
      default: false,
    },
    showTopLine: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  border-top: 1px solid $very-light-grey;
  height: 1px;
}
.spacer {
  height: 60px;
  @include xl-1440 {
    height: 50px;
  }
  @include lg {
    height: 35px;
  }
}
.copy ::v-deep p {
  font-family: "OnAirLight";
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6;
  padding-top: 10px;
  @include lg {
    font-size: 14px;
    line-height: 1.57;
  }
}
.copy ::v-deep a {
  color: $blue-01;
  text-decoration: underline;
}
.faq-item {
  color: $blue-01;
  padding: 60px 40px;
  border-bottom: 1px solid $very-light-grey;
  @include xl-1440 {
    padding: 50px 30px;
  }
  @include lg {
    padding: 50px 50px;
  }
  @include sm {
    background: $white;
    padding: 50px 40px;
  }

  h2 {
    font-family: "OnAirBold";
    font-size: 26px;
    line-height: 1.23;
    font-weight: normal;
    margin: 0;
    padding: 0;
    @include lg {
      font-size: 20px;
      line-height: 1.4;
    }
  }
}

</style>
