<template>
  <h1 class="event-logo">
    <img class="image" v-if="eventLogoUrl" :src="eventLogoUrl" />
    <span
      class="event-name"
      :class="{ hidden: !!eventLogoUrl }"
      v-html="title"
    />
  </h1>
</template>

<script>
export default {
  name: 'EventLogo',
  props: {
    title: {
      type: String,
    },
    eventLogo: {
      type: Object,
      required: false,
    },
  },
  computed: {
    eventLogoUrl() {
      return this.getResponsiveImage(this.eventLogo);
    },
    maxFontSize() {
      if (this.isMobile) {
        return '48px';
      }
      if (this.isTablet) {
        return '62px';
      }
      return '80px';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/base.scss";

.event-logo {
  text-align: center;
  width: 100%;
}
.image {
  max-width: 100%;
}
.event-name {
  font-family: 'OnAirBold';
  font-weight: normal;
  @include linear-font-size(36px, 65px, $screen-320-sm-min);
  line-height: 1;

  &.hidden {
    display: none;
  }
}
</style>
