<template>
  <div class="artist" :class="{'artist-highlight': artist.sale_highlight}">
    <div
      v-if="artist.tickets_available === false && ticketType === 'exclusive_tickets_module'"
      class="artist-ticket-soldout"
      v-html="'+ Im Vor-Vorverkauf vergriffen + Im Vor-Vorverkauf vergriffen'"
    />
    <router-link
      v-if="ticketType !== 'past_tickets_module'"
      :to="artist.event_serie.journeypages ? artist.event_serie.journeypages.url : ''"
      rel="follow"
    >
      <img
        v-if="!artist.event_serie.image"
        src="@/assets/images/no-image.jpg"
        alt="no-image"
      >
      <img
        v-else
        :src="getResponsiveImage(artist.event_serie.image)"
        :alt="artist.event_name"
      >
    </router-link>
    <div v-else>
      <img
        v-if="!artist.event_serie.image"
        src="@/assets/images/no-image.jpg"
        alt="no-image"
      >
      <img
        v-else
        :src="getResponsiveImage(artist.event_serie.image)"
        :alt="artist.event_name"
      >
    </div>
    <div class="artist-name" :title="artist.event_serie.es_name">
      {{ getArtistName(artist.event_serie.artists) }}
    </div>
    <div class="artist-description">
      {{ artist.event_name }}
    </div>
    <div
      v-if="ticketType !== 'past_tickets_module'"
      class="artist-button"
    >
      <button-component
        :button-type="isMobile ? 'secondary' : 'tertiary'"
        :background-color="artist.sale_highlight ? 'white' : !artist.journey_highlight ? 'blue' : 'white'"
        :label="artist.journey_text"
        :button-link="{
          internal: true,
          to: artist.event_serie.journeypages ? artist.event_serie.journeypages.url : ''
        }"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'artist',
  props: {
    artist: {
      type: Object,
      required: true,
    },
    ticketType: {
      type: String,
      default: '',
    },
  },
  methods: {
    getArtistName(value) {
      if (value && value.length) {
        return `${value[0]}`;
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
  .artist {
    width: calc(100% - 24px);
    position: relative;
    height: 100%;
    margin-bottom: 40px;
    padding: 12px;

    img {
      width: 100%;
    }
    &-name {
      font-family: "OnAirBold";
      line-height: 1.28;
      color: $blue-01;
      padding-top: 8px;
      font-size: 16px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-description {
      font-family: "OnAirRoman";
      line-height: 1.44;
      color: $blue-01;
      padding-top: 12px;
      font-size: 10px;
    }
    &-button {
      position: absolute;
      bottom: 0;

      ::v-deep {
        .white-theme {
          border: solid 1px $blue-01;
        }
      }
    }
    &.artist-highlight {
      background-color: $blue-02;

      .artist-name, .artist-description {
        color: $white;
      }

      .artist-button {
        bottom: 12px;
      }
    }
  }
  .artist-ticket-soldout {
    position: absolute;
    top: -18px;
    left: 0;
    right: 0;
    background-color: $red;
    height: 18px;
    line-height: 18px;
    color: $white;
    font-family: 'OnAirRoman';
    font-size: 12px;
    overflow: hidden;
    white-space: pre;
  }
  @include sm {
    .artist {
      margin-bottom: 0;
      width: 275px;
      max-width: 100%;
      margin: 0 auto;
    }
    .artist-name {
      padding-top: 15px;
      font-size: 20px;
      padding-top: 17px;
      margin-left: -2px;
    }
    .artist-description {
      font-size: 12px;
      line-height: 1.5;
      padding-top: 10px;
      margin-left: -2px;
    }
    .artist-button {
      position: relative;
      padding-top: 20px;
      margin-left: -1px;
    }
  }
</style>
