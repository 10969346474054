<template>
  <div data-view class="mediathek-wrapper">
    <GridLines :classList="{'disable-sm': true}" />
    <multi-video-overlay
      v-if="showYoutubePlayer"
      :selectedItem="selectedYoutubeItem"
      :prevItem="prevYoutubeItem"
      :nextItem="nextYoutubeItem"
      :onChange="openPlayer"
      :onClose="closePlayer"
      :on-open-video="openVideoInNewTab"
    />
    <presale-teaser
      v-if="hasTakeoverSlider"
      :data="pageData.takeover_content"
    />
    <!-- TODO: clarify what should happen with this one -->
    <intro-video
      v-if="
        !hasTakeoverSlider && pageData.media_teaser && pageData.media_teaser.media_content.length
      "
      :teaser-data="pageData.media_teaser"
      :media-content="pageData.media_teaser.media_content[0]"
      :on-open-video="openVideoInNewTab"
    />
    <div
      v-if="!hasTakeoverSlider && pageData.media_teaser"
      class="intro full"
      :class="{
        'active-intro-video': pageData.media_teaser && pageData.media_teaser.media_content.length
      }"
    >
      <teaser-priority
        :class="{
          'active-intro-video': pageData.media_teaser && pageData.media_teaser.media_content.length
        }"
        :image="getResponsiveImage(pageData.media_teaser.image)"
        :backgroundImage="getResponsiveImage(pageData.media_teaser.background)"
        :backgroundImageLandscape="getResponsiveImage(pageData.media_teaser.background_landscape)"
        :title="pageData.media_teaser.title"
        :subtitle="pageData.media_teaser.subtitle"
        :description="pageData.media_teaser.description"
      />
    </div>
    <!-- TODO: till here -->
    <media-list-settings
      class="media-list-settings-desktop-version"
      :class="{ 'media-list-settings-default':
        pageData.media_teaser && !pageData.media_teaser.media_content.length }"
      :sort-data="sortData"
      :on-filter-data="onFilterData"
      :filter-items="filterItems"
    />
    <masonry
      :cols="{default: 4, 992: 2, 576: 1}"
      class="mediathek"
      v-if="!showYoutubePlayer"
      >
      <div
        v-if="!hasTakeoverSlider  && showMansoryTeaser()"
        class="intro main"
        :class="{ 'active-intro-video': pageData.media_teaser.media_content.length }"
      >
        <teaser-priority
          :image="getResponsiveImage(pageData.media_teaser.image)"
          :backgroundImage="getResponsiveImage(pageData.media_teaser.background)"
          :backgroundImageLandscape="getResponsiveImage(pageData.media_teaser.background_landscape)"
          :title="pageData.media_teaser.title"
          :subtitle="pageData.media_teaser.subtitle"
          :description="pageData.media_teaser.description"
          :special-media-content="pageData.media_teaser.media_content[0] || null"
          :on-open-video="openVideoInNewTab"
        />
      </div>
      <media-list-settings
        v-if="isMobile"
        class="media-list-settings-mobile-version"
        :sort-data="sortData"
        :on-filter-data="onFilterData"
        :filter-items="filterItems"
      />
      <div
        v-for="(item, index) in mediaItems"
        :key="index"
        class="mediathek-item"
        >
        <media-module-item
          :show-spacer="isTablet ? index === 1 : [0,2].includes(index)"
          @open-player="openPlayer"
          v-bind="item"
        />
      </div>
    </masonry>
  </div>
</template>

<script>
import TeaserPriority from '@/components/teaser/TeaserPriority.vue';
import GridLines from '@/components/misc/GridLines.vue';
import MultiVideoOverlay from '@/components/player/MultiVideoOverlay.vue';
import PresaleTeaser from '@/views/common/components/PresaleTeaser/presale-teaser.vue';
import MediaModuleItem from '@/views/common/components/media/MediaModuleItem.vue';
import IntroVideo from './components/IntroVideo.vue';
import MediaListSettings from './components/MediaListSettings.vue';

export default {
  name: 'Mediathek',
  components: {
    TeaserPriority,
    GridLines,
    MultiVideoOverlay,
    IntroVideo,
    MediaListSettings,
    PresaleTeaser,
    MediaModuleItem,
  },
  data: () => ({
    showYoutubePlayer: false,
    selectedYoutubeItem: null,
    nextYoutubeItem: null,
    prevYoutubeItem: null,
    mediaItems: [],
    sortingData: {
      key: 'new',
    },
    filteringData: {},
  }),
  computed: {
    defaultMediaItems() {
      return this.pageData.media_content.content.map((el) => this.applyFilterKeysToMediaItem(el));
    },
    filterItems() {
      return this.buildFilterData(this.defaultMediaItems);
    },
    hasTakeoverSlider() {
      return this.pageData.takeover_content && this.pageData.takeover_content.length;
    },
  },
  methods: {
    showMansoryTeaser() {
      if (this.pageData.media_teaser) {
        if (!this.pageData.media_teaser.media_content.length) {
          return true;
        }
        return this.isMobile;
      }
      return false;
    },
    getDate(date) {
      if (!date) return '';
      return new Date(date).toLocaleDateString('de');
    },
    applySettings() {
      const additionalItems = {};
      const mediaArray = this.defaultMediaItems.reduce((acc, cur, index) => {
        cur.index = index;
        if (cur.type === 'internal_navigation_block' || cur.type === 'newsletter_alarm_block') {
          additionalItems[cur.type] = cur;
          return acc;
        }
        acc.push(cur);
        return acc;
      }, []);

      if (this.sortingData.key === 'asc') {
        mediaArray.sort(this.sortByAsc);
      } else {
        mediaArray.sort(this.sortByDate);
      }

      const filteredMediaArray = this.filterMediaArray(mediaArray);

      Object.keys(additionalItems).map((key) => {
        const elem = additionalItems[key];
        filteredMediaArray.splice(elem.index, 0, elem);
        return key;
      });
      this.mediaItems = [...filteredMediaArray];
    },
    sortByAsc(a, b) {
      const regex = /(<([^>]+)>)/ig;
      const aHeader = a.value.header.replace(regex, '').toLowerCase();
      const bHeader = b.value.header.replace(regex, '').toLowerCase();
      if (aHeader > bHeader) return 1;
      if (aHeader < bHeader) return -1;
      return 0;
    },
    sortByDate(a, b) {
      const aDate = new Date(a.value.upload_at).valueOf();
      const bDate = new Date(b.value.upload_at).valueOf();
      if (aDate > bDate) return -1;
      if (aDate < bDate) return 1;
      return 0;
    },
    applyFilterKeysToMediaItem(mediaItem) {
      if (mediaItem.type !== 'youtube_video_ext') return { filterKeys: '', ...mediaItem };
      const { Types: categoryTypes } = mediaItem.value.categories;
      const filterKeys = [];
      Object.entries(categoryTypes).forEach(([categoryType, categories]) => {
        categories.forEach((category) => {
          filterKeys.push(`${categoryType}#${category}`);
        });
      });
      return { filterKeys, ...mediaItem };
    },
    filterMediaArray(sortedMediaArray) {
      const filteredKeys = this.getActiveFilterKeys();
      if (filteredKeys.length === 0) return [...sortedMediaArray];
      return sortedMediaArray.filter((mediaItem) => {
        let hasKeys = true;
        filteredKeys.forEach((filterKey) => {
          if (!mediaItem.filterKeys.includes(filterKey)) hasKeys = false;
        });
        return hasKeys;
      });
    },
    getAllFilterKeys() {
      return Object.keys(this.filteringData);
    },
    isActiveFilterKey(filterKey) {
      return !!this.filteringData[filterKey].value;
    },
    getActiveFilterKeys() {
      const allFilterKeys = this.getAllFilterKeys();
      return allFilterKeys.filter((filterKey) => this.isActiveFilterKey(filterKey));
    },
    countMediaItemsByFilterKey(filterKey) {
      return this.mediaItems.filter((item) => item.filterKeys.includes(filterKey)).length;
    },
    hasFilterKey(filterKey, filterData) {
      return filterData.find((el) => el.key === filterKey);
    },
    buildFilterData(mediaItems) {
      const filterData = [];
      const videoItems = mediaItems.filter((item) => item.type === 'youtube_video_ext');
      videoItems.forEach((mediaItem) => {
        mediaItem.filterKeys.forEach((key) => {
          if (!this.hasFilterKey(key, filterData)) {
            const [group, text] = key.split('#');
            const count = this.countMediaItemsByFilterKey(key);
            const value = this.filteringData[key]
              ? this.filteringData[key].value : false;
            filterData.push({
              text, key, count, value, group,
            });
          }
        });
      });
      return filterData;
    },
    onFilterData(data) {
      this.filteringData = {};
      data.map((el) => {
        this.filteringData[el.key] = {
          value: el.value,
        };
        return el;
      });
      this.applySettings();
    },
    sortData(data) {
      this.sortingData = data;
      this.applySettings();
    },
    getYoutubeItemIndex(videoSlug) {
      return this.mediaItems.findIndex((item) => (
        ((item.type === 'youtube_video_ext' || item.type === 'local_video_ext')
          && item.value.title_slug === videoSlug)
      ));
    },
    getVideoItem(index) {
      return typeof this.mediaItems[index] !== 'undefined' ? this.mediaItems[index] : null;
    },
    getNextVideoItem(currentIndex) {
      return this.mediaItems.find(
        (item, index) => index > currentIndex
          && (item.type === 'youtube_video_ext' || item.type === 'local_video_ext'),
      );
    },
    getPrevVideoItem(currentIndex) {
      const prevItem = this.mediaItems.filter(
        (item, index) => index < currentIndex
          && (item.type === 'youtube_video_ext' || item.type === 'local_video_ext'),
      );
      return prevItem.length > 0 ? prevItem[prevItem.length - 1] : null;
    },
    openVideoInNewTab(item) {
      const link = `https://www.youtube.com/watch?v=${item.value.youtube_id}`;
      this.openYoutubeInNewWindow(link);
    },
    openDefaultPlayer(item) {
      this.selectedYoutubeItem = item;
      this.showYoutubePlayer = true;
      this.$router.replace({
        params: {
          videoSlug: item.value.title_slug,
        },
      }).catch(() => {});
    },
    openPlayer(videoSlug) {
      if (!videoSlug) return;
      const selectedIndex = this.getYoutubeItemIndex(videoSlug);
      if (selectedIndex < 0) {
        const { media_teaser } = this.pageData;
        if (media_teaser && media_teaser.media_content.length) {
          this.selectedYoutubeItem = media_teaser.media_content[0];
          this.showYoutubePlayer = this.selectedYoutubeItem.value.title_slug === videoSlug;
        }
        return;
      }
      this.selectedYoutubeItem = this.getVideoItem(selectedIndex);
      this.nextYoutubeItem = this.getNextVideoItem(selectedIndex);
      this.prevYoutubeItem = this.getPrevVideoItem(selectedIndex);
      this.showYoutubePlayer = true;
      this.$router.replace({
        params: {
          videoSlug,
        },
      }).catch(() => {});
    },
    closePlayer() {
      this.showYoutubePlayer = false;
      this.selectedYoutubeItem = null;
      this.nextYoutubeItem = null;
      this.prevYoutubeItem = null;
      this.$router.replace({
        params: {
          videoSlug: undefined,
        },
      }).catch(() => {});
    },
  },
  mounted() {
    this.applySettings();
    const params = { ...this.$route.params };
    this.openPlayer(params.videoSlug);
  },
};
</script>

<style lang="scss" scoped>
.active-intro-video,
.media-list-settings-mobile-version {
  display: none;
}
.mediathek-wrapper {
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
}
.intro {
  background-color: $blue-02;
  ::v-deep .teaser-wrapper {
    margin-top: -95px;
    @include lg {
      margin-top: 0;
    }
  }

  &.full {
    display: none;
    @include lg {
      display: block;
    }
  }
  &.main {
    @include lg {
      display: none;
    }
  }
}
.media-list-settings-default {
  margin-left: 25%;
  width: 100%;
  @include lg {
    margin-left: 0;
    width: auto;
  }
}
@include sm {
  .media-list-settings-desktop-version {
    display: none;
  }
  .media-list-settings-mobile-version {
    display: flex;
  }
}

.mediathek {
  @include lg {
    padding: 0 31px;
    width: calc(100%-62px);
  }
  @include sm {
    padding: unset;
    width: unset;
    .active-intro-video {
      display: block;
    }
  }

  .mediathek-item {
    height: auto;

    @include sm {
      margin: 0 10px;
    }
  }
}

</style>
