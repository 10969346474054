<template>
  <ul class="social-media-link-list">
    <li
      v-for="socialMediaLink in socialMediaLinks"
      :key="socialMediaLink.plattform"
      class="social-media-link"
    >
      <a :href="socialMediaLink.url" target="_blank">
        <template v-if="isGlyphicon(socialMediaLink.plattform)">
          <span class="glyphicon" :class="`glyphicon-${socialMediaLink.plattform}`"></span>
        </template>
        <template v-else>
          <span class="icon" :class="`icon-${socialMediaLink.plattform}`"></span>
        </template>
      </a>
    </li>
  </ul>
</template>

<script>
const SOCIAL_MEDIA_GLYPHICONS = ['facebook', 'twitter', 'youtube', 'instagram'];

export default {
  name: 'SocialMediaLinks',
  props: {
    socialMediaLinks: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isGlyphicon(plattform) {
      return SOCIAL_MEDIA_GLYPHICONS.indexOf(plattform) >= 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.social-media-link-list {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}
.social-media-link {
  margin-right: 35px;
  line-height: 40px;

  &:last-child {
    margin-right: 0;
  }

  .glyphicon {
    font-size: 26px;
  }
  .glyphicon-youtube {
    top: 6px;
  }
  .icon-spotify {
    margin-top: 3px;
  }
  .icon-amazon_music {
    margin-top: 7px;
  }
}
</style>
