<template>
  <Overlay
    :displayCloseButton="true"
    :displayTitleBar="false"
    height="100%"
    width="100%"
    @closeOverlay="onClose">
    <div class="video-wrapper">
      <div
        v-show="!isMobile"
        class="paging prev"
        @mouseover="showPrev = true"
        @mouseleave="showPrev = false"
      >
        <div
          v-if="prevItem"
          class="thumbnail"
          :class="{'on': showPrev}"
          @mouseover="showPrev = true"
          @mouseleave="showPrev = false"
          @click="onClickItem(prevItem)"
        >
          <img
            v-if="prevItem.value.thumbnail"
            :src="getResponsiveImage(prevItem.value.thumbnail)"
            :alt="prevItem.value.header"
          />
          <span v-custom-html="prevItem.value.header" />
        </div>
        <div
          v-if="prevItem"
          class="pager prev"
          @click="onClickItem(prevItem)">
          Letztes<br />Video
        </div>
        <div class="pager prev"
          v-if="!prevItem && nextItem"
          :style="{'background': 'none'}" />
      </div>
      <div class="player">
        <div :style="{ 'width': `${playerWidth}px`, 'height': `${playerHeight}px` }">
          <div
            v-if="selectedItem.type === 'youtube_video_ext'"
            class="youtube-content-wrapper"
          >
            <div class="content-wrapper-mask">
              <div class="mask-content">
                <div class="mask-text">
                  Wir leiten dich jetzt zu YouTube weiter.
                </div>
                <div class="mask-button">
                  <button-component
                    button-type="secondary"
                    background-color="white"
                    label="Jetzt ansehen"
                    :icon-props="{
                      enabled: true,
                      name: 'play-button',
                      position: 'left'
                    }"
                    @change="onClickPlayButton"
                  />
                </div>
              </div>
            </div>
          </div>
          <default-video-player
            v-if="selectedItem.type === 'local_video_ext'"
            :video="selectedItem.value.local_video"
            :autoplay="autoplay"
            :mute="mute"
          />
        </div>
        <div class="title-wrapper">
          <h1 v-custom-html="selectedItem.value.header" />
          <div
          v-if="selectedItem.value.expiration && selectedItem.value.expiration.expiration_warning"
          class="expiration-warning"
          v-html="selectedItem.value.expiration.expiration_warning"
          />
        </div>
        <div class="copy" v-custom-html="selectedItem.value.description" />
      </div>
      <div
        v-if="!isMobile"
        class="paging next"
        @mouseover="showNext = true"
        @mouseleave="showNext = false"
      >
        <div
          v-if="nextItem"
          class="thumbnail"
          :class="{'on': showNext}"
          @mouseover="showNext = true"
          @mouseleave="showNext = false"
          @click="onClickItem(nextItem)"
        >
          <img
            v-if="nextItem.value.thumbnail"
            :src="getResponsiveImage(nextItem.value.thumbnail)"
            :alt="nextItem.value.header"
          />
          <span v-custom-html="nextItem.value.header" />
        </div>
        <div
          v-if="nextItem"
          class="pager next"
          @click="onClickItem(nextItem)"
        >
          Nächstes<br />Video
        </div>
        <div class="pager next"
          v-if="!nextItem && prevItem"
          :style="{'background': 'none'}" />
      </div>
    </div>
    <feedback-teaser />
  </Overlay>
</template>
<script>
import DefaultVideoPlayer from '@/components/player/DefaultVideoPlayer.vue';
import Overlay from '@/components/misc/Overlay.vue';
import FeedbackTeaser from '@/components/misc/FeedbackTeaser.vue';

export default {
  name: 'MultiVideoOverlay',
  components: {
    Overlay,
    DefaultVideoPlayer,
    FeedbackTeaser,
  },
  props: {
    autoplay: {
      type: Boolean,
      default: true,
    },
    mute: {
      type: Boolean,
      default: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    onOpenVideo: {
      type: Function,
    },
    onClose: {
      type: Function,
      required: true,
    },
    selectedItem: {
      type: Object,
      default: () => (null),
    },
    nextItem: {
      type: Object,
      default: () => (null),
    },
    prevItem: {
      type: Object,
      default: () => (null),
    },
  },
  computed: {
    offsetWidth() {
      let offset = 0;
      if (this.innerWidth <= 576) offset = 490;
      else if (this.innerWidth <= 992) offset = 250;
      else if (this.innerWidth <= 1280) offset = 90;
      else if (this.innerWidth <= 1440) offset = 70;
      return 490 - offset;
    },
  },
  data: () => ({
    playerHeight: (window.innerWidth - 490) * 0.5625,
    playerWidth: window.innerWidth - 490,
    innerWidth: window.innerWidth,
    showPrev: false,
    showNext: false,
  }),
  methods: {
    onClickItem(data) {
      this.onChange(data.value.title_slug);
    },
    checkYoutubeSize() {
      this.innerWidth = window.innerWidth;
      this.playerWidth = window.innerWidth - this.offsetWidth;
      this.playerHeight = (window.innerWidth - this.offsetWidth) * 0.5625;
    },
    onClickPlayButton() {
      this.onOpenVideo(this.selectedItem);
    },
  },
  mounted() {
    this.checkYoutubeSize();
    window.addEventListener('resize', this.checkYoutubeSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkYoutubeSize);
  },
};
</script>
<style lang="scss" scoped>
.title-wrapper {
  margin-top: 7px;
  display: flex;
}
.player {
  @include lg {
    position: relative;
  }
  .youtube-content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .content-wrapper-mask {
    animation: fadeInElement 0.3s ease-out;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      background-color: $blue-01;
      opacity: 0.7;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .mask-content {
      position: relative;
      text-align: center;
    }

    .mask-text {
      font-family: 'OnAirBold';
      font-size: 26px;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: center;
      color: $white;
      padding-bottom: 18px;
      max-width: calc(100% - 30px);
      margin: 0 auto;

      @include lg {
        font-size: 20px;
      }

      @include sm {
        font-size: 16px;
      }
    }
  }
}
.expiration-warning {
  font-family: 'OnAirRoman';
  font-size: 12px;
  line-height: 1.67;
  color: $white;
  background-color: $red;
  padding: 2px 8px;
  margin-left: auto;
  white-space: nowrap;
  height: 20px;
  margin-top: 31px;

  @include lg {
    position: absolute;
    top: -78px;
    left: 15px;
  }
}
.video-wrapper {
  background: $black;
  z-index: 99999;
  width: 100%;
  height: 100%;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: 'OnAirBold';
    font-size: 34px;
    line-height: 1.06;
    margin-bottom: 10px;
    font-weight: normal;
  }
  .copy {
    font-family: 'OnAirLight';
    font-size: 13px;
    line-height: 1.83;
  }

  .paging {
    font-family: 'OnAirBold';
    cursor: pointer;
    position: relative;

    .pager {
      font-size: 15px;
      line-height: 1.6;
      padding-bottom: 20px;
      margin-bottom: 120px;
      &.prev {
        background: url('~@/assets/images/mediathek/prev.svg') no-repeat bottom right;
        text-align: right;
        padding-left: 205px;
        width: 55px;
        @include xl-1440 {
          padding-left: 170px;
        }
        @include xl {
          padding-left: 160px;
        }
        @include lg {
          padding-left: 60;
        }
      }
      &.next {
        background: url('~@/assets/images/mediathek/next.svg') no-repeat bottom left;
        padding-right: 205px;
        width: 65px;
        @include xl-1440 {
          padding-right: 170px;
        }
        @include xl {
          padding-right: 160px;
        }
        @include lg {
          padding-right: 60;
        }
      }
    }

    &.prev {
      padding-right: 10px;
      .thumbnail {
        right: 50px;
        position: absolute;
        @include lg {
          right: -230px;
        }
      }
    }

    &.next {
      padding-left: 10px;
      .thumbnail {
        left: 80px;
        position: absolute;
        @include lg {
          left: -210px;
        }
      }
    }
    .thumbnail {
      display: none;
      position: absolute;
      &.on {
        display: block;
      }
      margin-right: 20px;
      width: 150px;
      @include xl-1440 {
        width: 115px;
      }
      @include xl {
        width: 105px;
      }
      @include lg {
        background-color: rgba(0, 0, 0, 0.85);
        width: 200px;
        padding: 5px 5px 2px 5px;
      }
      img {
        width: 150px;
        @include xl-1440 {
          width: 115px;
        }
        @include xl {
          width: 105px;
        }
      }
      span {
        font-size: 12px;
        line-height: 1.7;
        @include lg {
          display: inline-block;
          position: absolute;
          left: 120px;
          font-size: 10px;
          line-height: 2.4;
        }
      }
    }
  }
}
</style>
