<template>
  <div>
    <h3 class="ticket-alarm-headline" v-if="false">
      <span class="navigation-logo-brand icon-logo icon-logo-white" />
      Music News
    </h3>
    <div v-if="false">
      <div class="thanks-info" v-if="showThanksInfo">
        Danke, dass du dich angemeldet hast!
        Wir haben dir eine E-Mail  geschickt. Bitte bestätigte deine Adresse,
        indem du auf den Link in der E-Mail klickst
      </div>
      <form v-else @submit="onSubmitForm">
        <p class="call-to-action">
          Alle aktuellen Updates und Neuigkeiten als Erster erfahren.
        </p>
        <div class="ticket-alarm-form">
          <TextInput
            class="email-wrapper"
            v-model="userInfo.email"
            name="ticket-email"
            :field="inputField"
            :required="false"
            :validate="() => {}"
            :class="[ errors.email ? 'ticket-input-error' : '' ]"/>
          <button type="submit" class="submit-button">
            <span class="xl-text">Jetzt&nbsp;anmelden</span>
            <span class="lg-text">Anmelden</span>
            <span class="md-text">OK</span>
          </button>
        </div>
        <div
          class="form-checkbox"
          :class="{ 'form-checkbox-inactive': !onShowCheckbox() }"
        >
          <div class="form-error" v-if="errors.email || errors.checkbox || errors.server">
            {{ errorMsg }}
          </div>
          <checkbox
            class="checkbox-wrapper"
            :class="[ errors.checkbox ? 'checkbox-wrapper-error' : '' ]"
            :classObject="{'footer': true}"
            :field="checkboxField"
            v-model="checkboxModel"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import TextInput from '@/components/form/TextInput.vue';
import Checkbox from '@/components/form/Checkbox.vue';

const checkboxLabel = '<p>Ich bin mindestens 16 Jahre alt und einverstanden, von o2 per E-Mail '
  + 'über Angebote rund ums <a href="/information/o2-music-news-thema-musik">Thema Musik</a>'
  + ' informiert zu werden. Ich kann meine Einwilligung jederzeit '
  + 'mit Wirkung für die Zukunft widerrufen, indem ich mich vom Newsletter abmelde. *</p>';

export default {
  name: 'TicketAlarm',
  components: {
    TextInput,
    Checkbox,
  },
  data: () => ({
    showThanksInfo: false,
    checkboxModel: false,
    userInfo: {
      email: '',
    },
    checkboxField: {
      label: checkboxLabel,
      required: false,
      name: 'footer-ticket-alarm',
    },
    inputField: {
      type: 'text',
      default: 'E-Mail Adresse',
      label: '',
    },
    errors: {
      checkbox: false,
      email: false,
      server: false,
    },
    defaultError: 'Bitte alle Felder korrekt ausfüllen.',
    errorMsg: 'Bitte alle Felder korrekt ausfüllen.',
  }),
  methods: {
    ...mapActions(['sendTicketAlarmForm']),
    onShowCheckbox() {
      return this.userInfo.email.length;
    },
    isValidEmail(value) {
      // eslint-disable-next-line no-useless-escape
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return emailRegex.test(value);
    },
    onSubmitForm(e) {
      e.preventDefault();
      if (this.pending) return;
      this.errorMsg = this.defaultError;
      this.errors.server = false;
      if (!this.checkboxModel) {
        this.errors.checkbox = true;
      } else {
        this.errors.checkbox = false;
      }
      const validEmail = this.isValidEmail(this.userInfo.email);
      if (!validEmail) {
        this.errors.email = true;
      } else {
        this.errors.email = false;
      }
      if (!this.errors.email && !this.errors.checkbox) {
        this.pending = true;
        this.sendTicketAlarmForm({
          data: {
            email: this.userInfo.email,
            legal_checkbox: true,
          },
          alarmType: 'footeralarm',
        }).then((response) => {
          this.pending = false;
          if (response.status === 200) {
            this.showThanksInfo = true;
            this.triggerDataLayerEvent('success');
            setTimeout(() => {
              this.showThanksInfo = false;
            }, 10000);
            this.errorMsg = this.defaultError;
          } else {
            this.errors.server = true;
            this.errorMsg = response.data && response.data.detail
              ? response.data.detail : `${response.status} error`;
            this.triggerDataLayerEvent(`failure: ${this.errorMsg}`);
          }
        });
      } else {
        this.triggerDataLayerEvent(`failure: ${this.errorMsg}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.email-wrapper {
  max-width: 361px;
  width: 100%;
}
.ticket-input-error {
  border: solid 1px $red;
}
.thanks-info {
  font-family: 'OnAirRoman';
  font-size: 15px;
  line-height: 1.6;
  color: $white;
  margin-top: 16px;
}
.icon-logo {
  font-weight: normal;

  &::before {
    font-size: 35px;
  }
}
.ticket-alarm-headline {
  line-height: 30px;
  font-size: 30px;
  font-family: 'OnAirBold';
  font-weight: normal;
  margin: 0;
}
.call-to-action {
  font-size: 15px;
  margin: 15px 0;
  font-family: "OnAirRoman";
}
.ticket-alarm-form {
  display: flex;
}
.form-error {
  font-family: 'OnAirBold';
  font-size: 12px;
  font-style: italic;
  line-height: 1.17;
  color: $red;
  margin-top: 8px;
  position: absolute;
  top: -35px;
  left: 0;
}
.form-checkbox {
  position: relative;
  margin-top: 35px;
  &-inactive {
    visibility: hidden;

    @include lg {
      visibility: visible;
    }
  }
}
.submit-button {
  position: relative;
  cursor: pointer;
  padding: 15px 37px;
  box-sizing: border-box;
  border: none;
  font-size: 12px;
  font-family: 'OnAirBold';
  line-height: 20px;
  color: $blue-01;
  background-color: $blue-04;
  margin-left: 20px;
  white-space: nowrap;

  span {
    position: relative;
    z-index: 1
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transform: scaleY(0);
    transform-origin: bottom center;
    background: #0090d0;
    z-index: 0;
    transition: transform 0.2s;
  }

  &:hover {
    &::after {
      transform: scaleY(1);
    }
  }

  .lg-text,
  .md-text {
    display: none;
  }

  @include lg {
    padding: 15px 30px 13px 30px;

    .xl-text,
    .md-text {
      display: none;
    }
    .lg-text {
      display: inline;
    }
  }

  @include md {
    margin-left: 0;

    .xl-text,
    .lg-text {
      display: none;
    }
    .md-text {
      display: inline;
    }
  }

}
</style>
<style lang="scss">
  .input-wrapper {
    margin: 0 !important;
  }
  .checkbox-wrapper {
    color: $white !important;
    margin-top: 18px;

    input {
      &:checked ~ .checkmark::before {
        content: "";
      }
    }
    label .text {
      line-height: 1.5;
    }
    .checkmark {
      border-color: $white !important;
      height: 20px !important;
      width: 20px !important;
      &::after {
        height: 10px !important;
        left: 5px !important;
        border-color: $blue-02 !important;
      }
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $white;
      }
    }

    @include sm {
      .checkmark {
        height: 26px !important;
        width: 26px !important;
        &::after {
          height: 14px !important;
          left: 9px !important;
        }
      }
      label .text {
        margin-left: 6px;
      }
    }
  }
  .checkbox-wrapper-error {
    .checkmark {
      border-color: $red !important;
    }
  }
</style>
