<template>
  <div class="input-wrapper">
    <label><span class="text">{{ label }}</span>
      <input
        type="radio"
        :name="name"
        :value="value"
        :checked="value === selected"
        @change="$emit('input', $event.target.value)"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioInput',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String || Number,
      required: false,
    },
    selected: {
      type: String || Number,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";
.input-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.57;
  color: $blue-01;
  font-family: "OnAirLight";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 50px;

  span.text {
    padding-top: 1px;
    display: block;
  }

  @media not all and (min-resolution:.001dpcm) {
    span.text {
      padding-top: 3px;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      background-color: $blue-02;
      border-color: $blue-02;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }

    &:hover ~ .checkmark {
      border-color: $blue-01;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border: 1px solid #b9b9b9;
    background-color: #fff;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 6px;
      left: 6px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #fff;
    }
  }
}
</style>
