<template>
  <swiper :options="swiperOption" class="crawler-swiper">
    <swiper-slide class="crawler-swiper-slide" v-for="slide in slides" :key="slide.id">
      <div v-if="type === 'blue'" class="crawler-item-wrapper">
        <div class="crawler-item-images">
          <img class="vertical-img" src="@/assets/images/presale-teaser/priority-tickets-blue.png" alt="priority-tickets">
          <img class="horizontal-img" src="@/assets/images/presale-teaser/priority-tickets-blue-horizontal@2x.png" alt="priority-tickets">
        </div>
      </div>
      <div v-else class="crawler-item-wrapper">
        <div class="crawler-item-images">
          <img class="vertical-img" src="@/assets/images/presale-teaser/priority-tickets-white.png" alt="priority-tickets">
          <img class="horizontal-img" src="@/assets/images/presale-teaser/priority-tickets-white-horizontal.png" alt="priority-tickets">
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
export default {
  name: 'PresaleTeaserCrawler',
  props: {
    type: {
      type: String,
      default: 'blue',
    },
  },
  data: () => ({
    slides: Array(7).fill('').map((el, i) => ({ id: i })),
    swiperOption: {
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      speed: 13000,
      simulateTouch: false,
      simulateSwipe: false,
      loop: true,
      allowTouchMove: false,
      slidesPerView: 6,
      direction: 'horizontal',
      breakpoints: {
        576: {
          slidesPerView: 4,
          direction: 'vertical',
        },
      },
    },
  }),
};
</script>
<style lang="scss" scoped>
  .crawler-swiper::v-deep {
    height: 668px;
    transform: rotate(180deg);

    @include lg {
      height: 472px;
    }
    @include sm {
      transform: rotate(0deg);
      height: 31px;
      width: 552px;
    }
    .swiper-wrapper {
      transition-timing-function: linear !important;
    }
    .crawler-item-wrapper {
      transform: translateX(-1px);

      @include lg {
        transform: translateX(0px);
      }
    }
  }
  .crawler-item-images {
    transform: rotate(180deg);

    .vertical-img {
      @include sm {
        display: none;
      }
    }
    .horizontal-img {
      display: none;
      height: 100%;
      @include sm {
        display: block;
      }
    }
    @include sm {
      height: 31px;
      transform: rotate(0deg);
    }
    img {
      display: flex;
      width: 100%;
      @include lg {
        width: 40px;
      }
      @include sm {
        width: auto;
      }
    }
  }
</style>
