<template>
  <div class="teaser-item-wrapper" :class="classList">
    <div class="teaser-item">
      <div class="header" v-html="header" />
      <div class="description" v-html="description" />
      <div class="button-wrapper">
        <button-component
          button-type="primary"
          background-color="red"
          :label="buttonText"
          @change="onClickButton"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeaserTicketAlarm',
  props: {
    header: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    classList: {
      type: Object,
      required: false,
    },
  },
  methods: {
    onClickButton() {
      this.$router.replace({ hash: 'ticketalarm' }).catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.teaser-item-wrapper {
  .teaser-item {
    text-align: center;
    padding: 0 10px;
    background: url('~@/assets/icons/news.svg') no-repeat;
    background-position: 50% 35px;
    background-size: 44px 44px;

    @include xl-1440 {
      background-position: 50% 27px;
    }
    @include lg {
      background-position: 50% 35px;
    }

    @include sm {
      border-left: 1px solid $very-light-grey;
      border-right: 1px solid $very-light-grey;
      margin: 0 20px;
      padding-bottom: 50px;
    }

    .header {
      font-family: 'OnAirBold';
      font-size: 26px;
      color: $blue-01;
      line-height: 1.16;
      padding: 110px 0 20px 0;

      @include xl-1440 {
        font-size: 20px;
        line-height: 1.4;
        padding: 90px 0 20px 0;
      }
      @include xl {
       padding: 90px 0 10px 0;
      }

      @include lg {
        font-size: 26px;
        line-height: 1.16;
        padding: 100px 0 20px 0;
      }
    }

    .description {
      font-family: 'OnAirLight';
      font-size: 15px;
      color: $blue-01;
      line-height: 1.6;
      margin: 0 25px 50px;
      @include xl-1440 {
        margin: 0 15px 50px;
      }
      @include xl {
        font-size: 14px;
        line-height: 1.57;
        margin: 0 15px 30px;
      }
      @include lg {
        font-size: 15px;
        line-height: 1.6;
        margin: 0 35px 40px;
      }
    }

    .button-wrapper {
      margin: 15px 0 50px 0;

      @include xl {
        margin: 25px 0 45px 0;
      }

      @include lg {
        margin: 15px 0 50px 0;
      }

      @include sm {
        margin: 15px 0 0 0;
      }
    }
  }

  &.no-outer-margins {
    .teaser-item {
      margin: 0;
    }
  }
  &.no-borders {
    .teaser-item {
      border: none;
    }
  }
}


</style>
