<template>
  <div class="sticky-wrapper" :class="{ 'ie-sticky': isIE }" :style="artistGridStyles">
    <div class="artist" :style="{ 'background-image': `url(${this.imageUrl})` }">
      <h2
        class="headline"
        v-resize-text="{ ratio: 0.4, maxFontSize: 90, delay: 50 }"
        v-custom-html="headline"
      />
      <h3 class="subheadline" v-custom-html="transformLive(sub_headline)" />
      <div class="copy" v-html="transformLive(copy)" />
      <button-component
        v-if="displayButton"
        :label="button.button_text"
        :backgroundColor="button_color"
        :button-link="{
          internal: button.is_internal,
          to: button.button_link
        }"
        class="artist-button"
      />
      <SocialMediaLinks :social-media-links="social_media_links" class="social-media-links" />
    </div>
  </div>
</template>

<script>
import stickybits from 'stickybits';
import GridItemMixin from '@/mixins/grid-item-mixin';
import SocialMediaLinks from '@/components/misc/SocialMediaLinks.vue';

export default {
  name: 'Artist',
  mixins: [GridItemMixin],
  components: {
    SocialMediaLinks,
  },
  props: {
    numberOfChildren: {
      type: Number,
      default: 30,
    },
    cover: {
      type: Object,
      default: () => {},
    },
    cover_tablet: {
      type: Object,
      default: () => {},
    },
    headline: {
      type: String,
      default: '',
    },
    sub_headline: {
      type: String,
      default: '',
    },
    copy: {
      type: String,
      default: '',
    },
    social_media_links: {
      type: Array,
      default: () => [],
    },
    button: {
      type: Object,
      default: () => {},
    },
    button_color: {
      type: String,
      default: 'blue',
    },
  },
  data() {
    return {
      stickybitsInstance: null,
      isOnDesktop: true,
      isOnMobile: false,
    };
  },
  computed: {
    artistGridStyles() {
      const gridRow = { 'grid-row': `span ${this.numberOfChildren * 2}` };
      return { ...this.gridStyles, ...gridRow };
    },
    imageUrl() {
      if (this.isTablet && this.cover_tablet) {
        return this.getResponsiveImage(this.cover_tablet);
      }
      return this.getResponsiveImage(this.cover);
    },
    displayButton() {
      return this.button && this.button.button_link !== null;
    },
    isIE() {
      // source: https://stackoverflow.com/a/22551342
      return /MSIE|Trident/.test(window.navigator.userAgent);
    },
  },
  methods: {
    onResize() {
      this.isOnDesktop = window.innerWidth > 992;
      this.isOnMobile = window.innerWidth <= 576;
      if (this.isIE) {
        this.toggleStickybits();
      }
    },
    toggleStickybits() {
      if (this.isOnDesktop && this.stickybitsInstance === null) {
        this.activateStickyBits();
      } else if (!this.isOnDesktop && this.stickybitsInstance !== null) {
        this.deactivateStickBits();
      } else if (this.stickybitsInstance !== null) {
        this.stickybitsInstance.update();
      }
    },
    activateStickyBits() {
      this.stickybitsInstance = stickybits('.ie-sticky', { useGetBoundingClientRect: true });
    },
    deactivateStickBits() {
      this.stickybitsInstance.cleanup();
      this.stickybitsInstance = null;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    if (this.isIE) {
      setTimeout(() => { // don't ask me why but this fixes a weird positioning issue in IE11
        this.stickybitsInstance.update();
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
.sticky-wrapper {
  width: 100%;
  height: 100%;

  @include lg {
    height: auto;
    width: auto;
    grid-column-end: span end !important;
    margin: 0 -31px 40px -31px;
  }

  @include sm {
    margin: 0 -19px 40px -19px;
  }

  @include ie11 {
    position: absolute;
    left: -100%;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;

    &.js-is-sticky {
      width: calc(50% - 79px);
      left: 158px;

      @include xl {
        width: calc(50% - 50px);
        left: 100px;
      }
    }

    @include lg {
      position: relative;
      left: auto;
      width: 100vw !important;
      height: auto;
      margin: 0 -31px 40px -31px;
    }

    @include sm {
      margin: 0 -19px 40px -19px;
    }
  }
}
.artist {
  position: sticky;
  overflow: hidden;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: calc(100vh - 95px);
  padding: 40px 65px;
  background-size: cover;
  background-position: center;
  color: $white;
  padding-bottom: 55px;

  @include xl {
    min-height: calc(100vh - 110px);
    padding-bottom: 70px;
  }

  @include lg {
    min-height: auto;
    position: relative;
    padding: 80px 65px 20px 65px;
  }

  @include sm {
    padding: 210px 19px 25px 19px;
  }

  @include ie11 {
    height: calc(100vh - 80px);
    min-height: auto;

    @include lg {
      height: auto;
    }
  }
}
.headline {
  font-size: 90px;
  line-height: 1.09;
  font-family: 'OnAirBold';
  font-weight: normal;
  margin: 0;

  @include lg {
    font-size: 80px !important;
  }

  @include sm {
    font-size: 70px !important;
  }
}
.subheadline {
  font-size: 20px;
  font-family: 'OnAirBold';
  font-weight: normal;

  @include sm {
    font-size: 15px;
  }
}
.copy, .copy ::v-deep h1, .copy ::v-deep h2, .copy ::v-deep p{
  font-size: 15px;
  font-family: 'OnAirLight';
  font-weight: normal;
  color: $white;
  line-height: 24px;
  padding-right: 15%;
  margin: 0;

  @include sm {
    font-size: 14px;
    line-height: 22px;
  }
}
.artist-button {
  display: inline-block;
  margin-top: 35px;
}
.social-media-links {
  margin-top: 35px;
}
</style>
