<template>
  <div class="presale-event">
    <div class="presale-event-content">
      <div class="priority-presents">
        <div v-if="ticketsState !== 'SALE'" class="priority-logo">
          <img src="@/assets/images/priority-tickets-blue.png" alt="priority-tickets-logo">
        </div>
        <div v-else class="priority-logo-empty" />
      </div>
      <div class="event-image-container">
        <div v-if="!isTicketsAvailable && isPresaleRunning" class="event-image-soldout">
          +++ Im Vor-Vorverkauf vergriffen +++
        </div>
        <img class="event-image" :src="eventImageUrl" />
      </div>
      <EventLogo
        :title="eventName"
        :eventLogo="eventLogo"
        class="event-logo"
      />
      <div class="share-button-wrapper">
        <ShareButton
          class="event-share"
          :title="eventName"
          :text="eventDescription"
          :url="eventUrl"
          type="blue"
        />
      </div>
      <div
        v-if="eventDescription"
        class="event-description-wrapper"
      >
        <div class="toggle-event-description">
          <div
            class="toggle-event-description-text"
            :class="{ 'toggle-event-description-text-active': showDescription }"
            @click="onToggleEventText()"
          >
            Weitere Infos
          </div>
        </div>
        <transition v-if="showDescription" name="expand">
          <p class="event-description">
            {{ eventDescription }}
          </p>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EventLogo from '@/components/misc/EventLogo.vue';
import ShareButton from '@/components/misc/ShareButton.vue';

export default {
  name: 'PresaleEvent',
  components: {
    EventLogo,
    ShareButton,
  },
  props: {
    eventSeries: {
      type: Object,
    },
    title: {
      type: String,
    },
    isPresaleRunning: {
      type: Boolean,
      default: false,
    },
    ticketsState: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDescription: false,
    };
  },
  computed: {
    isTicketsAvailable() {
      return this.pageData.event_series.tickets_available;
    },
    eventImageUrl() {
      if (this.pageData.event_image) {
        return this.getResponsiveImage(this.pageData.event_image);
      }
      if (this.eventSeries.local_image) {
        return this.getResponsiveImage(this.eventSeries.local_image);
      }
      return require('@/assets/images/no-image.jpg');
    },
    eventName() {
      if (this.eventSeries.artists && this.eventSeries.artists.length) {
        return this.eventSeries.artists[0];
      }
      return this.title ? this.title : this.eventSeries.eventserie;
    },
    eventDescription() {
      return this.pageData.event_description
        ? this.pageData.event_description
        : this.eventSeries.description;
    },
    eventUrl() {
      return `${window.location.origin}${this.pageName}`;
    },
    eventLogo() {
      return this.pageData.event_logo ? this.pageData.event_logo : null;
    },
    genreName() {
      return 'Rock & Pop'; // TODO this should come from the backend once the category is there
    },
  },
  methods: {
    onToggleEventText() {
      this.showDescription = !this.showDescription;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/base.scss";
@import "src/assets/styles/variables.scss";

.event-description-wrapper {
  width: 100%;
  padding: 15px 0 19px 0;
  margin-bottom: -35px;
  border-top: solid 1px $very-light-grey;
  @include sm {
    margin-bottom: -20px;
  }
}
.toggle-event-description {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'OnAirLight';
  font-size: 13px;
  line-height: 1.23;
  color: $blue-02;
  cursor: pointer;
  text-decoration: underline;
  position: relative;

  &-text {
    position: relative;
    user-select: none;
    &::after {
      content: '';
      position: absolute;
      width: 7px;
      height: 12px;
      right: -13px;
      top: 3px;
      background-image: url('~@/assets/images/presale-page/arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &-text-active {
    &::after {
      transform: rotate(180deg);
    }
  }

}
.presale-event {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.presale-event-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: $blue-02;
  background-color: $white;
  margin: 50px;
  padding: 40px;
  width: calc(100% - 180px);

  @include xl {
    margin: 30px;
    padding: 35px 20px;
    width: calc(100% - 110px);
  }
  @include sm {
    margin: 35px 20px 30px 20px;
    padding: 20px 15px;
    width: calc(100% - 70px);
  }
}
.priority-presents {
  font-family: "OnAirLight";
  width: 100%;
  span {
    display: inline-block;
    margin-left: 5px;
  }

  &-text {
    font-size: 25px;
    font-family: 'OnAirLight';
    color: $white;
    position:relative;
    top: 2px;
  }
}
.priority-logo {
  width: 140px;
  max-width: 100%;
  margin: 0 auto;
  img {
    width: 100%;
    display: flex;
  }
  padding-bottom: 20px;

  @include sm {
    padding-bottom: 15px;
  }
}
.priority-logo-empty {
  height: 35px;
  width: 100%;
}
.share-button-wrapper {
  width: 100%;
  text-align: center;

  &::v-deep {
    .event-share {
      margin-bottom: 25px;
      margin-top: 25px;
    }
  }
}
.event-image-container {
  position: relative;
}
.event-image-soldout {
  position: absolute;
  color: $white;
  background-color: $red;
  left: 0;
  right: 0;
  top: 0;
  font-family: 'OnAirRoman';
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  overflow: hidden;
}
.event-image {
  width: 220px;
  height: 220px;
  object-fit: cover;
}
.genre-like {
  margin: 20px 0;
}
.event-logo {
  max-width: 80%;
  margin: 35px 0 20px 0;

  @include lg {
    max-width: 100%;
    margin: 25px 0 20px 0;
  }
}
$line-height: 24px;
.event-description {
  max-width: 80%;
  width: 100%;
  text-align: center;
  font-family: "OnAirLight";
  font-size: 15px;
  line-height: $line-height;
  margin: 0 auto;
  padding-top: 18px;

  @include lg {
    max-width: 100%;
  }
  @include sm {
    margin-bottom: 30px;
  }
}
.event-share {
  margin-bottom: 65px;

  @include sm {
    margin-bottom: 20px;

    .event-description {
      margin-bottom: 30px;
    }
  }
}
</style>
