<template>
  <div class="input-wrapper" :class="classObject">
    <label v-if="field.label" :class="{'error': error}">{{ field.label }}</label>
    <radio-input
      v-for="option in field.choices"
      :key="option.value"
      :name="field.name"
      :value="option.value"
      :label="option.label"
      :selected="value"
      @input="val => {$emit('input', val); validate(field, val);}"
    />
  </div>
</template>

<script>
import RadioInput from '@/components/form/RadioInput.vue';

export default {
  name: 'RadioGroup',
  components: {
    RadioInput,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: String || Number,
      required: false,
    },
    classObject: {
      type: Object,
      required: false,
    },
    error: {
      type: Boolean,
      required: false,
    },
    validate: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";
.input-wrapper {
  position: relative;
  display: flex;

  label {
    display: block;
    width: 100px;
    font-size: 14px;
    font-family: "OnAirRoman";
    line-height: 1.71;
    color: $blue-01;
    &.error {
      color: $red;
    }
  }
}
</style>
