<template>
  <div class="livestream-module" :style="gridStyles">
    <div
      class="livestream-wrapper"
      :style="{'background-image': `url(${backgroundImage})`}"
    >
      <div class="top-left-corner">
        <div class="live-flag" v-if="live_flag">{{ live_flag }}</div>
        <div class="artist-name" v-if="artist_name">{{ artist_name }}</div>
      </div>
      <div class="headline-image" v-if="headline_image">
        <img :src="getResponsiveImage(headline_image)" />
      </div>
      <h2 class="headline" v-else>
        <PriorityLogoResponsive class="priority-logo" />
        <p class="headline-copy" v-html="headline" />
      </h2>
      <ThirdPartyCookiesInfo
        v-show="showThirdPartyCookieInfo"
        @close="showTPC = false"
        @changeTPCsupported="TPCsupported = false"
      />
      <div class="livestream">
        <div
          v-if="!videoIsPlaying"
          class="thumbnail"
          @click="onClickThumbnail"
          :class="[
            showMask && youtube_id ? 'thumbnail--masked' : '',
            !!youtube_id ? 'clickable' : ''
          ]"
        >
          <img
            class="livestream-thumbnail"
            :src="getResponsiveImage(thumbnail)"
          />
          <div v-if="youtube_id" class="play-button" />
          <h2 v-if="!isMobile" class="video-headline" v-html="video_header" />
          <p class="video-copy" v-if="video_description">
            <span class="stripe" />
            <span class="video-description" v-html="video_description" />
          </p>
          <ShareButton
            :title="video_header"
            :text="video_description"
            :url="shareUrl"
            width="30px"
            height="30px"
            class="click-share"
          />
          <template name="fade">
            <div v-if="youtube_id" class="content-wrapper-mask">
              <div class="mask-content">
                <div class="mask-text">
                  Wir leiten dich jetzt zu YouTube weiter.
                </div>
                <div class="mask-button">
                  <button-component
                    button-type="secondary"
                    background-color="white"
                    label="Jetzt ansehen"
                    :icon-props="{
                      enabled: true,
                      name: 'play-button',
                      position: 'left'
                    }"
                    @change="onClickPlayButton"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="thumbnail-content-on-mobile">
          <h2 v-if="isMobile" class="video-headline" v-html="video_header" />
          <p class="video-copy" v-if="video_description">
            <span class="stripe" />
            <span class="video-description" v-html="video_description" />
          </p>
          <ShareButton
            :title="video_header"
            :text="video_description"
            :url="shareUrl"
            width="30px"
            height="30px"
            class="click-share"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GridItemMixin from '@/mixins/grid-item-mixin';
import PriorityLogoResponsive from '@/components/misc/PriorityLogoResponsive.vue';
import ShareButton from '@/components/misc/ShareButton.vue';
import ThirdPartyCookiesInfo
  from '@/views/journey/components/modules/children/ThirdPartyCookiesInfo.vue';

export default {
  name: 'Livestream',
  mixins: [GridItemMixin],
  components: {
    PriorityLogoResponsive,
    ShareButton,
    ThirdPartyCookiesInfo,
  },
  props: {
    mouse_over: {
      type: Boolean,
      default: false,
    },
    chat_enabled: {
      type: Boolean,
      default: false,
    },
    mouse_over_text: {
      type: String,
      default: 'Mehr',
    },
    artist_name: {
      type: String,
      default: '',
    },
    headline: {
      type: String,
      default: '',
    },
    live_flag: {
      type: String,
      default: '',
    },
    youtube_id: {
      type: String,
      default: '',
    },
    video_header: {
      type: String,
      default: '',
    },
    video_description: {
      type: String,
      default: '',
    },
    background_image: {
      type: Object,
      default: () => {},
    },
    headline_image: {
      type: Object,
      default: () => {},
    },
    thumbnail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showChat: false,
      showMask: false,
      videoIsPlaying: false,
      TPCsupported: true,
      showTPC: true,
      playerHeight: (window.innerWidth * 0.66) * 0.5625,
    };
  },
  computed: {
    showThirdPartyCookieInfo() {
      return this.showChat && !this.TPCsupported && this.showTPC;
    },
    liveStreamStyles() {
      const styles = {
        'background-image': `url(${this.background_image.download_url})`,
      };
      return { ...this.gridStyles, ...styles };
    },
    shareUrl() {
      return `${window.location.origin}${this.pageName}`;
    },
    backgroundImage() {
      return this.getResponsiveImage(this.background_image);
    },
  },
  methods: {
    onClickThumbnail() {
      if (!this.youtube_id) {
        return;
      }
      this.showMask = !this.showMask;
    },
    onClickPlayButton() {
      const link = `https://www.youtube.com/watch?v=${this.youtube_id}`;
      this.trackActionEvent({
        eventName: 'videoPlayClick',
        eCat: 'Youtube - o2 Music',
        eAct: 'click - video play now',
        eLab: `${this.video_header} | ${link}`,
        nonInteraction: false,
      });
      this.openYoutubeInNewWindow(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.livestream-wrapper {
  position: relative;
  background-size: cover;
  background-position: center;
  margin-bottom: 150px;
  min-height: 40vw;

  @include lg {
    min-height: 60vw;
    margin-bottom: 120px;
  }

  @include sm {
    padding-bottom: 50px;
    margin-bottom: 60px;
  }
}
.top-left-corner {
  position: absolute;
  left: -5px;
  top: 50px;
  z-index: 100;
  max-width: 250px;

  @include lg {
    left: 0;
    top: 30px;
  }

  @include md {
    display: none;
  }
}
.live-flag {
  position: absolute;
  top: -4px;
  left: -2px;
  transform-origin: top left;
  transform: rotate(270deg) translate(-100%);
  background-color: $blue-02;
  padding: 0 10px;
  color: $white;
  font-family: "OnAirBold";
  font-size: 12px;
  line-height: 18px;
}
.artist-name {
  margin-left: 12px;
  font-family: "OnAirLight";
  font-size: 30px;
  color: $white;
}
.headline-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 40px;

  img {
    width: auto;
    height: 100px;

    @include sm {
      width: 100%;
      height: auto;
    }
  }
}
.headline {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "OnAirLight";
  font-weight: normal;
  font-size: 52px;
  text-align: center;
  margin: 0;
  padding-top: 40px;
  color: $white;

  @include xl {
    font-size: 45px;
  }

  @include lg {
    font-size: 40px;
    padding-top: 30px;
  }

  @include sm {
    flex-direction: column;
  }

  &-copy {
    margin-top: -12px;
  }

  .priority-logo {
    width: 240px;

    @include xl {
      width: 180px;
    }

    @include lg {
      width: 150px;
    }
  }
}
.livestream {
  position: absolute;
  bottom: -75px;
  left: 0;
  right: 0;
  width: 66%;
  margin: 0 auto;

  @include xl {
    width: 70%;
    bottom: -80px;
  }

  @include lg {
    width: 85%;
    bottom: -100px;
  }

  @include sm {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    width: calc(100% - 39px);
    margin: 20px 19px;
  }
  &--livechat {
    width: 80%;

    @include xl {
      width: 90%;
    }

    @include lg {
      width: 85%;
      bottom: -50px;
    }

    @include sm {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
      width: calc(100% - 39px);
      margin: 20px 19px;

      .is-playing {
        z-index: 100;
      }
    }
  }
}
.thumbnail {
  position: relative;
  overflow: hidden;
  display: flex;

  &.clickable {
    cursor: pointer;
  }
  img {
    width: 100%;
    height: auto;
  }
  .play-button {
    user-select: none;
    position: absolute;
    top: calc(50% - 45px);
    left: calc(50% - 45px);
    width: 90px;
    height: 90px;
    background-size: cover;
    background-image: url('~@/assets/images/mediathek/play.svg');

    @include xl {
      top: calc(50% - 35px);
      left: calc(50% - 35px);
      width: 70px;
      height: 70px;
    }

    @include md {
      top: calc(50% - 30px);
      left: calc(50% - 30px);
      width: 60px;
      height: 60px;
    }
  }
  .video-headline {
    position: absolute;
    bottom: 70px;
    left: -10px;

    @include lg {
      bottom: 50px;
    }

    @include sm {
      display: none;
    }
  }
  .video-copy {
    position: absolute;
    bottom: 25px;

    @include lg {
      bottom: 10px;
    }

    @include sm {
      display: none;
    }
  }
  .click-share {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;

    @include sm {
      display: none;
    }
  }
  .content-wrapper-mask {
    transition: opacity 0.3s ease-out;
    position: absolute;
    z-index: 1;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      background-color: $blue-01;
      opacity: 0.7;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .mask-content {
      position: relative;
      text-align: center;
    }

    .mask-text {
      font-family: 'OnAirBold';
      font-size: 26px;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: center;
      color: $white;
      padding-bottom: 18px;
      max-width: calc(100% - 30px);
      margin: 0 auto;

      @include lg {
        font-size: 20px;
      }
      @include sm {
        font-size: 16px;
      }
    }
  }
  &:hover {
    .content-wrapper-mask {
      opacity: 1;
      @include lg {
        opacity: 0;
      }
    }
  }

  &--masked {
    .content-wrapper-mask {
      @include lg {
        opacity: 1 !important;
      }
    }
  }
}
.video-headline {
  margin: 0;
  color: $white;
  font-family: "OnAirBold";
  font-weight: normal;
  @include proportional-font-size(40px, 85px, $add-line-height: true);
}
.video-copy {
  color: $white;
  font-family: "OnAirRoman";
  font-size: 13px;
  line-height: 20px;
  padding-left: 25px;
  position: relative;

  .stripe {
    position: absolute;
    background-color: $white;
    width: 16px;
    height: 1px;
    margin: 0 5px 1px -25px;
    top: 7px;
    vertical-align: middle;
  }
  .video-description {
    display: inline-block;
    width: auto;
  }
}
.thumbnail-content-on-mobile {
  display: none;
  position: relative;
  margin-top: 10px;

  @include sm {
    display: block;
  }
  .click-share {
    position: absolute;
    top: 5px;
    right: 0;
  }
  .video-headline {
    font-size: 40px;
    line-height: 45px;
    margin-right: 40px;
    margin-bottom: 10px;
  }
}
::v-deep .player {
  width: 100%;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  z-index: -10;
}
::v-deep .is-playing {
  opacity: 1;
  position: relative;
  pointer-events: auto;
  z-index: auto;
}
.livechat-button-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 39px;
  transform: rotate(-90deg);
  transform-origin: top right;
  margin: auto;

  @include lg {
    position: relative;
    transform: rotate(0deg);
    width: 100% !important;
  }

  @include sm {
    display: none;
  }
}
.livechat-button {
  cursor: pointer;
  position: relative;
  line-height: 39px;
  padding: 0 17px;
  padding-left: 50px;
  border: solid 1px $white;
  border-top: none;
  color: $white;
  font-size: 14px;
  font-family: 'OnAirBold';
  text-transform: uppercase;
  display: table;
  margin: 0 auto;
  user-select: none;

  &::before {
    content: "";
    position: absolute;
    background-image: url('~@/assets/icons/chat-icon.svg');
    background-size: contain;
    width: 28px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    left: 17px;
    top: 8px;
    transform: rotate(90deg);
  }

  @include lg {
    border: solid 1px #e1e1e1;
    background-color: $white;
    color: $blue-01;
    font-size: 12px;
    margin-top: 25px;
    height: auto;
    line-height: 1;
    letter-spacing: 0.1px;
    padding: 16px 20px;
    padding-left: 55px;

    &::before {
      background-image: url('~@/assets/icons/icon-chat-blue.svg');
      transform: rotate(0deg);
      top: 10px;
    }
  }

  @include sm {
    text-align: center;
    display: block;
  }
}
</style>
