<template>
  <div class="presale-end-message">
    <p class="first-line" v-html="pageData.presale_end_first_line" />
    <p
      class="second-line"
      v-resize-text="{ ratio: 0.5, maxFontSize: '68px', delay: 50 }"
      v-html="pageData.presale_end_second_line"
    />
  </div>
</template>

<script>
export default {
  name: 'PresaleEndMessage',
};
</script>

<style lang="scss" scoped>
.presale-end-message {
  padding: 60px 30px 50px 30px;
  background-color: $blue-02;
  margin: -3px;
  margin-bottom: 3px;
}
.first-line {
  font-family: "OnAirBold";
  font-weight: normal;
  line-height: 1.4;
  color: $white;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.second-line {
  font-family: "OnAirBold";
  font-weight: normal;
  color: $white;
  font-size: 68px;
  text-transform: uppercase;
}
</style>
