export default {
  data: () => ({
    pending: false,
    isMobile: window.innerWidth <= 576,
    isTablet: window.innerWidth <= 992 && window.innerWidth > 575,
  }),
  computed: {
    VUE_APP_API() {
      return this.$store.state.API;
    },
    VUE_APP_URL() {
      return this.$store.state.VUE_APP_URL;
    },
    VUE_APP_MODE() {
      return this.$store.state.mode;
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
    },
  },
  methods: {
    decodeHtmlToText(value) {
      return value.replace(/<[^>]+>/gm, '');
    },
    triggerDataLayerEvent(eResult) {
      if (window.tefDataLayer) {
        window.tefDataLayer.push({
          event: 'newsletterClickSubscribe',
          eCat: 'Newsletter - o2 Priority: Music',
          eAct: 'click',
          eLab: 'subscribe request intention',
          eResult,
          eVal: 0,
        });
      }
    },
    // eslint-disable-next-line
    trackActionEvent({ eventName, eCat, eAct, eLab, nonInteraction = false }) {
      window.tefDataLayer = window.tefDataLayer || [];
      window.tefDataLayer.push({
        event: eventName,
        eCat,
        eAct,
        eLab,
        nonInteraction,
      });
    },
    isUserAcceptCookies() {
      const usercentrics = window.localStorage.usercentrics
        ? JSON.parse(window.localStorage.usercentrics)
        : false;
      if (usercentrics) {
        const consent = usercentrics.consents.find(
          (el) => el.dataProcessors[0] === 'Google Analytics',
        );
        return consent && consent.consentStatus;
      }
      return false;
    },
    getResponsiveImage(data, type) {
      if (!data) return '';
      if (type === 'original') {
        return data.download_url;
      }
      if (this.isTablet) {
        return data.medium ? data.medium.url : data.download_url;
      }
      if (this.isMobile) {
        return data.small ? data.small.url : data.download_url;
      }
      return data.large ? data.large.url : data.download_url;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onResize() {
      this.isMobile = window.innerWidth <= 576;
      this.isTablet = window.innerWidth <= 992 && window.innerWidth > 575;
    },
    transformLive(html, bold = false) {
      if (html.match(/LIVE\s/)) {
        return html.replace(
          /LIVE\s/gm,
          `<span class="live${bold ? ' bold' : ''}">LIVE&nbsp;</span>`,
        );
      }
      return html.replace(/LIVE\b/gm, `<span class="live${bold ? ' bold' : ''}">LIVE</span>`);
    },
    isHtml(val) {
      return /<\/?[a-z][\s\S]*>/i.test(val);
    },
    isYoutubeLink(link) {
      return link.toLowerCase().includes('youtube.');
    },
    /*
    We leave it as a separate link.
    Perhaps youtube links will be handled different in the future again.
    */
    openYoutubeInNewWindow(linkToYoutube) {
      window.open(linkToYoutube, '_blank');
      /*
      const win = window.open(linkToYoutube, '_blank',
        `width=${window.screen.width || '800'}`,
        `height=${window.screen.height || '600'}`);
      win.focus();
      */
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
