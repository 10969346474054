<template>
  <div class="input-wrapper" :class="classObject">
    <div class="field">
      <label v-if="field.label" :class="{'error': error}">{{ field.label }}</label>
      <input
        :class="{'error': error} "
        :type="field.type"
        :placeholder="field.default"
        :required="field.required"
        :name="field.name"
        :value="value"
        @blur="validate(field, value)"
        @input="$emit('input', $event.target.value)"
      />
    </div>
    <span v-if="field.help_text" class="text">{{ field.help_text }}</span>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: String || Number,
      required: false,
      default: '',
    },
    classObject: {
      type: Object,
      required: false,
    },
    error: {
      type: Boolean,
      required: false,
    },
    validate: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";
.input-wrapper {
  margin: 12px 0;

  .field {
    position: relative;
  }

  span.text {
    display: block;
    font-size: 12px;
    font-family: "OnAirRoman";
    line-height: 1.67;
    color: $blue-01;
    padding-top: 10px;
  }

  label {
    display: block;
    font-size: 14px;
    font-family: "OnAirRoman";
    line-height: 1.71;
    background: #fff;
    position: absolute;
    padding: 0 3px;
    margin-left: 8px;
    bottom: 40px;
    color: $blue-01;

    &.error {
      color: $red;
    }
  }

  input {
    width: 100%;
    border: 1px solid #b9b9b9;
    padding: 14px 18px;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.4;
    font-family: "OnAirLight";
    color: $blue-01;
    box-sizing: border-box;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    &.error {
      border-color: $red;
    }
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input:focus {
    border-color: $blue-01;
    &.error {
      border-color: $red;
    }
  }
}
</style>
