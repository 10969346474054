<template>
  <div
    class="amazon-teaser"
    :class="{'youtube-teaser': getVideoData}"
    :style="{ 'background-image': `url(${teaserBackground})`}"
  >
    <div class="teaser-content">
      <div class="content-block content-block-headline">
        <div class="block-headline" v-custom-html="headline" />
        <div
          v-for="(item, $index) in colums"
          :key="$index"
          class="block-info"
          :class="`block-info-${item.type}`"
        >
          <ImageComponent
            v-if="item.type === 'link'"
            :class="[ $index === 1 ? 'marged-top' : '' ]"
            :image="item.value.image"
            :image_link="item.value.link"
          />
          <TextComponent
            v-else-if="item.type === 'text'"
            class="text-component"
            :class="[ $index === 1 ? 'marged-top' : '' ]"
            :headline="item.value.subheadline"
            :copy="item.value.copy"
            :button="item.value.link"
            :background-color="item.value.link ? item.value.link.button_color : ''"
          />
          <div v-else class="media-wrapper-block-component">
            <AmazonMusic
              v-if="item.type === 'amazon'"
              v-bind="item.value"
            />
            <Video
              v-else-if="item.type === 'youtube'"
              v-bind="item.value"
              class="youtube-component-block"
            />
          </div>
        </div>
      </div>
      <div class="content-block">
        <div class="media-music-component">
          <AmazonMusic
            v-if="getAmazonData"
            v-bind="getAmazonData.value"
          />
          <Video
            v-else-if="getVideoData"
            v-bind="getVideoData.value"
            class="youtube-component-block"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageComponent from '@/views/journey/components/modules/children/Image.vue';
import TextComponent from '@/views/journey/components/modules/children/Text.vue';
import AmazonMusic from '@/views/journey/components/modules/children/AmazonMusic.vue';
import Video from '@/views/journey/components/modules/children/Video.vue';

export default {
  name: 'amazon-teaser',
  components: {
    ImageComponent,
    TextComponent,
    AmazonMusic,
    Video,
  },
  props: {
    background: {
      type: Object,
      default: () => ({}),
    },
    colums: {
      type: Array,
      default: () => ([]),
    },
    headline: {
      type: String,
      default: 'Amazon Music',
    },
  },
  computed: {
    teaserBackground() {
      return this.getResponsiveImage(this.background);
    },
    getAmazonData() {
      const amazonData = this.colums.find((el) => el.type === 'amazon');
      return amazonData;
    },
    getVideoData() {
      const videoData = this.colums.find((el) => el.type === 'youtube');
      return videoData;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
  .amazon-teaser {
    background-color: blue;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 190px;
    margin-top: 55px;
    &.youtube-teaser {
      margin-bottom: 130px;
    }
  }
  .teaser-content {
    display: flex;
    flex-wrap: wrap;
  }
  .content-block {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  .block-headline {
    padding-top: 35px;
    width: 100%;
    font-size: 130px;
    font-family: 'OnAirBold';
    color: $white;
    margin-left: -15px;
    line-height: 1;
  }
  .block-info {
    width: 50%;
    padding-top: 15px;
  }
  .text-component {
    color: $white !important;
  }
  .marged-top {
    margin-top: -100px;
  }
  .media-music-component {
    width: 100%;
    position: absolute;
    bottom: 0 !important;

    .amazon-music {
      margin-bottom: -100px;
    }
  }
  .media-wrapper-block-component {
    display: none;
  }
  .youtube-component-block {
    min-height: 200px;
    ::v-deep {
      .video-text {
        color: $white;
        position: absolute;
        bottom: 90px;
        left: 40px;
      }
    }
  }
  @include lg {
    .amazon-teaser {
      padding: 0 30px;
      margin-bottom: 275px;
    }
    .content-block {
      width: 100%;
    }
    .block-headline {
      width: 50%;
      font-size: 100px;
      margin-left: 0;
    }
    .block-info {
      padding-top: 40px;
      position: relative;
      .image-wrapper {
        padding-left: 54px;
        padding-right: 54px;
      }
      .text-component {
        padding-left: 54px;
        padding-right: 54px;
      }
      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 3;
      }
      &:nth-child(3) {
        order: 2;
      }
      &:nth-child(4) {
        order: 4;
      }
    }
    .marged-top {
      margin-top: 0;
    }
    .media-music-component {
      width: 50%;
      display: none;
    }
    .media-wrapper-block-component {
      display: block;
      margin-bottom: 0;
      position: absolute;
      width: 100%;
      top: 0;
      transform: translate(0, -355px);
      .amazon-music {
        margin-bottom: 0;
        // padding: 0 54px;
        ::v-deep {
          iframe {
            height: 563px !important;
          }
        }
      }
    }
    .block-info-amazon, .block-info-youtube {
      height: 0;
      align-self: flex-end;
      padding: 0;
    }
    .youtube-component-block {
      ::v-deep {
        .video-text {
          bottom: 90px;
          left: 0;
        }
      }
    }
  }
  @include sm {
    .amazon-teaser {
      padding: 0 22px;
      margin-bottom: 325px;
      margin-top: 75px;
      &.youtube-teaser {
        margin-bottom: 225px;
      }
    }

    .block-headline {
      padding-top: 40px;
      font-size: 80px;
      width: 100%;
    }
    .block-info {
      width: 100%;
      padding-top: 40px;
      .text-component, .image-wrapper {
        padding-left: 0;
        padding-right: 0;
      }
      .image-wrapper {
        margin-bottom: 0;
      }
      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 2;
      }
      &:nth-child(3) {
        order: 3;
      }
      &:nth-child(4) {
        order: 4;
      }
    }
    .media-wrapper-block-component {
      transform: translate(0, 0);
      .amazon-music {
        padding: 0;
        ::v-deep {
          iframe {
            height: 500px !important;
          }
        }
      }
    }
    .block-info-amazon {
      height: 190px;
    }
    .youtube-component-block {
      ::v-deep {
        .video-text {
          bottom: 90px;
          left: 15px;
        }
      }
    }
  }
</style>
