<template>
  <div class="social-share-wrapper wrapper" :class="{'small': small }">
    <div class="header" :class="{'small': small }" v-html="headline"></div>
    <div
      v-if="social_media_links.length > 0"
      class="social-block-links" :class="{'small': small }">
      <div
        v-for="item in social_media_links"
        :key="item.plattform"
        class="social-block-link">
        <a :href="item.url" target="_blank" rel="noopener">
          <span :class="`glyphicon glyphicon-${item.plattform}`" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SocialShare',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    headline: {
      type: String,
      default: 'Folge uns jetzt auf Social Media:',
    },
    social_media_links: {
      type: Array,
    },
  },
};
</script>
<style lang="scss" scoped>
.social-share-wrapper {
  overflow: hidden;
}
.social-block-links {
  display: flex;
  flex-direction: row;
  width: auto;
  &.small {
    justify-content: left;
    margin-top: 25px;
    margin-left: -14px;
    width: auto;
  }
  .social-block-link {
    padding: 4px 15px;

    a {
      display: block;
    }
    @include xl-1440 {
      padding: 5px 12.5px;
    }
    @include lg {
      padding: 5px 15px;
    }
    @include sm {
      padding: 5px 17px;
    }
    span {
      font-size: 32px;
      color: $blue-01;
      &.glyphicon-youtube {
        margin-top: 5px;
      }
      @include xl {
        &.glyphicon-youtube {
          margin-top: 3px;
        }
      }
      @include lg {
        &.glyphicon-youtube {
          margin-top: 5px;
        }
      }
    }
  }
  @include xl {
    width: 100%;
    justify-content: center;
  }
  @include lg {
    width: auto;
    justify-content: flex-start;
  }
  @include sm {
    width: 100%;
    justify-content: center;
  }
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &.small {
    padding-left: 30px;
    display: block;
    padding-top: 20px;
    @include lg {
      padding-left: 48px;
    }
    @include sm {
      padding-left: 19px;
    }
  }
  @include xl {
    display: block;
  }
  @include lg {
    display: flex;
    justify-content: center;
  }
  @include sm {
    display: block;
  }
  .header {
    width: auto;
    font-family: 'OnAirBold';
    font-size: 20px;
    line-height: 1.4;
    color: $blue-01;
    text-align: center;
    padding-right: 53px;
    &.small {
      text-align: left;
      width: 200px;
      max-width: 100%;
      padding-right: auto;
      @include xl {
        width: 190px;
      }
      @include lg {
        width: 200px;
      }
    }
    @include xl {
      width: 100%;
      margin-bottom: 20px;
    }
    @include lg {
      width: auto;
      margin-bottom: 0;
      text-align: right;
    }
    @include sm {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      padding-right: 0;
    }
  }
}
</style>
