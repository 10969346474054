<template>
  <div
    class="exclusive-tickets-sales"
    :class="{
      'exclusive-tickets-sales-single':  exclusiveTicketsModule.length === 1,
      'exclusive-tickets-sales-empty':  exclusiveTicketsModule.length === 0,
      'exclusive-tickets-sales-multi':  exclusiveTicketsModule.length > 1,
    }"
  >
    <div
      class="teaser-priority-wrapper"
      :style="{ 'background-image': `url(${wrapperBackground})`}"
    >
      <teaser-priority
        :image="getResponsiveImage(pageData.teaser.image)"
        :title="pageData.teaser.title"
        :subtitle="pageData.teaser.subtitle"
        :description="pageData.teaser.description"
      >
        <template slot="button">
          <div class="exclusive-button-component" v-if="shouldShowLoginPriority">
            <button-component
              :label="pageData.teaser.button_text || 'Jetzt für Priority anmelden'"
              button-type="secondary"
              background-color="white"
              class="compact"
              @change="loginButton"
            />
          </div>
        </template>
        <template slot="additional-info" v-if="shouldShowLoginPriority">
          <div class="exclusive-additional-info">
            Erfahre mehr zu
            <a href="https://www.o2online.de/priority/" target="_blank" rel="noopener">Priority</a>
            und <br> entdecke weitere <a href="https://www.o2online.de/priority/" target="_blank" rel="noopener">exklusive Vorteile</a>.
          </div>
        </template>
      </teaser-priority>
    </div>
    <div class="exclusive-tickets-sales-list-wrapper">
      <div class="exclusive-tickets-sales-list">
        <div
          v-for="(artist, $index) in exclusiveTicketsModule"
          class="artist-block"
          :key="$index"
        >
          <div class="artist-content">
            <h1 v-if="$index === 0" v-html="pageData.teaser.headline || 'Aktuellste Pre-Sales:'" />
            <div class="artist-content-wrapper">
              <div
                v-if="artist.tickets_available === false"
                class="artist-ticket-soldout"
                v-html="'+++ Im Vor-Vorverkauf vergriffen +++ Im Vor-Vorverkauf vergriffen'"
              />
              <router-link
                :to="artist.event_serie.journeypages ? artist.event_serie.journeypages.url : ''"
                rel="follow"
                class="artist-content-link"
              >
                <img
                  v-if="!artist.event_serie.image"
                  src="@/assets/images/no-image.jpg"
                  :alt="artist.event_name"
                >
                <img
                  v-else
                  :src="getResponsiveImage(artist.event_serie.image)"
                  :alt="artist.event_name">
              </router-link>
              <div
                class="artist-content-name"
                v-html="getArtist(artist.event_serie.artists)"
                v-resize-text="{ ratio: 0.6, maxFontSize: '32px', delay: 50 }"
              />
              <div class="artist-content-description">
                {{ artist.event_name }}
              </div>
              <div class="artist-content-button">
                <button-component
                  button-type="secondary"
                  :background-color="artist.journey_highlight ? 'blue' : 'blue-border'"
                  :label="artist.journey_highlight ? artist.journey_text : 'Mehr erfahren'"
                  :button-link="{
                    internal: true,
                    to: artist.event_serie.journeypages ? artist.event_serie.journeypages.url : ''
                  }"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="additional-info-mobile">
          Erfahre mehr zu
          <a href="https://www.o2online.de/priority/" target="_blank" rel="noopener">Priority</a>
          und <br> entdecke weitere <a href="https://www.o2online.de/priority/" target="_blank" rel="noopener">exklusive Vorteile</a>.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthService from '@/services/AuthService';
import TeaserPriority from '@/components/teaser/TeaserPriority.vue';

const auth = new AuthService();

export default {
  name: 'exclusive-tickets-sales',
  components: {
    TeaserPriority,
  },
  data() {
    return {
      isLoggedIn: false,
      isPriority: false,
    };
  },
  props: {
    exclusiveTicketsModule: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    wrapperBackground() {
      if (this.exclusiveTicketsModule.length < 2) {
        if (this.isMobile && this.pageData.teaser.background_landscape) {
          return this.getResponsiveImage(this.pageData.teaser.background_landscape);
        }
        return this.getResponsiveImage(this.pageData.teaser.background);
      }
      return '';
    },
    shouldShowLoginPriority() {
      return !(this.isLoggedIn && this.isPriority);
    },
  },
  created() {
    this.handlePriorityRedirect();
  },
  methods: {
    getArtist(artists) {
      return artists.join(', ');
    },
    loginButton() {
      auth.login();
      this.trackActionEvent({
        eventName: 'priorityRegistrationClick',
        eCat: 'Priority - Ticket Presale',
        eAct: 'click - Priority registration',
        eLab: 'Priority for concerts',
        nonInteraction: false,
      });
    },
    async handlePriorityRedirect() {
      if (this.$store.state.cameBackFromRedirect) {
        this.isLoggedIn = await auth.isLoggedIn();
        this.isPriority = await auth.hasPriorityConditionsAccepted();
        if (this.isLoggedIn && !this.isPriority) {
          auth.toLogPriority();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .teaser-priority-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .artist-content-button {
    display: flex;
  }
  .exclusive-tickets-sales {
    background-color: $blue-02;
    display: flex;
    overflow: hidden;

    &-list {
      display: flex;
      width: 100%;

      .artist-block {
        width: 33.33%;
      }
    }
  }
  .exclusive-tickets-sales-single,
  .exclusive-tickets-sales-empty {
    .teaser-priority-wrapper {
      width: calc(100% - 540px);

      @include lg {
        width: calc(100% - 400px);
      }
      ::v-deep .teaser-wrapper {
        height: 100%;

        .teaser {
          h1 {
            font-size: 52px;
            line-height: 1.11;
            letter-spacing: -0.5px;
            margin: 20px 0 20px 0;

            @include sm {
              font-size: 44px;
              line-height: 1.2;
              letter-spacing: -0.2px;
              margin: 20px 0;
            }
          }
          .copy {
            max-width: 407px;
            margin: 0;

            @include lg {
              max-width: 60%;
              width: 60%;
            }
            .description {
              padding-top: 0;
            }
            h2 {
              padding-bottom: 12px;
            }
          }
          .teaser-item img {
            width: 233px;
            max-width: 100%;

            @include sm {
              height: auto;
            }
          }
          .exclusive-button-component {
            padding-top: 25px;
            width: 100%;
            margin-top: auto;
          }
          .exclusive-additional-info {
            font-family: 'OnAirLight';
            font-size: 15px;
            line-height: 1.6;
            padding-top: 15px;

            a {
              text-decoration: underline;
            }

            @include sm {
              display: none;
            }
          }
          @include sm {
            padding: 39px 32px 20px 32px !important;
          }
        }
      }
    }
    .exclusive-tickets-sales-list {
      width: 540px;

      @include lg {
        width: 400px;
      }

      .artist-block {
        width: 100%;
      }
    }
  }
  .exclusive-tickets-sales-empty {
    min-height: 360px;

    .teaser-priority-wrapper {
      width: 100%;
      ::v-deep .teaser-wrapper .teaser {
        padding: 40px 20px 20px 55px;

        .teaser-item {
          display: flex;
          flex-direction: column;

          img {
            width: 200px;
          }

          h1 {
            font-size: 40px;
            width: 100%;
            margin: 20px 0 10px;
            max-width: 561px;
          }
        }

        .copy {
          max-width: 407px;
          margin: 0;

          @include lg {
            max-width: 892px;
            width: 90%;
          }
        }

        .exclusive-button-component {
          padding-top: 29px;
        }

        .exclusive-additional-info {
          font-size: 12px;
          padding-top: 20px;
        }

        @include lg {
          padding-bottom: 0;
          height: calc(100% - 74px);
          padding: 40px 30px 30px 30px;

          .copy {
            margin: 0;
          }
          .exclusive-additional-info {
            position: relative !important;
            bottom: 0;

            br {
              display: none;
            }
          }
          .exclusive-button-component {
            width: 100%;
          }
        }

        @include sm {
          padding: 39px 20px 25px 20px;
          height: calc(100% - 59px);
          .teaser-item h1 {
            font-size: 32px;
          }
        }

        @include min-1180 {
          .teaser-item h1 {
            width: 968px;
            max-width: none;
            margin-bottom: 34px;
          }

          .copy {
            width: 435px;
            max-width: none;
          }

          .exclusive-button-component {
            padding-top: 25px;
          }
        }
        h1 {
          max-width: 700px;
        }
        .description {
          width: 435px;
        }
        h1 {
          max-width: 700px;
        }
        .description {
          width: 435px;
        }

        @include min-1180 {
          .teaser-item h1 {
            width: 968px;
            max-width: none;
            margin-bottom: 34px;
          }

          .copy {
            width: 435px;
            max-width: none;
          }

          .exclusive-button-component {
            padding-top: 25px;
          }
        }
      }
    }

    .exclusive-tickets-sales-list {
      width: 0;
    }

    @include sm {
      min-height: 525px;
      padding-bottom: 0 !important;

      .exclusive-additional-info {
        display: block !important;
        padding-top: 20px;
      }
    }

    .exclusive-tickets-sales-list {
      width: 0;
      display: none;
    }

    .exclusive-additional-info br {
      display: none;
    }
  }
  .artist-content-wrapper {
    background-color: $white;
    padding: 18px;
    width: 280px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    @include sm {
      width: calc(100% - 38px);
    }
  }
  .artist-ticket-soldout {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: $red;
    height: 18px;
    line-height: 18px;
    color: $white;
    font-family: 'OnAirRoman';
    font-size: 12px;
    overflow: hidden;
    white-space: pre;
  }
  .artist-content {
    position: relative;
    padding-top: 0;
    padding-bottom: 80px;
    padding-top: 117px;

    h1 {
      position: absolute;
      font-family: 'OnAirBold';
      color: $white;
      font-size: 26px;
      font-weight: normal;
      line-height: 1.23;
      margin: 0;
      top: 58px;
      left: 109px;

      @include sm {
        padding-top: 10px;
        padding-bottom: 35px;
        text-align: left !important;
      }
    }

    img {
      width: 100%;
    }

    &-name {
      font-family: "OnAirBold";
      font-size: 32px;
      line-height: 1.28;
      color: $blue-01;
      padding-top: 20px;
    }
    &-description {
      font-family: "OnAirRoman";
      font-size: 12px;
      line-height: 1.67;
      color: $blue-01;
      padding-top: 10px;
    }
    &-button {
      padding-top: 18px;
      padding-bottom: 16px;
    }
  }
  .exclusive-tickets-sales-single {
    .artist-content h1 {
      width: auto;
    }
  }

  @include xl {
    .artist-content {
      // width: 252px;

      &-name {
        font-size: 32px;
      }
    }
  }

  @include lg {
    .exclusive-tickets-sales {
      flex-wrap: wrap;
    }
    .teaser-priority-wrapper {
      width: 100%;
    }
    .exclusive-tickets-sales-list {
      width: 100%;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 41px;
    }
    .artist-content {
      position: relative;
      width: calc(100% - 46px);
      height: calc(100% - 180px);
      padding-left: 23px;
      padding-right: 23px;

      h1 {
        top: -70px;
      }
    }
    .teaser-priority-wrapper {
      ::v-deep .teaser-wrapper .teaser {
        flex-wrap: wrap;
        padding: 50px 30px;
        padding-bottom: 0;
        .teaser-item {
          width: 100%;
          text-align: left;
          img {
            float: left;
          }
          h1 {
            float: left;
            width: auto;
            margin-top: 20px;
            margin-left: 30px;
          }
        }
        .copy {
          margin-left: 0;
          margin-bottom: 0;
          h2, .description {
            margin-left: 0;
            margin-right: 0;
            max-width: 100%;
          }
        }
      }
    }
    .exclusive-tickets-sales-single {
      .teaser-priority-wrapper {
        ::v-deep .teaser-wrapper .teaser {
          padding: 80px 48px;
          .teaser-item {
            img {
              float: none;
              padding-top: 0;
            }
            h1 {
              float: none;
              margin-left: 0;
              width: 80%;
            }
          }
          .copy {
            width: 70%;
          }

          @include lg {
            padding-top: 45px;
          }
        }
      }
      .exclusive-tickets-sales-list {
        padding: 0;
      }
      .artist-content {
        padding: 0;
        margin: 0 auto;
        padding-top: 117px;
        padding-bottom: 80px;
        height: auto;

        h1 {
          top: 60px;
          left: 0;
          right: 0;
          text-align: center;

          @include lg {
            top: 45px;
          }
        }
        &-name {
          font-size: 34px;
        }

        @include lg {
          padding-top: 95px;
        }
      }
    }
  }

  @include sm {
    .exclusive-tickets-sales {
      padding-bottom: 25px;
    }
    .teaser-priority-wrapper {
      width: 100% !important;
      ::v-deep .teaser-wrapper .teaser {
        padding: 30px 20px;
        padding-bottom: 0;
        .teaser-item {
          max-width: 90%;
          img {
            float: none;
          }
          h1 {
            float: none;
            margin-left: 0;
            font-size: 52px;
          }
        }
        .copy {
          margin-top: 20px;
          min-width: 100%;
        }
      }
    }
    .exclusive-tickets-sales-list {
      width: calc(100% - 64px) !important;
      flex-wrap: wrap;
      padding-left: 32px !important;
      padding-right: 32px !important;
      .artist-block {
        width: 100%;
      }
    }
    .artist-content {
      width: 100% !important;
      max-width: 100%;
      padding: 0;
      padding-top: 25px !important;
      height: auto;
      margin-bottom: 0 !important;
      padding-bottom: 25px !important;

      &-name {
        font-size: 20px !important;
        line-height: 1.22;
        padding-top: 17px;
      }

      &-description {
        font-size: 12px;
        line-height: 1.5;
        padding-top: 10px;
      }

      h1 {
        position: relative;
        top: 0 !important;
        margin: 0;
      }

      &-button {
        position: relative;
        padding-bottom: 0;
        padding-top: 20px;

        @include sm {
          padding-bottom: 16px;
        }
      }
    }
  }
  .additional-info-mobile {
    display: none;

    @include sm {
      display: block;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: $white;
      font-family: 'OnAirLight';
    }

    a {
      text-decoration: underline;
    }
  }
  .exclusive-tickets-sales-multi {
    position: relative;
    @include xl {
      padding-bottom: 40px;
      flex-wrap: wrap;
    }
    @include sm {
      padding-bottom: 25px;
    }
    .artist-content {
      width: calc(100% - 36px);
      max-width: calc(382px - 66px);
      margin: 0 auto;
      padding-bottom: 0;
      height: calc(100% - 117px);

      @include xl {
        height: auto;
        padding-top: 25px;
        padding-bottom: 40px;
        width: calc(100% - 40px);
        max-width: calc(100% - 40px);
        height: calc(100% - 65px);
      }
      @include sm {
        width: 100% !important;
        max-width: 100% !important;
        height: auto;
      }
    }
    .artist-block {
      width: 33.3%;
      height: auto;
      @include sm {
        width: 100%;
      }
    }
    .artist-content-wrapper {
      width: auto;
      height: calc(100% - 36px);
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      flex-flow: column nowrap;
    }
    .artist-content-link {
      width: 100%;
    }
    .artist-content-button {
      margin-top: auto;
    }
    .artist-content h1 {
      left: auto;
      white-space: nowrap;

      @include xl {
        top: -25px;
      }
      @include sm {
        white-space: normal;
      }
    }
    .teaser-priority-wrapper {
      @include xl {
        width: 100%;
      }
      ::v-deep .teaser-wrapper {
        height: 100%;
        .teaser {
          padding: 50px 30px 25px 55px;
          height: calc(100% - 75px);

          @include xl {
            padding: 44px 30px 25px 30px;
            height: calc(100% - 69px);
          }
          @include sm {
            padding: 39px 32px 0 32px !important;
            height: calc(100% - 39px);
          }
          h1 {
            font-size: 52px;
            line-height: 1.11;
            letter-spacing: -0.5px;
            margin: 20px 0 20px 0;

            @include xl {
              font-size: 44px;
              line-height: 1.2;
              letter-spacing: -0.2px;
              margin: 20px 0;
              display: block;
              width: 100%;
            }
          }
          .copy {
            max-width: 407px;
            margin: 0;
            .description {
              padding-top: 0;
            }
            h2 {
              padding-bottom: 12px;
            }
            @include xl {
              max-width: 60%;
              padding-top: 0;
              margin-top: 0;
            }
          }
          .teaser-item {
            @include sm {
              max-width: 100%;
            }
          }
          .teaser-item img {
            width: 233px;
            max-width: 100%;

            @include sm {
              height: auto;
            }
          }
          .exclusive-button-component {
            padding-top: 25px;
            @include xl {
              display: block;
              width: 100%;
              padding-bottom: 10px;
            }
            @include sm {
              padding-bottom: 5px;
              padding-top: 20px;
            }
          }
          .exclusive-additional-info {
            font-family: 'OnAirLight';
            font-size: 15px;
            line-height: 1.6;
            padding-top: 15px;

            a {
              text-decoration: underline;
            }

            @include xl {
              display: none;
            }
          }
        }
      }
    }
    .exclusive-tickets-sales-list-wrapper {
      width: 75%;
      padding-bottom: 25px;
      @include xl {
        width: 100%;
        padding-bottom: 0;
      }
    }
    .exclusive-tickets-sales-list {
      width: 100%;
      @include xl {
        padding-top: 25px;
        padding-left: 10px;
        padding-right: 10px;
        width: calc(100% - 20px) !important;
      }
      @include sm {
        padding-top: 0;
        width: calc(100% - 64px) !important;
      }
    }
    .additional-info-mobile {
      @include xl {
        position: absolute;
        bottom: 0;
        display: block;
        color: $white;
        font-size: 14px;
        line-height: 1.57;
        font-family: 'OnAirLight';
        left: 30px;
        bottom: 30px;
        br {
          display: none;
        }
      }
      @include sm {
        position: relative;
        left: 0;
        bottom: 0;
      }
    }
  }
</style>
