<template>
  <transition name="bounce-in-up">
    <div v-if="showFeedback" class="footer-feedback">
      <div class="feedback">
        <div class="feedback-text">
          Dein Feedback zur Website ist uns sehr wichtig.
        </div>
        <div class="feedback-close" @click="onCLoseFeedback" />
        <div class="feedback-button">
          <button-component
          class="button-component"
          label="Feedback geben"
          background-color="blue"
          button-type="secondary"
          @change="onClickEvent"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'feedback',
  data: () => ({
    showFeedback: false,
  }),
  methods: {
    onClickEvent() {
      if (window.KAMPYLE_ONSITE_SDK && this.isUserAcceptCookies()) {
        window.KAMPYLE_ONSITE_SDK.showForm(16401);
      } else {
        window.open(
          'https://assets.kampyle.com/resources/direct/form.html?region=prodEuIrland&websiteId=330018&formId=16401',
          '_blank',
        );
      }
    },
    onCLoseFeedback() {
      this.showFeedback = !this.showFeedback;
      sessionStorage.setItem('showFeedbackSS', true);
    },
  },
  mounted() {
    const showFeedbackSS = !!sessionStorage.getItem('showFeedbackSS');
    if (!showFeedbackSS) {
      setTimeout(() => {
        this.showFeedback = true;
      }, 2000);
    }
  },
};
</script>

<style lang="scss" scoped>
  @keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(2000px);
    }
    60% {
      opacity: 1;
      transform: translateY(-30px);
    }
    80% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .bounce-in-up-enter-active {
    animation: bounce 1s;
  }
  .bounce-in-up-leave-active {
    animation: bounce 1s reverse;
  }
  .footer-feedback {
    position: absolute;
    background-image: url('~@/assets/images/video-overlay-feedback/feedback-bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 237px;
    height: 142px;
    bottom: 30px;
    right: 22px;

    @include sm {
      bottom: 15px;
      right: 15px;
    }
  }
  .feedback-text {
    padding: 15px;
    padding-right: 30px;
    font-size: 14px;
    font-family: 'OnAirBold';
  }
  .feedback-button {
    padding-left: 15px;
  }
  .feedback-close {
    width: 15px;
    height: 15px;
    background-image: url('~@/assets/images/video-overlay-feedback/close-button.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }
</style>
