<template>
  <compontent
    class="rotating-badge"
    :class="{'is-clickable': hasLink}"
    :is="'div'"
    :style="{ backgroundImage }"
    @click="onClick"
  >
    <svg class="rotating-badge__text" viewBox="0 0 190 190">
      <defs><path id="p" d="M 95, 95 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0"/></defs>
      <text>
        <textPath fill="currentColor" style="font-size: 21px;" xlink:href="#p">{{ text }}</textPath>
      </text>
    </svg>
  </compontent>
</template>

<script>
export default {
  name: 'rotating-badge',
  props: {
    icon: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
  },
  computed: {
    backgroundImage() {
      return this.getBadgeImage(this.icon) || `url(${require('@/assets/icons/tv.svg')})`;
    },
    hasLink() {
      return !!this.link;
    },
  },
  methods: {
    getBadgeImage(image) {
      if (!image) return '';
      return `url(${this.getResponsiveImage(image)})`;
    },
    onClick() {
      if (this.hasLink) this.$router.push(this.link);
    },
  },
};
</script>

<style lang="scss" scoped>

.rotating-badge {
  width: 115px;
  height: 115px;
  position: relative;
  overflow: hidden;
  background: $red;
  border-radius: 100%;
  font-weight: bold;
  color: white;
  background-position: center;
  background-size: 35%;
  background-repeat: no-repeat;
  z-index: 2;

  &.is-clickable {
    cursor: pointer;
  }

  &__text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation-name: rotate;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @include safari {
      letter-spacing: -1px;
    }
  }
}

@keyframes rotate {
  from { transform: rotate(360deg); }
  to { transform: rotate(0); }
}
</style>
