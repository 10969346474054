<template>
  <div class="back-button" @click="clickHandler">
    Zurück
  </div>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    onClick: {
      type: Function,
      required: false,
    },
  },
  data: () => ({}),
  methods: {
    goBack() {
      this.$router.go(-1);
      const originRoute = this.$route.path;
      this.$nextTick(() => {
        if (this.$route.path === originRoute) {
          this.$router.push('/');
        }
      });
    },
    clickHandler() {
      if (this.onClick && typeof this.onClick === 'function') this.onClick();
      else this.goBack();
    },
  },
};
</script>

<style lang="scss" scoped>
.back-button {
  display: block;
  width: 70px;
  color: $blue-01;
  font-family: "OnAirBold";
  font-size: 12px;
  line-height: 1.51;
  position: relative;
  padding: 40px 0 40px 40px;
  cursor: pointer;
  background: url('~@/assets/icons/arrow.svg') no-repeat center left;
  @include sm {
    padding: 30px 0 30px 40px;
  }
}
</style>
