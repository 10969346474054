<template>
  <div
    class="horizontal-crawler"
    :class="{ 'disable-canvas-wrapper': !showInMobile }"
  >
    <div
      class="canvas-wrapper"
      ref="canvasWrapper"
    >
      <canvas ref="canvas"></canvas>
    </div>
    <h2 class="crawler-title">
      {{ text }}
    </h2>
  </div>
</template>
<script>
const defaultConfig = {
  1920: {
    fontSize: 100,
    colors: ['#000066', '#41b6e6'],
    gradientCount: 4,
  },
  1680: {
    fontSize: 86,
    colors: ['#000066', '#41b6e6'],
    gradientCount: 4,
  },
  1440: {
    fontSize: 80,
    colors: ['#000066', '#41b6e6'],
    gradientCount: 4,
  },
  1280: {
    fontSize: 70,
    colors: ['#000066', '#41b6e6'],
    gradientCount: 4,
  },
  993: {
    fontSize: 70,
    colors: ['#000066', '#41b6e6'],
    gradientCount: 2,
  },
};

export default {
  name: 'horizontal-crawler',
  props: {
    text: {
      type: String,
      default: '+++Info ',
    },
    fontFamily: {
      type: String,
      default: 'OnAirThin',
    },
    canvasConfig: {
      type: Object,
      default: () => (defaultConfig),
    },
    showInMobile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    x: 0,
    speed: -1.5,
    canvas: null,
    ctx: null,
    animation: null,
    offsetWidth: 0,
    config: {},
  }),
  methods: {
    createCanvas() {
      const { canvas } = this.$refs;
      if (!canvas && !canvas.getContext) return;
      const ctx = canvas.getContext('2d');
      const { offsetWidth, offsetHeight } = this.$refs.canvasWrapper;
      this.offsetWidth = offsetWidth;
      canvas.setAttribute('width', offsetWidth);
      canvas.setAttribute('height', offsetHeight);
      this.canvas = canvas;
      this.ctx = ctx;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.animation = setInterval(() => {
        this.updateCanvas();
      }, 30);
    },
    drawText() {
      let index = Object.keys(this.canvasConfig).find((el) => +el >= window.innerWidth);
      if (!index) {
        index = Object.keys(this.canvasConfig).reduce((acc, cur) => (+acc > +cur ? acc : cur), 0);
      }
      this.config = this.canvasConfig[index];
      const { canvas, ctx } = this;
      canvas.height = this.config.fontSize;

      const txt = `${this.text} `;
      ctx.font = `normal ${this.config.fontSize}px ${this.fontFamily}`;
      const grd = ctx.createLinearGradient(0, 0, this.offsetWidth, 0);

      let fullGrd = 0;
      let colorIndex = 0;
      if (this.config.gradientCount === 1) {
        const leftMargin = 19 / window.innerWidth;
        grd.addColorStop(0, this.config.colors[0]);
        grd.addColorStop(leftMargin, this.config.colors[0]);
        grd.addColorStop(leftMargin, this.config.colors[1]);
        grd.addColorStop(1 - leftMargin, this.config.colors[1]);
        grd.addColorStop(1 - leftMargin, this.config.colors[0]);
        grd.addColorStop(1, this.config.colors[0]);
      } else {
        Array(this.config.gradientCount).fill('').map((el) => {
          const selfGrid = fullGrd;
          fullGrd = selfGrid + 100 / this.config.gradientCount;
          grd.addColorStop(selfGrid / 100, this.config.colors[colorIndex]);
          grd.addColorStop(fullGrd / 100, this.config.colors[colorIndex]);
          colorIndex = colorIndex === 1 ? 0 : 1;
          return el;
        });
      }

      ctx.fillStyle = grd;
      const textWidth = ctx.measureText(txt).width;
      const initialData = {
        left: this.x,
        top: `${this.config.fontSize / 1.3}`,
      };
      let count = initialData.left + textWidth + 5;
      ctx.fillText(txt, initialData.left, initialData.top);
      while (count < canvas.width) {
        ctx.fillText(txt, count, initialData.top);
        count += textWidth + 5;
      }
    },
    updateCanvas() {
      const { canvas, ctx } = this;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.drawText();
      this.moveCanvas();
    },
    moveCanvas() {
      this.x += this.speed;
    },
    onResizeApp() {
      clearInterval(this.animation);
      this.createCanvas();
    },
    destroyCanvas() {
      const { canvas, ctx } = this;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      clearInterval(this.animation);
    },
  },
  mounted() {
    this.createCanvas();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResizeApp);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResizeApp);
    this.destroyCanvas();
  },
};
</script>
<style lang="scss" scoped>
  .canvas-wrapper {
    width: 100%;
  }
  .crawler-title {
    display: none;
  }
  @include sm {
    .crawler-title {
      font-family: 'OnAirThin';
      font-size: 45px;
      line-height: 1.16;
      letter-spacing: 0.5px;
      color: $blue-01;
      text-align: center;
      font-weight: normal;
      margin-top: 0;
    }
    .disable-canvas-wrapper {
      .canvas-wrapper {
        display: none;
      }
      .crawler-title {
        display: block;
      }
    }
  }
</style>
