<template>
  <div class="grid-lines" :class="classList">
    <div class="grid-line grid-line-1" />
    <div class="grid-line grid-line-2" />
    <div class="grid-line grid-line-3" />
  </div>
</template>

<script>
export default {
  name: 'GridLines',
  props: {
    classList: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-lines {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: $white;

  &.disable-sm {
    @include sm {
      display: none;
    }
  }
}

.grid-line {
  content: "";
  position: absolute;
  width: 1px;
  top: 0;
  bottom: 0;
  z-index: -1;

  &.grid-line-1 {
    border-left: 1px solid $very-light-grey;
    left: 25%;

    @include lg {
      left: 31px;
    }

    @include sm {
      display: block;
      left: 19px;
    }
  }

  &.grid-line-2 {
    border-left: 1px solid $very-light-grey;
    left: calc(50% - 1px);

    @include sm {
      display: none;
    }
  }

  &.grid-line-3 {
    border-right: 1px solid $very-light-grey;
    left: calc(75% - 2px);

    @include lg {
      left: auto;
      right: 31px;
    }

    @include sm {
      display: block;
      left: auto;
      right: 19px;
    }
  }
}
</style>
