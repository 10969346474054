export default {
  computed: {
    pageName() {
      if (this.$route.name === 'Mediathek' && this.$route.params.videoSlug) {
        return '/mediathek';
      }
      return this.$route.path;
    },
    draftPreview() {
      return this.$route.query.draft_preview;
    },
    pageData() {
      return this.draftPreview ? this.$store.getters.getPropDraftPage(this.pageName)
        : this.$store.getters.getPropPage(this.pageName);
    },
  },
  mounted() {
    window.$store = this.$store;
  },
};
