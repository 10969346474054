<template>
  <div class="takeover-teaser-slide">
    <div
      class="slide-item slide-item-artist-ticket"
      :class="{ 'slide-item-artist-ticket-full': !priorityTicketsTickerEnabled }"
    >
      <div class="slide-item-artist">
        <div class="event-logo">
          <img
            v-if="localImage"
            :src="getResponsiveImage(localImage)"
            alt="artist-image"
          >
        </div>
      </div>
      <div class="slide-item-ticket">
        <div class="slide-item-ticket-content">
          <div class="ticket-info">
            <div class="ticket-title" v-html="text" />
            <div v-if="eventLink" class="ticket-button">
              <button-component
                class="button-component cta-link"
                :class="buttonId"
                :label="buttonText"
                background-color="blue"
                button-type="primary"
                :button-link="{
                  internal: eventLink.type === 'internal_link',
                  to: eventLink.value || '/'
                }"
              />
            </div>
            <div v-if="calendarLink.calendar_file" class="calendar-button">
              <button-component
                class="calendar-link"
                :class="buttonId"
                background-color="blue"
                button-type="primary"
                :label="calendarLink.calendar_button_text"
                :icon-props="{ enabled: true, name: 'calendar calendar--white' }"
                :button-link="{
                  internal: false,
                  to: calendarLink.calendar_file.download_url
                }"
              />
            </div>
            <div v-if="disruptorEnabled">
              <div
                v-if="!inStock"
                class="ticket-online-badge"
                :style="{'background-image': getBadgeImage(disruptor)  }"
              />
              <div v-else class="ticket-online-badge ticket-online-badge--sold-out" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="priorityTicketsTickerEnabled"
      class="slide-item slide-item-crawler"
    >
      <PresaleTeaserCrawler type="white" />
    </div>
    <div class="slide-item slide-item-mobile-item">
      <div class="slide-item-ticket-content">
        <div class="ticket-info">
          <div class="ticket-title" v-html="text" />
          <div v-if="eventLink" class="ticket-button">
            <button-component
              class="button-component"
              :class="buttonId"
              :label="buttonText"
              background-color="blue"
              button-type="primary"
              :button-link="{
                internal: eventLink.type === 'internal_link',
                to: eventLink.value || '/'
              }"
            />
          </div>
          <div v-if="calendarLink.calendar_file" class="calendar-button">
              <button-component
                background-color="blue"
                :class="buttonId"
                button-type="primary"
                :label="calendarLink.calendar_button_text"
                :icon-props="{ enabled: true, name: 'calendar calendar--white' }"
                :button-link="{
                  internal: false,
                  to: calendarLink.calendar_file.download_url
                }"
              />
            </div>
          <div v-if="disruptorEnabled">
            <div
              v-if="!inStock"
              class="ticket-online-badge"
              :style="{'background-image': getBadgeImage(disruptor)  }"
            />
            <div v-else class="ticket-online-badge ticket-online-badge--sold-out" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PresaleTeaserCrawler
  from '@/views/common/components/PresaleTeaser/presale-teaser-crawler.vue';

export default {
  name: 'takeover-teaser',
  components: {
    PresaleTeaserCrawler,
  },
  props: {
    inStock: {
      type: Boolean,
      default: false,
    },
    hours: {
      type: [Number, String],
      default: 0,
    },
    minutes: {
      type: [Number, String],
      default: 0,
    },
    seconds: {
      type: [Number, String],
      default: 0,
    },
    presaleEnd: {
      type: Date,
      default: '',
    },
    artist: {
      type: String,
      default: '',
    },
    eventLink: {
      type: Object,
      default: () => ({}),
    },
    event: {
      type: String,
      default: '',
    },
    soldOutMessage: {
      type: String,
      default: 'WOW – alle Prioirty Tickets schon weg.',
    },
    text: {
      type: String,
      default: '',
    },
    localImage: {
      type: Object,
      default: () => ({}),
    },
    buttonId: {
      type: String,
      required: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    calendarLink: {
      type: Object,
      required: () => ({}),
    },
    disruptorEnabled: {
      type: Boolean,
      default: false,
    },
    priorityTicketsTickerEnabled: {
      type: Boolean,
      default: false,
    },
    disruptor: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({

  }),
  computed: {
    maxTitleFontSize() {
      if (this.isMobile) {
        return 46;
      }
      if (this.isTablet) {
        return 56;
      }
      return 82;
    },
    maxSubTitleFontSize() {
      if (this.isMobile) {
        return '20px';
      }
      if (this.isTablet) {
        return '20px';
      }
      return '26px';
    },
  },
  methods: {
    getBadgeImage(disruptor) {
      if (disruptor) {
        return `url(${this.getResponsiveImage(disruptor)})`;
      }
      return {};
    },
    getTicketDate(dateData) {
      if (!dateData) {
        return '-';
      }
      const day = `0${dateData.getDate()}`.slice(-2);
      const month = `0${dateData.getMonth() + 1}`.slice(-2);
      const year = dateData.getFullYear();
      return `${day}.${month}.${year}`;
    },
    getTicketTime(dateData) {
      if (!dateData) {
        return '-';
      }
      const hours = `0${dateData.getHours()}`.slice(-2);
      const minutes = `0${dateData.getMinutes()}`.slice(-2);
      return `${hours}:${minutes}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes rotate{
  to{ transform: rotate(360deg); }
}
.takeover-teaser-slide {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.slide-item {
  height: 100%;
  @include sm {
    height: auto;
  }
}
.slide-item-artist-ticket {
  display: flex;
  width: calc(100% - 56px);

  @include lg {
    width: calc(100% - 40px);
  }
  @include sm {
    width: 100%;
    flex-wrap: wrap;
  }
  &.slide-item-artist-ticket-full {
    width: 100%;
  }
}
.slide-item-artist {
  width: 58%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include lg {
    width: 58%;
  }
  @include sm {
    width: 100%;
    min-height: 300px;
    order: 1;
  }

  .artist-countdown {
    padding-top: 40px;
    font-size: 35px;
    font-family: 'OnAirBold';
    line-height: 1.02;
    letter-spacing: normal;
    color: $blue-02;

    @include lg {
      padding-top: 25px;
    }
    @include sm {
      padding-top: 25px;
      font-size: 36px;
    }
  }
  .artist-countdown-off {
    min-height: 100px;

    @include lg {
      min-height: 85px;
    }
    @include sm {
      min-height: 35px;
    }
  }
  .countdown-timer {
    position: relative;
    margin-right: 28px;
    display: inline-block;
    width: 40px;

    &:last-child {
      margin-right: 22px;
    }

    &::after {
      content: 'Std.';
      position: absolute;
      top: 10px;
      right: -24px;
      font-family: 'OnAirRoman';
      font-size: 14px;
      line-height: 1.07;
      transform: rotate(-90deg);
    }
    &-hours::after {
      right: -25px;
    }
    &-minute::after {
      content: 'Min.';
      right: -27px;
    }
    &-second::after {
      content: 'Sec.'
    }
  }
  .artist-appointment {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: $blue-02;
    padding-top: 10px;
    font-family: 'OnAirLight';
  }
  .artist-image {
    width: 220px;
    margin: 0 auto;
    padding-top: 18px;
    img {
      width: 100%;
    }

    @include lg {
      padding-top: 15px;
      width: 154px;
    }
    @include sm {
      padding-top: 15px;
      width: 146px;
    }
  }
  .artist-title {
    font-size: 80px;
    font-family: 'OnAirBold';
    line-height: 1;
    color: $blue-02;
    padding-top: 30px;
    h2 {
      display: inline-block;
      margin: 0;
      @include linear-font-size(36px, 62px, $screen-320-sm-min);
    }

    @include lg {
      padding-top: 20px;
    }
    @include sm {
      font-size: 30px;

      h2 {
        font-size: inherit !important;
      }
    }
  }
  .artist-tour-title {
    font-size: 26px;
    line-height: 1.1;
    font-family: 'OnAirBold';
    color: #0019a5;
    color: $blue-02;
    padding-top: 16px;
    h3 {
      margin: 0;
      @include linear-font-size(20px, 26px, $screen-320-sm-min);
    }

    @include lg {
      padding-top: 13px;
    }
    @include sm {
      padding-bottom: 32px;
    }
  }
}
.slide-item-artist .event-logo {
  width: 100%;
  img {
    width: 100%;
  }
}
.slide-item-ticket, .slide-item-mobile-item {
  width: 42%;
  background-color: $white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: $blue-02;
  position: relative;

  @include lg {
    width: 42%;
  }
  @include sm {
    width: 100%;
    display: none;
  }

  &-content {
    padding: 72px 50px;
    width: calc(100% - 100px);

    @include xl {
      padding: 44px;
      width: calc(100% - 88px);
    }
    @include lg {
      padding: 19px;
      width: calc(100% - 38px);
    }
    @include sm {
      padding: 17px;
      width: calc(100% - 34px);
    }
  }
  .ticket-title {
    font-size: 32px;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    font-family: 'OnAirBold';
    color: $blue-02;
    padding-bottom: 40px;

    @include lg {
      font-size: 26px;
      line-height: 1.32;
      padding-bottom: 40px;
    }
    @include sm {
      padding-bottom: 20px;
      font-size: 20px;
    }
  }
  .ticket-subtitle {
    font-family: 'OnAirLight';
    font-size: 15px;
    line-height: 1.6;
    padding-top: 20px;
    padding-bottom: 40px;
    width: 386px;
    margin: 0 auto;
    max-width: 95%;

    @include lg {
      padding-bottom: 35px;
      font-size: 14px;
    }
    @include sm {
      padding-bottom: 10px;
      line-height: 1.57;
      max-width: 100%;
      width: 100%;
    }
  }
  .ticket-logo {
    background-image: url('~@/assets/images/presale-teaser/o2-logo-blue.svg');
    height: 78px;
    width: 78px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 0 auto;
    margin-bottom: 20px;
    @include lg {
      width: 72px;
      height: 72px;
    }
    @include sm {
      width: 40px;
      height: 40px;
    }
  }
  .ticket-button::v-deep,
    .calendar-button {

    .button-component {
      width: 262px;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;

      @include lg {
        width: 240px;
      }
    }
    @include sm {
      display: none;
    }
  }
  .calendar-button {
    margin-top: 10px;

    .button-component {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .ticket-notification {
    font-family: 'OnAirBold';
    font-size: 15px;
    line-height: 1.6;
    color: $white;
    position: absolute;
    bottom: 25px;
    left: 19px;
    right: 19px;

    &--sold-out {
      font-size: 15px;
      line-height: 1.6;
      font-family: 'OnAirLight';

      a {
        text-decoration: underline;
      }
    }

    @include lg {
      font-size: 14px;
      line-height: 1.57;
      bottom: 20px;
    }
    @include sm {
      display: none;
    }
  }
  .ticket-online-badge {
    position: absolute;
    width: 140px;
    height: 140px;
    background-image: url('~@/assets/images/presale-teaser/badge-new.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    top: -43px;
    right: 30px;
    transform: rotate(0deg);
    animation: rotate 18s linear infinite;

    @include lg {
      width: 100px;
      height: 100px;
      right: 20px;
      top: -30px;
    }
    @include sm {
      width: 75px;
      height: 75px;
      right: -25px;
      top: -48px;
      z-index: 1000;
    }

    &--sold-out {
      background-image: url('~@/assets/images/presale-teaser/badge-new.png');
    }
  }
}
.slide-item-crawler {
  overflow: hidden;
  background-color: $blue-02;
}
.slide-item-crawler {
  width: 56px;

  @include lg {
    width: 40px;
  }
  @include sm {
    height: 31px;
    width: 100%;
    order: 2;
  }
}
.slide-item-mobile-item {
  display: none;
  background-color: $white;
  width: calc(100% - 38px);
  color: $white;
  padding: 0 19px;
  min-height: 280px;

  .ticket-button {
    padding: 20px 0;
  }
  @include sm {
    display: block;
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ticket-notification {
    font-size: 13px;
    line-height: 1.69;
    font-family: 'OnAirBold';
    padding-bottom: 12px;
    &--sold-out {
      font-size: 15px;
      line-height: 1.6;
      font-family: 'OnAirLight';

      a {
        text-decoration: underline;
      }
    }
  }
  .ticket-button,
  .calendar-button {
    display: block;
  }
}
</style>
