<template>
  <div v-if="slides.length" class="similar-event-series">
    <h3 class="headline">{{ headline }}</h3>
    <swiper
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="slide in slides"
        :key="slide.id"
      >
        <span @click="onSlideClick(slide.id)">
          <span
            v-negative="{
              mouse_over: true,
              mouse_over_text: 'mehr',
            }"
          >
            <img class="artist-image" :src="slide.imageUrl" :alt="slide.artistName" />
          </span>
          <h4 class="artist-name">{{ slide.artistName }}</h4>
        </span>
      </swiper-slide>
    </swiper>
    <div class="swiper-nav-buttons">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script>
const FORTY_EIGHT_HOURS = 1000 * 60 * 60 * 48;
const UNITS = {
  second: { duration: 1000, text: 'Sek.' },
  minute: { duration: 60000, text: 'Min.' },
  hour: { duration: 3600000, text: 'Std.' },
  day: { duration: 86400000, text: 'Tagen' },
};

export default {
  name: 'SimilarArtists',
  props: {
    similarEventSeries: {
      type: Array,
    },
    headline: {
      type: String,
      default: 'Ähnliche Künstler:',
    },
  },
  data() {
    return {
      slides: [],
      now: new Date(),
      swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true,
        grabCursor: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  methods: {
    getImageUrl(eventSeries) {
      if (eventSeries.local_image) {
        return eventSeries.local_image.download_url;
      }
      return eventSeries.image;
    },
    onSlideClick(id) {
      const slide = this.slides.find((s) => s.id === id);
      this.$router.push({ path: slide.link });
    },
    getUnit(timeDifference) {
      if (timeDifference >= (49 * UNITS.hour.duration)) {
        return UNITS.day;
      }
      if (timeDifference >= (99 * UNITS.minute.duration)) {
        return UNITS.hour;
      }
      if (timeDifference >= (99 * UNITS.second.duration)) {
        return UNITS.minute;
      }
      return UNITS.second;
    },
    getValue(timeDifference, unit) {
      const val = Math.round(timeDifference / unit.duration);
      return Math.max(val, 0);
    },
    updateSimilarArtists() {
      const {
        similar_artists_presale_running: presaleRunningText,
        similar_artists_fallback: presaleRunningTextFallback,
      } = this.pageData;

      for (let i = 0; i < this.similarEventSeries.length; i += 1) {
        const { event_series: eventSeries } = this.similarEventSeries[i];

        const endDate = eventSeries.presale_end ? new Date(eventSeries.presale_end) : null;
        const startDate = endDate ? new Date(endDate.getTime() - FORTY_EIGHT_HOURS) : null;
        const isPresaleRunning = endDate !== null && startDate < this.now && this.now < endDate;

        if (eventSeries.available) {
          this.slides.push({
            id: this.similarEventSeries[i].id,
            imageUrl: this.getImageUrl(eventSeries),
            link: eventSeries.link,
            artistName: eventSeries.eventserie,
            presaleStart: startDate,
            presaleEnd: endDate,
            isPresaleRunning: isPresaleRunning,
            buttonText: isPresaleRunning ? presaleRunningText : presaleRunningTextFallback,
          });
        }
      }
    },
  },
  mounted() {
    this.updateSimilarArtists();
  },
};
</script>

<style lang="scss" scoped>
.similar-event-series {
  width: 100%;
}
.headline {
  margin: 0 0 30px 19px;
  color: $blue-01;
  font-size: 20px;
  font-family: "OnAirBold";
  font-weight: normal;
}
.swiper-slide {
  width: 150px;
  margin-right: 25px;

  &:first-child {
    margin-left: 19px;
  }
}
.artist-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.artist-name {
  margin: 10px 0 0 0;
  color: $blue-01;
  font-size: 15px;
  font-family: "OnAirBold";
  font-weight: normal;
  max-width: 150px;
}
.journey-button {
  max-width: 100%;
  margin-top: 9px;
  padding: 5px 15px;
  box-sizing: border-box;
  border: 1px solid $blue-02;
  font-size: 10px;
  font-family: "OnAirBold";
  font-weight: normal;
  background-color: $white;
  color: $blue-02;

  &:hover {
    color: $white;
  }

  &.presale-running {
    background-color: $blue-02;
    color: $white;
  }
}

.swiper-nav-buttons {
  display: flex;
  margin: 16px auto 0;
  justify-content: flex-end;
  height: 35px;
  gap: 15px;

  .swiper-button-prev,
  .swiper-button-next {
    cursor: pointer;
    position: relative;
    background-image: url('~@/assets/images/presale-teaser/arrow-active.svg');
    background-size: cover;
    background-color: #0019a5;
    width: 50px;
    height: 35px;
    left: auto;
    right: auto;
    top: auto;
    margin-top: 0;

    &::after {
      content: "";
      display: none;
    }

    @include sm {
      width: 40px;
      height: 28px;
    }
  }
  .swiper-button-prev {
    transform: rotate(180deg);
  }
}
</style>
