<template>
  <div class="presale-sold-out">
    <p class="first-line" v-html="presaleSoldoutFirstLine" />
    <p class="second-line">
      Der offizielle Vorverkauf startet <br>
      am {{ getTicketDate(presaleStart) }} um {{ getTicketTime(presaleStart) }} Uhr
    </p>
  </div>
</template>

<script>
export default {
  name: 'PresaleSoldOut',
  props: ['presaleStart'],
  computed: {
    presaleSoldoutFirstLine() {
      return this.pageData.presale_soldout_first_line || 'Unsere Priority Tickets sind leider vergriffen.';
    },
  },
  methods: {
    getTicketDate(dateData) {
      if (!dateData) {
        return '-';
      }
      const day = `0${dateData.getDate()}`.slice(-2);
      const month = `0${dateData.getMonth() + 1}`.slice(-2);
      const year = dateData.getFullYear();
      return `${day}.${month}.${year}`;
    },
    getTicketTime(dateData) {
      if (!dateData) {
        return '-';
      }
      const hours = `0${dateData.getHours()}`.slice(-2);
      const minutes = `0${dateData.getMinutes()}`.slice(-2);
      return `${hours}:${minutes}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.presale-sold-out {
  padding: 60px 30px 50px 30px;
  background-color: $blue-02;
  margin: -3px
}
.first-line {
  font-family: "OnAirBold";
  font-weight: normal;
  line-height: 1.4;
  color: $white;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.second-line {
  color: $white;
  font-family: "OnAirBold";
  font-size: 15px;
  line-height: 1.6;
}
</style>
