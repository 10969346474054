<template>
  <div class="ticket-alarm">
    <horizontal-crawler
      :text="ticker_text"
      :canvas-config="canvasConfig"
    />
    <div class="ticket-alarm-button">
      <button-component
        button-type="primary"
        background-color="red"
        :label="ticket_alarm_button_text"
        :icon-props="{ enabled: true, name: 'news news--white' }"
        @change="onClickButton"
      />
    </div>
  </div>
</template>
<script>
import HorizontalCrawler from '@/components/horizontal-crawler/HorizontalCrawler.vue';

export default {
  name: 'ticker-module',
  props: {
    ticker_text: {
      type: String,
      default: 'Kein Konzert mehr verpassen.',
    },
    ticket_alarm_button_text: {
      type: String,
      default: 'Jetzt Ticketalarm einrichten',
    },
    ticker_text_color: {
      type: String,
    },
    ticker_text_color_alternative: {
      type: String,
    },
  },
  components: {
    HorizontalCrawler,
  },
  computed: {
    canvasConfig() {
      const colors = [
        this.ticker_text_color,
        this.ticker_text_color_alternative,
      ];
      return {
        1920: {
          fontSize: 100,
          colors,
          gradientCount: 4,
        },
        1680: {
          fontSize: 86,
          colors,
          gradientCount: 4,
        },
        1440: {
          fontSize: 80,
          colors,
          gradientCount: 4,
        },
        1280: {
          fontSize: 70,
          colors,
          gradientCount: 4,
        },
        993: {
          fontSize: 70,
          colors,
          gradientCount: 2,
        },
      };
    },
  },
  methods: {
    onClickButton() {
      this.$router.replace({ hash: 'ticketalarm' }).catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
  .ticket-alarm {
    width: auto;
    text-align: center;
    margin-top: 142px;
    margin-bottom: 90px;
  }
  .ticket-alarm-button {
    margin-top: 53px;
  }
  @include xl-1440 {
    .ticket-alarm {
      margin-top: 102px;
    }
    .ticket-alarm-button {
      margin-top: 47px;
    }
  }
  @include xl {
    .ticket-alarm {
      margin-top: 90px;
      margin-bottom: 96px;
    }
    .ticket-alarm-button {
      margin-top: 44px;
    }
  }
  @include lg {
    .ticket-alarm-button {
      margin-top: 29px;
    }
    .ticket-alarm {
      width: calc(100% - 62px);
      margin: 0 auto;
      margin-top: 74px;
      margin-bottom: 90px;
      overflow: hidden;
    }
  }
  @include sm {
    .ticket-alarm {
      width: calc(100% - 38px);
      margin-top: 65px;
      margin-bottom: 65px;
    }
    .canvas-wrapper {
      display: none;
    }
    .ticket-alarm-button {
      margin-top: 35px;
    }
    .ticket-alarm-title {
      display: block;
      font-family: 'OnAirThin';
      font-size: 45px;
      line-height: 1.16;
      letter-spacing: 0.5px;
      color: $blue-01;
      text-align: center;
      font-weight: normal;
    }
  }
</style>
