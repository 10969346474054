<template>
  <div class="legal-notification">
    <div class="legal-notification-content">
      <div class="" v-html="preFooterDescription" />
      <div class="button-wrapper">
        <button-component
          :label="preFooterButtonText"
          button-type="tertiary"
          background-color="blue-border"
          :button-link="{
            internal: true,
            to: preFooterButtonLink ? preFooterButtonLink.url : ''
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'legal-notification',
  props: {
    preFooterDescription: {
      type: String,
      default: '* Solange der Vorrat reicht. Priority Tickets können Privatkund*innen von o2, die am Priority Vorteilsprogramm teilnehmen, in Anspruch genommen werden. Weitere Infos <a href="https://www.o2online.de/priority/">hier</a>.',
    },
    preFooterButtonText: {
      type: String,
      default: 'Zu den FAQ',
    },
    preFooterButtonLink: {
      type: Object,
      default: () => ({
        url: '/journeys/priority-tickets-faq/',
      }),
    },
  },
};
</script>
<style lang="scss" scoped>
  .legal-notification {
    width: 100%;
    margin-top: 30px;
    border-top: solid 1px $very-light-grey;
    background-color: $white;
  }
  .legal-notification-content {
    padding: 35px 50px;
    color: #999a9b;
    font-family: 'OnAirRoman';
    font-size: 12px;
    line-height: 1.67;
    width: 600px;
    max-width: 100%;
    &::v-deep a {
      text-decoration: underline;
      color: #999a9b;
    }

    @include lg {
      width: 562px;
      padding: 30px 50px;
    }
    @include sm {
      width: auto;
      padding: 20px 25px;
    }
  }
  .button-wrapper {
    display: block;
    padding-top: 17px;

    @include lg {
      padding-top: 20px;
      padding-bottom: 8px;
    }
    @include sm {
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }
  .journey-legal-notification {
    background-color: $blue-01;
    border-top: none;
    margin-top: 0;

    .legal-notification-content {
      color: $white;

      &::v-deep a {
        text-decoration: underline;
        color: $white;
      }
    }
    .button-wrapper .button-component {
      color: $white;
      background-color: transparent;
      border: solid 1px $white;

      &:hover {
        color: $blue-02;
      }
      &::after {
        background-color: $white;
      }
    }
  }
</style>
