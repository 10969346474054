<template>
  <div class="video" :style="gridStyles">
    <YoutubeVideoItem
      :youtube-id="youtube_id"
      :is-youtube="true"
      :thumbnail="getResponsiveImage(thumbnail)"
      :thumbnail-link="thumbnail_link"
      :no-margins="true"
      :on-click="openOverlay"
      class="thumbnail"
    />
    <div class="video-text">
      <h2 class="headline" v-custom-html="header" />
      <p class="copy" v-html="description" />
    </div>
  </div>
</template>

<script>
import GridItemMixin from '@/mixins/grid-item-mixin';
import YoutubeVideoItem from '@/views/common/components/media/YoutubeVideoItem.vue';

export default {
  name: 'Video',
  mixins: [GridItemMixin],
  components: {
    YoutubeVideoItem,
  },
  props: {
    mouse_over: {
      type: Boolean,
      default: false,
    },
    mouse_over_text: {
      type: String,
      default: 'Mehr',
    },
    header: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    youtube_id: {
      type: String,
      required: false,
    },
    thumbnail: {
      type: Object,
      default: () => {},
    },
    thumbnail_link: {
      typye: Object,
      required: false,
    },
  },
  data() {
    return {
      overlayIsOpen: false,
    };
  },
  methods: {
    openOverlay() {
      const link = `https://www.youtube.com/watch?v=${this.youtube_id}`;
      this.openYoutubeInNewWindow(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.video {
  padding: 0 28px;
  margin-bottom: 80px;
  color: $blue-01;

  @include lg {
    padding: 0;
  }
}
.thumbnail {
  width: 100%;
}
::v-deep .youtube-player {
  width: 100%;
}
.headline {
  font-family: "OnAirBold";
  font-weight: normal;
  font-size: 26px;
  line-height: 1.23;
  margin: 10px 0 5px 0;
  padding-left: 12px;

  @include lg {
    padding: 0 20px;
  }

  @include sm {
    font-size: 20px;
    padding: 0;
  }
}
.copy {
  font-family: "OnAirLight";
  font-size: 15px;
  line-height: 1.6;
  padding-left: 12px;

  @include lg {
    padding: 0 20px;
  }

  @include sm {
    padding: 0;
  }
}
</style>
