import Vue from 'vue';

Vue.directive('custom-html', {
  bind: (el, binding) => {
    const { value } = binding;
    el.innerHTML = value.replace(/amp;/g, '');
  },
  update: (el, binding) => {
    const { value } = binding;
    el.innerHTML = value.replace(/amp;/g, '');
  },
});

Vue.directive('resize-html-text', {
  bind: (el, binding) => {
    const { value } = binding;
    if (window.innerWidth < 576) {
      return;
    }
    setTimeout(() => {
      const { maxFontSize } = value;
      function onResizeText(type = 'small') {
        const widthParent = el.parentNode.getBoundingClientRect().width;
        const size = el.style.fontSize || maxFontSize;
        const parsedSize = parseInt(size, 10);
        if (type === 'small') {
          el.style.fontSize = `${parsedSize - 2}px`;
          if (el.getBoundingClientRect().width >= widthParent - 20) {
            onResizeText('small');
          }
        } else if (type === 'big' && parsedSize < maxFontSize) {
          el.style.fontSize = `${parsedSize + 2}px`;
          if (el.getBoundingClientRect().width <= widthParent - 80) {
            onResizeText('big');
          }
        }
      }
      onResizeText();
      let { innerWidth } = window;
      let interval = null;
      window.addEventListener('resize', () => {
        clearInterval(interval);
        interval = setInterval(() => {
          if (window.innerWidth < innerWidth) {
            onResizeText('small');
          } else {
            onResizeText('big');
          }
          innerWidth = window.innerWidth;
          clearInterval(interval);
        }, 300);
      }, false);
    }, 0);
  },
});

Vue.directive('negative', {
  inserted: (el, binding) => {
    const { mouse_over, mouse_over_text } = binding.value;
    if (!mouse_over) return;
    const div = document.createElement('div');
    div.classList.add('negative-element');
    const span = document.createElement('span');
    span.innerHTML = mouse_over_text;
    div.appendChild(span);
    el.classList.add('negative-element-wrapper');
    el.addEventListener('mouseover', () => {
      div.classList.add('negative-element-active');
    });
    el.addEventListener('mouseout', () => {
      div.classList.remove('negative-element-active');
    });
    el.addEventListener('mousemove', (event) => {
      const { offsetX, offsetY } = event;
      div.style.top = `${offsetY - 40}px`;
      div.style.left = `${offsetX - 40}px`;
      const img = el.querySelector('img');
      if (img && offsetX > img.offsetWidth) {
        div.classList.remove('negative-element-active');
        el.classList.add('cursor-default');
      } else {
        div.classList.add('negative-element-active');
        el.classList.remove('cursor-default');
      }
    });
    el.appendChild(div);
  },
});
