<template>
  <div
    class="icalendar-link-item"
    @click="trackClick"
  >
    <div
      v-if="headline"
      class="icalendar-link-item__title"
      :class="{ 'html': isHtml(headline) }"
      v-html="headline"
    />
    <button-component
      v-if="buttonText"
      :label="buttonText"
      button-type="primary"
      background-color="red"
      :icon-props="{ enabled: true, name: 'alert-white' }"
      :button-link="{
        internal: false,
        to: file.download_url
      }"
    />
  </div>
</template>
<script>
export default {
  name: 'ICalendarLinkItem',
  props: {
    headline: {
      type: String,
    },
    file: {
      type: Object,
    },
    buttonText: {
      type: String,
    },
  },
  methods: {
    trackClick() {
      this.trackActionEvent({
        eventName: 'customInteractionClick',
        eCat: 'analysis',
        eAct: 'click - iCal journey',
        eLab: this.buttonText,
        nonInteraction: false,
      });
    },
  },
};
</script>
<style lang="scss" scoped>

.icalendar-link-item {
  padding-bottom: 50px;
  height: auto;
  position: relative;

  &__title {
    font-family: 'OnAirLight';
    text-transform: uppercase;
    font-size: 40px;
    color: $blue-01;
    line-height: 1;
    margin-bottom: 30px;
    margin-left: -1px;

    &::v-deep {
      sub {
        font-size: 40px;
      }
    }

    @include xl {
      font-size: 30px;
      &::v-deep {
        sub {
          font-size: 30px;
        }
      }
    }

    &.html {
      ::v-deep b {
        font-family: 'OnAirBold';
      }
    }
  }

  .button-component {
    max-width: 100%;
    padding-left: min(8%, 37px);
    padding-right: min(8%, 37px);
  }
}
</style>
