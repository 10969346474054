import { render, staticRenderFns } from "./FeedbackTeaser.vue?vue&type=template&id=faab32aa&scoped=true&"
import script from "./FeedbackTeaser.vue?vue&type=script&lang=js&"
export * from "./FeedbackTeaser.vue?vue&type=script&lang=js&"
import style0 from "./FeedbackTeaser.vue?vue&type=style&index=0&id=faab32aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "faab32aa",
  null
  
)

export default component.exports