<template>
  <div class="icalendar-image-item-wrapper">
    <div class="icalendar-image-item" :class="{ 'no-margins': noMargins }">
      <div
        v-if="expirationData.expiration && expirationData.expiration_warning"
        class="expiration-warning"
        v-html="expirationData.expiration_warning"
      />
      <div
        class="thumbnail"
        @click="onClickThumbnail"
        :class="[{
          'thumbnail-active' : showMask,
          'mouseover': mouseOver || calendarFile,
        }]"
      >
        <img :src="thumbnail" :alt="header" />
        <div v-if="mouseOver || calendarFile" class="content-wrapper-mask">
          <div class="mask-content">
            <div class="mask-text">
              {{ mouseOver ? mouseOverText : calendarText }}
            </div>
            <div class="mask-button" @click="trackClick">
              <button-component
                v-if="calendarFile"
                label="Zum Kalender hinzufügen"
                button-type="secondary"
                background-color="white"
                :icon-props="{ enabled: true, name: 'calendar' }"
                :button-link="{
                  internal: false,
                  to: calendarFile.download_url
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="copy" v-if="header || description || extraContent">
        <h3 v-custom-html="header" />
        <div v-html="description" class="description"></div>
        <span class="extra-content">{{ extraContent }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ICalImageItem',
  props: {
    mouseOver: {
      type: Boolean,
      default: false,
    },
    mouseOverText: {
      type: String,
      default: 'Mehr',
    },
    header: {
      type: String,
      required: false,
    },
    thumbnail: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    extraContent: {
      type: String,
      required: false,
    },
    noMargins: {
      type: Boolean,
      default: false,
    },
    calendarText: {
      type: String,
      required: false,
    },
    calendarFile: {
      type: Object,
    },
    expirationData: {
      type: Object,
      default: () => ({
        expiration: null,
        expiration_warning: '',
      }),
    },
  },
  data: () => ({
    showMask: false,
  }),
  methods: {
    onClickThumbnail() {
      this.showMask = !this.showMask;
    },
    trackClick() {
      this.trackActionEvent({
        eventName: 'customInteractionClick',
        eCat: 'analysis',
        eAct: 'click - iCal journey',
        eLab: this.header,
        nonInteraction: false,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.icalendar-image-item-wrapper {
  .expiration-warning {
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'OnAirRoman';
    font-size: 12px;
    line-height: 1.67;
    color: $white;
    background-color: $red;
    padding: 2px 8px;
    z-index: 1;
  }
  .icalendar-image-item {
    margin: 70px 25px 50px;
    height: auto;
    position: relative;

    @include xl-1440 {
      margin: 55px 25px 30px;
    }
    @include xl {
      margin: 45px 8px 25px;
    }
    @include lg {
      margin: 60px 40px 60px;
    }
    @include sm {
      margin: 68px 25px 50px;
    }

    &.no-margins {
      margin: 0
    }

    .thumbnail {
      width: 100%;
      position: relative;

      img {
        width: 100%;
        display: block;
      }

      .content-wrapper-mask {
        transition: opacity 0.3s ease-out;
        position: absolute;
        z-index: 1;
        opacity: 0;
        display: flex;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        flex-wrap: wrap;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        &::before {
          content: '';
          position: absolute;
          background-color: $blue-01;
          opacity: 0.7;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        .mask-content {
          position: relative;
          text-align: center;
        }

        .mask-text {
          font-family: 'OnAirBold';
          font-size: 16px;
          line-height: 1.44;
          letter-spacing: normal;
          text-align: center;
          color: $white;
          padding-bottom: 18px;
          width: 213px;
          max-width: calc(100% - 30px);
          margin: 0 auto;
        }

        .mask-button {
          .button-component {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
      &.mouseover {

        &:hover {
          .content-wrapper-mask {
            opacity: 1;

            @include lg {
              opacity: 0;
            }
          }
        }

        &.thumbnail-active {
          .content-wrapper-mask {

            @include lg {
              opacity: 1;
            }
          }
        }
      }
    }

    .copy {
      padding: 18px 30px 10px 5px;
      position: relative;

      h3 {
        font-family: 'OnAirBold';
        @include linear-font-size(16px, 20px);
        font-weight: normal;
        line-height: 1.3;
        color: $blue-01;
        display: block;
        margin: 0;
      }

      .description {
        font-family: 'OnAirRoman';
        font-size: 12px;
        line-height: 1.83;
        color: $blue-01;
        margin: 0;
        padding-top: 5px;
        @include xl-1440 {
          line-height: 1.67;
          padding-top: 3px;
        }
      }

      .extra-content {
        display: block;
        font-family: 'OnAirLight';
        font-style: italic;
        font-size: 11px;
        line-height: 2.2;
        color: $warm-grey;
        transform: rotate(90deg) translate(100%, 0);
        transform-origin: 100% 0;
        position: absolute;
        padding-left: 1px;
        right: 0;
        top: 10px;

        &::before {
            content: "";
            position: absolute;
            width: 9px;
            height: 1px;
            background-color: $warm-grey;
            top: 10px;
            left: -13px;
        }
      }
    }
  }
}
</style>
