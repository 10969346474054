<template>
  <div class="highlight-tiles">
    <h1 v-text="headline" class="highlight-tiles__headline" />

    <div class="highlight-container">
      <HighlightJourney
        v-for="(highlight, index) in content"
        :key="highlight.id"
        :highlight-id="highlight.id"
        :highlight-journey="highlight.value"
        :class="[index !== 0 ? 'small' : '']"
        button-type="secondary"
      />
    </div>
  </div>
</template>

<script>
import HighlightJourney from './HighlightJourney.vue';

export default {
  components: {
    HighlightJourney,
  },
  props: {
    headline: {
      type: String,
      default: 'Mehr Music erleben',
    },
    content: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss" scoped>
.highlight-tiles {
  display: flex;
  flex-direction: column;

  &__headline {
    margin: 0;
    color: $blue-01;
    font-size: 44px;
    align-self: center;
    text-align: center;
    margin-bottom: 30px;
    font-family: 'OnAirBold';
  }
  .highlight-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;

    & > * {
      // flex: 0 0 50%;
      margin-bottom: 60px;
    }
  }

  @include min-768 {
    &__headline {
      font-size: 58px;
    }

    .highlight-container {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }

  @include min-1024 {
    padding: 0 49px 140px 0;
  }
}
</style>
