var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"livestream-module",style:(_vm.gridStyles)},[_c('div',{staticClass:"livestream-wrapper",style:({'background-image': ("url(" + _vm.backgroundImage + ")")})},[_c('div',{staticClass:"top-left-corner"},[(_vm.live_flag)?_c('div',{staticClass:"live-flag"},[_vm._v(_vm._s(_vm.live_flag))]):_vm._e(),(_vm.artist_name)?_c('div',{staticClass:"artist-name"},[_vm._v(_vm._s(_vm.artist_name))]):_vm._e()]),(_vm.headline_image)?_c('div',{staticClass:"headline-image"},[_c('img',{attrs:{"src":_vm.getResponsiveImage(_vm.headline_image)}})]):_c('h2',{staticClass:"headline"},[_c('PriorityLogoResponsive',{staticClass:"priority-logo"}),_c('p',{staticClass:"headline-copy",domProps:{"innerHTML":_vm._s(_vm.headline)}})],1),_c('ThirdPartyCookiesInfo',{directives:[{name:"show",rawName:"v-show",value:(_vm.showThirdPartyCookieInfo),expression:"showThirdPartyCookieInfo"}],on:{"close":function($event){_vm.showTPC = false},"changeTPCsupported":function($event){_vm.TPCsupported = false}}}),_c('div',{staticClass:"livestream"},[(!_vm.videoIsPlaying)?_c('div',{staticClass:"thumbnail",class:[
          _vm.showMask && _vm.youtube_id ? 'thumbnail--masked' : '',
          !!_vm.youtube_id ? 'clickable' : ''
        ],on:{"click":_vm.onClickThumbnail}},[_c('img',{staticClass:"livestream-thumbnail",attrs:{"src":_vm.getResponsiveImage(_vm.thumbnail)}}),(_vm.youtube_id)?_c('div',{staticClass:"play-button"}):_vm._e(),(!_vm.isMobile)?_c('h2',{staticClass:"video-headline",domProps:{"innerHTML":_vm._s(_vm.video_header)}}):_vm._e(),(_vm.video_description)?_c('p',{staticClass:"video-copy"},[_c('span',{staticClass:"stripe"}),_c('span',{staticClass:"video-description",domProps:{"innerHTML":_vm._s(_vm.video_description)}})]):_vm._e(),_c('ShareButton',{staticClass:"click-share",attrs:{"title":_vm.video_header,"text":_vm.video_description,"url":_vm.shareUrl,"width":"30px","height":"30px"}}),[(_vm.youtube_id)?_c('div',{staticClass:"content-wrapper-mask"},[_c('div',{staticClass:"mask-content"},[_c('div',{staticClass:"mask-text"},[_vm._v(" Wir leiten dich jetzt zu YouTube weiter. ")]),_c('div',{staticClass:"mask-button"},[_c('button-component',{attrs:{"button-type":"secondary","background-color":"white","label":"Jetzt ansehen","icon-props":{
                    enabled: true,
                    name: 'play-button',
                    position: 'left'
                  }},on:{"change":_vm.onClickPlayButton}})],1)])]):_vm._e()]],2):_vm._e(),_c('div',{staticClass:"thumbnail-content-on-mobile"},[(_vm.isMobile)?_c('h2',{staticClass:"video-headline",domProps:{"innerHTML":_vm._s(_vm.video_header)}}):_vm._e(),(_vm.video_description)?_c('p',{staticClass:"video-copy"},[_c('span',{staticClass:"stripe"}),_c('span',{staticClass:"video-description",domProps:{"innerHTML":_vm._s(_vm.video_description)}})]):_vm._e(),_c('ShareButton',{staticClass:"click-share",attrs:{"title":_vm.video_header,"text":_vm.video_description,"url":_vm.shareUrl,"width":"30px","height":"30px"}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }