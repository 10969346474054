<template>
  <div class="footer-feedback">
    <div class="feedback">
      <div class="feedback-img">
        <img
          src="@/assets/icons/icon-chat.png"
          alt="feedback-icon">
      </div>
      <div class="feedback-text">
        Dein Feedback zur Website ist uns sehr wichtig
      </div>
      <div class="feedback-button">
        <button-component
          class="button-component"
          label="Feedback geben"
          background-color="white"
          button-type="secondary"
          @change="onClickEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'feedback',
  methods: {
    onClickEvent() {
      if (window.KAMPYLE_ONSITE_SDK && this.isUserAcceptCookies()) {
        window.KAMPYLE_ONSITE_SDK.showForm(16401);
      } else {
        window.open(
          'https://assets.kampyle.com/resources/direct/form.html?region=prodEuIrland&websiteId=330018&formId=16401',
          '_blank',
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-feedback {
  padding: 54px 0;
  background-color: #eaecee;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 75%;
    background-color: #bac3cb;
    left: 0;
    right: 0;
    margin: 0 auto;

    @include lg {
      width: 88%;
    }
    @include sm {
      width: 75%;
    }
  }

  .feedback {
    display: flex;
    justify-content: center;
  }
  .feedback-img {
    margin: 0 15px;
  }
  .feedback-text {
    font-size: 15px;
    color: #5f6264;
    line-height: 1.67;
    font-family: 'OnAirBold';
    padding-top: 2px;
  }
  .feedback-button {
    margin-left: 25px;
    margin-top: -6px;

    .button-component {
        &.white-theme {
          color: #5f6264;
          &:hover {
            color: $white;
          }
          &::after {
            background: #5f6264;
          }
        }
    }
  }

  @include sm {
    padding: 30px 0;

    .feedback {
      flex-wrap: wrap;
    }
    .feedback-img {
      width: 100%;
      text-align: center;
    }
    .feedback-text {
      margin-top: 10px;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
    .feedback-button {
      margin-left: 0;
    }
  }
}
</style>
