<template>
  <button
    class="button-component"
    :class="[ buttonType, `${backgroundColor}-theme`]"
    @click="$emit('change')"
  >
    <div
      class="button-component-label"
      :class="[iconProps.enabled ? 'button-component-enabled-icon' : '',
        iconProps.position ? `button-component-enabled-icon-${iconProps.position}` : '' ]"
    >
      <span v-html="transformLive(label)" />
      <span
        v-if="iconProps.enabled"
        class="button-icon"
        :class="iconProps.name"
      />
    </div>
    <div  v-if="buttonLink" class="button-component-link">
      <router-link
        v-if="buttonLink.internal"
        :to="buttonLink.to || '/'"
        rel="follow"
      />
      <a v-else :href="buttonLink.to" target="_blank" rel="noopener" />
    </div>
  </button>
</template>

<script>
/**
 * `Options:
 *  Buttons type: 'primary' | 'secondary' | 'tertiary'
 *  Buttons background color: 'white' | 'blue'
 *  Icon props for icon: { enabled: true, name: 'icon-ring' }
 *  label: text
 * `
 */
export default {
  name: 'button-component',
  props: {
    /**
		 * Buttons links
		 * Options: { internal: true, to: link target to }
		 */
    buttonLink: {
      type: [Object, Boolean],
      default: false,
    },
    /**
		 * Buttons type
		 * Options: 'primary' | 'secondary' | 'tertiary'
		 */
    buttonType: {
      type: String,
      default: 'primary',
    },
    /**
		 * Buttons background color
		 * Options: 'white' | 'blue'
		 */
    backgroundColor: {
      type: String,
      default: 'blue',
    },
    /**
		 * Icon props component can pass props for icon
		 * e.g. `{ enabled: true, name: 'icon-ring' }`
		 */
    iconProps: {
      type: Object,
      default: () => ({
        enabled: false,
        name: '',
        position: '',
      }),
    },
    /**
		 * Text for the button
		 */
    label: {
      type: String,
      default: 'label',
    },
  },
  computed: {},
};
</script>
