<template>
  <div class="link-block" :class="{ 'left-column': placedInLeftColumn }" :style="gridStyles">
    <TeaserItem
      :header="header"
      :buttonText="button_text"
      :buttonLink="button_link"
      :isInternalLink="is_internal"
      :showSpacer="false"
      :class-list="{ 'no-outer-margins': true, 'no-borders': true }"
    />
  </div>
</template>

<script>
import GridItemMixin from '@/mixins/grid-item-mixin';
import TeaserItem from '@/views/common/components/media/TeaserItem.vue';

export default {
  name: 'LinkBlock',
  mixins: [GridItemMixin],
  components: {
    TeaserItem,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    button_text: {
      type: String,
      required: false,
    },
    button_link: {
      type: String,
      required: false,
    },
    is_internal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    placedInLeftColumn() {
      return this.grid_column === 'center';
    },
  },
};
</script>

<style lang="scss" scoped>
.link-block {
  margin: 0 80px 40px 0;

  ::v-deep .header {
    margin-left: -1px;
  }
  ::v-deep button {
    margin: 0;
  }
  ::v-deep .teaser-item {
    overflow: visible;
  }
  @include xl {
    margin: 0 20px 40px 0;
    ::v-deep .button-component {
      width: fit-content;
      text-align: center;
    }
  }
  @include lg {
    margin: 0 80px 40px 0;
    ::v-deep .button-component {
      width: initial;
      text-align: initial;
    }
  }
}
.left-column {
  margin-left: 40px;
}
</style>
