<template>
  <div
    class="intro-module grid"
    :class="{ 'intro-module-first': index === 0 }"
  >
    <div class="canvas-container" ref="canvasContainer">
      <canvas ref="introCanvas"></canvas>
    </div>
    <div class="subheadline-container">
      <TextComponent :headline="sub_headline" :copy="copy" />
      <div class="button" v-if="button_text">
        <button-component
          :label="button_text"
          :button-link="{
            internal: true,
            to: button_link
          }"
        />
      </div>
    </div>
    <div class="second-image">
      <ImageComponent :image="second_image" />
    </div>
  </div>
</template>

<script>
import ImageComponent from '@/views/journey/components/modules/children/Image.vue';
import TextComponent from '@/views/journey/components/modules/children/Text.vue';

export default {
  name: 'Intro',
  components: {
    ImageComponent,
    TextComponent,
  },
  props: {
    headline: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    sub_headline: {
      type: String,
      default: '',
    },
    copy: {
      type: String,
      default: '',
    },
    button_text: {
      type: String,
      default: '',
    },
    button_link: {
      type: String,
      default: '/',
    },
    first_image: {
      type: Object,
      default: () => {},
    },
    second_image: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isOnTablet: false,
      isOnMobile: false,
    };
  },
  computed: {
    fontSize() {
      return this.isOnTablet ? 150 : 240;
    },
  },
  methods: {
    updateBooleans() {
      this.isOnTablet = window.innerWidth <= 992;
      this.isOnMobile = window.innerWidth <= 576;
    },
    printTextWithLineBreak(ctx, text, fitWidth, drawOutline, doNotPrint) {
      // source https://stackoverflow.com/a/4478894

      const x = -20;
      const y = 20;
      const color = drawOutline ? '#ffffff' : '#000066';

      ctx.font = `${this.fontSize}px OnAirBold`;
      ctx.textBaseline = 'top';
      ctx.fillStyle = color;
      ctx.strokeStyle = color;
      ctx.lineWidth = 3;

      if (fitWidth <= 0) {
        ctx.fillText(text, x, y);
        return 1;
      }

      let words = text.split(' ');
      let currentLine = 0;
      let idx = 1;
      let reducedFontSize = this.fontSize;

      // check if every word fits into the canvas. If not, then reduce the font-size.
      words.forEach((word) => {
        let wordWidth = ctx.measureText(word).width;

        while (wordWidth > fitWidth) {
          reducedFontSize -= 1;
          ctx.font = `${reducedFontSize}px OnAirBold`;
          wordWidth = ctx.measureText(word).width;
        }
      });

      while (words.length > 0 && idx <= words.length) {
        const str = words.slice(0, idx).join(' ');
        const w = ctx.measureText(str).width;

        if (w > fitWidth) {
          if (idx === 1) {
            idx = 2;
          }
          if (doNotPrint === false) {
            if (drawOutline) {
              ctx.strokeText(
                words.slice(0, idx - 1).join(' '), x, y + (reducedFontSize * currentLine),
              );
            } else {
              ctx.fillText(
                words.slice(0, idx - 1).join(' '), x, y + (reducedFontSize * currentLine),
              );
            }
          }
          currentLine += 1;
          words = words.splice(idx - 1);
          idx = 1;
        } else {
          idx += 1;
        }
      }

      if (idx > 0) {
        if (drawOutline) {
          ctx.strokeText(words.join(' '), x, y + (reducedFontSize * currentLine));
        } else {
          ctx.fillText(words.join(' '), x, y + (reducedFontSize * currentLine));
        }
      }

      return {
        lines: currentLine + 1,
        fontSize: reducedFontSize,
      };
    },
    calcTextHeight(ctx, text, fitWidth) {
      const lineValues = this.printTextWithLineBreak(ctx, text, fitWidth, false, false);
      return lineValues.lines * lineValues.fontSize;
    },
    drawCanvas() {
      this.updateBooleans();
      const canvas = this.$refs.introCanvas;

      if (
        !this.$refs.canvasContainer
        || this.$refs.canvasContainer.style.display === 'none'
        || !canvas
      ) {
        return; // do not draw canvas if there is none
      }

      const ctx = canvas.getContext('2d');

      // twice the width for higher resolution (otherwise text might be blurry)
      canvas.width = this.$refs.canvasContainer.offsetWidth * 2;

      const textHeight = this.calcTextHeight(ctx, this.headline, canvas.width);
      canvas.height = textHeight;

      const img = new Image();
      img.src = this.getResponsiveImage(this.first_image);

      img.onload = () => {
        const padding = this.isOnMobile ? 0 : 40;

        // half the size of the canvas (= 1 column) minus padding on each side
        const ratio = img.width / img.height;
        const scale = this.isOnTablet ? 1.0 : 0.5;
        const imgWidth = canvas.width * scale - (2 * padding);
        const imgHeight = imgWidth / ratio;

        // resize canvas so that it fits the image and add some extra space
        const minimumHeight = Math.max(imgHeight, textHeight);
        canvas.height = minimumHeight * 1.25;

        // draw blue text
        this.printTextWithLineBreak(ctx, this.headline, canvas.width, false, false);

        // calculate image position and draw it
        const left = this.isOnTablet ? padding : (imgWidth + (3 * padding));
        const top = canvas.height - imgHeight;
        ctx.drawImage(img, left, top, imgWidth, imgHeight);

        // draw an invisble rectangle with the same size and position as the image
        ctx.beginPath();
        ctx.rect(left, top, imgWidth, imgHeight);

        // use clip() to only print the text with white stroke within that rectangle
        ctx.clip();
        this.printTextWithLineBreak(ctx, this.headline, canvas.width, true, false);
      };
    },
  },
  mounted() {
    this.drawCanvas();
    window.addEventListener('resize', this.drawCanvas, false);
  },
  destroyed() {
    window.removeEventListener('resize', this.drawCanvas, false);
  },
};
</script>

<style lang="scss" scoped>
.intro-module-first {
  padding-top: 100px;

  @include sm {
    padding-top: 50px;
  }
}
.canvas-container {
  grid-column: start / span center;
  grid-row: 1;

  canvas {
    width: 100%;
  }

  @include lg {
    grid-column: start;
    grid-row: span 2;
  }

  @include ie11 {
    width: 50% !important;
    padding: 0 !important;

    @include sm {
      width: 100% !important;
    }
  }
}
.subheadline-container {
  grid-column: center;
  grid-row: 1;
  padding-top: 30px;

  .button {
    padding: 0 20px;

    @include sm {
      padding: 0;
      margin-left: -1px;
    }
  }

  @include lg {
    grid-column: last-column;
    grid-row: 1;
  }
}
.second-image {
  grid-column: last-column;
  grid-row: 1;

  @include lg {
    grid-column: last-column;
    grid-row: 2;
    margin-top: 50px;
  }
}
</style>
