<template>
  <div class="tickets-on-sales">
    <h2 v-html="onSaleTicketsModule.value.heading" />
    <div class="artists-list">
      <div
        v-for="(artist, $index) in onSaleTicketsModule.value.events"
        :key="$index"
        class="artist-block"
        :class="{
          'artist-block-newletter':  artist.type === 'newsletter_alarm_block',
          'artist-block-highlight': artist.sale_highlight,
        }"
      >
        <teaser-ticket-alarm
          v-if="artist.type === 'newsletter_alarm_block'"
          :header="artist.header"
          :buttonText="artist.button_text"
          :description="artist.description"
        />
        <artist
          v-else
          :artist="artist"
          :ticket-type="onSaleTicketsModule.type || artist.type"
        />
      </div>
    </div>
  </div>
</template>
<script>
import TeaserTicketAlarm from '@/views/common/components/media/TeaserTicketAlarm.vue';
import Artist from './Artist.vue';

export default {
  name: 'tickets-on-sales',
  components: {
    Artist,
    TeaserTicketAlarm,
  },
  props: {
    onSaleTicketsModule: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
  .tickets-on-sales {
    padding-top: 25px;
  }
  .artists-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .artist-block {
    width: calc(16.66% - 24px);
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 40px;
  }
  .artist-block-newletter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(33.33% - 50px);
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 40px;
    border: solid 1px $very-light-grey;
  }
  h2 {
    margin: 0 0 0 16px;
    font-family: 'OnAirBold';
    font-size: 24px;
    font-weight: normal;
    color: $blue-01;
  }
  @include xl-1440 {
    h2 {
      margin-left: 20px;
    }
    .artist-block {
      width: calc(20% - 24px);
    }
    .artist-block-newletter {
      width: calc(40% - 50px);
    }
    .artists-list {
      padding-left: 9px;
      padding-right: 9px;
    }
  }
  @include xl {
    h2 {
      margin-left: 24px;
    }
    .artist-block {
      width: calc(20% - 20px);
      margin-left: 10px;
      margin-right: 10px;
    }
    .artist-block-newletter {
      width: calc(40% - 30px);
      margin-left: 14px;
      margin-right: 14px;
    }
  }
  @include lg {
    h2 {
      margin-left: 30px;
    }
    .artist-block {
      width: calc(25% - 20px);
      margin-left: 10px;
      margin-right: 10px;
    }
    .artist-block-newletter {
      width: calc(50% - 42px);
      margin-left: 20px;
      margin-right: 20px;
      background-color: $white;
    }
    .artists-list {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @include sm {
    h2 {
      margin-left: 20px;
      margin-left: 20px;
      text-align: center;
      width: calc(100% - 40px);
    }
    .artist-block {
      width: 100%;
      margin-left: -10px;
      margin-right: -10px;
      margin-bottom: 50px;
    }
    .artist-block-newletter {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;

      ::v-deep .teaser-item-wrapper .teaser-item {
        border-left: none;
        border-right: none;
        margin: 0;
      }
    }
    .artists-list {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
</style>
