<template>
  <div class="content-module grid">
    <Artist
      v-bind="artist"
      :number-of-children="content_children.length"
      grid_column="start / span center"
    />
    <div class="spacer"></div>
    <Component
      v-for="child in content_children"
      :key="child.id"
      :is="childComponents[child.type]"
      v-bind="child.value"
    />
  </div>
</template>

<script>
import Artist from '@/views/journey/components/modules/children/Artist.vue';
import Text from '@/views/journey/components/modules/children/Text.vue';
import LinkBlock from '@/views/journey/components/modules/children/LinkBlock.vue';
import Image from '@/views/journey/components/modules/children/Image.vue';
import Video from '@/views/journey/components/modules/children/Video.vue';
import Spotify from '@/views/journey/components/modules/children/Spotify.vue';
import AmazonMusic from '@/views/journey/components/modules/children/AmazonMusic.vue';
import Quote from '@/views/journey/components/modules/children/Quote.vue';

export default {
  name: 'Content',
  components: {
    Artist,
  },
  props: {
    artist: {
      type: Object,
      default: () => {},
    },
    content_children: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      childComponents: {
        text: Text,
        image: Image,
        video: Video,
        spotify: Spotify,
        link_block: LinkBlock,
        quote: Quote,
        amazon_music: AmazonMusic,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.content-module {
  @include ie11 {
    position: relative;
    min-height: 100vh;

    @media (min-width: #{$screen-lg-min}) {
      margin-left: 50%;

      > *:not(.ie-sticky) {
        width: calc(50% - 60px) !important;
        padding: 0 30px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
}
.spacer {
  grid-column: center / span end;
  margin-top: 40px;

  @include lg {
    display: none;
  }

  @include ie11 {
    display: none;
  }
}
</style>
