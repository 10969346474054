<template>
  <Overlay @closeOverlay="closeOverlay">
    <iframe :src="shopLink" />
  </Overlay>
</template>

<script>
import Overlay from '@/components/misc/Overlay.vue';

export default {
  name: 'PresaleShopOverlay',
  components: {
    Overlay,
  },
  props: {
    shopLink: {
      type: String,
    },
  },
  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
};
</script>

<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
