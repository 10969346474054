import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);
const VUE_APP_API = process.env.NODE_ENV !== 'production' ? '' : process.env.VUE_APP_API;

/* eslint-disable no-new */
export default new Vuex.Store({
  state: {
    API: VUE_APP_API,
    VUE_APP_URL: process.env.VUE_APP_URL,
    mode: process.env.VUE_APP_MODE,
    pages: {},
    draftPage: {},
    siteSettings: null,
    ticketsCountdown: null,
    showEventimLoader: false,
    cameBackFromRedirect: document.referrer.indexOf('/callback.html?code') > -1,
  },
  mutations: {
    setCameBackFromRedirect(state, payload) {
      state.cameBackFromRedirect = payload;
    },
    setPageData(state, { response, pageName }) {
      state.pages[pageName] = response.data;
    },
    setDraftPageData(state, { response, pageName }) {
      state.draftPage[pageName] = response.data;
    },
    setNewCMSApi(state, url) {
      state.API = url;
    },
    setSiteSettings(state, response) {
      state.siteSettings = response.data;
    },
    setTicketsCountdown(state, response) {
      state.ticketsCountdown = response;
    },
    setEventimLoader(state, response) {
      state.showEventimLoader = response;
    },
  },
  getters: {
    getPropPage: (state) => (id) => state.pages[id],
    getPropDraftPage: (state) => (id) => state.draftPage[id],
  },
  actions: {
    async sendFormData(store, { data, campaignName }) {
      const { API } = store.state;
      const url = `${API}/api/v2/marketing/raffles/${campaignName}/`;
      try {
        await axios.post(url, data);
        return true;
      } catch (error) {
        return {
          status: error.response.status,
          message: error.response.data,
        };
      }
    },
    sendTicketAlarmForm(store, { data, alarmType }) {
      const { API } = store.state;
      const url = `${API}/api/v2/settings/${alarmType}/`;
      return axios.post(url, data)
        .then((response) => (response))
        .catch((error) => (error.response));
    },
    getFilteredData(store, { params, url }) {
      const { API } = store.state;
      return axios.get(`${API}${url}`, {
        params,
      })
        .then((response) => (response))
        .catch((error) => (error.response));
    },
    getPageData(store, { params = {}, pageName }) {
      const { API } = store.state;
      const url = `${API}/api/v2/pages/by_path/`;
      return axios.get(url, {
        params,
      }).then((response) => {
        store.commit('setPageData', {
          response,
          pageName,
        });
        return response;
      }).catch((error) => (error.response));
    },
    getDraftPageData(store, { params = {}, pageName, jwtToken }) {
      const { API } = store.state;
      const url = `${API}/api/v2/draft/by_path/`;
      return axios.get(url, {
        params,
        headers: { Authorization: `Bearer ${jwtToken}` },
      }).then((response) => {
        store.commit('setDraftPageData', {
          response,
          pageName,
        });
        return response;
      }).catch((error) => (error.response));
    },
    getSiteSettings(store) {
      const { API } = store.state;
      const url = `${API}/api/v2/settings/site/`;
      return axios.get(url).then((response) => {
        store.commit('setSiteSettings', response);
        return response;
      }).catch((error) => (error.response));
    },
  },
});
