<template>
  <div class="exclusive-block-slider-contnet">
    <swiper :options="swiperOptions" class="exclusive-block-swiper" v-if="showSwiper">
      <swiper-slide v-for="(slide, $index) in slides" :key="$index" class="exclusive-swiper-slide">
        <div class="exclusive-block-slide-wrapper">
          <div class="exclusive-block-slide">
            <div class="block-slide-image">
              <img
                v-if="slide.event_serie.image"
                :src="getResponsiveImage(slide.event_serie.image)" alt="">
              <img
                v-else
                src="@/assets/images/no-image.jpg"
                alt=""
              >
            </div>
            <div class="block-slide-title" v-html="getArtistName(slide.event_serie.artists)" v-resize-text="{ ratio: 0.6, maxFontSize: '32px', delay: 50 }"/>
            <div class="block-slide-subtitle" v-html="slide.event_serie.es_name" />
            <div class="block-slide-button">
              <button-component
                class="button-component"
                :label="slide.journey_text"
                background-color="blue-border"
                button-type="tertiary"
                :button-link="{
                  internal: true,
                  to: slide.event_serie.journeypages.url || '/'
                }"
              />
            </div>
          </div>
        </div>
      </swiper-slide>
      <div
        v-if="slides.length > 3"
        class="exclusive-swiper-button exclusive-swiper-button-next"
        slot="button-prev"
      />
      <div
        v-if="slides.length > 3"
        class="exclusive-swiper-button exclusive-swiper-button-prev"
        slot="button-next"
      />
    </swiper>
  </div>
</template>
<script>
export default {
  name: 'exclusive-block-slider',
  props: ['eventsData'],
  data: () => ({
    showSwiper: true,
    swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 22,
      allowTouchMove: true,
      navigation: {
        nextEl: '.exclusive-swiper-button-next',
        prevEl: '.exclusive-swiper-button-prev',
      },
      breakpoints: {
        576: {
          spaceBetween: 22,
          slidesPerView: 3,
        },
        992: {
          allowTouchMove: false,
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1280: {
          allowTouchMove: false,
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    },
  }),
  computed: {
    slides() {
      return this.eventsData;
    },
  },
  methods: {
    getArtistName(artists) {
      return artists.join(', ');
    },
  },
};
</script>
<style lang="scss" scoped>
  .exclusive-block-slider-contnet {
    width: calc(100% - 66px);
    margin: 0 auto;
    margin-top: 40px;
    padding-bottom: 65px;
    @include xl {
      width: calc(100% - 100px);
      padding-bottom: 0;
    }
    @include sm {
      width: calc(100% - 92px);
      padding-bottom: 0;
    }
  }
  .exclusive-block-slide-wrapper {
    width: 100%;
    height: 100%;
    background-color: $white;
    color: $blue-01;
    max-width: 316px;
    margin: 0 auto;
    @include xl {
      max-width: 100%;
    }
  }
  .exclusive-swiper-slide {
    height: auto;
  }
  .exclusive-block-slide {
    padding: 18px;
    display: flex;
    flex-flow: column nowrap;
    height: calc(100% - 36px);

    @include xl {
      height: calc(100% - 30px);
      padding: 15px;
    }
  }
  .block-slide-image img {
    width: 100%;
    display: flex;
  }
  .block-slide-title {
    padding-top: 20px;
    padding-bottom: 10px;
    font-family: 'OnAirBold';
    font-size: 32px;
    line-height: 1;
    @include xl {
      padding-top: 18px;
      padding-bottom: 8px;
      font-size: 32px;
      line-height: 1.01;
    }
  }
  .block-slide-subtitle {
    font-family: 'OnAirRoman';
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: normal;
    @include xl {
      line-height: 1.73;
    }
  }
  .block-slide-button {
    padding-top: 19px;
    padding-bottom: 15px;
    margin-top: auto;
    @include xl {
      padding-top: 12px;
      padding-bottom: 13px;
    }
  }
  .exclusive-block-swiper {
    overflow: initial;
  }
  .exclusive-swiper-button {
    width: 50px;
    height: 35px;
    cursor: pointer;
    z-index: 1;
    @include lg {
      display: none;
    }
  }
  .swiper-button-disabled {
    opacity: 0.5;
    cursor: default;
  }
  .exclusive-swiper-button-next {
    position: absolute;
    right: 0;
    bottom: -65px;
    background-image: url('~@/assets/images/exclusive-events-module/arrow-right.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .exclusive-swiper-button-prev {
    position: absolute;
    right: 70px;
    bottom: -65px;
    transform: rotate(180deg);
    background-image: url('~@/assets/images/exclusive-events-module/arrow-right.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
</style>
