var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"takeover-teaser-slide"},[_c('div',{staticClass:"slide-item slide-item-artist-ticket",class:{ 'slide-item-artist-ticket-full': !_vm.priorityTicketsTickerEnabled }},[_c('div',{staticClass:"slide-item-artist"},[_c('div',{staticClass:"event-logo"},[(_vm.localImage)?_c('img',{attrs:{"src":_vm.getResponsiveImage(_vm.localImage),"alt":"artist-image"}}):_vm._e()])]),_c('div',{staticClass:"slide-item-ticket"},[_c('div',{staticClass:"slide-item-ticket-content"},[_c('div',{staticClass:"ticket-info"},[_c('div',{staticClass:"ticket-title",domProps:{"innerHTML":_vm._s(_vm.text)}}),(_vm.eventLink)?_c('div',{staticClass:"ticket-button"},[_c('button-component',{staticClass:"button-component cta-link",class:_vm.buttonId,attrs:{"label":_vm.buttonText,"background-color":"blue","button-type":"primary","button-link":{
                internal: _vm.eventLink.type === 'internal_link',
                to: _vm.eventLink.value || '/'
              }}})],1):_vm._e(),(_vm.calendarLink.calendar_file)?_c('div',{staticClass:"calendar-button"},[_c('button-component',{staticClass:"calendar-link",class:_vm.buttonId,attrs:{"background-color":"blue","button-type":"primary","label":_vm.calendarLink.calendar_button_text,"icon-props":{ enabled: true, name: 'calendar calendar--white' },"button-link":{
                internal: false,
                to: _vm.calendarLink.calendar_file.download_url
              }}})],1):_vm._e(),(_vm.disruptorEnabled)?_c('div',[(!_vm.inStock)?_c('div',{staticClass:"ticket-online-badge",style:({'background-image': _vm.getBadgeImage(_vm.disruptor)  })}):_c('div',{staticClass:"ticket-online-badge ticket-online-badge--sold-out"})]):_vm._e()])])])]),(_vm.priorityTicketsTickerEnabled)?_c('div',{staticClass:"slide-item slide-item-crawler"},[_c('PresaleTeaserCrawler',{attrs:{"type":"white"}})],1):_vm._e(),_c('div',{staticClass:"slide-item slide-item-mobile-item"},[_c('div',{staticClass:"slide-item-ticket-content"},[_c('div',{staticClass:"ticket-info"},[_c('div',{staticClass:"ticket-title",domProps:{"innerHTML":_vm._s(_vm.text)}}),(_vm.eventLink)?_c('div',{staticClass:"ticket-button"},[_c('button-component',{staticClass:"button-component",class:_vm.buttonId,attrs:{"label":_vm.buttonText,"background-color":"blue","button-type":"primary","button-link":{
              internal: _vm.eventLink.type === 'internal_link',
              to: _vm.eventLink.value || '/'
            }}})],1):_vm._e(),(_vm.calendarLink.calendar_file)?_c('div',{staticClass:"calendar-button"},[_c('button-component',{class:_vm.buttonId,attrs:{"background-color":"blue","button-type":"primary","label":_vm.calendarLink.calendar_button_text,"icon-props":{ enabled: true, name: 'calendar calendar--white' },"button-link":{
                internal: false,
                to: _vm.calendarLink.calendar_file.download_url
              }}})],1):_vm._e(),(_vm.disruptorEnabled)?_c('div',[(!_vm.inStock)?_c('div',{staticClass:"ticket-online-badge",style:({'background-image': _vm.getBadgeImage(_vm.disruptor)  })}):_c('div',{staticClass:"ticket-online-badge ticket-online-badge--sold-out"})]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }