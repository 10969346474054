<template>
  <div class="marquee">
    <div class="marquee-inner">
      <span
        v-html="`+++ ${transformLive(text, true)} `"
        v-for="index in 25"
        :key="index"
      />
      +++
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ticker',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.marquee {
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  font-family: "OnAirLight";
  font-size: 48px;
  color: $blue-01;

  @include lg {
    width: calc(100% - 62px);
    margin: 0 31px;
    font-size: 40px;
  }
  @include sm {
    width: calc(100% - 38px);
    margin: 0 19px;
    font-size: 28px;
  }
}
.marquee .marquee-inner {
  @include marquee-text;
  display: inline-block;
  will-change: transform;
  animation: marquee 600s linear infinite;
}
</style>
