<template>
  <div class="text-container" :style="gridStyles">
    <h2 v-if="headline" class="headline" v-html="transformLive(headlineWithLinebreaks, true)" />
    <p class="text" v-html="copy" />
    <button-component
      v-if="displayButton"
      :label="button.button_text"
      :button-link="{
        internal: button.is_internal,
        to: button.button_link
      }"
      :background-color="backgroundColor"
      class="text-button"
    />
  </div>
</template>

<script>
import GridItemMixin from '@/mixins/grid-item-mixin';

export default {
  name: 'TextComponent',
  mixins: [GridItemMixin],
  props: {
    headline: {
      type: String,
      default: '',
    },
    copy: {
      type: String,
      default: '',
    },
    button: {
      type: Object,
      default: null,
    },
    backgroundColor: {
      type: String,
      default: 'blue',
    },
  },
  computed: {
    headlineWithLinebreaks() {
      return this.headline.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
    displayButton() {
      return this.button && this.button.button_link !== null;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-container {
  color: $blue-01;
  padding: 0 40px;
  margin-bottom: 80px;

  @include sm {
    padding: 0;
  }
}
.headline {
  font-family: "OnAirBold";
  font-size: 15px;
  font-weight: normal;
}
.text ::v-deep h1, .text ::v-deep h2 {
  font-family: "OnAirLight";
  font-size: 15px;
  line-height: 24px;
  font-weight: normal;
  margin: 0;
}
.text ::v-deep a {
  font-family: "OnAirLight";
  font-size: 15px;
  line-height: 24px;
  font-weight: normal;
  margin: 0;
  color: $blue-01;
}
.text {
  font-family: "OnAirLight";
  font-size: 15px;
  line-height: 24px;
}
.text-button {
  margin-top: 30px;
}
</style>
