var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filtering-block"},[_c('div',{staticClass:"label",class:{ 'open': _vm.showFilterList },on:{"click":function($event){return _vm.onOpenFilterList($event)}}},[_c('div',[_c('span',{staticClass:"headline-desktop"},[_vm._v(_vm._s(_vm.filterHeader))])]),(!_vm.showFilterList)?_c('div',{staticClass:"reset-filter-wrapper"},[(_vm.hasGroups)?[_vm._l((_vm.filterListData),function(item,$index){return [(item.value)?_c('span',{key:("reset-" + $index),staticClass:"reset-filter reset-filter-desktop",on:{"click":function($event){return _vm.onResetSingleFilter($event, $index)}}},[_vm._v(_vm._s(item.text)+" "),_c('span',{staticClass:"reset-filter-icon"})]):_vm._e()]})]:_vm._e(),(_vm.isFiltered())?_c('span',{staticClass:"reset-filter reset-filter-desktop",on:{"click":function($event){return _vm.onResetFilter($event)}}},[_vm._v("Alle löschen "),_c('span',{staticClass:"reset-filter-icon"})]):_vm._e()],2):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"filtering-list",class:{ 'filtering-list-open': _vm.showFilterList },on:{"click":function($event){return _vm.onClickWrapper($event)}}},[_c('div',{staticClass:"filtering-list-headline"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.filterHeader))]),(_vm.isFiltered())?_c('span',{staticClass:"reset-filter",on:{"click":function($event){return _vm.onResetFilter($event)}}},[_vm._v("Zurücksetzen "),_c('span',{staticClass:"reset-filter-icon"})]):_vm._e()]),_c('div',{staticClass:"filtering-elements"},[(!_vm.hasGroups)?_vm._l((_vm.filterListData),function(ref,$index){
var text = ref.text;
var count = ref.count;
var key = ref.key;
return _c('div',{key:$index,staticClass:"filtering-element",class:{'filtering-element-disabled': _vm.isDisabled($index)},on:{"click":function($event){return _vm.onClickItem($event)}}},[_c('checkbox',{attrs:{"field":{
                label: (text + " (" + count + ")"),
                name: key,
              },"value":_vm.filterListData[$index].value,"disabled":_vm.isDisabled($index)},on:{"input":_vm.onChangeCheckbox},model:{value:(_vm.filterListData[$index].value),callback:function ($$v) {_vm.$set(_vm.filterListData[$index], "value", $$v)},expression:"filterListData[$index].value"}})],1)}):_vm._l((_vm.filterGroups),function(group,$i){return _c('div',{key:("group-" + $i),staticClass:"filtering-group"},[_c('div',{staticClass:"filtering-element-headline"},[_vm._v(_vm._s(group))]),_vm._l((_vm.getFiltersByGroup(group)),function(ref){
              var text = ref.text;
              var count = ref.count;
              var key = ref.key;
return _c('div',{key:_vm.getFilterIndexByKey(key),staticClass:"filtering-element filtering-element-grouped",class:{'filtering-element-disabled': _vm.isDisabled(_vm.getFilterIndexByKey(key))},on:{"click":function($event){return _vm.onClickItem($event)}}},[_c('checkbox',{attrs:{"field":{
                  label: (text + " (" + count + ")"),
                  name: key,
                },"value":_vm.filterListData[_vm.getFilterIndexByKey(key)].value,"disabled":_vm.isDisabled(_vm.getFilterIndexByKey(key))},on:{"input":_vm.onChangeCheckbox},model:{value:(_vm.filterListData[_vm.getFilterIndexByKey(key)].value),callback:function ($$v) {_vm.$set(_vm.filterListData[_vm.getFilterIndexByKey(key)], "value", $$v)},expression:"filterListData[getFilterIndexByKey(key)].value"}})],1)})],2)})],2),_c('div',{staticClass:"filtering-save"},[_c('button-component',{staticClass:"button-component",attrs:{"label":"Schliessen & speichern"},on:{"change":_vm.onClickEvent}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }