<template>
  <div class="priority-teaser">
    <div
      class="priority-teaser-wrapper"
      :style="teaserBackgroundImageCss"
    >
      <img
        v-if="title_image"
        :src="getResponsiveImage(title_image)"
        alt="">
      <img
        v-else
        src="@/assets/images/priority-logo/priority-logo-negative-new.png"
        alt="">
      <div class="priority-teaser-subtitle" v-html="title_alt" />
      <button-component
        background-color="white"
        :label="button_text"
        :button-link="{
            internal: false,
            to: this.button_link
          }"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'priority-teaser',
  props: {
    title_alt: {
      type: String,
      default: 'Entdecke jetzt weitere tolle Vorteile!',
    },
    title_image: {
      type: Object,
      default: null,
    },
    background: {
      type: Object,
      default: null,
    },
    button_text: {
      type: String,
      default: 'Jetzt mehr erfahren',
    },
    button_link: {
      type: String,
      default: 'https://o2online.de',
    },
  },
  computed: {
    teaserBackgroundImageCss() {
      const backgroundImage = this.getResponsiveImage(this.background)
        || require('@/assets/images/img-priority.png');
      return {
        backgroundImage: `url(${backgroundImage})`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
  .priority-teaser {
    width: 50%;
    margin: 0 auto;
    margin-top: 73px;
    padding-bottom: 80px;
  }
  .priority-teaser-wrapper {
    background-color: $blue-01;
    text-align: center;
    padding: 22px 22px 38px 22px;
    background-repeat: no-repeat;
    background-size: cover;
    img {
      height: 107px;
    }
  }
  .priority-teaser-subtitle {
    font-family: 'OnAirLight';
    font-size: 26px;
    line-height: 1.35;
    color: $white;
    margin-top: 8px;
  }
  .button-component {
    margin-top: 36px;
  }
  @include xl-1440 {
    .priority-teaser {
      padding-bottom: 75px;
    }
  }
  @include xl {
    .priority-teaser {
      padding-bottom: 70px;
    }
  }
  @include lg {
    .priority-teaser {
      width: calc(100% - 62px);
      margin-top: 50px;
      padding-bottom: 60px;
    }
  }
  @include sm {
    .priority-teaser {
      width: 100%;
      margin-top: 45px;
      padding-bottom: 50px;
    }
    .priority-teaser-wrapper {
      padding: 22px 22px 45px 22px;
    }
  }
</style>
