<template>
  <div class="takeover-serie-teaser-livestream-slide livestream-teaser">
    <div class="livestream-teaser-content">
      <div class="livestream-teaser-logo">
        <img v-if="priorityLogo" :src="priorityLogo" alt="priority-logo" width="160px" />
        <span
          v-if="priorityLogoText"
          v-html="transformLive(priorityLogoText)"
          class="livestream-teaser-logo-text"
        />
      </div>
      <div
        v-if="!artistLogo"
        v-html="transformLive(artistName)"
        class="livestream-teaser-header"
      />
      <div class="livestream-teaser-headerimage" v-if="artistLogo">
        <img :src="artistLogo" alt="artist-logo" />
      </div>
      <span
        v-html="transformLive(liveText, true)"
        class="livestream-teaser-not-live"
        :class="{ 'livestream-teaser-live' :enableLiveFlag }"
      />
      <div class="livestream-teaser-buttons">
        <button-component
          v-if="showCtaButton"
          class="button-component cta-link"
          :class="buttonId"
          background-color="white"
          :label="buttonLabel"
          :button-link="buttonLink"
        />
        <button-component
          v-if="calendarLink.calendar_file"
          background-color="white"
          button-type="primary"
          class="calendar-link"
          :class="buttonId"
          :label="calendarLink.calendar_button_text"
          :icon-props="{ enabled: true, name: 'calendar' }"
          :button-link="{
            internal: false,
            to: calendarLink.calendar_file.download_url
          }"
        />
      </div>
    </div>
    <RotatingBadge
      v-if="showTVLink"
      :icon="tvLinkIcon"
      :link="tvLinkUrl"
      :text="tvLinkText || 'TV-STREAM • FAQ • TV-STREAM • FAQ •'"
      class="livestream-teaser__badge"
    />
  </div>
</template>
<script>
import RotatingBadge from '@/components/rotating-badge/RotatingBadge.vue';

export default {
  name: 'takeover-livestream-teaser',
  components: {
    RotatingBadge,
  },
  props: {
    priorityLogo: {
      type: String,
      required: false,
    },
    priorityLogoText: {
      type: String,
      required: false,
    },
    artistLogo: {
      type: String,
      required: false,
    },
    artistName: {
      type: String,
      required: false,
    },
    backgroundImage: {
      type: String,
      required: false,
    },
    backgroundImageMobile: {
      type: String,
      required: false,
    },
    buttonHidden: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      required: false,
    },
    buttonId: {
      type: String,
      required: false,
    },
    buttonLink: {
      type: Object,
      required: () => ({}),
    },
    calendarLink: {
      type: Object,
      required: () => ({}),
    },
    liveText: {
      type: String,
      required: false,
    },
    enableLiveFlag: {
      type: Boolean,
      default: false,
    },
    showTVLink: {
      type: Boolean,
      default: false,
    },
    tvLinkText: {
      type: String,
      default: '',
    },
    tvLinkIcon: {
      type: String,
      default: '',
    },
    tvLinkUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    showCtaButton() {
      return !this.buttonHidden && typeof this.buttonLink.value !== 'undefined';
    },
  },
};
</script>
<style lang="scss" scoped>
.livestream-teaser {
  margin-bottom: 0;
  position: relative;
  height: 100%;
  font-family: "OnAirLight";
  color: $white;
  font-size: 18px;
  line-height: 1.33;
  background: none;
  display: flex;
  width: 100%;
  text-align: left;

  &-image {
    @include sm {
      object-fit: cover;
      height: auto;
      object-position: left;
    }
  }

  &-content {
    position: absolute;
    display: block;
    width: 80%;
    height: auto;
    left: 0;
    bottom: 116px;
    @include lg {
      left: 5%;
      bottom: 10%;
    }
    @include sm {
      left: 10%;
      bottom: 60px;
    }
  }
  &-headerimage {
    display: block;
    height: 64px;
    width: 80%;
    margin: 6px 0 30px;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    @include lg {
      height: 50px;
      width: 95%;
      margin-bottom: 0;
      margin-top: 24px;
    }
  }
  &-header {
    font-family: "OnAirBold";
    @include linear-font-size(64px, 130px);
    font-size: 130px;
    line-height: 0.96;

    @include sm {
      line-height: 1;
    }
  }
  &-not-live {
    display: block;
    margin: 20px 0 45px 0;
    @include xl {
      margin: 10px 0 35px 0;
    }
    @include sm {
      margin: 20px 0 45px 0;
    }
  }
  &-live {
    display: block;
    background: url('~@/assets/images/live.svg') no-repeat top left;
    padding-left: 60px;
    margin: 20px 0 45px 0;
    @include xl {
      margin: 10px 0 35px 0;
    }
    @include sm {
      margin: 20px 0 45px 0;
    }
  }
  &-logo {
    height: 75px;
    display: flex;
    align-items: center;
    @include sm {
      height: 60px;
    }
  }

  &-buttons {
    display: flex;
    flex-direction: row;

    .button-component + .button-component {
      margin-left: 10px;
    }
  }
  .livestream-teaser-logo-text {
    font-size: 30px;
    display: inline-flex;
    white-space: nowrap;
  }

  &__badge {
    position: absolute;
    right: 0;
    bottom: 100px;
    width: 130px;
    height: 130px;
    margin-right: -7px;

    @include lg {
      bottom: 30px;
    }

    @include md {
      bottom: 20px;
    }

    @include sm {
      bottom: 10px;
      width: 105px;
      height: 105px;
    }
  }
}
</style>
