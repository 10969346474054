<template>
  <div
    class="slide-item-content"
    :class="`slide-item-content-${data.type}`"
  >
    <takeover-serie-teaser
      v-if="data.type === 'takeover_serie_teaser'"
      :in-stock="data.value.in_stock"
      :hours="hours"
      :minutes="minutes"
      :seconds="seconds"
      :presale-end="presaleEnd"
      :local-image="data.value.local_image"
      :artist="data.value.artist"
      :calendar-link="data.value.calendar_link"
      :sold-out-message="data.value.sold_out_message"
      :event-link="data.value.button_link || {}"
      :event="data.value.event"
      :disruptor-enabled="data.value.disruptor_enabled"
      :disruptor="data.value.disruptor"
      :disruptor-sold-out="data.value.disruptor_sold_out"
    />
    <takeover-teaser
      v-if="data.type === 'takeover_teaser'"
      :in-stock="data.value.in_stock"
      :hours="hours"
      :minutes="minutes"
      :seconds="seconds"
      :presale-end="presaleEnd"
      :local-image="data.value.event_logo"
      :artist="data.value.artist"
      :sold-out-message="data.value.sold_out_message"
      :event-link="data.value.button_link || {}"
      :event="data.value.event"
      :text="data.value.text"
      :buttonId="data.value.button_id"
      :button-text="data.value.button_text"
      :calendar-link="data.value.calendar_link"
      :disruptor-enabled="data.value.disruptor_enabled"
      :priority-tickets-ticker-enabled="data.value.priority_tickets_ticker_enabled"
      :disruptor="data.value.disruptor"
    />
    <takeover-teaser-small
      v-if="data.type === 'takeover_teaser_small'"
      :in-stock="data.value.in_stock"
      :hours="hours"
      :minutes="minutes"
      :seconds="seconds"
      :presale-end="presaleEnd"
      :local-image="data.value.event_logo"
      :artist="data.value.artist"
      :sold-out-message="data.value.sold_out_message"
      :event-link="data.value.button_link || {}"
      :event="data.value.event"
      :text="data.value.text"
      :buttonId="data.value.button_id"
      :button-text="data.value.button_text"
      :calendar-link="data.value.calendar_link"
      :red-circle-enabled="data.value.red_circle_enabled"
      :priority-tickets-ticker-enabled="data.value.priority_tickets_ticker_enabled"
    />
    <takeover-teaser-livestream
      v-if="data.type === 'takeover_livestream_teaser'"
      :priorityLogo="getResponsiveImage(data.value.priority_logo)"
      :artistLogo="getResponsiveImage(data.value.event_logo)"
      :backgroundImage="getResponsiveImage(data.value.desktop_background)"
      :backgroundImageMobile="getResponsiveImage(data.value.mobile_background)"
      :priorityLogoText="data.value.priority_additional_text"
      :buttonHidden="data.value.button_hidden"
      :buttonLabel="data.value.button_text"
      :buttonId="data.value.button_id"
      :buttonLink="data.value.button_link || {}"
      :artistName="data.value.event_logo_alternative"
      :calendar-link="data.value.calendar_link"
      :liveText="data.value.text"
      :enableLiveFlag="data.value.live_flag"
      :showTVLink="data.value.tv_icon.activate"
      :tvLinkText="data.value.tv_icon.button_text"
      :tvLinkIcon="data.value.tv_icon.tv_icon_logo"
      :tvLinkUrl="tvIconButtonLink"
    />
  </div>
</template>
<script>
import TakeoverSerieTeaser from './slide-items/takeover-serie-teaser.vue';
import TakeoverTeaserSmall from './slide-items/takeover-teaser-small.vue';
import TakeoverTeaser from './slide-items/takeover-teaser.vue';
import TakeoverTeaserLivestream from './slide-items/takeover-teaser-livestream.vue';

const UNITS = {
  second: { duration: 1000 },
  minute: { duration: 60000 },
  hour: { duration: 3600000 },
  day: { duration: 86400000 },
};

export default {
  name: 'PresaleTeaserSlideItem',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    TakeoverSerieTeaser,
    TakeoverTeaserSmall,
    TakeoverTeaser,
    TakeoverTeaserLivestream,
  },
  data: () => ({
    jsInterval: null,
    hours: null,
    minutes: null,
    seconds: null,
  }),
  computed: {
    presaleEnd() {
      if (!this.data.value.presale_end) {
        return null;
      }
      return new Date(this.data.value.presale_end);
    },
    tvIconButtonLink() {
      if (!this.data.value.tv_icon) return null;
      const { button_link } = this.data.value.tv_icon;
      if (button_link) {
        return button_link.value;
      }
      return null;
    },
  },
  methods: {
    getTicketDate(dateData) {
      if (!dateData) {
        return '-';
      }
      const day = `0${dateData.getDate()}`.slice(-2);
      const month = `0${dateData.getMonth() + 1}`.slice(-2);
      const year = dateData.getFullYear();
      return `${day}.${month}.${year}`;
    },
    getTicketTime(dateData) {
      if (!dateData) {
        return '-';
      }
      const hours = `0${dateData.getHours()}`.slice(-2);
      const minutes = `0${dateData.getMinutes()}`.slice(-2);
      return `${hours}:${minutes}`;
    },
    updateCountdown() {
      const now = new Date();
      if (!this.presaleEnd) {
        return;
      }
      const timeDifference = this.presaleEnd.getTime() - now.getTime();

      const secondsInt = Math.floor((timeDifference / UNITS.second.duration) % 60);
      this.seconds = secondsInt.toString().padStart(2, '0');

      const minutesInt = Math.floor((timeDifference / UNITS.minute.duration) % 60);
      this.minutes = minutesInt.toString().padStart(2, '0');

      const hoursInt = Math.floor((timeDifference / UNITS.hour.duration));
      this.hours = hoursInt.toString().padStart(2, '0');

      this.$store.commit('setTicketsCountdown', `${this.hours}:${this.minutes}:${this.seconds}`);
    },
  },
  mounted() {
    this.updateCountdown();
    this.jsInterval = setInterval(this.updateCountdown, 100);
  },
  beforeDestroy() {
    clearInterval(this.jsInterval);
  },
};
</script>
<style lang="scss" scoped>
  @keyframes rotate{
    to{ transform: rotate(360deg); }
  }
  .slide-item-content {
    width: 1344px;
    max-width: calc(100% - 254px);
    height: 100%;
    display: flex;

    @include xl {
      max-width: calc(100% - 110px);
      margin-left: 0;
    }
    @include lg {
      max-width: calc(100% - 50px);
      height: 440px;
    }
    @include sm {
      max-width: calc(100% - 40px);
      height: calc(100vh - 166px);
      max-height: 100%;
      flex-wrap: wrap;
      margin-bottom: 69px;
      margin-top: 33px;
      flex-direction: column;
    }
    &-takeover_teaser_small {
      justify-content: center;
    }
  }
</style>
