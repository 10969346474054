<template>
  <div class="livestream-teaser">
    <img
      class="livestream-teaser-image"
      :src="isMobile ? backgroundImageMobile : backgroundImage"
      alt="livestream-teaser-image"
      width="100%"
      height="100%" />
    <div class="livestream-teaser-content">
      <div class="livestream-teaser-logo">
        <img v-if="priorityLogo" :src="priorityLogo" alt="priority-logo" width="160px" />
        <PriorityLogo v-else width="160px" />
        <span
          v-if="priorityLogoText"
          v-html="transformLive(priorityLogoText)"
          class="livestream-teaser-logo-text"
        />
      </div>
      <div
        v-if="!artistLogo"
        v-html="transformLive(artistName)"
        class="livestream-teaser-header"
      />
      <div class="livestream-teaser-headerimage" v-if="artistLogo">
        <img :src="artistLogo" alt="artist-logo" />
      </div>
      <span
         v-html="transformLive(liveText)"
        class="livestream-teaser-not-live"
        :class="{ 'livestream-teaser-live' :enableLiveFlag }"
      />
      <button-component
        background-color="white"
        :label="buttonLabel"
        :button-link="{
            internal: true,
            to: this.buttonLink
          }"
      />
    </div>
  </div>
</template>
<script>
import PriorityLogo from '@/components/misc/PriorityLogo.vue';

export default {
  name: 'livestream-teaser',
  components: {
    PriorityLogo,
  },
  props: {
    priorityLogo: {
      type: String,
      required: false,
    },
    priorityLogoText: {
      type: String,
      required: false,
    },
    artistLogo: {
      type: String,
      required: false,
    },
    artistName: {
      type: String,
      required: false,
    },
    backgroundImage: {
      type: String,
      required: false,
    },
    backgroundImageMobile: {
      type: String,
      required: false,
    },
    buttonLabel: {
      type: String,
      required: false,
    },
    buttonLink: {
      type: String,
      required: false,
    },
    liveText: {
      type: String,
      required: false,
    },
    enableLiveFlag: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickTeaserButton() {
      this.$router.push(this.buttonLink);
    },
  },
};
</script>
<style lang="scss" scoped>
.livestream-teaser {
  margin-bottom: 0;
  position: relative;
  height: auto;
  font-family: "OnAirLight";
  color: $white;
  font-size: 18px;
  line-height: 1.33;
  background: none;
  display: flex;

  @include sm {
    font-size: 15px;
    line-height: 1.6;
    background-image: var(--backgroud-image-mobile);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: calc(100vh - #{$mobile-navigation-height}) !important;
    margin-bottom: 0;
  }

  &-image {
    @include sm {
      object-fit: cover;
      height: auto;
      object-position: left;
    }
  }

  &-content {
    position: absolute;
    display: block;
    width: 80%;
    height: auto;
    left: 125px;
    bottom: 20%;
    @include lg {
      left: 5%;
      bottom: 10%;
    }
    @include sm {
      left: 10%;
      bottom: 60px;
    }
  }
  &-headerimage {
    display: block;
    height: 200px;
    width: 80%;
    img {
      max-width: 100%;
      max-height: 100%;
    }

    @include lg {
      height: 100px;
      width: 95%;
    }
  }
  &-header {
    font-family: "OnAirBold";
    @include linear-font-size(64px, 130px);
    font-size: 130px;
    line-height: 0.96;

    @include sm {
      line-height: 1;
    }
  }
  &-not-live {
    display: block;
    margin: 20px 0 45px 0;
    @include xl {
      margin: 10px 0 35px 0;
    }
    @include sm {
      margin: 20px 0 45px 0;
    }
  }
  &-live {
    display: block;
    background: url('~@/assets/images/live.svg') no-repeat top left;
    padding-left: 60px;
    margin: 20px 0 45px 0;
    @include xl {
      margin: 10px 0 35px 0;
    }
    @include sm {
      margin: 20px 0 45px 0;
    }
  }
  &-logo {
    height: 75px;
    display: flex;
    align-items: center;
    @include sm {
      height: 60px;
    }
  }
  .livestream-teaser-logo-text {
    font-size: 30px;
  }
}
</style>
