<template>
  <div class="show-more-button" @click="showMore"></div>
</template>

<script>
export default {
  name: 'ShowMoreButton',
  methods: {
    showMore() {
      this.trackActionEvent({
        eventName: 'customInteractionClick',
        eCat: 'analysis',
        eAct: 'click - more information',
        eLab: '-',
        nonInteraction: false,
      });
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.show-more-button {
  display: block;
  width: 82px;
  height: 82px;
  margin: 0 auto;
  background: url('~@/assets/images/load-more.svg') no-repeat center;
  cursor: pointer;

  @include sm {
    width: 72px;
    height: 72px;
  }
}
</style>
