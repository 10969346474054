<template>
  <div class="presale-ticket-alarm">
    <p><span class="icon icon-alert-white" /></p>
    <p class="headline">{{ pageData.ticket_alarm_headline }}</p>
    <p class="call-to-action">{{ pageData.ticket_alarm_text }}</p>
    <p class="button-row">
      <button-component
        class="button-component"
        label="Jetzt Ticketalarm aktivieren"
        background-color="white"
        :button-type="isTablet ? 'secondary' : 'primary'"
        :button-link="{
          internal: false,
          to: `${this.VUE_APP_API}/api/v2/events/series/alarm/${eventSeries.id}.ics`
        }"
      />
    </p>
    <p class="additional-info">
      Entdecke jetzt <a href="https://o2online.de/priority" target="_blank">weitere Vorteile von Priority</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'PresaleTicketAlarm',
  props: ['eventSeries'],
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/base.scss";
@import "src/assets/styles/variables.scss";

.presale-ticket-alarm {
  display: block;
  text-align: center;
  background-color: $blue-02;
  padding: 25px 20px;
  color: $white;
  border-bottom: 1px solid $black;

  @include lg {
    padding: 25px 15px;
  }
}
.headline {
  font-family: "OnAirBold";
  font-size: 20px;
  margin: 15px 0 10px 0;
}
.call-to-action {
  font-family: "OnAirLight";
  font-size: 13px;
}
.button-row {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
  min-height: 35px;
  padding-top: 20px;
}
.additional-info {
  padding-top: 20px;
  font-family: 'OnAirLight';
  font-size: 13px;
  line-height: 1.85;

  a {
    text-decoration: underline;
  }
}
</style>
