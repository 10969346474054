<template>
  <div class="amazon-music" v-if="amazon_link" :style="gridStyles">
    <template>
      <iframe
        :src="amazon_link"
        :height="mode === 'playlist' ? 550 : 290"
        scrolling="no"
        frameborder="0"
        style="border:none; overflow:hidden;"
        allowtransparency="true"
      />
    </template>
  </div>
</template>

<script>
import GridItemMixin from '@/mixins/grid-item-mixin';

export default {
  name: 'AmazonMusic',
  mixins: [GridItemMixin],
  props: {
    mode: {
      type: String,
      default: 'playlist',
    },
    amazon_link: {
      type: String,
      default: '',
    },
  },
  computed: {
    embedType() {
      return this.spotify_uri.substring(
        this.spotify_uri.indexOf(':') + 1,
        this.spotify_uri.lastIndexOf(':'),
      );
    },
    isPlayer() {
      return this.element === 'play';
    },
    spotifyId() {
      return this.spotify_uri.substring(this.spotify_uri.lastIndexOf(':') + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.amazon-music {
  padding: 0 28px;
  margin-bottom: 80px;
  grid-column: center / span end !important;

  @include sm {
    padding: 0;
  }

  iframe {
    width: 100%;
  }
}
.is-player {
  padding: 0 20px;

  // // spotify player is not supported in ie11
  // @include ie11 {
  //   display: none;
  // }
}
</style>
