<template>
  <div class="teaser-item-wrapper" :class="classList">
    <div class="teaser-item">
      <div
        v-html="replacedHeader"
        v-resize-text="{ ratio: 0.6, maxFontSize: 45, delay: 50 }"
        class="header"
      />
      <div class="button-wrapper" v-if="buttonLink">
        <span>
          <button-component
            :label="buttonText"
            :button-link="{
              internal: isInternalLink,
              to: buttonLink
            }"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TeaserItem',
  props: {
    header: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
    },
    buttonLink: {
      type: String,
      required: false,
    },
    isInternalLink: {
      type: Boolean,
      default: true,
    },
    classList: {
      type: Object,
      required: false,
    },
  },
  computed: {
    replacedHeader() {
      return this.header.replace(/amp;/g, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.teaser-item {
  overflow: hidden;

  @include sm {
    border-left: 1px solid $very-light-grey;
    border-right: 1px solid $very-light-grey;
    margin: 0 20px;
  }
}

.header {
  font-family: 'OnAirBold';
  font-size: 45px;
  color: $blue-01;
  line-height: 1.11;
  margin-left: -6px;
  margin-top: 30px;

  @include xl {
    font-size: 40px;
    margin-top: 40px;
    margin-left: -5px;
  }

  @include lg {
    font-size: 45px;
    margin-top: 40px;
    margin-left: -6px;
  }
}

.button-wrapper {
  margin: 15px 0 50px 0;

  @include xl {
    margin: 25px 0 45px 0;
  }

  @include lg {
    margin: 15px 0 60px 0;
  }

  @include sm {
    margin: 15px 0 40px 0;
  }
}

.no-outer-margins {
  .header {
    margin: 0;
  }
  .teaser-item {
    margin: 0;
  }
}
.no-borders {
  .teaser-item {
    border: none;
  }
}
</style>
