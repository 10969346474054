<template>
  <div class="media-list-settings">
    <div class="headline-block">
      <div class="headline">
        {{ pageData.media_content_header }}
      </div>
      <sorting
        class="sorting-wrapper"
        :sort-list="sortList"
        :active-item="activeItem"
        @change="onChangeSorting"
      />
    </div>
    <filtering
      class="filter-block"
      :filter-list="filterItems"
      @change="onChangeFiltering"
      :has-groups="true"
    />
  </div>
</template>
<script>
import Filtering from '@/components/filtering/Filtering.vue';
import Sorting from '@/components/sorting/Sorting.vue';

export default {
  name: 'media-list-settings',
  props: {
    sortData: {
      type: Function,
      default: () => ({}),
    },
    onFilterData: {
      type: Function,
      default: () => ({}),
    },
    filterItems: {
      type: Array,
      default: () => ([]),
    },
  },
  components: {
    Sorting,
    Filtering,
  },
  data: () => ({
    activeItem: 0,
  }),
  computed: {
    sortList() {
      return [
        {
          text: 'Neueste',
          key: 'new',
        },
        {
          text: 'A-Z',
          key: 'asc',
        },
      ];
    },
  },
  methods: {
    onChangeSorting(data) {
      this.activeItem = data.$index;
      this.sortData(data.item);
    },
    onChangeFiltering(data) {
      this.onFilterData(data);
    },
  },
};
</script>
<style lang="scss" scoped>
  .media-list-settings {
    min-height: 95px;
    height: auto;
    display: flex;
  }
  .headline-block {
    width: calc(25% + 1px);
    position: relative;

    .headline {
      font-family: 'OnAirBold';
      font-size: 34px;
      line-height: 1.18;
      color: $blue-01;
      position: absolute;
      left: 22px;
      top: 60px;

      @include xl {
        font-size: 26px;
      }
    }
  }
  .sorting-wrapper {
    position: absolute;
    right: 22px;
    top: 75px;
  }
  .filter-block {
    width: calc(50% - 2px);
    background-color: $white;
    border-bottom: solid 1px $very-light-grey;
  }
  @include xl {
    .sorting-wrapper {
      top: 68px;
    }
  }
  @include lg {
    .media-list-settings {
      padding: 0 31px;
    }
    .headline-block {
      width: calc(50% + 1px);
    }
  }
  @include sm {
    .media-list-settings {
      padding: 0;
      min-height: 80px;
      height: 80px;
      flex-direction: row-reverse;
    }
    .filter-block {
      width: 67%;
    }
    .headline-block {
      width: 33%;
      border-left: solid 1px $very-light-grey;
      border-bottom: solid 1px $very-light-grey;
      .headline {
        display: none;
      }
    }
    .sorting-wrapper {
      right: 20px;
      bottom: 30px;
      top: unset;
    }
  }
</style>
