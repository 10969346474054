<template>
  <div class="exclusive-block-priority-content">
    <div class="priority-content">
      <img
        src="@/assets/images/exclusive-events-module/priority-tickets.png"
        alt="priority-tickets"
      >
    </div>
    <div class="priority-title" v-html="headline" />
    <div class="priority-subtitle" v-html="subheadline" />
    <div class="priority-button">
      <button-component
        class="button-component"
        :label="buttonText"
        background-color="white"
        button-type="primary"
        :button-link="{
          internal: true,
          to: '/priority-tickets'
        }"
      />
    </div>
    <div class="priority-subinfo" v-html="footer" />
    <div class="priority-badge">
      <div class="priority-badge-blue" />
      <div class="priority-badge-white" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'exclusive-block-priority',
  props: [
    'buttonText',
    'headline',
    'subheadline',
    'footer',
  ],
  data: () => ({}),
  methods: {
    onClickEvent() {
      console.log('123');
    },
  },
};
</script>
<style lang="scss" scoped>
  .exclusive-block-priority-content {
    padding-left: 50px;
    padding-right: 58px;
    padding-bottom: 25px;
    position: relative;

    @include xl {
      padding-bottom: 0;
      padding-right: 50px;
    }
    @include sm {
      padding-bottom: 0;
      padding-right: 46px;
      padding-left: 46px;
    }
  }
  .priority-content img {
    width: 233px;
    max-width: 100%;
    display: flex;
    @include sm {
      width: 210px;
    }
  }
  .priority-title {
    font-family: 'OnAirLight';
    font-size: 48px;
    line-height: 1.08;
    letter-spacing: -0.4px;
    padding-top: 25px;
    padding-bottom: 20px;
    width: 90%;
    @include xl {
      font-size: 44px;
      line-height: 1.21;
      letter-spacing: -0.2px;
      padding-top: 20px;
    }
    @include sm {
      padding-top: 25px;
    }
  }
  .priority-subtitle {
    font-family: 'OnAirLight';
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: normal;

    @include xl {
      font-size: 14px;
      line-height: 1.57;
      width: 50%;
    }
    @include sm {
      width: 100%;
    }
  }
  .priority-button {
    padding-top: 25px;
    padding-bottom: 40px;
    @include xl {
      .button-component {
        padding: 15px 15px;
      }
    }
    @include xl {
      .button-component {
        padding: 15px 37px;
      }
      padding-bottom: 0;
    }
  }
  .priority-subinfo {
    font-family: "OnAirLight";
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: normal;
    padding-bottom: 13px;
    &::v-deep {
      a {
        text-decoration: underline;
      }
    }

    @include xl {
      display: none;
    }
  }
  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
  .priority-badge {
    position: absolute;
    top: -130px;
    right: -45px;
    width: 130px;
    height: 130px;

    @include xl {
      right: 75px;
      top: -100px;
      width: 103px;
      height: 103px;
    }
    @include lg {
      right: 68px;
    }

    @include sm {
      right: 28px;
      width: 93px;
      top: -90px;
      height: 93px;
    }

    .priority-badge-white {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 130px;
      height: 55px;
      overflow: hidden;
      &::before {
        content: "";
        background-image: url('~@/assets/images/exclusive-events-module/coming-soon-white-badge.svg');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        position: absolute;
        width: 130px;
        height: 130px;
        top: -75px;
        animation: spin 14s infinite linear;

        @include xl {
          width: 103px;
          height: 103px;
          top: -60px;
        }

        @include sm {
          width: 93px;
          height: 93px;
          top: -50px;
        }
      }
      @include xl {
        width: 103px;
        height: 43px;
      }

      @include sm {
        width: 93px;
        height: 43px;
      }
    }
    .priority-badge-blue {
      position: absolute;
      top: 0;
      left: 0;
      width: 130px;
      height: 75px;
      overflow: hidden;
      &::before {
        content: "";
        background-image: url('~@/assets/images/exclusive-events-module/coming-soon-blue-badge.svg');
        background-repeat: no-repeat;
        background-position: top;
        background-size: contain;
        position: absolute;
        width: 130px;
        height: 130px;
        animation: spin 14s infinite linear;

        @include xl {
          width: 103px;
          height: 103px;
        }

        @include sm {
          width: 93px;
          height: 93px;
        }
      }

      @include xl {
        width: 103px;
        height: 60px;
      }

      @include sm {
        width: 93px;
        height: 50px;
      }
    }
  }
</style>
