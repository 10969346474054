<template>
  <div class="social-media-wall">
    <div class="header">
      <div class="share main" v-if="!isTablet && !isMobile">
        <social-share
          :small="true"
          :headline="social_media_header"
          :social_media_links="social_media_links"
        />
      </div>
      <div class="horizontal-wrapper">
        <horizontal-crawler
          :text="customizable_headline.text"
          :canvas-config="canvasConfig"
          :show-in-mobile="true"
          font-family="OnAirRoman"
        />
      </div>
    </div>

    <div v-if="!isAcceptance" class="social-wall-acceptance">
      <div class="social-wall-acceptance-headline">
        {{ data_acceptance.headline }}
      </div>
      <button-component
        class="button-component-acceptance"
        :label="data_acceptance.button_text"
        background-color="blue"
        button-type="primary"
        @change="onClickAccept"
      />
      <div class="social-wall-acceptance-description" v-html="data_acceptance.legal_text" />
    </div>

    <masonry
      v-if="isAcceptance"
      :cols="{default: 4, 992: 2, 576: 1}"
      class="social-wall-content"
      >
      <div class="share" v-if="isTablet || isMobile">
        <social-share
          :small="true"
          :headline="social_media_header"
          :social_media_links="social_media_links"
        />
      </div>
      <div class="filler" v-if="isTablet" />
      <social-media-wall-item
        v-for="(social, index) in socialWallItems"
        :key="social.id"
        :post="social"
        :showSpacer="showSpacer(index)"
        :showTopLine="showTopLine(index)"
        :active-video="activeVideo"
      />
    </masonry>
    <span class="load-more" @click="loadMore" v-if="hasMore && isAcceptance" />
  </div>
</template>

<script>
import HorizontalCrawler from '@/components/horizontal-crawler/HorizontalCrawler.vue';
import SocialShare from '@/components/misc/SocialShare.vue';
import SocialMediaWallItem from
  '@/views/journey/components/modules/children/SocialMediaWallItem.vue';

export default {
  name: 'SocialMediaWall',
  components: {
    HorizontalCrawler,
    SocialMediaWallItem,
    SocialShare,
  },
  props: {
    headline: {
      type: String,
    },
    social_media_header: {
      type: String,
    },
    social_media_links: {
      type: Array,
    },
    customizable_headline: {
      type: Object,
    },
    data: {
      type: Object,
    },
    data_acceptance: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    socialWall() {
      return this.data.posts || [];
    },
    socialWallItems() {
      return this.socialWall.slice(0, this.show);
    },
    hasMore() {
      return this.show < this.socialWall.length;
    },
    add() {
      return this.isMobile ? 3 : 4;
    },
    canvasConfig() {
      const colors = [
        this.customizable_headline.main_color,
        this.customizable_headline.alternative_color,
      ];
      return {
        1680: {
          fontSize: 160,
          colors,
          gradientCount: 3,
        },
        1440: {
          fontSize: 140,
          colors,
          gradientCount: 3,
        },
        993: {
          fontSize: 120,
          colors,
          gradientCount: 2,
        },
        577: {
          fontSize: 90,
          colors,
          gradientCount: 1,
        },
      };
    },
  },
  data: () => ({
    show: 4,
    innerWidth: window.innerWidth,
    activeVideo: '-1',
    isAcceptance: false,
  }),
  methods: {
    onClickAccept() {
      this.isAcceptance = true;
      sessionStorage.setItem('isAcceptance', 'true');
    },
    triggerPlayVideo(index) {
      this.activeVideo = index;
    },
    loadMore() {
      if (this.hasMore) this.show += this.add;
    },
    setWidth() {
      this.innerWidth = window.innerWidth;
    },
    showSpacer(index) {
      if (this.isMobile) return false;
      return this.isTablet ? index === 1 : index === 0 || index === 2;
    },
    showTopLine(index) {
      switch (true) {
        case this.isMobile:
          return index === 0;
        case this.isTablet:
          return index < 2;
        default:
          return index < 4;
      }
    },
  },
  mounted() {
    this.isAcceptance = sessionStorage.getItem('isAcceptance') === 'true';
    this.show = this.add;
    this.$nextTick(() => {
      window.addEventListener('resize', this.setWidth);
    });
    this.$root.$on('onTriggerPlayVideo', this.triggerPlayVideo);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWidth);
    this.$root.$off('onTriggerPlayVideo', this.triggerPlayVideo);
  },
};
</script>

<style lang="scss" scoped>
  .header {
    padding: 30px 0;
    display: flex;
    @include lg {
      display: block;
      padding: 30px 0 10px;
    }
  }
  .share {
    width: 25%;
    @include lg {
      width: unset;
      margin-bottom: 30px;
    }
  }
  .horizontal-wrapper {
    width: 75%;
    @include lg {
      width: calc(100% - 60px);
      padding-left: 30px;
    }
    @include sm {
      padding-left: unset;
      width: 100%;
    }
  }

.social-media-wall {
  .filler {
    height: 1px;
  }
  .load-more {
    display: block;
    width: 82px;
    height: 82px;
    margin: 45px auto;
    background: url('~@/assets/images/load-more.svg') no-repeat center;
    cursor: pointer;
    @include sm {
      width: 72px;
      height: 72px;
    }
  }
  .social-wall-content {
    position: relative;

    @include lg {
      padding: 0 30px 0 30px;
    }
    @include sm {
      padding: 0;
    }

  }
}
.social-wall-acceptance {
  width: calc(50% - 50px);
  margin: 0 auto;
  text-align: center;
  color: $blue-02;
  padding: 25px;
  font-family: 'OnAirLight';
  @include lg {
    width: calc(80% - 50px);
  }
  @include md {
    width: calc(100% - 50px);
  }
}
.social-wall-acceptance-headline {
  font-family: 'OnAirBold';
  font-size: 24px;
  padding-bottom: 20px;
}
.button-component-acceptance {
  margin-bottom: 20px;
}
.social-wall-acceptance-description {
  font-size: 14px;
  &::v-deep a {
    color: $blue-02;
    text-decoration: underline;
  }
}
</style>
