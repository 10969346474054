<template>
  <div class="not-found-page" data-view>
    <div class="content" v-if="pageData">
      <h1>{{ pageData.header_title }}</h1>
      <div
        class="content-text"
        v-if="pageInfo"
        v-html="pageInfo.value"
      />
      <div class="content-button">
        <button-component
        class="content-button"
        :label="pageData.button_text"
        :button-link="{
          internal: true,
          to: pageData.button_link.url
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'not-found',
  components: {},
  computed: {
    pageInfo() {
      return this.pageData.content[0] || {};
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
  .not-found-page {
    background-color: $blue-01;
  }
  .content {
    background-color: $white;
    width: 762px;
    margin: 0 auto;
    padding: 0 123px 50px 59px;
    padding-bottom: 50px !important;

    @include xl {
      padding: 0 35px;
      max-width: calc(100% - 70px);
    }
    @include sm {
      padding: 0 20px;
    }

    h1 {
      font-size: 70px;
      font-weight: normal;
      line-height: 1.11;
      font-family: 'OnAirLight';
      margin: 0;
      padding-top: 25px;
      padding-bottom: 25px;
      color: $blue-01;

      @include xl {
        font-size: 62px;
        line-height: 1.13;
      }

      @include sm {
        font-size: 50px;
        line-height: 1.2;
        padding-bottom: 10px;
      }
    }

    &-button {
      text-align: center;
      margin: 0 auto;
    }

    &-text {
      font-family: 'OnAirLight';
      color: $blue-01 !important;
      line-height: 1.57;
      padding-top: 15px;
      padding-bottom: 15px;

      ::v-deep a {
        color: $blue-01;
        text-decoration: underline;
      }

      @include sm {
        font-size: 14px;
        line-height: 1.57;
        padding-right: 20px;
      }
    }
  }
</style>
