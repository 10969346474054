<template>
  <div
    class="block block--main"
    :class="[(isNavigationActive && openUpMenu) ? 'open' : '']"
  >
    <component
      class="main-link navigation-link"
      v-for="link in links"
      :key="link.key"
      :is="(link.url || false) ? 'a' : 'div'"
      :class="[(link.highlighted || false) ? 'main-link--highlighted' : '']"
      :href="getLink(link)"
      @click="onClickMain(link)"
    >
      <span :class="[
        `icon icon-main icon-${link.glyphicon}`,
        link.highlighted ? '' : 'lg:hidden'
      ]"  />
      <span v-html="strippedHtml(link.title)" />
    </component>

    <div class="meta lg:hidden">
      <a href="tel:+49089787979409" class="meta-button">
        Bestellhotline 089 78 79 79 409
      </a>

      <a class="main-link">
        <span class="icon icon-main icon-shop" />
        <span>O<sub>2</sub> Shops</span>
      </a>

      <div class="links">
        <a href="#">PRIVATKUNDEN</a>
        <a href="#">GESCHÄFTSKUNDEN</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: () => ([]),
    },
    isNavigationActive: Boolean,
    openUpMenu: Boolean,
    isLoggedIn: Boolean,
  },
  data: () => ({
    selected: false,
  }),
  watch: {
    selected(newVal) {
      const value = this.links.find((link) => link.icon === newVal) || {};
      this.$emit('update-selected', value.sub || false);
    },
  },
  methods: {
    strippedHtml(val) {
      const regex = /(<([^>]+)>)/ig;
      return val.replace(regex, '');
    },
    onClickMain(link) {
      if (!!link.url || this.selected === link.icon) this.selected = false;
      else this.selected = link.icon;
    },
    getLink(item) {
      if (typeof item.url === 'string') return item.url;
      if (this.isLoggedIn) return item.url.loggedIn;
      return item.url.default || '#';
    },
  },
};
</script>
