export default {
  methods: {
    setMetaData() {
      if (this.pageData) {
        const {
          seo_title,
          search_description,
          canonical_url,
          social_tags_image,
          meta_robots,
        } = this.pageData.meta;
        // title
        document.title = seo_title || 'O2 music app';
        // description
        const descriptionElement = document.querySelector('meta[name="description"]');
        descriptionElement.setAttribute('content', search_description
          || 'O2 music app description');
        // canonical
        const canonicalElement = document.querySelector('link[rel="canonical"]');
        canonicalElement.setAttribute(
          'href', canonical_url || this.VUE_APP_URL + window.location.pathname,
        );
        // robots
        const robotElement = document.querySelector('meta[name="robots"]');
        robotElement.setAttribute('content', meta_robots === 'take-the-global-setting'
          ? this.siteSettings.global_meta_robots : meta_robots);
        // Social Tags: Facebook Open Graph
        const ogTitleElement = document.querySelector('meta[property="og:title"]');
        ogTitleElement.setAttribute('content', document.title);
        const ogUrlElement = document.querySelector('meta[property="og:url"]');
        ogUrlElement.setAttribute(
          'content', canonical_url || this.VUE_APP_URL + window.location.pathname,
        );
        const ogDescriptionElement = document.querySelector('meta[property="og:description"]');
        ogDescriptionElement.setAttribute('content', search_description
          || 'O2 music app description');
        const ogImageElement = document.querySelector('meta[property="og:image"]');
        const timeStamp = `?v=${new Date().valueOf()}`;
        ogImageElement.setAttribute('content', (social_tags_image
          ? social_tags_image.meta.download_url
          : require('@/assets/images/social_share.jpg')) + timeStamp);
        // Social Tags: Twitter Cards
        const twTitleElement = document.querySelector('meta[name="twitter:title"]');
        twTitleElement.setAttribute('content', document.title);
        const twDescriptionElement = document.querySelector('meta[name="twitter:description"]');
        twDescriptionElement.setAttribute('content', search_description
        || 'O2 music app description');
        const twImageElement = document.querySelector('meta[name="twitter:image"]');
        twImageElement.setAttribute('content', social_tags_image
          ? social_tags_image.meta.download_url : require('@/assets/images/social_share.jpg'));
      }
    },
    setGoogleTagManager() {
      /* eslint-disable */
      if ((window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.IS_PRERENDERING) || this.VUE_APP_MODE !== 'production') {
      // if ((window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.IS_PRERENDERING)) {
        return;
      }
      const script = document.createElement('script');
      script.setAttribute('id', 'usercentrics-cmp');
      script.setAttribute('data-settings-id', '2BGLbVtv');
      script.setAttribute('data-eu-mode', 'true');
      script.setAttribute('type', 'application/javascript');
      script.onload = () => {
        ((w,d,s,l,i) => {
          w[l]=w[l]||[];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event:'gtm.js'
          });
          var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
          j.onload = () => {
            this.$nextTick(() => {
              if (this.videoLink) {
                this.onGetVideo();
              }
            });
          }
        })(window,document,'script','tefDataLayer','GTM-M3ZFJX');
      };
      script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
      document.head.appendChild(script);

    }
  },
  mounted() {
    this.setGoogleTagManager();
  },
  updated() {
    setTimeout(() => {
      this.$root.$emit('update-app-teaser-tween');
      this.$root.$emit('update-list-artist-tween');
    }, 500)
  },
  watch:{
    $route (){
      this.setMetaData();
    }
  }
};
