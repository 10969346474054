<template>
  <div class="raffle-header">
    <h1 v-html="title" />
    <div v-html="desc" class="raffle-header-description" />
    <div v-if="additionalText" class="raffle-header-description">
      <p>{{ additionalText }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RaffleHeader',
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    desc: {
      type: String,
      required: false,
      default: '',
    },
    additionalText: {
      type: String,
      required: false,
    },
  },
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
@import "src/assets/styles/base.scss";
@import "src/assets/styles/variables.scss";
.raffle-header {
  color: $blue-01;
  font-family: "OnAirLight";

  h1 {
    font-size: 70px;
    font-weight: 300;
    line-height: 1.11;
    margin: 0;
    max-width: 553px;

    @include xl {
      font-size: 62px;
      line-height: 1.13;
      max-width: 451px;
    }

    @include lg {
      max-width: 784px;
    }

    @include sm {
      max-width: unset;
      font-size: 50px;
      line-height: 1.2;
    }
  }

  .raffle-header-description ::v-deep a {
    color: $blue-01;
    text-decoration: underline;
  }
  .raffle-header-description {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.6;
    padding-top: 25px;
    max-width: 476px;

    @include lg {
      max-width: 416px;
    }

    @include sm {
      max-width: unset;
      font-size: 14px;
      line-height: 1.57;
    }
  }
}

</style>
