<template>
  <div class="default-video-player">
    <video
      controls
      :autoplay="autoplay"
      :muted="mute"
    >
      <source
        :src="video.url"
        :type="video.type"
      >
    Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>

export default {
  name: 'DefaultVideoPlayer',
  props: {
    video: {
      type: Object,
      required: true,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    mute: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
  }),
};
</script>
<style lang="scss" scoped>
  .default-video-player video {
    width: 100%;
  }
</style>
