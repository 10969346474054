<template>
  <div class="overlay-background" @click="closeOverlay">
    <div class="overlay" @click.stop :style="{ 'height': height, 'width': width }">
      <div class="title-bar" v-if="displayTitleBar">
        <img class="close-button" src="@/assets/icons/close.svg" @click="closeOverlay">
      </div>
      <div v-else-if="displayCloseButton" class="close-wrapper">
        <img class="close-button" src="@/assets/icons/close.svg" @click="closeOverlay">
      </div>
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Overlay',
  props: {
    displayTitleBar: {
      type: Boolean,
      default: true,
    },
    displayCloseButton: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '80%',
    },
    width: {
      type: String,
      default: '80%',
    },
  },
  methods: {
    closeOverlay() {
      this.$emit('closeOverlay');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";

.overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(5, 5, 60, 0.7);
}
.overlay {
  background-color: $white;
  width: 80%;
  height: 80%;

  .title-bar {
    display: flex;
    justify-content: flex-end;
    background-color: $blue-02;
    padding: 10px 15px;
    color: $white;
  }

  .close-wrapper {
    height: 30px;
    text-align: right;
    position: absolute;
    right: 30px;
    top: 30px;
  }
}
.close-button {
  cursor: pointer;
}
</style>
