<template>
  <div class="o2-footer">
    <ul class="o2-footer-links">
      <li
        v-for="footerLink in footerLinks"
        :key="footerLink.key"
        class="o2-footer-link"
      >
        <a :href="footerLink.url">{{ footerLink.title }}</a>
      </li>
    </ul>

    <ul class="social-icons">
      <li class="social-icon">
        <a href="https://www.facebook.com/o2musicde/" title="o2 auf Facebook" target="_blank" rel="noopener">
          <span class="glyphicon glyphicon-facebook"></span>
        </a>
      </li>
      <li class="social-icon">
        <a href="https://twitter.com/o2music" title="o2 auf Twitter" target="_blank" rel="noopener">
          <span class="glyphicon glyphicon-twitter"></span>
        </a>
      </li>
      <li class="social-icon">
        <a href="https://www.youtube.com/playlist?list=PLktkLyCMl0POszglYoMM8t9mLb9zax9BZ" title="o2 auf YouTube" target="_blank" rel="noopener">
          <span class="glyphicon glyphicon-youtube"></span>
        </a>
      </li>
      <li class="social-icon">
        <a href="https://www.instagram.com/o2musicde" title="o2 auf Instagram" target="_blank" rel="noopener">
          <span class="glyphicon glyphicon-instagram"></span>
        </a>
      </li>
    </ul>

    <div class="copyright-wrapper">
      <a href="https://telefonica.de" target="_blank" rel="noopener">&copy; Telefónica Germany GmbH &amp; Co. OHG</a>
      <img src="@/assets/images/telefonica-new-logo.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'o2-footer',
  computed: {
    footerLinks() {
      return this.$store.state.siteSettings.footerO2Links;
    },
  },
};
</script>

<style lang="scss" scoped>
.o2-footer {
  padding: 80px 0;
  font-family: "OnAirLight";
  font-size: 14px;
  color: #5f6264;
  background-color: #eaecee;

  a {
    color: #5f6264;
    transition: color 250ms, background-color 250ms;

    &:hover {
      color: #002e6e;
      text-decoration: none;
    }
  }
}
.o2-footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
}
.o2-footer-link {
  margin: 0 10px;
}
.copyright-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  img {
    margin-left: 15px;
    height: 28px;

    @include sm {
      margin-left: 8px;
      height: 25px;
    }
  }
}
.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 35px 0;

  .social-icon {
    margin: 0 30px;
    font-size: 26px;
  }
}
</style>
