<template>
  <div v-if="pageData" class="raffle-content-wrapper">
      <div class="raffle-content">
        <back-button
          :onClick="goBack"
        />
        <template v-if="currentState === raffleStates.error">
          <raffle-header
            :title="errorTexts.headline"
            :desc="errorTexts.text"
            :additionalText="apiError" />
          <div class="button-wrapper">
            <span @click="setStateInitial"><button-component :label="errorTexts.button" /></span>
          </div>
        </template>
        <template v-else-if="currentState === raffleStates.success">
          <raffle-header
            :title="successTexts.headline"
            :desc="successTexts.text" />
          <div class="button-wrapper">
            <span @click="goBack"><button-component :label="successTexts.button" /></span>
          </div>
        </template>
        <template v-else-if="currentState === raffleStates.initial">
          <raffle-header
            :title="texts.headline"
            :desc="texts.text" />
          <raffle-form
            :onError="onError"
            :onSuccess="setStateSuccess" />
        </template>
      </div>
  </div>
</template>
<script>
import RaffleHeader from '@/views/raffle/components/RaffleHeader.vue';
import RaffleForm from '@/views/raffle/components/RaffleForm.vue';
import BackButton from '@/components/button/BackButton.vue';

const RAFFLE_STATES = {
  success: 'success',
  error: 'error',
  initial: 'initial',
};

export default {
  name: 'RaffleContent',
  components: {
    RaffleHeader,
    RaffleForm,
    BackButton,
  },
  computed: {
    texts() {
      const { headline, text } = this.pageData;
      return { headline, text };
    },
    successTexts() {
      const { thank_you_headline, thank_you_text, thank_you_button } = this.pageData;
      return {
        headline: thank_you_headline,
        text: thank_you_text,
        button: thank_you_button,
      };
    },
    errorTexts() {
      const { error_page_headline, error_page_text, error_page_button } = this.pageData;
      return {
        headline: error_page_headline,
        text: error_page_text,
        button: error_page_button,
      };
    },
  },
  data: () => ({
    raffleStates: RAFFLE_STATES,
    currentState: RAFFLE_STATES.initial,
    apiError: null,
  }),
  methods: {
    goBack() {
      if (this.currentState === this.raffleStates.error) {
        this.updateState(this.raffleStates.initial);
        return;
      }
      if (this.$route.query.campaign) {
        this.$router.push({ name: 'Home' });
      } else {
        this.$router.go(-1);
      }
    },
    updateState(state) {
      this.currentState = this.raffleStates[state];
      this.apiError = null;
      this.scrollToTop();
    },
    onError(msg = null) {
      this.setStateError();
      this.apiError = msg;
    },
    setStateError() {
      this.updateState(this.raffleStates.error);
    },
    setStateSuccess() {
      this.updateState(this.raffleStates.success);
    },
    setStateInitial() {
      this.updateState(this.raffleStates.initial);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/styles/base.scss";
@import "src/assets/styles/variables.scss";
.raffle-content-wrapper {
  background: #fff;
  padding: 0 123px 0 59px;

  @include xl {
    padding: 0 35px;
  }
  @include sm {
    padding: 0 20px;
  }

  .raffle-content {
    width: auto;
    display: block;

    @include lg {
      border: 1px solid $very-light-grey;
      border-top: none;
      border-bottom: none;
      padding-left: 50px;
    }

    @include sm {
      padding-left: 0;
    }

    .button-wrapper {
      margin: 55px 0 150px;
    }
  }
}
</style>
