<template>
  <div
    class="highlight-journey"
    :class="[highlightJourney.teaser_size, highlightJourney.image_position]"
  >
    <div
      class="image-container clickable" @click="goTo"
    >
      <div
        class="image-cover"
        v-negative="{
            mouse_over: highlightJourney.mouse_over,
            mouse_over_text: highlightJourney.mouse_over_text
          }"
        @mouseover="onMouseOver()"
        @mouseleave="onMouseOut()"
      >
        <img :src="coverImageUrl" :alt="highlightJourney.title" class="image">
        <transition name="fade" v-if="highlightJourney.cover_video">
          <div
            v-show="isHovered || isTablet || isMobile"
            class="local-video"
          >
            <video
              v-if="highlightJourney.cover_video"
              ref="video"
              muted
              :autoplay="isTablet || isMobile"
              loop
              preload="auto"
              playsinline
            >
              <source
                :src="highlightJourney.cover_video.local_video.url"
                :type="highlightJourney.cover_video.local_video.type"
              >
            </video>
          </div>
        </transition>
        <div v-if="highlightJourney.banderole_checkbox" class="highlight-journey-banderole" />
      </div>
    </div>
    <div class="content">
      <h2
        v-if="highlightJourney.resize_title"
        class="title clickable"
        :class="[ isHovered || isMobile || isTablet ? 'title-active' : '' ]"
        @click="goTo"
        v-resize-text="{ ratio: 0.6, maxFontSize: maxFontSize, delay: 50 }"
        v-html="transformLive(highlightJourney.title)"
        v-custom-html="highlightJourney.title"
      />
      <h2
        v-else
        class="title clickable"
        :class="[ isHovered || isMobile || isTablet ? 'title-active' : '' ]"
        @click="goTo"
        v-custom-html="highlightJourney.title"
        v-html="transformLive(highlightJourney.title)"
      />
      <div class="information" v-html="highlightJourney.information" />
      <div class="button-container" @click="goTo">
        <button-component
          :label="highlightJourney.button_text"
          :button-type="buttonType"
          background-color="blue"
          :button-link="{
            internal: true,
            to: highlightJourney.button_link || '/'
          }"
          @click="goTo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, ScrollTrigger } from 'gsap/all';

export default {
  name: 'HightlightJourney',
  props: {
    highlightJourney: {
      type: Object,
      default: () => {},
    },
    highlightId: {
      type: String,
      required: true,
    },
    buttonType: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      isOnTablet: window.innerWidth <= 992,
      tween: null,
      isHovered: false,
      playPromise: null,
    };
  },
  computed: {
    coverImageUrl() {
      return this.getResponsiveImage(this.highlightJourney.cover);
    },
    maxFontSize() {
      if (this.highlightJourney.teaser_size === 'small') {
        return '64px';
      }
      if (this.highlightJourney.teaser_size === 'medium') {
        return '96px';
      }
      return '120px';
    },
    isIE() {
      // source: https://stackoverflow.com/a/22551342
      return /MSIE|Trident/.test(window.navigator.userAgent);
    },
    link() {
      return this.highlightJourney.button_links;
    },
    hasLink() {
      return !!this.link;
    },
    isInternalLink() {
      return !!this.link && this.link.type === 'internal_link';
    },
    isExternalLink() {
      return !!this.link && this.link.type === 'external_link';
    },
    isExternalYoutubeLink() {
      return this.isExternalLink && this.isYoutubeLink(this.link.value);
    },
  },
  methods: {
    onMouseOver() {
      this.isHovered = true;
      const videoElem = this.$refs.video;
      if (!videoElem) return;
      videoElem.play();
    },
    onMouseOut() {
      this.isHovered = false;
      const videoElem = this.$refs.video;
      if (!videoElem) return;
      if (!videoElem.paused) {
        videoElem.currentTime = 0.1;
        videoElem.pause();
      }
    },
    goTo() {
      if (this.hasLink) {
        this.trackActionEvent({
          eventName: 'customInteractionClick',
          // event_name: 'click_cta', // TODO
          eCat: 'analysis',
          eAct: `click - ${this.highlightJourney.button_text}`,
          eLab: this.decodeHtmlToText(this.highlightJourney.title),
          nonInteraction: false,
        });
        const { value: link } = this.link;
        if (this.isInternalLink) {
          this.$router.push(link);
        } else if (this.isExternalLink && !this.isExternalYoutubeLink) {
          window.open(link, '_blank');
        } else if (this.isExternalYoutubeLink) {
          this.openYoutubeInNewWindow(link);
        }
      }
    },
    addScrollAnimation() {
      // We want to start the scroll animation once the top of the element reaches the bottom
      // of the page.
      let start = 'top bottom';

      // But we have to correct this, when the element is already in view. Then we want the
      // animation to only at the current vertical position of the element.
      const distanceToTop = this.$el.getBoundingClientRect().top;
      if (distanceToTop < window.innerHeight) {
        start = `top ${distanceToTop}`;
      }

      // Add animation to element.
      window.requestAnimationFrame = window.requestAnimationFrame.bind(window);
      gsap.registerPlugin(ScrollTrigger);
      this.tween = gsap.to(this.$el, {
        y: `${this.highlightJourney.scroll_speed}px`,
        ease: 'none',
        id: this.highlightId,
        scrollTrigger: {
          trigger: this.$el,
          start,
          end: 'bottom top',
          scrub: true,
        },
      });
    },
    enableScrollAnimation(tween) {
      if (tween) {
        tween.scrollTrigger.enable();
      }
    },
    disableScrollAnimation(tween) {
      if (tween) {
        tween.pause(0);
        tween.scrollTrigger.disable();
      }
    },
    configureScrollAnimation() {
      this.isOnTablet = window.innerWidth <= 992;

      if (!this.tween && !this.isOnTablet) {
        this.addScrollAnimation();
      }

      if (this.isOnTablet) {
        this.disableScrollAnimation(this.tween);
      } else {
        this.enableScrollAnimation(this.tween);
      }
    },
  },
  mounted() {
    if (this.highlightJourney.resize_title) {
      if (this.isIE) {
        const resizeEvent = window.document.createEvent('UIEvents');
        resizeEvent.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(resizeEvent);
      } else {
        window.dispatchEvent(new Event('resize'));
      }
    }
    this.configureScrollAnimation();
    window.addEventListener('resize', this.configureScrollAnimation, false);
  },
  destroyed() {
    window.removeEventListener('resize', this.configureScrollAnimation, false);
  },
  watch: {
    isTablet() {
      const videoElem = this.$refs.video;
      if (!videoElem) return;
      videoElem.play();
    },
    isMobile() {
      const videoElem = this.$refs.video;
      if (!videoElem) return;
      videoElem.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: all .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.highlight-journey {
  display: flex;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;

  @include lg {
    padding: 0 31px;
  }
  @include sm {
    flex-direction: column;
    padding: 0 19px;
  }

  &.large {
    width: 75%;

    @include lg {
      width: 100%;
    }
  }
  &.medium {
    width: 50%;

    @include lg {
      width: 100%;
    }
  }
  &.small {
    width: 50%;

    @include lg {
      width: 100%;
    }
  }
  .image-cover {
    position: relative;
  }
  .local-video {
    position: absolute;
    width: 100%;
    display: flex;
    margin: auto;
    bottom: 0;
    top: 0;
    left: 0;

    video {
      width: 100%;
      justify-content: center;
    }
  }
}

$large-image-padding: 10%;

.image-container {
  box-sizing: border-box;
  z-index: 2;

  .large.image-left & {
    flex: 0 0 calc(66.6% - #{$large-image-padding});
    padding-left: $large-image-padding;

    @include ie11 {
      // see https://github.com/philipwalton/flexbugs#flexbug-8
      flex-basis: calc(66.6% - #{$large-image-padding} - #{$large-image-padding});
    }
    @include lg {
      flex: 0 0 50%;
      padding-left: 31px;
    }
    @include sm {
      flex: 0 0 100%;
      width: 100%;
      padding: 0;
    }
  }
  .large.image-right & {
    flex: 0 0 calc(66.6% - #{$large-image-padding});
    padding-right: $large-image-padding;

    @include ie11 {
      flex-basis: calc(66.6% - #{$large-image-padding} - #{$large-image-padding});
    }
    @include lg {
      flex: 0 0 50%;
      padding-right: 31px;
    }
    @include sm {
      flex: 0 0 100%;
      width: 100%;
      padding: 0;
    }
  }
  .medium.image-left & {
    flex: 0 0 60%;

    @include lg {
      flex: 0 0 50%;
    }
    @include sm {
      flex: 0 0 100%;
      width: 100%;
      padding: 0;
    }
  }
  .medium.image-right & {
    flex: 0 0 60%;

    @include lg {
      flex: 0 0 50%;
    }
    @include sm {
      flex: 0 0 100%;
      width: 100%;
      padding: 0;
    }
  }
  .small.image-left & {
    flex: 0 0 50%;

    @include lg {
      padding: 0 50px;
    }
    @include sm {
      flex: 0 0 100%;
      width: 100%;
      padding: 0;
    }
  }
  .small.image-right & {
    flex: 0 0 calc(50% - 50px);

    @include lg {
      padding-right: 50px;
    }
    @include sm {
      flex: 0 0 100%;
      width: 100%;
      padding: 0;
    }
  }
}
.image {
  width: 100%;
  height: auto;
  display: flex;
}
.content {
  z-index: 1;
  box-sizing: border-box;

  .image-left & {
    padding-right: 20px;
  }

  .image-right & {
    padding-left: 20px;
  }

  .large & {
    flex: 0 0 calc(33.3% + #{$large-image-padding});

    @include ie11 {
      flex-basis: calc(33.3% + #{$large-image-padding});
    }
    @include lg {
      flex: 0 0 50%;
    }
    @include sm {
      flex: 0 0 100%;
      width: 100%;
    }
  }
  .medium & {
    flex: 0 0 40%;

    @include lg {
      flex: 0 0 50%;
    }
    @include sm {
      flex: 0 0 100%;
      width: 100%;
    }
  }
  .small & {
    flex: 0 0 50%;

    @include lg {
      flex: 0 0 50%;
    }
    @include sm {
      flex: 0 0 100%;
      width: 100%;
    }
  }
  .small.image-left & {
    @include lg {
      margin-left: -50px;
    }
    @include sm {
      margin-left: 0;
    }
  }
  .small.image-right & {
    @include lg {
      flex: 0 0 calc(50% + 50px);

      @include ie11 {
        flex-basis: calc(50% + 50px);
      }
    }
  }
}
.title {
  font-family: 'OnAirBold';
  font-weight: normal;
  margin: 0;
  color: $blue-01;
  transition: transform 0.3s;

  @include sm {
    margin-top: 15px;
  }

  .large & {
    @include proportional-font-size(80px, 120px);
  }
  .medium & {
    @include proportional-font-size(60px, 96px);
  }
  .small & {
    @include proportional-font-size(50px, 64px);
  }
  .image-left & {
    margin-left: -10px;

    &-active {
      transform: translate3d(35px, 0, 0);
    }

    @include sm {
      margin-left: 0;

      &-active {
        transform: translate3d(0, 0, 0);
      }
    }
  }
  .image-right & {
    margin-right: -10px;

    &-active {
      transform: translate3d(-35px, 0, 0);
    }

    @include sm {
      margin-right: 0;

      &-active {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
.information {
  position: relative;
  font-family: 'OnAirRoman';
  font-size: 13px;
  line-height: 1.6;
  color: $blue-01;
  margin-bottom: 25px;
  margin-top: 20px;

  &::v-deep {
    sub {
      font-size: 8px;
    }
  }

  .image-left & {
    padding-left: 25px;

    @include sm {
      padding-left: 0;
    }

    &::before {
      position: absolute;
      content: "";
      width: 18px;
      height: 1px;
      background-color: $blue-01;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      @include sm {
        display: none;
      }
    }
  }
  .image-right & {
    padding-right: 25px;

    @include sm {
      padding-right: 0;
    }

    &::after {
      position: absolute;
      content: "";
      width: 18px;
      height: 1px;
      background-color: $blue-01;
      right: 0;
      bottom: 50%;

      @include sm {
        display: none;
      }
    }
  }
}
.button-container {
  .image-left & {
    margin-left: 25px;

    @include sm {
      margin-left: 0;
    }
  }
  .image-right & {
    margin-right: 25px;

    @include sm {
      margin-right: 0;
    }
  }
}
.image-right {
  .content {
    order: 1;
    text-align: right;

    @include sm {
      order: 2;
    }
  }
  .image-container {
    order: 2;

    @include sm {
      order: 1;
    }
  }
}
.clickable {
  cursor: pointer;
}
.highlight-journey-banderole {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &::before {
    content: '';
    position: absolute;
    height: 26px;
    background-color: $blue-02;
    left: 0;
    right: 0;
    top: 0;
    background-image: url('~@/assets/images/priority-logo/priority-tickets@2x.png');
    background-position: left center;
    background-size: contain;
  }
  &::after {
    content: '';
    position: absolute;
    height: 26px;
    background-color: $blue-02;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('~@/assets/images/priority-logo/priority-tickets@2x.png');
    background-position: left center;
    background-size: contain;
  }
}
</style>
