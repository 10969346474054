var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mediathek-wrapper",attrs:{"data-view":""}},[_c('GridLines',{attrs:{"classList":{'disable-sm': true}}}),(_vm.showYoutubePlayer)?_c('multi-video-overlay',{attrs:{"selectedItem":_vm.selectedYoutubeItem,"prevItem":_vm.prevYoutubeItem,"nextItem":_vm.nextYoutubeItem,"onChange":_vm.openPlayer,"onClose":_vm.closePlayer,"on-open-video":_vm.openVideoInNewTab}}):_vm._e(),(_vm.hasTakeoverSlider)?_c('presale-teaser',{attrs:{"data":_vm.pageData.takeover_content}}):_vm._e(),(
      !_vm.hasTakeoverSlider && _vm.pageData.media_teaser && _vm.pageData.media_teaser.media_content.length
    )?_c('intro-video',{attrs:{"teaser-data":_vm.pageData.media_teaser,"media-content":_vm.pageData.media_teaser.media_content[0],"on-open-video":_vm.openVideoInNewTab}}):_vm._e(),(!_vm.hasTakeoverSlider && _vm.pageData.media_teaser)?_c('div',{staticClass:"intro full",class:{
      'active-intro-video': _vm.pageData.media_teaser && _vm.pageData.media_teaser.media_content.length
    }},[_c('teaser-priority',{class:{
        'active-intro-video': _vm.pageData.media_teaser && _vm.pageData.media_teaser.media_content.length
      },attrs:{"image":_vm.getResponsiveImage(_vm.pageData.media_teaser.image),"backgroundImage":_vm.getResponsiveImage(_vm.pageData.media_teaser.background),"backgroundImageLandscape":_vm.getResponsiveImage(_vm.pageData.media_teaser.background_landscape),"title":_vm.pageData.media_teaser.title,"subtitle":_vm.pageData.media_teaser.subtitle,"description":_vm.pageData.media_teaser.description}})],1):_vm._e(),_c('media-list-settings',{staticClass:"media-list-settings-desktop-version",class:{ 'media-list-settings-default':
      _vm.pageData.media_teaser && !_vm.pageData.media_teaser.media_content.length },attrs:{"sort-data":_vm.sortData,"on-filter-data":_vm.onFilterData,"filter-items":_vm.filterItems}}),(!_vm.showYoutubePlayer)?_c('masonry',{staticClass:"mediathek",attrs:{"cols":{default: 4, 992: 2, 576: 1}}},[(!_vm.hasTakeoverSlider  && _vm.showMansoryTeaser())?_c('div',{staticClass:"intro main",class:{ 'active-intro-video': _vm.pageData.media_teaser.media_content.length }},[_c('teaser-priority',{attrs:{"image":_vm.getResponsiveImage(_vm.pageData.media_teaser.image),"backgroundImage":_vm.getResponsiveImage(_vm.pageData.media_teaser.background),"backgroundImageLandscape":_vm.getResponsiveImage(_vm.pageData.media_teaser.background_landscape),"title":_vm.pageData.media_teaser.title,"subtitle":_vm.pageData.media_teaser.subtitle,"description":_vm.pageData.media_teaser.description,"special-media-content":_vm.pageData.media_teaser.media_content[0] || null,"on-open-video":_vm.openVideoInNewTab}})],1):_vm._e(),(_vm.isMobile)?_c('media-list-settings',{staticClass:"media-list-settings-mobile-version",attrs:{"sort-data":_vm.sortData,"on-filter-data":_vm.onFilterData,"filter-items":_vm.filterItems}}):_vm._e(),_vm._l((_vm.mediaItems),function(item,index){return _c('div',{key:index,staticClass:"mediathek-item"},[_c('media-module-item',_vm._b({attrs:{"show-spacer":_vm.isTablet ? index === 1 : [0,2].includes(index)},on:{"open-player":_vm.openPlayer}},'media-module-item',item,false))],1)})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }