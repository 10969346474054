<template>
  <div class="presale-login-alert" @click="login">
    <img src="@/assets/icons/o2-signin.svg" />
    <p v-html="pageData.login_alert" />
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';

const auth = new AuthService();

export default {
  name: 'PresaleLoginAlert',
  props: ['eventName'],
  methods: {
    login() {
      const countdownTimer = this.$store.state.ticketsCountdown;
      auth.login();
      this.trackActionEvent({
        eventName: 'customInteractionClick',
        eCat: 'Priority - Ticket Presale',
        eAct: 'click - o2 customer verification',
        eLab: `${this.eventName} | ${countdownTimer}`,
        nonInteraction: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";

.presale-login-alert {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: $red;
  margin: -3px -3px 3px -3px;
  padding: 20px 30px;
  color: $white;
  font-family: "OnAirBold";
  font-size: 14px;
  line-height: 22px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
}
</style>
