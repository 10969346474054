import Vue from 'vue';
import VueRouter from 'vue-router';

// Views
import Home from '@/views/home/Home.vue';
import JourneyPage from '@/views/journey/JourneyPage.vue';
import ContentPage from '@/views/content-page/ContentPage.vue';
import Privacy from '@/views/privacy/Privacy.vue';
import Imprint from '@/views/imprint/Imprint.vue';
import NotFound from '@/views/not-found/NotFound.vue';
import Raffle from '@/views/raffle/Raffle.vue';
import Mediathek from '@/views/mediathek/Mediathek.vue';
import MetaTicketShop from '@/views/meta-ticket-shop/MetaTicketShop.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound,
  },
  {
    path: '/gewinnspiele/:campaignName',
    name: 'Raffle',
    component: Raffle,
  },
  {
    path: '/journeys/:journeyId',
    name: 'JourneyPage',
    component: JourneyPage,
  },
  {
    path: '/information/:contentPage',
    name: 'ContentPage',
    component: ContentPage,
  },
  {
    path: '/mediathek/:videoSlug?',
    name: 'Mediathek',
    component: Mediathek,
  },
  // {
  //   path: '/mediathek/:videoSlug',
  //   name: 'MediathekVideo',
  //   component: Mediathek,
  // },
  {
    path: '/priority-tickets',
    name: 'MetaTicketShop',
    component: MetaTicketShop,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
