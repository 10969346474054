<template>
  <div class="mobileapp-teaser">
    <div class="mobileapp-teaser-wrapper">
      <img
        src="@/assets/images/app-teaser.png"
        alt="mobileapp-teaser"
        class="mobileapp-teaser-img"
      >
      <div class="mobileapp-teaser-content">
        <div class="content-text">
          <div
            class="content-text-title"
            v-html="'Mein O<sub>2</sub> App'"
          />
          <div
            class="content-text-subtitle"
            v-html="'Hol dir die wichtigsten Services & Vorteile.'"
          />
        </div>
        <div class="apple-button">
          <a
            target="_blank"
            href="https://o2.de/2/o2musicappteaser"
            rel="noopener"
          >
            <img
              src="@/assets/images/app-store.png"
              alt="app-store"
            >
          </a>
        </div>
        <div class="google-button">
          <a
            target="_blank"
            href="https://o2.de/2/o2musicappteaser"
            rel="noopener"
          >
            <img
              src="@/assets/images/google-play.png"
              alt="app-store"
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, ScrollTrigger } from 'gsap/all';

export default {
  name: 'app-teaser',
  data() {
    return {
      isOnTablet: window.innerWidth <= 992,
      tween: null,
    };
  },
  methods: {
    reTween() {
      if (this.tween && this.tween.scrollTrigger) {
        this.tween.scrollTrigger.refresh();
      }
    },
    addScrollAnimation() {
      // Add animation to element.
      window.requestAnimationFrame = window.requestAnimationFrame.bind(window);
      gsap.registerPlugin(ScrollTrigger);
      this.tween = gsap.to(this.$el, {
        y: -150,
        ease: 'none',
        id: 'app-teaser',
        scrollTrigger: {
          trigger: this.$el,
          start: 'top bottom',
          end: 'top 33%',
          scrub: true,
        },
      });
    },
    enableScrollAnimation(tween) {
      if (tween) {
        tween.scrollTrigger.enable();
      }
    },
    disableScrollAnimation(tween) {
      if (tween) {
        tween.pause(0);
        tween.scrollTrigger.disable();
      }
    },
    configureScrollAnimation() {
      this.isOnTablet = window.innerWidth <= 992;

      if (!this.tween && !this.isOnTablet) {
        this.addScrollAnimation();
      }

      if (this.isOnTablet) {
        this.disableScrollAnimation(this.tween);
      } else {
        this.enableScrollAnimation(this.tween);
      }
    },
  },
  mounted() {
    this.configureScrollAnimation();
    window.addEventListener('resize', this.configureScrollAnimation, false);
    this.$root.$on('update-app-teaser-tween', this.reTween);
  },
  destroyed() {
    window.removeEventListener('resize', this.configureScrollAnimation, false);
    this.$root.$off('update-app-teaser-tween', this.reTween);
  },
};
</script>

<style lang="scss" scoped>
  .mobileapp-teaser {
    width: 25%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    &-wrapper {
      max-width: 224px;
      margin: 0 auto;
      transform: translate(0, 70px);

      .mobileapp-teaser-img {
        width: 155%;
        margin-left: -43%;
      }
    }
    &-content{
      position: absolute;
      top: 175px;
      width: 224px;

      .content-text-title {
        font-size: 25.3px;
        color: $white;
        font-family: 'OnAirBold';
        text-align: center;
        line-height: 1.12;

        ::v-deep sub {
          font-size: 12px;
          margin-left: -2px;
          margin-top: -2px;
        }
      }

      .content-text-subtitle {
        font-size: 12px;
        line-height: 1.51;
        color: $white;
        font-family: 'OnAirRoman';
        text-align: center;
        margin-top: 6px;
        margin-bottom: 9px;
        padding-left: 30px;
        padding-right: 30px;
      }
      .apple-button, .google-button {
        width: auto;
        padding-left: 35px;
        padding-right: 35px;
        margin-top: 26px;

        a {
          width: 100%;
          display: block;
        }

        img {
          width: 100%;
        }
      }
    }

    @include xl-1440 {
      &-wrapper, &-content {
        width: 214px;
      }
      &-wrapper {
        width: 214px;
        transform: translate(0, 60px);
      }
    }
    @include xl {
      &-wrapper {
        width: 203px;
        transform: translate(0, 50px);
      }
      &-content {
        width: 203px;
        top: 155px;

        .apple-button, .google-button {
          margin-top: 23px;
        }
      }
    }
    @include lg {
      width: 50%;
      top: -85px;
    }
    @include md {
      position: relative;
      top: auto;
      order: 2;
      width: auto;

      &-wrapper {
        width: auto;
        max-width: initial;
        transform: translate(0, 0);

        .mobileapp-teaser-img {
          display: none;
        }
      }
      &-content {
        position: relative;
        top: auto;
        width: auto;

        .content-text-title {
          text-align: left;
          font-size: 30px;
          position: relative;
          padding-left: 65px;

          &::before {
            content: '';
            position: absolute;
            width: 100px;
            height: 100px;
            left: 0;
            background-image: url('~@/assets/images/app-o2-icon.png');
            background-repeat: no-repeat;
            background-size: cover;
            top: -40px;
            left: -30px;
          }
        }
        .content-text-subtitle {
          text-align: left;
          padding-left: 0;
          font-size: 15px;
          margin-top: 9px;
          margin-bottom: 0;
        }
        .apple-button, .google-button {
          max-width: 120px;
          width: 100%;
          padding-left: 0;
          padding-right: 20px;
          display: inline-block;
          margin-top: 13px;

          img {
            height: 40px;
          }
        }
      }
    }
  }
</style>
