<template>
  <div class="music-footer">
    <TicketAlarm class="ticket-alarm" />
    <div class="priority-links-wrapper">
      <PriorityLogo
        width="138px"
        link="https://www.o2online.de/priority/"
      />
      <p class="priority-links-infotext">
        Bist du bei O<sub>2</sub>? Entdecke exklusive Vorteile:
      </p>
      <ul class="priority-links">
        <li
          v-for="exclusiveBenefit in exclusiveBenefits"
          :key="exclusiveBenefit.key"
          class="priority-link"
        >
          <a :href="exclusiveBenefit.url" rel="noopener">{{ exclusiveBenefit.title }}</a>
        </li>
      </ul>
    </div>
    <AppTeaser v-if="pageData" />
  </div>
</template>

<script>
import TicketAlarm from '@/components/misc/TicketAlarm.vue';
import PriorityLogo from '@/components/misc/PriorityLogo.vue';
import AppTeaser from '@/components/footer/AppTeaser.vue';

export default {
  name: 'music-footer',
  components: {
    TicketAlarm,
    PriorityLogo,
    AppTeaser,
  },
  computed: {
    exclusiveBenefits() {
      return this.$store.state.siteSettings.footerExclusiveBenefits;
    },
  },
};
</script>

<style lang="scss" scoped>

.music-footer {
  display: flex;
  padding: 60px 50px;
  color: $white;
  background-color: $blue-02;

  ::v-deep .input-wrapper input {
    font-size: 13px;
  }

  @include md {
    flex-direction: column;
    padding: 40px 20px;
  }
  .priority-links-wrapper {
    margin-top: -10px;
  }
  .ticket-alarm,
  .priority-links-wrapper {
    flex: 1 1 0;

    @include md {
      max-width: 275px;
    }
  }
  .ticket-alarm {
    margin-right: 80px;

    @include md {
      margin-right: unset;
      margin-bottom: 50px;
    }
  }

  @include lg {
    flex-wrap: wrap;

    .ticket-alarm,
    .priority-links-wrapper {
      flex: initial;
    }
    .ticket-alarm {
      width: 50%;
      order: 1;
    }
    .priority-links-wrapper {
      margin-top: 25px;
      width: 60%;
      order: 3;
    }
  }
  @include md {
    .ticket-alarm,
    .priority-links-wrapper {
      width: 100%;
      max-width: 100%;
    }
  }
}
.priority-links-infotext {
  font-size: 15px;
  font-family: "OnAirRoman";

  @include xl {
    width: 50%;
  }
  @include lg {
    width: auto;
  }
}
.priority-links {
  margin-top: 15px;
}
.priority-link {
  font-size: 15px;
  font-family: "OnAirLight";
  margin-bottom: 10px;
}
</style>
