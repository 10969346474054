<template>
  <div class="artists-list">
    <div
      v-if="headline"
      v-html="transformLive(headline, true)"
      class="artists-list-headline"
    />
    <TransitionGroup name="fade" tag="div" class="presales-wrapper">
      <presale-list-item
        v-for="(artist, $index) in presalesLimited"
        :key="$index"
        :link="artist.value.link"
        :image="getResponsiveImage(artist.value.cover)"
        :artist-name="artist.value.title"
        :information="artist.value.information"
        :extra-content="artist.value.extra_content"
        :list-index="$index"
        :mouse-over="artist.value.mouse_over"
        :mouse-over-text="artist.value.mouse_over_text"
      />
    </TransitionGroup>
    <show-more-button v-if="displayShowMore" @click="expand" />
  </div>
</template>

<script>
import ShowMoreButton from '@/components/button/ShowMoreButton.vue';
import PresaleListItem from './presale-list-item.vue';

const MOBILE_DISPLAY_LIMIT = 3;

export default {
  name: 'presale-list',
  components: {
    PresaleListItem,
    ShowMoreButton,
  },
  props: {
    headline: {
      type: String,
      default: '',
    },
    presales: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    displayLimit() {
      return this.isMobile ? MOBILE_DISPLAY_LIMIT : false;
    },
    displayShowMore() {
      return this.displayLimit && !this.expanded && this.presales.length > this.displayLimit;
    },
    presalesLimited() {
      if (!this.displayLimit || this.expanded) return this.presales;
      return this.presales.slice(0, this.displayLimit);
    },
  },
  methods: {
    expand() {
      this.expanded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.artists-list {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 50px;

  @include lg {
    width: auto;
    padding-left: 31px;
    padding-right: 31px;
    margin-bottom: 75px;
  }

  @include sm {
    width: auto;
    padding-left: 19px;
    padding-right: 19px;
  }

  .presales-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include sm {
      display: block;
    }
  }
}
.artists-list-headline {
  font-family: 'OnAirBold';
  font-size: 58px;
  line-height: 1.1;
  color: $blue-01;
  text-align: center;
  padding: 35px 0;
  padding-bottom: 55px;
  margin: 0 auto;
  width: 100%;

  @include lg {
    font-size: 54px;
    line-height: 1.11;
    padding-bottom: 40px;
    padding-top: 10px;
  }
  @include sm {
    font-size: 44px;
    line-height: 1.14;
    padding-bottom: 15px;
    padding-top: 0;
  }
}
</style>
