<template>
  <div class="top-section-welcome-block">
    <div class="top-section-teaser">
      <teaser-priority
        v-if="pageData.teaser"
        :image="getResponsiveImage(pageData.teaser.image)"
        :backgroundImage="getResponsiveImage(pageData.teaser.background)"
        :backgroundImageLandscape="getResponsiveImage(pageData.teaser.background_landscape)"
        :title="pageData.teaser.title"
        :subtitle="pageData.teaser.subtitle"
        :description="pageData.teaser.description"
        :video-default="pageData.teaser.video_desktop_mobile"
        :video-tablet="pageData.teaser.video_tablet"
        :video-current-times="{
          desktop: pageData.teaser.video_desktop_mobile_start_time || 2,
          tablet: pageData.teaser.video_tablet_start_time || 2,
        }"
      />
    </div>
    <div class="welcome-block-register" v-if="false">
      <a href="#ticketalarm" @click.prevent="openTicketAlarmOverlay">
        <span class="ticket-alarm" v-html="pageData.ticket_alarm_teaser_text" />
      </a>
    </div>
  </div>
</template>

<script>
import TeaserPriority from '@/components/teaser/TeaserPriority.vue';

export default {
  name: 'welcome-block',
  components: {
    TeaserPriority,
  },
  data: () => ({}),
  methods: {
    openTicketAlarmOverlay() {
      this.$router.replace({ hash: 'ticketalarm' }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
  .top-section-welcome-block {
    width: 25%;

    @include sm {
      min-width: 280px;
    }

    .top-section-teaser {
      background-color: $blue-02;
    }

    .welcome-block-register {
      border: solid 1px $very-light-grey;
      background-color: #fff;
      text-align: center;
      border-right: none;

      .ticket-alarm {
        padding-right: 45px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          background: url('~@/assets/icons/news.svg') no-repeat;
          width: 40px;
          height: 40px;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-position: 5px;
        }
      }

      a {
        position: relative;
        display: block;
        font-family: 'OnAirBold';
        font-size: 15px;
        color: $blue-01;
        display: flex;
        padding: 35px 8px;
        justify-content: center;
        align-items: center;
        @include xl {
          font-size: 14px;
          padding: 25px 8px;
        }
        @include lg {
          padding: 30px 8px;
        }
      }
    }
  }

  @include lg {
    .top-section-welcome-block {
      width: 100%;
      position: relative;
      min-height: auto;
    }
  }
</style>
