<template>
  <div class="image-module" :style="fallbackImageCss">
    <div class="image-trail-container" v-if="displayImageTrail">
      <ImageTrail :image-list="images" />
    </div>
    <div class="content">
      <h2
        class="headline"
        v-resize-text="{ ratio: 0.6, maxFontSize: 140, delay: 50 }"
        v-html="headline"
      />
      <div class="text">
        <h3 class="sub-headline" v-html="sub_headline" />
        <p class="copy" v-html="copy" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageTrail from '@/views/journey/components/modules/children/ImageTrail.vue';

export default {
  name: 'ImageModule',
  components: {
    ImageTrail,
  },
  props: {
    headline: {
      type: String,
      default: '',
    },
    sub_headline: {
      type: String,
      default: '',
    },
    copy: {
      type: String,
      default: '',
    },
    fallback_image: {
      type: Object,
      default: () => {},
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOnTablet: window.innerWidth <= 992,
    };
  },
  computed: {
    displayImageTrail() {
      return this.images.length > 0 && !this.isOnTablet;
    },
    fallbackImageCss() {
      if (this.displayImageTrail || !this.fallback_image) return {};
      return {
        backgroundImage: `url(${this.fallback_image.download_url})`,
      };
    },
  },
  methods: {
    updateIsOnTablet() {
      this.isOnTablet = window.innerWidth <= 992;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateIsOnTablet, false);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateIsOnTablet, false);
  },
};
</script>

<style lang="scss" scoped>
.image-module {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $blue-02;
  background-size: cover;
  background-position: top left;
  color: $white;
  height: 575px;
  min-height: 80vh;
  overflow: hidden;
  z-index: 1;

  @include lg {
    min-height: 50vh;
  }

  @include sm {
    min-height: 600px;
  }
}
.image-trail-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.content {
  pointer-events: none;
  position: relative;
  z-index: 3;
  max-width: 100%;
}
.headline {
  font-family: "OnAirBold";
  font-weight: normal;
  @include linear-font-size(60px, 140px);
  line-height: 1em;
  text-align: center;
  margin: 0;
  margin-top: -75px;
}
.text {
  position: absolute;
  margin: 0 20px;
  width: 100%;

  .sub-headline {
    font-family: "OnAirBold";
    font-weight: normal;
    text-align: center;
    padding: 0 10%;
    @include linear-font-size(15px, 20px);

    @include lg {
      padding: 0;
    }
  }
  .copy {
    font-family: "OnAirLight";
    text-align: center;
    padding: 0 10%;
    font-size: 15px;
    line-height: 1.6;

    @include lg {
      padding: 0;
    }
  }
}
</style>
