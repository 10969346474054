<template>
  <footer class="footer">
    <MusicFooter />
    <Feedback />
    <O2Footer />
  </footer>
</template>

<script>
import MusicFooter from '@/components/footer/MusicFooter.vue';
import Feedback from '@/components/footer/Feedback.vue';
import O2Footer from '@/components/footer/O2Footer.vue';

export default {
  name: 'footer-component',
  components: {
    MusicFooter,
    Feedback,
    O2Footer,
  },
};
</script>

<style lang="scss" scoped>
  .footer {
    background-color: $white;
    position: relative;
  }
</style>
