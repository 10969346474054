<template>
  <div v-html="copy" class="copy" />
</template>
<script>
export default {
  name: 'SimpleParagraph',
  props: {
    copy: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
  .copy ::v-deep p {
    padding-bottom: 10px;

    b {
      font-family: "OnAirBold";
      font-weight: normal;
    }
  }
  .copy ::v-deep a {
    color: $blue-01;
    text-decoration: underline;
  }
  .copy {
    color: $blue-01;
    font-family: "OnAirLight";
    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
    padding-top: 20px;
    max-width: 476px;

    @include lg {
      max-width: unset;
      padding-right: 120px;
    }

    @include sm {
      font-size: 14px;
      line-height: 1.57;
      padding-right: 20px;
    }
  }
</style>
