<template>
  <div class="local-video-item-wrapper">
    <div class="local-video-item" :class="{ 'no-margins': noMargins }">
      <div
        v-if="expirationData.expiration && expirationData.expiration_warning"
        class="expiration-warning"
        v-html="expirationData.expiration_warning"
      />
      <div
        class="thumbnail"
        @click="onClick(videoId, videoType)"
        v-negative="{
            mouse_over: mouseOver,
            mouse_over_text: mouseOverText
          }"
      >
        <div class="play-button" />
        <img :src="thumbnail" :alt="header" />
      </div>
      <div class="copy" v-if="header || description || extraContent">
        <h3>{{ header }}</h3>
        <div v-html="description" class="description"></div>
        <span class="extra-content">{{ extraContent }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocalVideoItem',
  props: {
    mouseOver: {
      type: Boolean,
      default: false,
    },
    mouseOverText: {
      type: String,
      default: 'Mehr',
    },
    videoId: {
      type: String,
      required: true,
    },
    videoType: {
      type: String,
      default: 'local_video_ext',
    },
    header: {
      type: String,
      required: false,
    },
    thumbnail: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    extraContent: {
      type: String,
      required: false,
    },
    noMargins: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      required: true,
    },
    expirationData: {
      type: Object,
      default: () => ({
        expiration: null,
        expiration_warning: '',
      }),
    },
  },
};
</script>
<style lang="scss" scoped>
.local-video-item-wrapper {
  .expiration-warning {
    position: absolute;
    top: -32px;
    left: 0;
    font-family: 'OnAirRoman';
    font-size: 12px;
    line-height: 1.67;
    color: $white;
    background-color: $red;
    padding: 2px 8px;
  }
  .local-video-item {
    margin: 70px 25px 50px;
    height: auto;
    position: relative;

    @include xl-1440 {
      margin: 55px 25px 30px;
    }
    @include xl {
      margin: 45px 8px 25px;
    }
    @include lg {
      margin: 70px 65px 70px;
    }
    @include sm {
      margin: 68px 25px 50px;
    }

    &.no-margins {
      margin: 0
    }

    .thumbnail {
      width: 100%;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
      }

      .play-button {
        width: 100%;
        height: 100%;
        position: absolute;
        background: url('~@/assets/images/mediathek/play.svg') no-repeat center center;
      }
    }

    .copy {
      padding: 18px 30px 10px 5px;
      position: relative;

      h3 {
        font-family: 'OnAirBold';
        font-size: 20px;
        font-weight: normal;
        line-height: 1.3;
        color: $blue-01;
        display: block;
        margin: 0;
      }

      .description {
        font-family: 'OnAirRoman';
        font-size: 12px;
        line-height: 1.83;
        color: $blue-01;
        margin: 0;
        padding-top: 5px;
        @include xl-1440 {
          line-height: 1.67;
          padding-top: 3px;
        }
      }

      .extra-content {
        display: block;
        font-family: 'OnAirLight';
        font-style: italic;
        font-size: 11px;
        line-height: 2.2;
        color: $warm-grey;
        transform: rotate(90deg) translate(100%, 0);
        transform-origin: 100% 0;
        position: absolute;
        padding-left: 1px;
        right: 0;
        top: 10px;

        &::before {
            content: "";
            position: absolute;
            width: 9px;
            height: 1px;
            background-color: $warm-grey;
            top: 10px;
            left: -13px;
        }
      }
    }
  }
}
</style>
