<template>
  <div class="faq-wrapper">
    <div class="horizontal-wrapper">
      <horizontal-crawler
        :text="customizable_headline.text"
        :canvas-config="canvasConfig"
        :show-in-mobile="true"
        font-family="OnAirRoman"
      />
    </div>
    <masonry
      :cols="{default: 4, 992: 2, 576: 1}"
      class="faq-content"
    >
    <faq-item
      v-for="(item, index) in faqItems"
      :key="`faq-${index}`"
      :headline="item.headline"
      :text="item.text"
      :showSpacer="showSpacer(index)"
      :showTopLine="showTopLine(index)"
    />
    </masonry>
  </div>
</template>
<script>
import HorizontalCrawler from '@/components/horizontal-crawler/HorizontalCrawler.vue';
import FaqItem from
  '@/views/journey/components/modules/children/FaqItem.vue';

export default {
  name: 'Faq',
  components: {
    HorizontalCrawler,
    FaqItem,
  },
  props: {
    customizable_headline: {
      type: Object,
    },
    frequently_asked_questions: {
      type: Array,
    },
  },
  computed: {
    faqItems() {
      return this.frequently_asked_questions || [];
    },
    canvasConfig() {
      const colors = [
        this.customizable_headline.main_color,
        this.customizable_headline.alternative_color,
      ];
      return {
        1680: {
          fontSize: 160,
          colors,
          gradientCount: 4,
        },
        1440: {
          fontSize: 140,
          colors,
          gradientCount: 4,
        },
        993: {
          fontSize: 120,
          colors,
          gradientCount: 2,
        },
        577: {
          fontSize: 90,
          colors,
          gradientCount: 1,
        },
      };
    },
  },
  data: () => ({
    innerWidth: window.innerWidth,
  }),
  methods: {
    setWidth() {
      this.innerWidth = window.innerWidth;
    },
    showSpacer(index) {
      if (this.isMobile) return false;
      return this.isTablet ? index === 1 : index === 1 || index === 3;
    },
    showTopLine(index) {
      switch (true) {
        case this.isMobile:
          return index === 0;
        case this.isTablet:
          return index < 2;
        default:
          return index < 4;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.setWidth);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWidth);
  },
};
</script>
<style lang="scss" scoped>
.faq-wrapper {
  @include lg {
    padding: 0 30px;
  }
  @include sm {
    padding: 0;
  }
}
.horizontal-wrapper {
  padding: 40px 0;
}
</style>
