import Vue from 'vue';
import VueMasonry from 'vue-masonry-css';
import VueYoutube from 'vue-youtube';
import VueResizeText from 'vue-resize-text';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import PagePreview from '@/mixins/page-preview';
import MainMixin from '@/mixins/main-mixin';
import ButtonComponent from '@/components/button/Button.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@/directives/main';

require('swiper/css/swiper.css');

Vue.use(VueMasonry);
Vue.use(VueYoutube);
Vue.use(VueResizeText);
Vue.use(VueAwesomeSwiper);
Vue.config.productionTip = false;

Vue.mixin(MainMixin);
Vue.mixin(PagePreview);

Vue.component('button-component', ButtonComponent);

router.beforeEach(async (to, from, next) => {
  let { path } = to;
  const { query } = to;
  if (query.VUE_APP_API) {
    store.commit('setNewCMSApi', query.VUE_APP_API);
  }
  if (!store.state.siteSettings) {
    await store.dispatch('getSiteSettings');
  }
  if (to.query.draft_preview) {
    await store.dispatch('getDraftPageData', {
      params: { path },
      pageName: path,
      jwtToken: to.query.draft_preview,
    }).then(() => {
      next();
    });
    return;
  }
  if (to.name === 'Mediathek' && to.params.videoSlug) {
    path = '/mediathek';
  }
  await store.dispatch('getPageData', { params: { path }, pageName: path }).then((response) => {
    if (response.status === 404 && path.indexOf('not-found') < 0) {
      if (
        path.indexOf('gewinnspiele') > -1
        && store.state.siteSettings.navigation.raffle_not_found_page
      ) {
        router.replace(store.state.siteSettings.navigation.raffle_not_found_page.url);
        return;
      }
      next('/not-found');
      return;
    }
    next();
  });
  next();
  if (to.path !== from.path) {
    MainMixin.methods.scrollToTop();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
