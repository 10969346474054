<template>
  <div class="sorting-block">
    <div class="label" @click="onOpenSortList($event)">
      Sortieren
      <span class="sorting-arrow" :class="{ 'open': showSortList }" />
    </div>
    <transition name="fade">
      <div class="sorting-list" v-if="showSortList">
        <div
          class="sorting-element"
          v-for="(item, $index) in sortList"
          :key="$index"
          :class="{ 'active': activeItem === $index }"
          @click="onClickItem($event, item, $index)"
        >
        {{ item.text }}
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'sorting',
  props: {
    sortList: {
      type: Array,
      default: () => ([]),
    },
    activeItem: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    showSortList: false,
  }),
  methods: {
    onOpenSortList(e) {
      if (this.showSortList) {
        e.stopPropagation();
        this.showSortList = !this.showSortList;
      } else {
        setTimeout(() => {
          e.stopPropagation();
          this.showSortList = !this.showSortList;
        }, 0);
      }
    },
    onClickEvent() {
      this.showSortList = false;
    },
    onClickItem(e, item, $index) {
      e.stopPropagation();
      this.$emit('change', { item, $index });
    },
  },
  mounted() {
    document.addEventListener('click', this.onClickEvent);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickEvent);
  },
};
</script>
<style lang="scss" scoped>
  .sorting-block {
    position: relative;
    cursor: pointer;

    .label {
      font-family: 'OnAirRoman';
      font-size: 12px;
      line-height: 1.51;
      color: $blue-01;
      user-select: none;
    }
    .sorting-arrow {
      background: url('~@/assets/icons/arrow-down.svg') no-repeat center left;
      width: 16px;
      height: 16px;
      display: block;
      background-size: contain;
      float: right;
      margin-left: 6px;

      &.open {
        transform: rotate(180deg);
      }
    }
  }
  .sorting-list {
    position: absolute;
    top: 20px;
    right: 0;
    width: 230px;
    background-color: $white;
    border: solid 1px $blue-02;
    z-index: 9999;
    .sorting-element {
      font-family: 'OnAirBold';
      font-size: 12px;
      color: $blue-02;
      background-color: $white;
      padding: 18px 30px;
      border-bottom: solid 1px #b9b9b9;

      &.active, &:hover {
        color: $white;
        background-color: $blue-02;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  @include sm {
    .sorting-block {
      right: 20px;
      bottom: 30px;
    }
    .sorting-list {
      top: 49px;
      right: -20px;
    }
  }
</style>
