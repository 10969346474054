<template>
  <div class="media-module">
    <div class="media-module__grid">
      <div
        v-if="!isDesktop"
        class="media-module__media-content"
      >
        <template v-for="(groupItems, groupIndex) in mediaContentSortedLimited">
          <template v-for="(item, index) in groupItems">
            <media-module-item
              v-if="item"
              :key="`media-${groupIndex}-${index}`"
              class="media-module__grid-item-mobile"
              v-bind="item"
            />
          </template>
          <show-more-button
            v-if="groupItems.length < mediaContentSorted[groupIndex].length"
            :key="`more-${groupIndex}`"
            @click="expandGroup(groupIndex)"
          />
          <br v-else :key="`more-br-${groupIndex}`" />
        </template>
      </div>
      <masonry
        v-else
        :cols="{default: 3, 992: 1}"
        class="media-module__media-content"
      >
        <template v-for="(item, index) in mediaContent">
          <media-module-item
            v-if="item"
            :key="`media-${index}`"
            :show-spacer="index === 3 || (index === 5 && !isTablet)"
            :class="[
              'media-module__grid-item',
              { 'fadeIn': index < mediaContentCountOld },
            ]"
            v-bind="item"
          />
          <br v-else :key="`placeholder-${index}`" />
        </template>
      </masonry>
      <div class="media-module__sticky-content">
        <media-module-item
          v-for="(item, index) in stickyContentLimited"
          :key="`sticky-${index}`"
          v-bind="item"
        />
      </div>
    </div>
    <show-more-button v-if="isDesktop && displayShowMore" @click="expand" />
  </div>
</template>
<script>
import MediaModuleItem from '@/views/common/components/media/MediaModuleItem.vue';
import ShowMoreButton from '@/components/button/ShowMoreButton.vue';

const CONTENT_COUNT_STEP = 12;
const MOBILE_START_STEP = 4;
const MOBILE_COUNT_STEP = 3;

export default {
  name: 'MediaModule',
  components: {
    MediaModuleItem,
    ShowMoreButton,
  },
  props: {
    media_content: {
      type: Array,
      default: () => ([]),
    },
    sticky_content: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    mediaContentCountOld: CONTENT_COUNT_STEP,
    mediaContentCount: CONTENT_COUNT_STEP,
    mediaContentSortedLimited: [],
    visible: false,
  }),
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.visible = true;
      }, 1000);
    });
    this.mediaContentSortedLimited = this.mediaContentSorted.map(
      (item) => item.slice(0, MOBILE_START_STEP),
    );
  },
  computed: {
    isDesktop() {
      return !this.isMobile && !this.isTablet;
    },
    mediaContentGrouped() {
      return this.groupByValue(this.media_content, 'display_group');
    },
    mediaContentSorted() {
      return this.mediaContentGrouped.map((item) => {
        item.sort((a, b) => a.value.priority_within_group - b.value.priority_within_group);
        return item;
      });
    },
    mediaContentLimited() {
      return this.mediaContentSorted.map(
        (item) => item.slice(0, this.mediaContentCount / this.mediaContentGrouped.length),
      );
    },
    mediaContent() {
      if (this.mediaContentLimited.length === 1) {
        return this.mediaContentLimited[0];
      }
      return this.isTablet || this.isMobile
        ? this.mediaContentLimited.flat()
        : this.merge(
          this.mediaContentLimited,
          this.mediaContentCount / this.mediaContentLimited.length,
        );
    },
    stickyContentLimited() {
      return this.sticky_content.slice(0, this.mediaContentCount);
    },
    displayShowMore() {
      return this.media_content.length > this.mediaContent.filter((c) => !!c).length;
    },
  },
  methods: {
    expandGroup(index) {
      const newArr = this.mediaContentSorted.map((item, i) => {
        const count = i !== index
          ? this.mediaContentSortedLimited[i].length
          : this.mediaContentSortedLimited[index].length + MOBILE_COUNT_STEP;
        return item.slice(0, count);
      });
      this.mediaContentSortedLimited = [...newArr];
    },
    merge(groupedArray, count) {
      const mergedArray = [];
      [...Array(count)].forEach((_, i) => groupedArray.forEach((item) => {
        mergedArray.push(item[i] ? item[i] : null);
      }));
      return mergedArray;
    },
    groupByValue(xs, key) {
      return Object.values(xs.reduce((rv, x) => {
        (rv[x.value[key]] = rv[x.value[key]] || []).push(x);
        return rv;
      }, {}));
    },
    expand() {
      this.mediaContentCountOld = this.mediaContentCount;
      this.mediaContentCount += CONTENT_COUNT_STEP;
      const interval = setInterval(() => {
        if (this.mediaContentCountOld < this.mediaContentCount) this.mediaContentCountOld += 1;
        else clearInterval(interval);
      }, 50);
    },
  },
};
</script>
<style lang="scss" scoped>
.media-module {
  @include lg {
    padding: 0 31px;
  }
  @include sm {
    padding: 0 10px;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @include sm {
      flex-direction: column-reverse;
    }
  }

  &__media-content {
    width: 75%;

    @include lg {
      width: 50%;
    }

    @include sm {
      width: 100%;
    }
  }

  &__sticky-content {
    position: sticky;
    top: 0;
    width: 25%;

    @include lg {
      width: 50%;
    }

    @include sm {
      position: relative;
      width: 100%;
    }
  }

  &__grid-item-mobile {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__grid-item {
    transition: opacity 1s ease-out;
    opacity: 0;

    &.fadeIn {
      opacity: 1;
    }
  }

}
</style>
