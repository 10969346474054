<template>
  <div>
    <div v-if="showSpacer" class="spacer" />
    <div v-if="showTopLine" class="line" />
    <div class="social-wall-item">
      <a
        :href="post.post_link"
        target="_blank"
        rel="noopener"
      >
        <div class="post-image">
          <img
            v-if="post.post_image_cdn"
            class="message-social-img"
            :src="post.post_image_cdn"
            :alt="post.comment">
          <div
            v-if="post.post_video_cdn"
            class="video-wrapper"
            @click="onClickVideo($event)"
          >
            <social-media-wall-item-video
              :video-id="post.id"
              :video-link="post.post_video_cdn"
              :video-image="post.post_image"
              :active-video="activeVideo"
            />
          </div>
        </div>
        <div class="message-social-icon">
          <span class="glyphicon" :class="[ `glyphicon-${post.type}` ]" />
        </div>

        <div class="social-comment">{{ shorten(post.comment) }}</div>
        <div class="social-user">
          <div class="image" v-if="post.external_image">
            <img
              :src="post.external_image"
              :alt="post.external_fullname"
            >
          </div>
          <div class="nickname" v-if="post.external_fullname">
            {{ post.external_fullname }}
          </div>
          <div class="time" v-if="post.modified_timestamp">
            {{ toLocalDate(post.modified_timestamp) }}
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import SocialMediaWallItemVideo from './SocialMediaWallItemVideo.vue';

export default {
  name: 'SocialMediaWallItem',
  components: {
    SocialMediaWallItemVideo,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    showSpacer: {
      type: Boolean,
      default: false,
    },
    showTopLine: {
      type: Boolean,
      default: false,
    },
    activeVideo: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClickVideo(e) {
      e.preventDefault();
    },
    toLocalDate(date) {
      if (!date) return '';
      const full_date = date * 1000;
      const limit_on_hours = 1000 * 60 * 60 * 6;
      const now_date = new Date().valueOf();
      const diff = now_date - full_date;
      if (diff < limit_on_hours) {
        const hours = Math.round((diff) / (1000 * 60 * 60));
        return `${hours || 1} hour ago`;
      }
      return new Date(date * 1000).toLocaleDateString('de');
    },
    shorten(text) {
      const strLen = 190;
      const preparedText = text.length > strLen ? `${text.slice(0, strLen - 2)}...` : text;
      return preparedText;
    },
  },
};
</script>
<style lang="scss" scoped>
.post-image {
  position: relative;
}
.video-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: $black;
}
.line {
  border-top: 1px solid $very-light-grey;
  height: 1px;
}
.spacer {
  height: 35px;
  @include xl-1440 {
    height: 50px;
  }
  @include xl {
    height: 40px;
  }
  @include lg {
    height: 80px;
  }
}
.social-wall-item {
  text-align: center;
  padding: 45px 20px 20px 20px;
  border-bottom: 1px solid $very-light-grey;
  @include sm {
    background-color: $white;
    padding: 45px 20px 20px 20px;
  }
  a {
    text-decoration: none;
  }
  img {
    width: 100%;
    display: flex;
  }
  .message-social-icon {
    padding-bottom: 21px;
    padding-top: 16px;
    color: $black;
  }
  .social-comment {
    font-family: 'OnAirLight';
    margin-left: 35px;
    margin-right: 35px;
    margin-bottom: 42px;
    font-size: 12px;
    color: #14171a;
  }
  .social-user {
    border-top: 1px solid #979797;
    font-family: 'OnAirLight';
    text-align: left;
    font-size: 10px;
    margin: 0 35px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 22px;
    line-height: 12px;
    align-items: center;
    justify-content: center;
    @include xl-1440 {
      margin: 0 10px;
    }
    @include xl {
      margin: 0;
    }
    @include lg {
      margin: 0 35px;
    }
    .image {
      width: 30px;
      img {
        width: 22px;
        border-radius: 50%;
      }
    }
    .nickname {
      font-size: 10px;
      color: #000;
      margin-right: 10px;
    }
    .time {
      color: #657786;
    }
  }
}
</style>
