<template>
  <div class="livestream-third-party-cookie">
    <div class="third-party-cookie-information" />
    <div class="third-party-cookie-title">
      Ändere deine Cookie-Einstellungen, um beim Livechat dabei zu sein.
    </div>
    <div class="third-party-cookie-description">
      Bitte erlaube Website-übergreifende Cookies in deinen Browser-Einstellungen.
    </div>
    <div class="third-party-cookie-close" @click="$emit('close')" />
    <div v-if="showChat" class="third-party-cookie-iframe">
      <iframe src="https://ava-third-party-cookie-check.ava-digi.de/start.html" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ThirdPartyCookiesInfo',
  props: {
    showChat: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    receiveMessage(evt) {
      if (evt.data === 'MM:3PCunsupported') {
        this.$emit('changeTPCsupported');
      }
    },
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage, false);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage, true);
  },
};
</script>
<style lang="scss" scoped>
.livestream-third-party-cookie {
  position: absolute;
  right: 11px;
  top: 12px;
  background-color: $white;
  width: 280px;
  padding: 20px 38px 20px 72px;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.19);
  border: solid 1px var($very-light-grey);
  z-index: 100;

  .third-party-cookie-title {
    font-family: 'OnAirBold';
    font-size: 13px;
    line-height: 1.54;
    color: $blue-01;
  }
  .third-party-cookie-description {
    font-family: 'OnAirLight';
    font-size: 13px;
    line-height: 1.69;
    letter-spacing: normal;
    color: $blue-01;
    padding-top: 7px;
  }
  .third-party-cookie-close {
    content: '';
    position: absolute;
    right: 10px;
    top: 10px;
    width: 14px;
    height: 14px;
    background-image: url('~@/assets/icons/close-btn-blue.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
  }
  .third-party-cookie-information {
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    width: 32px;
    height: 32px;
    background-image: url('~@/assets/icons/information-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: auto;
  }
  @include sm {
    display: none;
  }
}
.third-party-cookie-iframe {
  display: none;
}
</style>
