<template>
  <div class="presale-dates" :class="{ 'presale-running': isPresaleRunning }">
    <h2 class="tour-name">
      {{ tourName }}
    </h2>
    <ul class="event-dates">
      <li
        v-for="event in events"
        :key="event.event_id"
        class="event-date"
        @click.prevent="onActivateEvent(event.id)"
        :class="{
          clickable: eventsClickable,
          'tickets-disable': !event.tickets_available && eventSeries.state !== 'EXCLUSIVE',
          'show-buy-button': event.id === activeClickedEvent,
        }"
      >
        <div class="date">
          {{ formatDate(event.start) }}
        </div>
        <div class="city">
          <div class="">
            {{ event.city }}
          </div>
          <div class="city-venue">
            {{ event.venue }}
          </div>
        </div>
        <div class="arrow">
          <img src="@/assets/icons/arrow-right-black.svg">
        </div>
        <div class="click-area">
          <div class="click-area-city">
            {{ event.city }}
          </div>
          <div class="buy-button" @click.prevent="eventClicked(event.id)">
            {{ pageData.buy_button }}
          </div>
        </div>
      </li>
    </ul>
    <PresaleShopOverlay
      v-if="displayOverlay"
      @closeOverlay="displayOverlay = false"
      :shop-link="shopUrl"
    />
  </div>
</template>

<script>
import PresaleShopOverlay from '@/views/journey/components/presale/PresaleShopOverlay.vue';
import AuthService from '@/services/AuthService';
import axios from 'axios';

const auth = new AuthService();

export default {
  name: 'PresaleDates',
  components: {
    PresaleShopOverlay,
  },
  props: {
    eventSeries: {
      type: Object,
    },
    eventName: {
      type: String,
      default: '',
    },
    ticketsState: {
      type: String,
      default: '',
    },
    isPresaleRunning: {
      type: Boolean,
    },
    onShowPopupError: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      shopUrl: null,
      evenSeriesShopLink: null,
      eventShopLinks: null,
      displayOverlay: false,
      isLoggedIn: null,
      activeClickedEvent: -1,
    };
  },
  computed: {
    events() {
      return this.eventSeries.events;
    },
    eventSeriesId() {
      return this.eventSeries.id;
    },
    numberOfEvents() {
      return this.events.length;
    },
    tourName() {
      return this.eventSeries.eventserie;
    },
    apiUrl() {
      const apiPath = 'api/v2/events/shop-link/'; // TODO this should go someplace else
      return `${this.$store.state.API}/${apiPath}`;
    },
    eventsClickable() {
      return (this.isPresaleRunning === true || this.ticketsState === 'SALE')
        && (this.evenSeriesShopLink || (this.eventShopLinks && this.eventShopLinks.length > 0));
    },
  },
  methods: {
    formatDate(dateString) {
      const options = {
        day: '2-digit', month: 'short', year: 'numeric',
      };
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('de-DE', options).format(date);
    },
    retrieveShopLinks() {
      auth.getIdToken().then((idToken) => {
        const headers = { Authorization: `bearer ${idToken}` };
        const params = { id: this.eventSeriesId };
        axios({
          method: 'get',
          url: this.apiUrl,
          headers,
          params,
        }).then((response) => {
          this.evenSeriesShopLink = response.data.event_series_url;
          this.eventShopLinks = response.data.event_urls;
        });
      });
    },
    onActivateEvent(id) {
      this.activeClickedEvent = id;
    },
    eventClickable(event_id) {
      return (this.isPresaleRunning === true || this.ticketsState === 'SALE')
        && (this.evenSeriesShopLink || (this.eventShopLinks && event_id in this.eventShopLinks));
    },
    getCookie() {
      if (window.ga && window.ga.getAll && window.ga.getAll()[0]) {
        return window.ga.getAll()[0].get('clientId');
      }
      return 'unavailable';
    },
    getGaData() {
      if (!this.isUserAcceptCookies()) {
        return 'unavailable';
      }
      return this.getCookie();
    },
    eventClicked(eventId) {
      if (!this.eventClickable(eventId)) {
        return;
      }
      this.shopUrl = (this.eventShopLinks && this.eventShopLinks[eventId])
        ? this.eventShopLinks[eventId]
        : this.evenSeriesShopLink;
      const shopUrl = new URL(this.shopUrl);
      const countdownTimer = this.$store.state.ticketsCountdown;
      shopUrl.searchParams.set('_ga', this.getGaData());
      this.shopUrl = shopUrl.toString();
      this.trackActionEvent({
        eventName: 'customInteractionClick',
        eCat: 'Priority - Ticket Presale',
        eAct: 'click - get music tickets',
        eLab: `${this.eventName} | ${countdownTimer}`,
        nonInteraction: false,
      });
      this.$store.commit('setEventimLoader', true);
      document.body.classList.add('open-eventim-overlay');
      setTimeout(() => {
        this.$store.commit('setEventimLoader', false);
        document.body.classList.remove('open-eventim-overlay');
        this.$nextTick(() => {
          const winOpen = window.open(this.shopUrl, '_blank');
          if (!winOpen || winOpen.closed || typeof winOpen.closed === 'undefined') {
            this.onShowPopupError(this.shopUrl);
          }
        });
      }, 4000);
    },
  },
  mounted() {
    if (this.isPresaleRunning || ['SALE', 'EXCLUSIVE'].includes(this.ticketsState)) {
      auth.hasPriorityConditionsAccepted().then((hasPriorityConditionsAccepted) => {
        const priorityPermissionExists = hasPriorityConditionsAccepted === true ? 'true' : 'false';
        this.trackActionEvent({
          eventName: 'priorityPermissionStatus',
          eCat: 'Priority - Ticket Presale',
          eAct: 'load - o2 customer verification',
          eLab: `Priority Permission exists – ${priorityPermissionExists}`,
          nonInteraction: true,
        });
        if (hasPriorityConditionsAccepted === true) {
          this.isLoggedIn = true;
          this.retrieveShopLinks();
          if (this.$route.query['priority-alert']) {
            const query = { ...this.$route.query };
            delete query['priority-alert'];
            this.$router.push({ query });
          }
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";

.number-of-events {
  text-align: right;
  font-family: "OnAirRoman";
  font-size: 12px;
  color: $blue-02;
  margin: 10px 0;
}
.presale-running .number-of-events {
  margin-top: 0;
}
.tour-name {
  font-family: "OnAirBold";
  font-size: 20px;
  font-weight: normal;
  color: $blue-02;
  margin: 0 0 20px 0;
}
.event-date-grid {
  margin-top: 20px;
}
.event-date {
  display: flex;
  align-items: center;
  font-family: "OnAirBold";
  color: $blue-02;
  border-top: 1px solid $very-light-grey;
  padding: 10px 0;
  height: 25px;

  &.clickable {
    cursor: pointer;
    position: relative;

    .arrow {
      display: block;
    }
    &:hover {
      padding: 0;
      height: 45px;

      .click-area {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        right: 0;
      }
    }
    &.show-buy-button {
      @include lg {
        padding: 0;
        height: 45px;

        .click-area {
          position: absolute;
          display: flex;
          top: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }
  &.tickets-disable {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }

  &:last-child {
    border-bottom: 1px solid $very-light-grey;
  }

  .date {
    font-size: 15px;
    height: 15px;
    min-width: 40%;
    margin-right: 10px;
    padding-left: 20px;
    position: relative;
    line-height: 15px;
    font-family: 'OnAirRoman';
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -2px;
      background-image: url('~@/assets/icons/icon-marker.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 13px;
      height: 17px;
    }
  }
  .city {
    font-size: 15px;
    height: 34px;
    line-height: 20px;
    overflow: hidden;
    flex-grow: 2;
  }
  .city-venue {
    font-size: 14px;
    font-family: 'OnAirLight';
    line-height: 14px;
  }
  .arrow {
    margin: 5px;
    height: 13px;
    display: none;

    img {
      width: 7px;
    }
  }
}
.click-area {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px;
  background-color: $blue-02;
  margin: 0 -22px 0 -25px;
  height: 35px;

  .click-area-city {
    font-size: 15px;
    height: 15px;
    line-height: 15px;
    color: $white;
  }

  .buy-button {
    background-color: $white;
    color: $blue-01;
    font-size: 10px;
    line-height: 10px;
    padding: 10px 15px 8px 15px;
  }
}
</style>
