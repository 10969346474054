<template>
  <div class="image-wrapper" :style="gridStyles">
    <img
      v-if="!image_link"
      :src="imageUrl"
      class="image"
    />
    <router-link
      v-else-if="image_link.type === 'internal_link'"
      v-negative="{
          mouse_over,
          mouse_over_text,
        }"
      :to="image_link.value">
      <img
        :src="imageUrl"
        class="image"
      />
    </router-link>
    <a
      v-else
      v-negative="{
          mouse_over,
          mouse_over_text,
        }"
      :href="image_link.value"
      target="_blank"
    >
      <img
        :src="imageUrl"
        class="image"
      />
    </a>
  </div>
</template>

<script>
import GridItemMixin from '@/mixins/grid-item-mixin';

export default {
  name: 'ImageComponent',
  mixins: [GridItemMixin],
  props: {
    mouse_over: {
      type: Boolean,
      default: false,
    },
    mouse_over_text: {
      type: String,
      default: 'Mehr',
    },
    image: {
      type: Object,
      default: () => {},
    },
    image_link: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    imageUrl() {
      return this.getResponsiveImage(this.image);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  padding: 0 28px;
  margin-bottom: 80px;

  @include sm {
    padding: 0;
  }
}
.image {
  width: 100%;
  height: auto
}
</style>
