<template>
  <span>
    <a
      v-if="link.length"
      :href="link"
      rel="noopener"
      target="_blank"
    >
      <img
        src="@/assets/images/priority-logo/priority-logo-negative-rgb@2x.png"
        alt="o2 Priority"
        :style="{ 'width': width }"
      />
    </a>
    <img
      v-else
      src="@/assets/images/priority-logo/priority-logo-negative-rgb@2x.png"
      alt="o2 Priority"
      :style="{ 'width': width }"
    />
  </span>
</template>

<script>
export default {
  name: 'priority-logo',
  props: {
    width: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
img {
 width: 100%;
}
</style>
