<template>
  <div class="generic-wrapper">
    <generic-form
      :formFields="formFields"
      :onSubmitForm="onSubmitForm"
      :buttonText="buttonText"
      :requiredMissingText="requiredMissingText"
      :requiredFieldsText="requiredFieldsText"
    />
  </div>
</template>

<script>
import GenericForm from '@/components/form/GenericForm.vue';

const DEFAULT_SUBMIT_BUTTON_TEXT = 'Absenden';
const DEFAULT_REQUIRED_FIELDS_TEXT = 'Pflichtfelder';
const DEFAULT_MISSING_FIELDS_TEXT = 'Bitte überprüfe Deine Eingabe';

export default {
  name: 'RaffleForm',
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
    onError: {
      type: Function,
      required: true,
    },
  },
  components: {
    GenericForm,
  },
  computed: {
    formFields() {
      const { form_fields } = this.pageData;
      return this.prepareFormfields(form_fields);
    },
    requiredMissingText() {
      const { validation_error_text } = this.pageData;
      return validation_error_text || DEFAULT_MISSING_FIELDS_TEXT;
    },
    requiredFieldsText() {
      const { required_fields_text } = this.pageData;
      return required_fields_text || DEFAULT_REQUIRED_FIELDS_TEXT;
    },
    buttonText() {
      const { submit_button_text } = this.pageData;
      return submit_button_text || DEFAULT_SUBMIT_BUTTON_TEXT;
    },
  },
  methods: {
    async onSubmitForm(formData) {
      this.apiError = null;
      const { campaignName } = this.$route.params;
      const query = { ...this.$route.query };
      if (typeof query.campaign !== 'undefined') {
        formData.campaign = query.campaign;
      }
      const data = { ...formData };
      const response = await this.$store.dispatch('sendFormData', { data, campaignName });
      if (response === true) {
        this.onSuccess();
      } else {
        const { message } = response;
        const additionalErrorMsg = message.detail || null;
        this.onError(additionalErrorMsg);
      }
    },
    prepareFormfields(formFields) {
      return formFields.map((item) => this.convertFormField(item));
    },
    convertFormField(field) {
      const label = field.required && field.type !== 'checkbox' ? `${field.label}*` : field.label;
      return {
        label,
        type: field.type,
        name: field.name,
        required: field.required,
        choices: field.choices,
        default: field.default,
        help_text: field.help_text,
        show_validation_error: false,
        minimum_age: field.minimum_age,
        validation_error_message: field.validation_error_message,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/styles/base.scss";
@import "src/assets/styles/variables.scss";

.generic-wrapper {
  position: relative;
  width: 100%;

  @include lg {
    width: 70%;
  }

  @include sm {
    width: 100%;
  }
}
</style>
