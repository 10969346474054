<template>
  <div class="external-teaser">
    <div class="external-teaser-wrapper">
      <div v-if="headline" class="external-teaser-title" v-html="headline" />
      <div v-if="copy" class="external-teaser-subtitle" v-html="copy" />
      <button-component
        v-if="button_text"
        :label="button_text"
        :button-link="{
          internal: false,
          to: button_link
        }"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'TeaserExternal',
  props: {
    headline: {
      type: String,
    },
    copy: {
      type: String,
    },
    button_text: {
      type: String,
    },
    button_link: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
  .external-teaser {
    width: calc(50% - 2px);
    margin: 73px auto 0;
    border-top: 1px solid $very-light-grey;
    border-bottom: 1px solid $very-light-grey;
  }
  .external-teaser-wrapper {
    background-color: $white;
    text-align: center;
    padding: 50px 22px 43px;
    @include lg {
      padding: 50px 12px 43px;
    }
    @include sm {
      padding: 40px 22px 33px;
    }
  }
  .external-teaser-title {
    font-family: 'OnAirBold';
    font-size: 34px;
    line-height: 1.18;
    color: $blue-01;
    @include sm {
      font-size: 26px;
      line-height: 1.16;
    }
  }
  .external-teaser-subtitle ::v-deep p {
    font-family: 'OnAirBold';
    font-size: 15px;
    line-height: 1.6;
    color: $blue-01;
    margin-top: 10px;
    @include sm {
      font-size: 14px;
      line-height: 1.57;
    }
  }
  .button-component {
    margin-top: 44px;
    @include sm {
      margin-top: 26px;
    }
  }

  @include lg {
    .external-teaser {
      width: calc(100% - 64px);
      margin-top: 50px;
    }
  }
  @include sm {
    .external-teaser {
      width: calc(100% - 40px);
    }
  }
</style>
