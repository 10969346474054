<template>
  <div class="home-page" data-view>
    <LivestreamTicker
      v-if="hasLiveStreamTicker"
      :text="pageData.livestream_ticker.text"
      :link="pageData.livestream_ticker.link"
    />
    <GridLines />
    <livestream-teaser
      v-if="liveStreamTeaser"
      :priorityLogo="getResponsiveImage(liveStreamTeaser.priority_logo)"
      :artistLogo="getResponsiveImage(liveStreamTeaser.event_logo)"
      :backgroundImage="getResponsiveImage(liveStreamTeaser.desktop_background)"
      :backgroundImageMobile="getResponsiveImage(liveStreamTeaser.mobile_background)"
      :priorityLogoText="liveStreamTeaser.priority_additional_text"
      :buttonLabel="liveStreamTeaser.button_text"
      :buttonLink="liveStreamTeaser.button_link"
      :artistName="liveStreamTeaser.event_logo_alternative"
      :liveText="liveStreamTeaser.text"
      :enableLiveFlag="liveStreamTeaser.live_flag"
    />
    <presale-teaser
      :data="pageData.takeover_content"
    />
    <highlight-journeys
      :journeys-data="journeysData"
    />
    <more-artists />
    <Component
      v-for="(module, $index) in modules"
      :key="module.id"
      :index="$index"
      :is="moduleComponents[module.type]"
      v-bind="module.value"
      :class="module.value && module.value.anchor_slug ? `anchor-${module.value.anchor_slug}` : ''"
    />
  </div>
</template>

<script>
import GridLines from '@/components/misc/GridLines.vue';
import SocialShare from '@/components/misc/SocialShare.vue';
import LivestreamTicker from '@/views/common/components/ticker/LivestreamTicker.vue';

// modules
import SocialMediaWall from '@/views/journey/components/modules/SocialMediaWall.vue';
import PresaleTeaser from '@/views/common/components/PresaleTeaser/presale-teaser.vue';

import Ticker from '@/views/common/components/ticker/Ticker.vue';
import TickerModule from '@/views/common/components/ticker/TickerModule.vue';
import MoreArtists from './components/more-artists.vue';
import HighlightJourneys from './components/highlight-journeys.vue';
import PresaleList from './components/presale-list.vue';
import PriorityTeaser from './components/priority-teaser.vue';
import LivestreamTeaser from './components/livestream-teaser.vue';
import AmazonTeaser from './components/amazon-teaser.vue';
import ExclusiveEventsModule from './components/ExclusiveEventsModule/exclusive-events-module.vue';
import HighlightTiles from './components/highlight-tiles.vue';

export default {
  name: 'Home',
  components: {
    HighlightJourneys,
    PresaleList,
    GridLines,
    SocialShare,
    PriorityTeaser,
    LivestreamTeaser,
    SocialMediaWall,
    MoreArtists,
    AmazonTeaser,
    PresaleTeaser,
    LivestreamTicker,
    ExclusiveEventsModule,
  },
  data() {
    return {
      moduleComponents: {
        social_module: SocialMediaWall,
        artist_module: MoreArtists,
        priority_module: PriorityTeaser,
        amazon_music_module: AmazonTeaser,
        presales_module: PresaleList,
        social_sharing_module: SocialShare,
        home_exclusive_events_module: ExclusiveEventsModule,
        journeys_module: HighlightTiles,
        ticker_module: Ticker,
        ticker_newsletter_module: TickerModule,
      },
    };
  },
  computed: {
    modules() {
      return this.pageData.content || [];
    },
    journeysData() {
      return this.pageData && this.pageData.journeys
        ? this.pageData.journeys : false;
    },
    liveStreamTeaser() {
      const { take_over_teaser } = this.pageData;
      return take_over_teaser || false;
    },
    hasLiveStreamTicker() {
      const { livestream_ticker } = this.pageData;
      return (livestream_ticker && livestream_ticker.isLive) || false;
    },
  },
  methods: {
    onRunAnchor() {
      const { hash } = this.$route;
      if (hash) {
        const elementClass = hash.replace('#', '.anchor-');
        const element = document.querySelector(elementClass);
        if (!element) return;
        setTimeout(() => {
          const topPos = element.getBoundingClientRect().top;
          element.scrollIntoView(true);
          window.scrollBy(0, -200);
          const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
          setTimeout(() => {
            if (!supportsNativeSmoothScroll) {
              element.scrollIntoView(true);
            } else {
              window.scrollTo({
                top: topPos,
                behavior: 'smooth',
              });
            }
          }, 100);
        }, 300);
      }
    },
  },
  mounted() {
    this.onRunAnchor();
  },
};
</script>
<style lang="scss" scoped>
.home-page {
  height: 100%;
  position: relative;
  padding-bottom: 75px;
}
.social-media-wall {
  margin-top: 50px;
  overflow: hidden;

  @include sm {
    margin-top: 5px;
  }
}
.social {
  width: calc(50% - 2px);
  margin: 20px auto 0;
  padding: 20px 0;
  background: $white;
  border-top: 1px solid $very-light-grey;
  border-bottom: 1px solid $very-light-grey;
  @include lg {
    width: calc(100% - 64px);
    margin: auto;
    margin-left: 32px;
  }
  @include sm {
    width: calc(100% - 40px);
    margin: auto;
    margin-left: 20px;
  }
}

</style>
