<template>
  <div class="media-module__item">
    <div v-if="showSpacer" class="spacer" />
    <!-- workaround: single module in cms used for youtube-video & calendar-image -->
    <!-- is used for calendar and for only image/text with mouse over -->
    <i-calendar-image-item
      v-if="type === 'youtube_video_ext'
        && !hasLink
        && (value.calendar && value.calendar.calendar_file || value.mouse_over)"
      class="media-module__item--vspaced media-module__item--hspaced"
      :header="value.header"
      :thumbnail="getResponsiveImage(value.thumbnail)"
      :description="value.description"
      :extra-content="value.extra_content || getDate(value.upload_at)"
      :expiration-data="value.expiration"
      :mouse-over="value.mouse_over"
      :mouse-over-text="value.mouse_over_text"
      :calendar-file="value.calendar ? value.calendar.calendar_file : null"
      :calendar-text="value.calendar ? value.calendar.calendar_button_text : ''"
      no-margins
    />
    <!-- also used for only images without any youtube information -->
    <youtube-video-item
      v-else-if="type === 'youtube_video_ext'"
      class="media-module__item--vspaced media-module__item--hspaced"
      :youtubeId="value.youtube_id"
      :is-youtube="isYoutube"
      :is-internal-link="isInternalLink"
      :is-external-link="isExternalLink && !isExternalYoutubeLink"
      :mouse-over="value.mouse_over"
      :mouse-over-text="value.mouse_over_text"
      :onClick="onClickHandler"
      :header="value.header"
      :thumbnail="getResponsiveImage(value.thumbnail)"
      :thumbnail-link="value.thumbnail_link"
      :description="value.description"
      :extra-content="value.extra_content || getDate(value.upload_at)"
      :expiration-data="value.expiration"
      no-margins
    />
    <local-video-item
      v-else-if="type === 'local_video_ext'"
      class="media-module__item--vspaced media-module__item--hspaced"
      :video-id="value.title_slug"
      :onClick="() => $emit('open-player', value.title_slug)"
      :header="value.header"
      :thumbnail="getResponsiveImage(value.thumbnail)"
      :description="value.description"
      :extraContent="value.extra_content || getDate(value.upload_at)"
      :mouse-over="value.mouse_over"
      :mouseOverText="value.mouse_over_text"
      :expiration-data="value.expiration"
      no-margins
    />
    <i-calendar-link-item
      v-else-if="type === 'calendar_link'"
      class="media-module__item--vspaced"
      :headline="value.header"
      :file="value.calendar_file"
      :button-text="value.calendar_button_text"
    />
    <teaser-item v-else-if="type === 'internal_navigation_block'"
      :header="value.header"
      :buttonText="value.button_text"
      :buttonLink="value.button_link"
      :classList="{'no-outer-margins': true, 'no-borders': true}"
    />
    <teaser-ticket-alarm v-else-if="type === 'newsletter_alarm_block'"
      :header="value.header"
      :buttonText="value.button_text"
      :description="value.description"
      :classList="{'no-outer-margins': true, 'no-borders': true}"
    />
    <div
      v-else-if="type === 'title'"
      class="media-module__item--vspaced media-module__headline"
      :class="{ 'html': isHtml(value.header) }"
    >
      <div v-html="value.header" />
    </div>
  </div>
</template>
<script>
import YoutubeVideoItem from '@/views/common/components/media/YoutubeVideoItem.vue';
import LocalVideoItem from '@/views/common/components/media/LocalVideoItem.vue';
import TeaserItem from '@/views/common/components/media/TeaserItem.vue';
import TeaserTicketAlarm from '@/views/common/components/media/TeaserTicketAlarm.vue';
import ICalendarLinkItem from '@/views/common/components/media/ICalendarLinkItem.vue';
import ICalendarImageItem from '@/views/common/components/media/ICalendarImageItem.vue';

export default {
  name: 'MediaModuleItem',
  components: {
    YoutubeVideoItem,
    LocalVideoItem,
    TeaserItem,
    TeaserTicketAlarm,
    ICalendarLinkItem,
    ICalendarImageItem,
  },
  props: {
    type: {
      type: String,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    showSpacer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isYoutubeVideoExt() {
      return this.type === 'youtube_video_ext';
    },
    hasYoutubeId() {
      return !!this.value.youtube_id;
    },
    hasLink() {
      return !!this.value.thumbnail_link;
    },
    isInternalLink() {
      const { thumbnail_link: thumbnailLink } = this.value;
      return !!thumbnailLink && thumbnailLink.type === 'internal_link';
    },
    isExternalLink() {
      const { thumbnail_link: thumbnailLink } = this.value;
      return !!thumbnailLink && thumbnailLink.type === 'external_link';
    },
    isExternalYoutubeLink() {
      return this.isExternalLink && this.isYoutubeLink(this.value.thumbnail_link.value);
    },
    isYoutube() {
      return this.isYoutubeVideoExt && (this.hasYoutubeId || this.isExternalYoutubeLink);
    },
  },
  methods: {
    onClickHandler() {
      const { youtube_id: youtubeId, thumbnail_link: thumbnailLink } = this.value;
      if (this.isInternalLink) {
        this.$router.push(thumbnailLink.value);
      } else if (this.isExternalLink && !this.isExternalYoutubeLink) {
        window.open(thumbnailLink.value, '_blank');
      } else if (this.isYoutube) {
        const link = this.hasYoutubeId
          ? `https://www.youtube.com/watch?v=${youtubeId}`
          : thumbnailLink.value;
        this.openYoutubeInNewWindow(link);
      }
    },
    getDate(date) {
      if (!date) return '';
      return new Date(date).toLocaleDateString('de');
    },
  },
};
</script>

<style lang="scss" scoped>
.media-module {

  &__item--vspaced {
    margin-top: 20px;
    margin-bottom: 20px;

    @include xl-1440 {
      margin-top: 55px;
      margin-bottom: 30px;
    }
    @include xl {
      margin-top: 45px;
      margin-bottom: 25px;
    }
    @include lg {
      margin-top: 60px;
      margin-bottom: 60px;
    }
    @include sm {
      margin-top: 35px;
      margin-bottom: 35px;
    }
  }
  &__item--hspaced {
    margin-left: 25px;
    margin-right: 25px;

    @include xl {
      margin-left: 8%;
      margin-right: 8%;
    }

    @include lg {
      margin-left: 15px;
      margin-right: 15px;
    }

    @include sm {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &__headline {
    height: auto;
    position: relative;
    font-family: 'OnAirLight';
    text-transform: uppercase;
    font-size: 40px;
    color: $blue-01;
    line-height: 1;
    margin-left: -1px;
    padding-bottom: 50px;

    &::v-deep {
      sub {
        font-size: 40px;
      }
    }

    @include xl {
      font-size: 30px;
      &::v-deep {
        sub {
          font-size: 30px;
        }
      }
    }
    &.html {
      ::v-deep b {
        font-family: 'OnAirBold';
      }
    }
  }
}

.spacer {
  height: 70px;

  @include xl-1440 {
    height: 60px;
  }
  @include xl {
    height: 50px;
  }
  @include sm {
    display: none;
  }
}
</style>
