<template>
  <div class="input-wrapper" :class="classObject">
    <div class="field">
      <label v-if="field.label" :class="{'error': error}">{{ field.label }}</label>
      <div class="date-input" :class="{'error': error, 'has-focus': hasFocus}">
        <input
          placeholder="TT"
          type="number"
          name="day"
          ref="day"
          v-model="day"
          @focus="hasFocus=true"
          @blur="hasFocus=false"
          @input="setValue($event.target)"
        />
        <span class="separator">/</span>
        <input
          placeholder="MM"
          type="number"
          name="month"
          ref="month"
          v-model="month"
          @focus="hasFocus=true"
          @blur="hasFocus=false"
          @input="setValue($event.target)"
        />
        <span class="separator">/</span>
        <input
          placeholder="YYYY"
          class="year"
          type="number"
          name="year"
          ref="year"
          v-model="year"
          @focus="hasFocus=true"
          @blur="hasFocus=false"
          @input="setValue($event.target)"
        />
      </div>
    </div>
    <input
      type="hidden"
      :name="field.name"
      :value="value"
    />
    <span
      v-if="field.help_text"
      class="text"
    >{{ field.help_text }}</span>
    <span
      v-if="field.show_validation_error"
      class="text error-text"
    >{{ field.validation_error_message }}</span>
  </div>
</template>

<script>

export default {
  name: 'DateInput',
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: String || Number,
      required: false,
      default: '',
    },
    classObject: {
      type: Object,
      required: false,
    },
    error: {
      type: Boolean,
      required: false,
    },
    validate: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    month: '',
    day: '',
    year: '',
    hasFocus: false,
  }),
  methods: {
    getDateString() {
      const { year, month, day } = this.$refs;
      if (year.value.length === 4 && month.value.length === 2 && day.value.length === 2) {
        return `${year.value}-${month.value}-${day.value}`;
      }
      return null;
    },
    setValue(event) {
      const datestring = this.getDateString();
      this.$emit('input', datestring);
      this.validate(this.field, datestring);
      if (event.name === 'day' && event.value.length === 2) this.$refs.month.focus();
      else if (event.name === 'month' && event.value.length === 2) this.$refs.year.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";
.input-wrapper {
  margin: 12px 0;

  .field {
    position: relative;
  }

  .separator {
    font-family: "OnAirLight";
    font-size: 20px;
    font-weight: 300;
    line-height: 1.1;
    color: $blue-01;
  }

  span.text {
    display: block;
    font-size: 12px;
    font-family: "OnAirRoman";
    line-height: 1.67;
    color: $blue-01;
    padding-top: 10px;
    &.error-text {
      color: $red;
    }
  }

  label {
    display: block;
    font-size: 14px;
    font-family: "OnAirRoman";
    line-height: 1.71;
    background: #fff;
    position: absolute;
    padding: 0 3px;
    margin-left: 8px;
    bottom: 39px;
    color: $blue-01;

    &.error {
      color: $red;
    }
  }

  .date-input {
    width: 100%;
    border: 1px solid #b9b9b9;
    padding: 13px 5px 11px;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.4;
    font-family: "OnAirLight";
    color: $blue-01;
    box-sizing: border-box;
    outline: none;
    &.has-focus {
      border-color: $blue-01;
      &.error {
        border-color: $red;
      }
    }
    &.error {
      border-color: $red;
    }
  }

  @media not all and (min-resolution:.001dpcm) {
    .date-input {
      padding: 14px 5px 7px;
    }
  }

  input {
    width: 40px;
    border: none;
    margin: 0 10px;
    padding-left: 5px;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.4;
    font-family: "OnAirLight";
    color: $blue-01;
    box-sizing: border-box;
    outline: none;
    -moz-appearance: textfield;

    &.year {
      width: 60px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

}
</style>
