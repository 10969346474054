<template>
  <header
    class="navigation"
    :class="[
      isNavigationActive ? 'navigation--open' : '',
      !openUpMenu ? 'navigation--open-default' : ''
    ]"
  >
    <nav class="navigation__meta">
      <ul class="navigation__meta-portal">
        <li class="navigation__meta-item">
          <a target="_blank" href="https://www.o2online.de/" rel="noopener">
            PRIVATKUNDEN
          </a>
        </li>
        <li class="navigation__meta-item">
          <a target="_blank" href="https://www.o2online.de/business/" rel="noopener">
            GESCHÄFTSKUNDEN
          </a>
        </li>
      </ul>
      <div class="navigation__meta-link-container">
        <ul class="navigation__meta-links">
          <li class="navigation__meta-item">
            <a target="_blank" href="https://www.o2online.de/kontakt/" rel="noopener">
              <span class="glyphicon glyphicon-phone" />
              <span>Bestellhotline 089 78 79 79 409</span>
            </a>
          </li>
          <li class="navigation__meta-item first">
            <a target="_blank" href="https://www.o2online.de/shops" rel="noopener">
              <span class="glyphicon glyphicon-retail" />
              <span>O<sub>2</sub> Shops</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <div class="navigation__logo">
      <a target="_blank" href="https://www.o2online.de/" rel="noopener">
        <div
          class="icon-logo"
          :class="[isNavigationActive ? 'icon-logo' : 'icon-logo']"
        />
      </a>
    </div>

    <div class="navigation__actions">
      <a href="https://www.o2online.de/warumo2/" class="action__item hidden">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24">
          <path d="M12 1c6.066 0 11 4.934 11 11s-4.934 11-11 11S1 18.066 1 12 5.934 1 12 1zm0 1.568c-5.202 0-9.432 4.23-9.432 9.432s4.23 9.432 9.432 9.432 9.432-4.23 9.432-9.432S17.202 2.568 12 2.568zm3.958 6.119a.766.766 0 011.02-.08l.089.079.077.09a.779.779 0 010 .934l-.076.09-6.798 6.883-3.35-3.578a.779.779 0 01.025-1.113.768.768 0 011.038-.046l.075.074 2.248 2.343z"></path>
        </svg>
        <p class="navigation-link hidden lg:hidden">Warum O<sub>2</sub></p>
      </a>
      <a href="https://www.o2online.de/suche/consumer/" class="action__item hidden">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24">
          <path d="M22.764 21.664l-6.482-6.482a8.622 8.622 0 002.004-5.54C18.286 4.89 14.396 1 9.643 1 4.889 1 1 4.89 1 9.643s3.89 8.643 8.643 8.643a8.622 8.622 0 005.54-2.004l6.481 6.482c.157.157.354.236.55.236s.393-.079.55-.236a.76.76 0 000-1.1zM2.571 9.643c0-3.89 3.183-7.072 7.072-7.072 3.89 0 7.071 3.183 7.071 7.072 0 3.89-3.182 7.071-7.071 7.071-3.89 0-7.072-3.182-7.072-7.071z"></path>
        </svg>
        <p class="navigation-link lg:hidden">Suche</p>
      </a>
      <a
        href="https://www.o2online.de/e-shop/warenkorb"
        class="action__item second"
        :class="[isMobile ? 'hidden' : '']"
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24">
          <path d="M18.686 17.893H8.19l-.7-2.75h12.594L23 3.357H4.537l-.155-.59C4.11 1.748 3.177 1 2.127 1h-.35A.784.784 0 001 1.786c0 .432.35.785.777.785h.35c.35 0 .661.236.739.59l3.77 14.85c-1.05.314-1.827 1.296-1.827 2.435A2.543 2.543 0 007.375 23c1.399 0 2.565-1.14 2.565-2.554a2.76 2.76 0 00-.194-.982h6.607a2.76 2.76 0 00-.194.982A2.543 2.543 0 0018.724 23c1.4 0 2.566-1.14 2.566-2.554-.04-1.414-1.166-2.553-2.604-2.553zm2.332-12.964L18.88 13.57H7.102L4.926 4.93zM8.074 21.154a1.03 1.03 0 01-.7.275c-.544 0-1.01-.433-1.01-.983 0-.275.117-.51.272-.707a.931.931 0 01.7-.275c.544 0 1.01.432 1.01.982.04.275-.077.511-.272.708zm11.35 0a.931.931 0 01-.7.275c-.544 0-1.01-.433-1.01-.983 0-.275.116-.51.272-.707a.931.931 0 01.7-.275c.544 0 1.01.432 1.01.982 0 .275-.078.511-.272.708z"></path>
        </svg>
        <p class="navigation-link lg:hidden">Warenkorb</p>
      </a>
      <a href="https://www.o2online.de/service/" class="action__item hidden">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24">
          <path d="M8.745 3.445a8.348 8.348 0 018.801-1.927l.152.058 1.12.437-5.594 5.59a.733.733 0 00-.046.983l.044.049 2.14 2.14a.73.73 0 00.982.046l.049-.045 5.595-5.596.437 1.12a8.358 8.358 0 01-1.87 8.955 8.355 8.355 0 01-8.343 2.08l-.117-.037-5.431 5.47a.786.786 0 01-1.06.05l-.051-.046a.786.786 0 01-.05-1.061l.046-.05 6.146-6.189.48.188a6.786 6.786 0 007.27-1.517 6.78 6.78 0 001.872-6.024l-.008-.038-3.803 3.805a2.3 2.3 0 01-3.182.07l-.072-.069-2.14-2.14a2.304 2.304 0 01-.07-3.181l.07-.072 3.804-3.803-.037-.007a6.77 6.77 0 00-5.917 1.769l-.106.103a6.786 6.786 0 00-1.569 7.135l.052.137.188.48-6.188 6.142a.786.786 0 01-1.153-1.065l.046-.05 5.469-5.429-.037-.115A8.356 8.356 0 018.63 3.563z"></path>
        </svg>
        <p class="navigation-link lg:hidden">Service</p>
      </a>
      <a
        :href="[
          isLoggedIn
          ? 'https://www.o2online.de/mein-o2/'
          : 'https://login.o2online.de/auth/login/'
        ]"
        class="action__item first"
        :class="[isMobile ? 'hidden' : '']"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M15.844 14.37a7.29 7.29 0 003.286-6.106C19.13 4.257 15.933 1 12 1S4.87 4.257 4.87 8.264a7.29 7.29 0 003.286 6.106C3.936 15.523 1 18.542 1 22.171c0 .457.365.829.814.829s.813-.372.813-.829c0-3.661 4.204-6.642 9.373-6.642s9.373 2.98 9.373 6.642c0 .457.365.829.813.829a.822.822 0 00.814-.829c0-3.63-2.936-6.648-7.156-7.801zM6.497 8.264c0-3.093 2.467-5.606 5.503-5.606s5.503 2.513 5.503 5.606-2.467 5.601-5.503 5.601-5.503-2.513-5.503-5.6z"></path>
        </svg>
        <p class="navigation-link lg:hidden">Mein O<sub>2</sub></p>
      </a>
      <button class="button-component action__item last lg:hidden" @click="onClickMenu">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-if="!isNavigationActive">
          <path d="M22.01 18.513c.547 0 .99.445.99.993s-.443.994-.99.994H1.99c-.547 0-.99-.445-.99-.994s.443-.993.99-.993zm0-7.507c.547 0 .99.445.99.994s-.443.994-.99.994H1.99c-.547 0-.99-.445-.99-.994s.443-.994.99-.994zm0-7.506c.547 0 .99.445.99.994s-.443.993-.99.993H1.99c-.547 0-.99-.445-.99-.993s.443-.994.99-.994z"></path>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" v-else>
          <path d="M18.851 19.8c.258 0 .48-.094.668-.281.187-.187.281-.41.281-.668 0-.257-.094-.468-.281-.632l-7.202-7.237 7.202-7.236c.164-.164.246-.375.246-.633 0-.257-.088-.474-.264-.65-.175-.175-.392-.263-.65-.263-.257 0-.48.082-.667.246l-7.202 7.237L3.781 2.48c-.188-.187-.41-.281-.668-.281-.257 0-.474.088-.65.263-.175.176-.263.393-.263.65 0 .258.094.48.281.668l7.202 7.201L2.48 18.22c-.187.188-.281.41-.281.668 0 .257.094.474.281.65.187.175.41.263.668.263.257 0 .468-.094.632-.281l7.201-7.202 7.237 7.202c.164.187.375.281.632.281z"/>
      </svg>
        <p>Menü</p>
      </button>
    </div>

    <div class="navigation__blocks">
      <DefaultBlock
        :isNavigationActive="isNavigationActive"
        :openUpMenu="openUpMenu"
        @toggle-main="openUpMenu = true"
        @on-click="onClickMenu"
        @on-feedback="onClickFeedback"
      />
      <MainBlock
        :links="topNavigation"
        :isNavigationActive="isNavigationActive"
        :openUpMenu="openUpMenu"
        :isLoggedIn="isLoggedIn"
        @update-selected="onUpdateSelected"
      />
    </div>
  </header>
</template>

<script>
import AuthService from '@/services/AuthService';
import MainBlock from './MainBlock.vue';
import DefaultBlock from './DefaultBlock.vue';

const auth = new AuthService();

export default {
  name: 'header-component',
  components: {
    MainBlock,
    DefaultBlock,
  },
  data: () => ({
    isNavigationActive: false,
    openUpMenu: false,
    isLoggedIn: false,
  }),
  computed: {
    topNavigation() {
      return this.$store.state.siteSettings.topNavigation;
    },
    leftNavigation() {
      return this.$store.state.siteSettings.leftNavigation;
    },
    leftNavigationSocialHeader() {
      return this.$store.state.siteSettings.leftNavigationSocialHeader || [];
    },
    leftNavigationSocial() {
      return this.$store.state.siteSettings.leftNavigationSocial || 'JETZT FAN WERDEN:';
    },
  },
  methods: {
    strippedHtml(val) {
      const regex = /(<([^>]+)>)/ig;
      return val.replace(regex, '');
    },
    onClickMenu() {
      this.isNavigationActive = !this.isNavigationActive;
      this.openUpMenu = false;
      if (this.isNavigationActive) {
        document.body.classList.add('open-nav');
      } else {
        document.body.classList.remove('open-nav');
      }
    },
    onClickFeedback() {
      if (window.KAMPYLE_ONSITE_SDK && this.isUserAcceptCookies()) {
        window.KAMPYLE_ONSITE_SDK.showForm(16401);
      } else {
        window.open(
          'https://assets.kampyle.com/resources/direct/form.html?region=prodEuIrland&websiteId=330018&formId=16401',
          '_blank',
        );
      }
    },
    onUpdateSelected(selected) {
      this.selected = selected;
    },
  },
  async mounted() {
    this.isLoggedIn = await auth.isLoggedIn();
  },
};
</script>
