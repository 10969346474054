/<template>
  <div class="ticket-shop" data-view>
    <GridLines />
    <exclusive-tickets-sales
      v-if="pageData.teaser"
      :exclusive-tickets-module="teaserEvents"
    />
    <div class="filtering-wrapper">
      <filtering
        class="filter-block"
        :filter-list="filterList"
        @change="onChangeFiltering"
        :filterHeader="pageData.filter_header"
      />
    </div>
    <tickets-on-sales
      v-if="
        content.exclusive_tickets_module.value
        && content.exclusive_tickets_module.value.events.length
        && !isItemsFiltered
      "
      :on-sale-tickets-module="content.exclusive_tickets_module"
    />
    <tickets-on-sales
      v-if="
        content.on_sale_tickets_module.value
        && content.on_sale_tickets_module.value.events.length
        && !isItemsFiltered
      "
      :on-sale-tickets-module="content.on_sale_tickets_module"
    />
    <tickets-on-sales
      v-if="
        content.past_tickets_module.value
        && content.past_tickets_module.value.events.length
        && !isItemsFiltered
      "
      :on-sale-tickets-module="content.past_tickets_module"
    />
    <tickets-on-sales
      v-if="isItemsFiltered"
      :on-sale-tickets-module="filteredData"
    />
    <legal-notification
      :pre-footer-description="pageData.pre_footer_description"
      :pre-footer-button-text="pageData.pre_footer_button_text"
      :pre-footer-button-link="pageData.pre_footer_button_link"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import LegalNotification from '@/components/misc/LegalNotification.vue';
import Filtering from '@/components/filtering/Filtering.vue';
import GridLines from '@/components/misc/GridLines.vue';
import ExclusiveTicketsSales from './components/ExclusiveTicketsSales.vue';
import TicketsOnSales from './components/TicketsOnSales.vue';

export default {
  name: 'MetaTicketShop',
  components: {
    ExclusiveTicketsSales,
    TicketsOnSales,
    GridLines,
    Filtering,
    LegalNotification,
  },
  data: () => ({
    filteringData: {},
    isItemsFiltered: false,
    filteredData: {
      value: {},
    },
  }),
  computed: {
    content() {
      return this.pageData.content.events.reduce((acc, cur) => {
        if (cur.value.ticket_alarm) {
          const position = window.innerWidth <= 992 ? 2 : 3;
          cur.value.events.splice(position, 0, {
            type: 'newsletter_alarm_block',
            ...cur.value.ticket_alarm,
          });
        }
        acc[cur.type] = cur;
        return acc;
      }, {
        exclusive_tickets_module: [],
        on_sale_tickets_module: [],
        past_tickets_module: [],
      });
    },
    teaserEvents() {
      if (!this.pageData.teaser.events.length) {
        return [];
      }
      return this.pageData.teaser.events.slice(0);
    },
    filterList() {
      const { categories } = this.pageData.content;
      return categories.map((el) => ({
        text: el.label,
        count: el.count,
        value: false,
        key: el.value,
      }));
    },
  },
  methods: {
    ...mapActions(['getFilteredData']),
    onChangeFiltering(data) {
      this.onFilterData(data);
    },
    applySettings() {
      const { filter } = this.pageData.content;
      let isItemsFiltered = false;
      const params = [];
      Object.keys(this.filteringData).map((key) => {
        if (this.filteringData[key].value) {
          isItemsFiltered = true;
          params.push(key);
        }
        return key;
      });
      if (!isItemsFiltered) {
        this.isItemsFiltered = isItemsFiltered;
        return;
      }
      this.getFilteredData({
        params: {
          event_serie__categories__category__in:
            params.join(),
        },
        url: filter,
      }).then((response) => {
        if (response.status !== 200) {
          return;
        }
        this.isItemsFiltered = isItemsFiltered;
        this.filteredData.value = {
          heading: this.pageData.filter_header || 'Filtered Items',
          events: response.data.results,
        };
      });
    },
    onFilterData(data) {
      this.filteringData = {};
      data.map((el) => {
        this.filteringData[el.key] = {
          value: el.value,
        };
        return el;
      });
      this.applySettings();
    },
  },
};
</script>
<style lang="scss" scoped>
  .filtering-wrapper {
    width: 50%;
    margin: 0 auto;
    border: solid 1px $very-light-grey;
    border-top: none;
    ::v-deep .filtering-list {
      border-left: solid 1px $very-light-grey;
      border-right: solid 1px $very-light-grey;
      @include lg {
        border-left: none;
        border-right: none;
      }
      @include sm {
        left: 0;
      }
    }
    @include lg {
      width: auto;
      margin-left: 31px;
      margin-right: 31px;
    }
    @include sm {
      margin: 0;
    }
  }
  .ticket-shop {
    ::v-deep .grid-lines {
      display: none;

      @include lg {
        display: block;
      }
    }
  }
</style>
