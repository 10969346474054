<template>
  <span>
    <a
      v-if="link.length"
      :href="link"
      target="_blank"
    >
      <img
        v-bind:src="priorityLogo"
        alt="o2 Priority"
        :style="{ 'width': width }"
      />
    </a>
    <img
      v-else
      v-bind:src="priorityLogo"
      alt="o2 Priority"
      :style="{ 'width': width }"
    />
  </span>
</template>

<script>
export default {
  name: 'priority-logo',
  props: {
    width: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      default: '',
    },
  },
  computed: {
    priorityLogo() {
      const centeredPriorityLogo = require(
        '@/assets/images/priority-logo/priority-logo-negative-centered@2x.png',
      );

      const leftPriorityLogo = require(
        '@/assets/images/priority-logo/priority-logo-negative-rgb@2x.png',
      );

      if (this.isMobile) {
        return centeredPriorityLogo;
      }
      return leftPriorityLogo;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
 width: 100%;
}
</style>
