<template>
  <div class="presale-wrapper" v-if="eventSeries">
    <PresaleEndCountdown
      v-if="isPresaleRunning && ticketsAvailable"
      :presale-end="presaleEndDate"
      class="mobile-countdown"
    />
    <PresaleEndMessage
      v-if="presaleHasEnded && ticketsState === 'SALE'"
      class="mobile-end-message"
    />
    <div class="mobile-start-countdown">
      <PresaleStartCountdown
        v-if="displayPresaleStartCountdown"
        :presale-start="presaleStartDate"
      />
    </div>
    <div class="presale-background" />
    <div class="presale-content">
      <PresaleEvent
        :ticketsState="ticketsState"
        :eventSeries="eventSeries"
        :title="pageData.title"
        :isPresaleRunning="isPresaleRunning"
      />
      <PresaleStrip
        :ticketsState="ticketsState"
        :presaleStartDate="presaleStartDate"
        :presaleEndDate="presaleEndDate"
        :isPresaleRunning="isPresaleRunning"
        :presaleHasEnded="presaleHasEnded"
        :eventSeries="eventSeries"
        :similarEventSeries="similarEventSeries"
        :tickets-available="ticketsAvailable"
        :on-show-popup-error="onShowPopupError"
        class="presale-strip"
      />
      <div class="right-spacer" />
    </div>
    <transition name="fade">
      <div v-if="showPopupError" class="presale-popup-blocker">
        <div class="info-image" />
        <div class="popup-blocker-text">
          Die Weiterleitung zu Eventim steht bereit, aber Pop-ups sind blockiert.
          Bitte deaktiviere den Pop-up-Blocker.
          <div class="text-link">
            <a target="_blank" :href="eventimLink">Jetzt fortfahren</a>
          </div>
        </div>
        <div class="close-button" @click="showPopupError = false" />
      </div>
    </transition>
  </div>
</template>

<script>
import PresaleEvent from '@/views/journey/components/presale/PresaleEvent.vue';
import PresaleStrip from '@/views/journey/components/presale/PresaleStrip.vue';
import PresaleStartCountdown from '@/views/journey/components/presale/PresaleStartCountdown.vue';
import PresaleEndCountdown from '@/views/journey/components/presale/PresaleEndCountdown.vue';
import PresaleEndMessage from '@/views/journey/components/presale/PresaleEndMessage.vue';

const FORTY_EIGHT_HOURS = 1000 * 60 * 60 * 48;

export default {
  name: 'Presale',
  components: {
    PresaleEvent,
    PresaleStrip,
    PresaleStartCountdown,
    PresaleEndCountdown,
    PresaleEndMessage,
  },
  data() {
    return {
      jsInterval: null,
      now: new Date(),
      showPopupError: false,
      eventimLink: '',
    };
  },
  computed: {
    displayPresaleStartCountdown() {
      return this.presaleStartDate > this.now;
    },
    eventSeries() {
      return this.pageData.event_series;
    },
    similarEventSeries() {
      return this.pageData.similar_event_series;
    },
    ticketsAvailable() {
      return this.eventSeries.tickets_available;
    },
    presaleEndDate() {
      return new Date(this.eventSeries.presale_end);
    },
    presaleStartDate() {
      const presaleStart = this.presaleEndDate.getTime() - FORTY_EIGHT_HOURS;
      return new Date(presaleStart);
    },
    isPresaleRunning() {
      return !this.presaleHasEnded
        && this.presaleStartDate < this.now
        && this.now < this.presaleEndDate;
    },
    presaleHasEnded() {
      return this.eventSeries.presale_end === null;
    },
    ticketsState() {
      return this.eventSeries.state;
    },
  },
  methods: {
    updateTime() {
      this.now = new Date();
    },
    onShowPopupError(link) {
      this.eventimLink = link;
      this.showPopupError = true;
    },
  },
  mounted() {
    this.jsInterval = setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.jsInterval);
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/base.scss";
@import "src/assets/styles/variables.scss";

.presale-wrapper,
.presale-background,
.presale-background img,
.presale-content {
  width: 100%;
  height: calc(100vh - #{$navigation-height});
  min-height: 800px;

  @include lg {
    height: calc(100vh - #{$mobile-navigation-height});
    min-height: 750px;
  }

  @include sm {
    height: auto;
  }
}
.presale-wrapper {
  height: auto;
  position: relative;
  overflow: hidden;
}
.presale-wrapper:not(:first-child) {
  border-top: 1px solid #ddd;
}
.presale-background {
  position: absolute;
  background-image: url('~@/assets/images/presale-teaser/bg-img-takeover.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;

  @include sm {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
}
.mobile-countdown {
  display: none;
  margin-bottom: 0;

  @include sm {
    display: block;
  }
}
.mobile-end-message {
  display: none;
  margin-bottom: 0;

  @include sm {
    display: block;
  }
}
.mobile-start-countdown {
  display: none;
  margin: 0;
  .presale-start-countdown {
    margin: 0;
    margin-top: -20px;
  }
  @include sm {
    display: block;
  }
}
.presale-content {
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @include sm {
    display: block;
    position: relative;
    overflow: hidden;
  }

  .presale-event {
    flex: 0 0 50%;
    box-sizing: border-box;

    @include xl {
      flex: 1 0 calc(50% - 62px);
    }
  }
  .presale-strip {
    flex: 0 0 25%;
    width: 25%;
    box-sizing: border-box;

    @include xl {
      flex: 1 0 calc(50% - 62px);
      width: calc(50% - 62px);
    }

    @include sm {
      width: auto;
      min-width: unset;
    }
  }
  .right-spacer {
    flex: 0 0 25%;

    @include xl {
      flex: 0 0 31px;
    }

    @include sm {
      display: none;
    }
  }
}
.presale-popup-blocker {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 356px;
  max-width: calc(100% - 24px);
  background-color: $white;
  border: solid 1px $very-light-grey;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.19);

  @include sm {
    position: fixed;
    top: 73px;
    z-index: 1000;
  }

  .popup-blocker-text {
    padding: 22px 29px 22px 62px;
    color: $blue-01;
    font-size: 13px;
    font-family: 'OnAirLight';
  }
  .text-link {
    padding-top: 10px;
    a {
      text-decoration: underline;
      color: $blue-01;
    }
  }
  .info-image {
    position: absolute;
    content: '';
    background-image: url('~@/assets/icons/information-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 32px;
    height: 32px;
    margin: auto;
    top: 26px;
    left: 16px;
  }
  .close-button {
    position: absolute;
    cursor: pointer;
    content: '';
    background-image: url('~@/assets/icons/close-btn-blue.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 14px;
    height: 14px;
    margin: auto;
    top: 12px;
    right: 12px;
  }
}
</style>
