<template>
  <div class="icalendar-module">
    <div
      class="icalendar-module-wrapper"
      :style="{ 'background-image': `url(${getResponsiveImage(calendar_background)})` }"
    >
      <div v-if="calendar_headline" class="icalendar-module-title" v-html="calendar_headline" />
      <div v-if="calendar_subheadline" class="icalendar-module-subtitle" v-html="calendar_subheadline" />
      <button-component
        v-if="calendar_button_text"
        :label="calendar_button_text"
        button-type="primary"
        background-color="white"
        :icon-props="{ enabled: true, name: 'calendar' }"
        :button-link="{
          internal: false,
          to: calendar_file.download_url
        }"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ICalendarModule',
  props: {
    calendar_file: {
      type: Object,
    },
    calendar_headline: {
      type: String,
    },
    calendar_subheadline: {
      type: String,
    },
    calendar_button_text: {
      type: String,
    },
    button_link: {
      type: String,
    },
    calendar_background: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
  .icalendar-module {
    width: calc(50% - 2px);
    margin: 73px auto 0;
  }
  .icalendar-module-wrapper {
    background-color: $blue-01;
    text-align: center;
    padding: 50px 22px 43px;
    background-size: cover;
    background-repeat: no-repeat;

    @include lg {
      padding: 50px 12px 43px;
    }
    @include sm {
      padding: 40px 22px 33px;
    }
  }
  .icalendar-module-title {
    font-family: 'OnAirBold';
    font-size: 34px;
    line-height: 1.18;
    color: $white;
    @include sm {
      font-size: 26px;
      line-height: 1.16;
    }
  }
  .icalendar-module-subtitle ::v-deep p {
    font-family: 'OnAirBold';
    font-size: 15px;
    line-height: 1.6;
    color: $white;
    margin-top: 10px;
    @include sm {
      font-size: 14px;
      line-height: 1.57;
    }
  }
  .button-component {
    margin-top: 44px;
    @include sm {
      margin-top: 26px;
    }
  }

  @include lg {
    .icalendar-module {
      width: calc(100% - 64px);
      margin-top: 50px;
    }
  }
  @include sm {
    .icalendar-module {
      width: calc(100% - 40px);
    }
  }
</style>
