<template>
  <div class="presale-details">
    <div class="presale-details-container">
      <template v-if="displayPresaleStartCountdown">
        <PresaleStartCountdown :presale-start="presaleStart" />
      </template>
      <PresaleAuthorizedAlert
        v-if="ticketsState === 'EXCLUSIVE' && isLoggedIn === true"
        class="exclusive-authorized-alert"
        :login-success-text="pageData.exclusive_phase_opt_in_success"
      />
      <ExclusiveLoginAlert
        v-if="ticketsState === 'EXCLUSIVE' && isLoggedIn === false && pageData.exclusive_phase_opt_in_description && pageData.exclusive_phase_opt_in"
        class="exclusive-login-alert"
        :login-text="pageData.exclusive_phase_opt_in_description"
        :login-button="pageData.exclusive_phase_opt_in"
        :event-name="eventName"
      />
      <PresaleDates
        class="presale-dates"
        :event-series="eventSeries"
        :is-presale-running="isPresaleRunning"
        :ticketsState="ticketsState"
        :event-name="eventName"
        :on-show-popup-error="onShowPopupError"
      />
    </div>
  </div>
</template>

<script>
import PresaleAuthorizedAlert from '@/views/journey/components/presale/PresaleAuthorizedAlert.vue';
import PresaleStartCountdown from '@/views/journey/components/presale/PresaleStartCountdown.vue';
import ExclusiveLoginAlert from '@/views/journey/components/presale/ExclusiveLoginAlert.vue';
import PresaleDates from '@/views/journey/components/presale/PresaleDates.vue';

export default {
  name: 'PresaleDetails',
  components: {
    PresaleAuthorizedAlert,
    PresaleStartCountdown,
    ExclusiveLoginAlert,
    PresaleDates,
  },
  props: {
    eventSeries: {
      type: Object,
    },
    eventName: {
      type: String,
    },
    ticketsState: {
      type: String,
    },
    presaleStart: {
      type: Date,
    },
    isPresaleRunning: {
      type: Boolean,
    },
    isLoggedIn: {
      type: Boolean,
    },
    onShowPopupError: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      jsInterval: null,
      now: new Date(),
    };
  },
  computed: {
    displayPresaleStartCountdown() {
      return this.presaleStart > this.now;
    },
  },
  methods: {
    updateTime() {
      this.now = new Date();
    },
  },
  mounted() {
    this.jsInterval = setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.jsInterval);
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables.scss";
.presale-details-container {
  @include sm {
    .presale-start-countdown {
      display: none;
    }
  }
}
.exclusive-authorized-alert {
  margin: -20px -25px 15px;

  @include sm {
    margin: -25px -25px 15px;
  }
}
.presale-details {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 25px;
  margin: -3px;
  min-height: 96px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background:  rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: $blue-02;
    border-radius: 2px;
  }
}
.line {
  margin-top: 40px;
  border: none;
  height: 1px;
  background-color: $blue-01;
}
</style>
