<template>
  <div class="takeover-teaser-modules">
    <presale-teaser
      :data="takeover_content"
    />
  </div>
</template>
<script>
import PresaleTeaser from '@/views/common/components/PresaleTeaser/presale-teaser.vue';

export default {
  name: 'TakeOverTeaserModule',
  components: {
    PresaleTeaser,
  },
  props: {
    takeover_content: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
