<template>
  <div
    class="artists-list-block"
    :style="{ 'margin-top': `${marginTop}px` }"
    @click="trackClick"
  >
    <div class="artist-content" :class="{'is-link': hasLink}" @click="onClick">
      <div class="artist-image" v-negative="{
          mouse_over: mouseOver,
          mouse_over_text: mouseOverText
        }">
        <img
          v-if="image"
          :src="image"
          :alt="artistName"
        >
        <img
          v-else
          src="@/assets/images/no-image.jpg"
          :alt="artistName"
        >
      </div>
      <div class="artist-block">
        <div class="artist-name">
          <h3 v-html="artistName" />
        </div>
        <div class="artist-event-place" v-html="information" />
        <span class="artist-event-date-wrapper">
          <div
            v-if="extraContent && extraContent.length"
            v-html="transformLive(extraContent)"
            class="artist-event-date"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, ScrollTrigger } from 'gsap/all';

export default {
  name: 'presale-list-item',
  props: {
    mouseOver: {
      type: Boolean,
      default: false,
    },
    mouseOverText: {
      type: String,
      default: 'Mehr',
    },
    link: {
      type: Object,
      required: false,
    },
    image: {
      type: [String, Boolean],
      default: false,
    },
    artistName: {
      type: String,
      default: '',
    },
    information: {
      type: String,
      default: '',
    },
    extraContent: {
      type: String,
      default: 'content extra',
    },
    listIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isOnTablet: window.innerWidth <= 992,
      tween: null,
    };
  },
  computed: {
    marginTop() {
      if (this.listIndex % 4 === 0) { // item 0, 4, 8...
        return 0;
      }
      if (this.listIndex % 3 === 0) { // item 3, 7, 11...
        return 250;
      }
      if (this.listIndex % 2 === 0) { // item 2, 6, 10...
        return 200;
      }
      return 150; // item 1, 5, 9...
    },
    presaleId() {
      return `presale-${this.listIndex}`;
    },
    hasLink() {
      return !!this.link;
    },
    isInternalLink() {
      return !!this.link && this.link.type === 'internal_link';
    },
    isExternalLink() {
      return !!this.link && this.link.type === 'external_link';
    },
    isExternalYoutubeLink() {
      return this.isExternalLink && this.isYoutubeLink(this.link.value);
    },
  },
  methods: {
    addScrollAnimation() {
      window.requestAnimationFrame = window.requestAnimationFrame.bind(window);
      gsap.registerPlugin(ScrollTrigger);
      this.tween = gsap.to(this.$el, {
        y: `-${this.marginTop}px`,
        id: this.presaleId,
        scrollTrigger: {
          trigger: this.$el,
          start: 'top bottom',
          end: 'top 33%',
          scrub: true,
        },
      });

      setTimeout(() => {
        ScrollTrigger.refresh(true);
      }, 1500);
    },
    enableScrollAnimation(tween) {
      if (tween) {
        tween.scrollTrigger.enable();
      }
    },
    disableScrollAnimation(tween) {
      if (tween) {
        tween.pause(0);
        tween.scrollTrigger.disable();
      }
    },
    configureScrollAnimation() {
      this.isOnTablet = window.innerWidth <= 992;

      if (!this.tween && !this.isOnTablet) {
        this.addScrollAnimation();
      }

      if (this.isOnTablet) {
        this.disableScrollAnimation(this.tween);
      } else {
        this.enableScrollAnimation(this.tween);
      }
    },
    reTween() {
      if (this.tween && this.tween.scrollTrigger) {
        this.tween.scrollTrigger.refresh();
      }
    },
    trackClick() {
      this.trackActionEvent({
        eventName: 'customInteractionClick',
        eCat: 'analysis',
        eAct: 'click - bald live',
        eLab: this.artistName,
      });
    },
    onClick() {
      if (this.hasLink) {
        const { value: link } = this.link;
        if (this.isInternalLink) {
          this.$router.push(link);
        } else if (this.isExternalLink && !this.isExternalYoutubeLink) {
          window.open(link, '_blank');
        } else if (this.isExternalYoutubeLink) {
          this.openYoutubeInNewWindow(link);
        }
      }
    },
  },
  mounted() {
    this.configureScrollAnimation();
    window.addEventListener('resize', this.configureScrollAnimation, false);
    this.$root.$on('update-list-artist-tween', this.reTween);
  },
  destroyed() {
    this.$root.$off('update-list-artist-tween', this.reTween);
    window.removeEventListener('resize', this.configureScrollAnimation, false);
  },
};
</script>

<style lang="scss" scoped>

.artists-list-block {
  width: 25%;
  margin-bottom: -250px;

  &:nth-child(4n+1) {
    margin-bottom: 0;
  }

  @include lg {
    width: 50%;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  @include sm {
    width: 100%;
  }
}
.artist-block {
  position: relative;
  min-height: 115px;
  padding-left: 7px;

  @include sm {
    min-height: 95px;
  }
}
.artist-content {
  width: 280px;
  height: auto;
  position: relative;
  margin: 20px auto 0;

  &.is-link {
    cursor: pointer;
  }

  .artist-image {
    display: flex;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .artist-name {
    margin-top: 15px;
    padding-right: 20px;

    h3 {
      font-family: 'OnAirBold';
      font-size: 20px;
      font-weight: normal;
      margin: 0;
      color: $blue-01;
      line-height: 1.4;

      &::v-deep {
        sub {
          font-size: 10px;
        }
      }
    }
  }
  .artist-event-place {
    padding-right: 20px;
    font-size: 12px;
    line-height: 1.6;
    color: $blue-01;
    font-family: 'OnAirRoman';
    margin-top: 5px;
    &::v-deep {
      sub {
        font-size: 8px;
      }
    }
  }
  .artist-event-date-wrapper {
    position: absolute;
    width: 0;
    height: 0;
    right: 5px;
    transform: rotate(90deg);
    transform-origin: top left;
    top: 1px;
  }
  .artist-event-date {
    font-size: 12px;
    line-height: 1.83;
    color: $blue-01;
    font-family: 'OnAirRoman';
    min-width: 115px;

    @include sm {
      min-width: 85px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 9px;
      height: 1px;
      background-color: $blue-01;
      top: 8px;
      left: -16px;
    }
  }
}

@include xl-1440 {
  .artist-content {
    width: calc(100% - 38px);
  }
}

@include lg {
  .artist-content {
    width: 280px;
    max-width: calc(100% - 40px);
  }
}

@include sm {
  .artist-content {
    width: calc(100% - 56px);
  }
  .artists-list-block {
    width: 100%;
  }
}
</style>
