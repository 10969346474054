var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro-module grid",class:{ 'intro-module-first': _vm.index === 0 }},[_c('div',{ref:"canvasContainer",staticClass:"canvas-container"},[_c('canvas',{ref:"introCanvas",on:{"click":function($event){return _vm.onClickCanvas($event)},"mousemove":function($event){return _vm.onMouseOver($event)}}})]),_vm._l((_vm.columns),function(item,$index){return _c('div',{key:$index,class:{
      'subheadline-container': item.type === 'text_column',
      'second-image': item.type === 'image_column',
      'blank-container': item.type === 'blank_column'
    }},[(item.type === 'text_column')?_c('div',[_c('TextComponent',{attrs:{"headline":item.value.headline,"copy":item.value.copy}}),(item.value.button)?_c('div',{staticClass:"button"},[_c('button-component',{attrs:{"label":item.value.button.text,"button-link":{
            internal: true,
            to: item.value.button.link || {}
          }}})],1):_vm._e()],1):_vm._e(),(item.type === 'image_column')?_c('div',[_c('ImageComponent',{attrs:{"image":item.value.image,"image_link":item.value.link}})],1):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }