<template>
  <div
    class="intro-video"
    :style="{ 'background-image': `url(${getResponsiveImage(teaserData.background)})` }"
  >
    <div class="teaser-priority-wrapper">
      <teaser-priority
        :image="getResponsiveImage(teaserData.image)"
        :title="teaserData.title"
        :subtitle="teaserData.subtitle"
        :description="teaserData.description"
      />
    </div>
    <div class="intro-video-content">
      <div
        class="content-wrapper"
      >
        <img
          :src="getResponsiveImage(mediaContent.value.thumbnail)"
          :alt="mediaContent.value.header"
        >
        <div class="content-text">
          <div class="content-text-extra">
            {{ mediaContent.value.extra_content }}
          </div>
          <div class="content-text-header">
            {{ mediaContent.value.header }}
          </div>
          <div class="content-text-description" v-html="mediaContent.value.description" />
          <div class="content-text-duration">
            {{ mediaContent.value.video_duration }}
          </div>
        </div>
        <div class="play-button" />
        <div class="content-wrapper-mask">
          <div class="mask-content">
            <div class="mask-text">
              Wir leiten dich jetzt zu YouTube weiter.
            </div>
            <div class="mask-button">
              <button-component
                :type="isTablet || isMobile ? 'secondary' : 'primary'"
                background-color="white"
                label="Jetzt ansehen"
                :icon-props="{
                  enabled: true,
                  name: 'play-button',
                  position: 'left'
                }"
                @change="onClickPlayButton"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TeaserPriority from '@/components/teaser/TeaserPriority.vue';

export default {
  name: 'intro-video',
  components: {
    TeaserPriority,
  },
  props: {
    teaserData: {
      type: Object,
    },
    mediaContent: {
      type: Object,
    },
    onOpenVideo: {
      type: Function,
    },
  },
  methods: {
    onClickPlayButton() {
      this.onOpenVideo(this.mediaContent);
    },
  },
};
</script>
<style lang="scss" scoped>
  .intro-video {
    display: flex;
    background-color: $blue-02;
    background-position: center;
    background-size: cover;
  }
  .teaser-priority-wrapper {
    background-color: transparent;
    width: 25%;
    padding-bottom: 55px;

    .teaser-wrapper {
      background-image: none;
    }
  }
  .intro-video-content {
    width: 75%;
    position: relative;

    .content-wrapper {
      position: absolute;
      max-height: 500px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 878px;
      display: flex;

      img {
        height: auto !important;
        width: 100% !important;
      }
      .play-button {
        cursor: pointer;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: url('~@/assets/images/mediathek/play.svg') no-repeat center center;
        z-index: 1;
      }
      &:hover {
        .content-wrapper-mask {
          opacity: 1;
        }
      }
    }
    .content-wrapper-mask {
      transition: opacity 0.3s ease-out;
      position: absolute;
      z-index: 1;
      display: flex;
      opacity: 0;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      flex-wrap: wrap;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        position: absolute;
        background-color: $blue-01;
        opacity: 0.7;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .mask-content {
        position: relative;
        text-align: center;
      }

      .mask-text {
        font-family: 'OnAirBold';
        font-size: 26px;
        line-height: 1.15;
        letter-spacing: normal;
        text-align: center;
        color: $white;
        padding-bottom: 37px;

        @include lg {
          font-size: 20px;
        }
      }
    }
  }
  .content-text {
    color: $white;
    position: absolute;
    width: calc(100% - 66px);
    bottom: 28px;
    left: 33px;

    &-extra {
      font-style: italic;
      font-size: 10px;
      font-family: 'OnAirThin';
      color: #b9b9b9;
    }
    &-header {
      font-family: 'OnAirBold';
      font-size: 26px;
      line-height: 1.08;
      margin-top: 10px;
    }
    &-description {
      font-family: 'OnAirLight';
      font-size: 15px;
      line-height: 1.6;
      margin-top: 5px;
    }
    &-duration {
      font-family: 'OnAirRoman';
      font-size: 12px;
      line-height: 1.83;
      position: absolute;
      right: -20px;
      bottom: -15px;
    }
  }

  @include xl-1440 {
    .content-text {
      width: calc(100% - 42px);
      bottom: 21px;
      left: 21px;
      &-duration {
        right: -6px;
        bottom: -11px;
      }
    }
    .intro-video-content {
      .content-wrapper {
        max-width: 90%;
      }
    }
  }
  @include xl {
    .content-text {
      width: calc(100% - 52px);
      bottom: 23px;
      left: 26px;
      &-duration {
        right: -9px;
        bottom: -16px;
      }
    }
  }

  @include iPadPro {
    .intro-video-content {
      .content-wrapper {
        width: 90%;
        height: auto;

        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  @include lg {
    .content-text {
      &-extra {
        font-size: 9px;
      }
      &-header {
        font-size: 20px;
      }
      &-description {
        font-size: 11px;
      }
      &-duration {
        font-size: 9px;
      }
    }
    .teaser-priority-wrapper {
      padding-bottom: 0;
      width: 325px;

      ::v-deep .teaser {
        flex-wrap: wrap;
        padding: 20px 0;

        .teaser-item {
          width: auto;
          margin-left: 40px;

          img {
            width: 200px;
            margin-left: 0;
          }
        }
        .copy {
          width: 100%;
          margin: 40px;
        }
      }
    }
    .intro-video-content {
      .content-wrapper {
        height: auto;
        width: 90%;

        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  @include sm {
    .intro-video {
      display: none;
    }
  }
</style>
