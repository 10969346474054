<template>
  <div v-if="events.length" class="exclusive-events-module">
    <div class="exclusive-module-content">
      <div class="exclusive-block exclusive-block-priority">
        <exclusive-block-priority
          :button-text="button_text"
          :headline="headline"
          :subheadline="subheadline"
          :footer="footer"
        />
      </div>
      <div class="exclusive-block exclusive-block-slider">
        <exclusive-block-slider
          :events-data="events"
        />
      </div>
      <div class="exclusive-block exclusive-block-info" v-html="footer" />
    </div>
  </div>
</template>
<script>
import ExclusiveBlockPriority from './exclusive-block-priority.vue';
import ExclusiveBlockSlider from './exclusive-block-slider.vue';

export default {
  name: 'exclusive-events-module',
  components: {
    ExclusiveBlockPriority,
    ExclusiveBlockSlider,
  },
  props: {
    button_text: {
      type: String,
      default: 'Alle',
    },
    headline: {
      type: String,
      default: 'Headline',
    },
    subheadline: {
      type: String,
      default: 'Subheadline',
    },
    footer: {
      type: String,
      default: 'Footer text',
    },
    events: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
.exclusive-events-module {
  margin: 55px 0;
  background-color: $blue-02;
  color: $white;
  padding: 55px 0;
  padding-bottom: 30px;

  @include xl {
    padding: 40px 0;
    margin: 40px 0;
    padding-bottom: 0;
  }
  @include sm {
    padding-bottom: 0;
  }
}
.exclusive-module-content {
  display: flex;
  @include xl {
    flex-wrap: wrap;
  }
}
.exclusive-block-priority {
  width: 25%;

  @include xl {
    width: 100%;
  }
}
.exclusive-block-slider {
  width: 75%;
  overflow: hidden;
  @include xl {
    overflow: initial;
    width: 100%;
  }
}
.exclusive-block-info {
  display: none;

  @include xl {
    display: block;
    font-family: 'OnAirLight';
    font-size: 14px;
    line-height: 1.57;
    padding: 35px 46px;
  }
  @include lg {
    display: block;
    font-family: 'OnAirLight';
    font-size: 14px;
    line-height: 1.57;
  }

  @include sm {
    padding: 25px 46px;
  }

  &::v-deep {
    a {
      text-decoration: underline;
    }
  }
}
</style>
