<template>
  <div class="more-artists-section">
    <div
      v-for="(artist, $index) in teasers"
      class="artist-wrapper"
      :key="$index"
    >
      <router-link
        class="artist-block"
        :to="artist.value.link || '/'"
      >
        <div v-html="transformLive(artist.value.header)" class="artist-name" />
        <div class="artist-img">
          <img
            :src="getResponsiveImage(artist.value.image)"
            :alt="artist.value.header"
          >
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MoreArtists',
  props: {
    teasers: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>
<style lang="scss" scoped>
  .more-artists-section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;

    .artist-wrapper {
      width: 50%;
      margin-bottom: 60px;
      margin-top: 60px;
      position: relative;
      text-align: right;

      &::before {
        content: '';
        position: absolute;
        width: 25px;
        height: 100%;
        background-color: $white;
        z-index: 1;
        right: -25px;
      }
    }
    .artist-wrapper:nth-child(odd) {
      margin-left: 25%;
    }
    .artist-block {
      transform: translate(20px, 0px);
      font-family: 'OnAirBold';
      font-size: 62px;
      line-height: 1.16;
      color: $blue-01;
      display: inline-block;
      transition: all 250ms;
      max-width: calc(100% - 220px);
      direction: rtl;

      &:hover {
        text-decoration: none;
        transform: translate(0, 0px);
        color: $orange;

        .artist-img {
          opacity: 1;
        }
      }
    }
    .artist-img {
      transition: opacity 250ms;
      position: absolute;
      width: 220px;
      height: 220px;
      margin: auto;
      top: 0;
      bottom: 0;
      left: -130px;
      z-index: -1;
      opacity: 0;

      img {
        width: 100%;
      }
    }

    @include lg {
      margin: 25px 30px;
      width: auto;

      .artist-wrapper {
        width: 50% !important;
        margin-top: 33px;
        margin-bottom: 33px;
        transform: translate(0, 0);
      }
      .artist-wrapper:nth-child(odd) {
        margin-left: 50%;
      }
      .artist-wrapper:nth-child(even) {
        margin-left: 0;
        .artist-img {
          left: 0 !important;
          right: auto !important;
        }
      }

      .artist-block {
        max-width: 100%;
        display: block;
        font-size: 58px;
      }
      .artist-img {
        left: auto;
        right: 0;
        width: 198px;
        height: 198px;
      }
    }

    @include sm {
      margin: 0 19px;

      .artist-wrapper {
        width: 100% !important;
        margin-left: 0 !important;
        margin-top: 30px;
        margin-bottom: 30px;

        &::before {
          display: none;
        }
      }
      .artist-wrapper:nth-child(odd) {
        text-align: right;
        .artist-img {
          right: 0 !important;
          left: auto !important;
        }
      }
      .artist-wrapper:nth-child(even) {
        text-align: left;
      }
      .artist-block {
        transform: translate(0, 0px);
        max-width: 80%;
        display: inline-block;
        font-size: 42px;
      }
      .artist-img {
        width: 120px;
        height: 120px;
        left: 0;
        right: auto;
      }
    }
  }
</style>
