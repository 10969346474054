<template>
  <div class="filtering-block">
    <div
      class="label"
      :class="{ 'open': showFilterList }"
      @click="onOpenFilterList($event)"
    >
      <div>
        <span class="headline-desktop">{{ filterHeader }}</span>
      </div>
      <div class="reset-filter-wrapper" v-if="!showFilterList">
        <template v-if="hasGroups">
          <template v-for="(item, $index) in filterListData">
            <span
              class="reset-filter reset-filter-desktop"
              @click="onResetSingleFilter($event, $index)"
              v-if="item.value"
              :key="`reset-${$index}`"
            >{{ item.text }}
              <span class="reset-filter-icon" />
            </span>
          </template>
        </template>
        <span
          v-if="isFiltered()"
          class="reset-filter reset-filter-desktop"
          @click="onResetFilter($event)"
        >Alle löschen
          <span class="reset-filter-icon" />
        </span>
      </div>


    </div>
    <transition name="fade">
      <div
        class="filtering-list"
        :class="{ 'filtering-list-open': showFilterList }"
        @click="onClickWrapper($event)"
      >
        <div class="filtering-list-headline">
          <span class="headline">{{ filterHeader }}</span>
          <span
            v-if="isFiltered()"
            class="reset-filter"
            @click="onResetFilter($event)"
          >Zurücksetzen
          <span class="reset-filter-icon" />
        </span>
        </div>
        <div class="filtering-elements">
          <template v-if="!hasGroups">
            <div
              v-for="({text, count, key}, $index) in filterListData"
              :key="$index"
              class="filtering-element"
              :class="{'filtering-element-disabled': isDisabled($index)}"
              @click="onClickItem($event)"
            >
              <checkbox
                :field="{
                  label: `${text} (${count})`,
                  name: key,
                }"
                :value="filterListData[$index].value"
                v-model="filterListData[$index].value"
                :disabled="isDisabled($index)"
                @input="onChangeCheckbox"
              />
            </div>
          </template>
          <template v-else>
            <div
              v-for="(group, $i) in filterGroups"
              :key="`group-${$i}`"
              class="filtering-group"
            >
              <div class="filtering-element-headline">{{ group }}</div>
              <div
                v-for="({text, count, key}) in getFiltersByGroup(group)"
                :key="getFilterIndexByKey(key)"
                class="filtering-element filtering-element-grouped"
                :class="{'filtering-element-disabled': isDisabled(getFilterIndexByKey(key))}"
                @click="onClickItem($event)"
              >
                <checkbox
                  :field="{
                    label: `${text} (${count})`,
                    name: key,
                  }"
                  :value="filterListData[getFilterIndexByKey(key)].value"
                  v-model="filterListData[getFilterIndexByKey(key)].value"
                  :disabled="isDisabled(getFilterIndexByKey(key))"
                  @input="onChangeCheckbox"
                />
              </div>
            </div>
          </template>
        </div>
        <div class="filtering-save">
          <button-component
            class="button-component"
            label="Schliessen & speichern"
            @change="onClickEvent"
          />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Checkbox from '@/components/form/Checkbox.vue';

export default {
  name: 'filtering',
  props: {
    filterList: {
      type: Array,
      default: () => ([]),
    },
    filterHeader: {
      type: String,
      default: 'Filter',
    },
    hasGroups: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Checkbox,
  },
  data: () => ({
    showFilterList: false,
    filterListData: [],
  }),
  computed: {
    filterGroups() {
      return [...new Set(this.filterListData.map((el) => el.group))];
    },
  },
  methods: {
    onClickWrapper(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    onResetFilter(e) {
      e.stopPropagation();
      this.filterListData = this.filterListData.map((el) => {
        el.value = false;
        return el;
      });
      this.$emit('change', this.filterListData);
    },
    onResetSingleFilter(e, index) {
      e.stopPropagation();
      this.filterListData[index].value = false;
      this.$emit('change', this.filterListData);
    },
    isDisabled(index) {
      return !this.filterList[index].count > 0;
    },
    isFiltered() {
      return !!this.filterListData.find((el) => !!el.value);
    },
    onChangeCheckbox() {
      this.$emit('change', this.filterListData);
    },
    onClickItem(e) {
      e.stopPropagation();
    },
    onOpenFilterList(e) {
      if (this.showFilterList) {
        e.stopPropagation();
        this.showFilterList = !this.showFilterList;
      } else {
        setTimeout(() => {
          e.stopPropagation();
          this.showFilterList = !this.showFilterList;
        }, 0);
      }
    },
    onClickEvent() {
      this.showFilterList = false;
    },
    getFiltersByGroup(group) {
      return this.filterList.filter((el) => el.group === group);
    },
    getFilterIndexByKey(filterKey) {
      return this.filterListData.findIndex((el) => el.key === filterKey);
    },
  },
  mounted() {
    this.filterListData = [...this.filterList];
    document.addEventListener('click', this.onClickEvent);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClickEvent);
    document.body.classList.remove('no-scroll-on-mobile');
    document.querySelector('html').classList.remove('no-scroll-on-mobile');
  },
  watch: {
    showFilterList() {
      if (this.showFilterList) {
        document.body.classList.add('no-scroll-on-mobile');
        document.querySelector('html').classList.add('no-scroll-on-mobile');
      } else {
        document.body.classList.remove('no-scroll-on-mobile');
        document.querySelector('html').classList.remove('no-scroll-on-mobile');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .filtering-save {
    display: none;
  }
  .filtering-block {
    position: relative;
  }
  .label {
    position: relative;
    font-family: 'OnAirBold';
    font-size: 18px;
    line-height: 1.4;
    color: $blue-01;
    padding: 20px 55px 20px 47px;
    cursor: pointer;
    min-height: 52px;
    display: flex;

    .headline-desktop {
      padding-right: 20px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      right: 60px;
      top: 23px;
      background-image: url('~@/assets/icons/plus.svg');
      background-size: contain;
    }
    &.open {
      min-height: 30px;
      padding-bottom: 10px;
      &::after {
        transform: rotate(90deg);
        background-image: url('~@/assets/icons/minus.svg');
      }
    }
  }
  .filtering-block {
    background-color: $white;
  }
  .filtering-list {
    visibility: hidden;
    position: absolute;
    width: calc(100% - 98px);
    background-color: $white;
    border-bottom: solid 1px $very-light-grey;
    padding: 40px 49px;
    padding-top: 0;
    z-index: 999;
    display: flex;
    flex-wrap: wrap;

    &-headline {
      display: none;
    }
    &-open {
      visibility: visible;
    }
  }
  .filtering-elements {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .filtering-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  hr { flex-basis: 100%; width: 0; margin: 0; border: none; }

  .filtering-element {
    width: 50%;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;

    &.filtering-element-grouped {
      padding-top: 0px;
      padding-bottom: 20px;
    }

    &.filtering-element-disabled {
      cursor: default;
    }

    &-headline {
      width: 100%;
      font-size: 12px;
      line-height: 1.67;
      color: $blue-01;
      font-family: "OnAirRoman";
      padding-bottom: 8px;
      padding-top: 5px;
    }
  }
  .reset-filter {
    cursor: pointer;
    float: left;
    font-family: 'OnAirRoman';
    font-size: 12px;
    line-height: 1.51;
    margin-right: 20px;
    margin-bottom: 10px;
    border: solid 1px $very-light-grey;
    padding: 5px 10px;
    position: relative;
    padding-right: 38px;

    &-icon {
      width: 16px;
      height: 16px;
      display: inline-block;
      transform: rotate(45deg);
      background-image: url('~@/assets/icons/plus.svg');
      background-size: cover;
      position: absolute;
      right: 10px;
      top: 5px;
    }
  }
  @include sm {
    .reset-filter-desktop {
      display: none;
    }
    .reset-filter {
      margin-right: 0;
      margin-bottom: 0;
      float: right;
      padding-right: 38px;
      &-icon {
        background-image: url('~@/assets/icons/plus-white.svg');
      }
    }
    .label {
      padding: 26px 23px;
      font-size: 20px;
      min-height: 20px;
      &::after {
        right: 23px;
        top: 28px;
      }
    }
    .filtering-list {
      position: fixed;
      top: 0;
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      left: 0;
      right: 0;
      padding: 0;
      display: block;
      z-index: 1111;
      overflow: auto;
    }
    .filtering-list-headline {
      background-color: $blue-02;
      font-family: 'OnAirBold';
      font-size: 20px;
      color: $white;
      padding: 25px;
      display: block;
      line-height: 1.5;
      margin-bottom: 12.5px;
      width: calc(100% - 50px);
      position: relative;
    }
    .filtering-elements {
      // height: calc(100vh - 225px);
      overflow: auto;
      display: block;
      margin-bottom: 225px;
    }
    .filtering-element {
      width: calc(100% - 50px);
      padding: 12.5px 25px;
    }
    .filtering-element-headline {
      width: calc(100% - 50px);
      padding-left: 25px;
    }
    .filtering-save {
      display: block;
      position: fixed;
      bottom: 0;
      text-align: center;
      background-color: $very-light-grey;
      width: 100%;
      z-index: 12;
      .button-component {
        margin-top: 29px;
        margin-bottom: 29px;
      }
    }
  }
</style>
