<template>
  <div class="presale-module">
    <div class="module-column">
      <div class="module-headline" v-custom-html="transformLive(headline)" />
    </div>
    <presale-list-item
      v-for="(artist, $index) in columns"
      :key="$index"
      class="module-column"
      :link="artist.value.link"
      :image="getResponsiveImage(artist.value.cover)"
      :artist-name="artist.value.title"
      :information="artist.value.information"
      :extra-content="artist.value.extra_content"
      :list-index="$index"
      :mouse-over="artist.value.mouse_over"
      :mouse-over-text="artist.value.mouse_over_text"
    />
  </div>
</template>

<script>
import PresaleListItem from '@/views/home/components/presale-list-item.vue';

export default {
  name: 'PresaleModule',
  components: {
    PresaleListItem,
  },
  props: {
    columns: {
      type: Array,
      default: () => ([]),
    },
    headline: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.presale-module {
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 0 !important;
  display: flex;
  flex-wrap: wrap;
  @include lg {
    margin: 0 31px;
  }
  @include lg {
    margin: 0 21px;
  }
}
.module-headline {
  font-family: 'OnAirBold';
  font-size: 64px;
  line-height: .8;
  letter-spacing: normal;
  color: $blue-01;
  margin-left: -5px;
  padding-top: 40px;
  @include lg {
    margin-left: 5px;
  }
  @include sm {
    margin-left: -7px;
    margin-bottom: 15px;
    padding-top: 0;
  }

  ::v-deep > sub {
    font-size: 64px;
    font-family: 'OnAirLight';
    line-height: .5;
  }
}
.module-column {
  width: 25%;

  @include lg {
    width: 50%;
  }
  @include sm {
    width: 100%;
  }
}
.module-column:nth-child(3n+2) {
  margin-left: 25%;

  @include lg {
    margin-left: 0;
  }
}
.module-column:nth-child(2) {
  margin-left: 0;
}
.module-column::v-deep {
  &.artists-list-block {
    margin-bottom: -200px !important;
    @include lg {
      margin-bottom: 0 !important;
    }
  }
}
</style>
