<template>
  <div id="app" class="app"
    :class="{ 'overlay-active': overlayActiveClassEnabled, rendered: renderedClassEnabled }"
  >
    <header-component v-if="siteSettings"/>
    <left-navigation v-if="siteSettings"/>
    <div class="app-wrapper">
      <router-view />
      <footer-component v-if="siteSettings"/>
    </div>
    <TicketAlarmOverlay
      v-if="overlayComponentEnabled"
      :class="{ 'active': overlayActiveClassEnabled }"
      class="ticket-alarm"
    />
    <eventim-overlay v-if="showEventimLoader"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import MetaData from '@/mixins/meta-data';

import HeaderComponent from '@/components/header/Header.vue';
import FooterComponent from '@/components/footer/Footer.vue';
import TicketAlarmOverlay from '@/components/ticket-alarm/TicketAlarmOverlay.vue';
import LeftNavigation from '@/components/left-navigation/LeftNavigation.vue';
import EventimOverlay from '@/components/misc/EventimOverlay.vue';

export default {
  name: 'App',
  mixins: [MetaData],
  components: {
    HeaderComponent,
    FooterComponent,
    LeftNavigation,
    TicketAlarmOverlay,
    EventimOverlay,
  },
  data() {
    return {
      overlayComponentEnabled: false,
      overlayActiveClassEnabled: false,
      renderedClassEnabled: true,
    };
  },
  computed: {
    ...mapState(['siteSettings', 'showEventimLoader']),
  },
  methods: {
    // To enable the overlay, we first have to activate the component itself by setting
    // overlayComponentEnabled to true. Only once it is rendered (after nextTick + 100ms) we can
    // set the 'active' class to start the animation.

    async enableOverlay() {
      this.overlayComponentEnabled = true;
      document.body.classList.add('no-scroll-on-mobile');
      await this.$nextTick();
      setTimeout(() => {
        this.overlayActiveClassEnabled = true;
      }, 100);
    },

    // To disable the overlay, we remove the 'active' class to start the animation. Only when the
    // animation is finished after 250ms we can disable the whole component.

    disableOverlay() {
      this.overlayActiveClassEnabled = false;
      document.body.classList.remove('no-scroll-on-mobile');
      setTimeout(() => {
        this.overlayComponentEnabled = false;
      }, 250);
    },

    updateOverlayState() {
      if (window.location.hash === '#ticketalarm') {
        this.enableOverlay();
      } else {
        this.disableOverlay();
      }
    },
  },
  mounted() {
    // eslint-disable-next-line
    if (!window.__PRERENDER_INJECTED) {
      this.renderedClassEnabled = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.app {
  width: 100%;
  transition: margin-left 250ms;

  &.overlay-active  {
    margin-left: -25%;

    @include lg {
      margin-left: -50%;
    }

    @include sm {
      margin-left: -100%;
    }
  }
}
.ticket-alarm {
  transition: margin-right 250ms;
  margin-right: -25%;

  @include lg {
    margin-right: -50%;
  }

  @include sm {
    margin-right: -100%;
  }

  &.active {
    margin-right: 0;
  }
}
</style>

<style lang="scss" src="./assets/styles/main.scss" />
