<template>
  <div
    class="left-navigation"
    :class="[
      navigationType ? `left-navigation--${navigationType}` : '',
      leftNavigationFull ? 'left-navigation-full' : ''
    ]"
  >
    <div class="left-navigation-content">
      <div class="left-navigation-logo">
        <router-link to="/" class="logo-link">
          <div class="icon-logo" />
        </router-link>
      </div>
      <div class="left-navigation-main">
        <ul ref="linksElement">
          <li
            v-for="navigationItem in leftNavigation"
            :key="navigationItem.key"
            :class="{ 'is-journey':
              isJourneyPageItem(navigationItem) && firstJourneyPageItem.key !== navigationItem.key
            }"
          >
            <router-link
              @click.native="removeFlagCameBackFromRedirect()"
              :to="navigationItem.url"
              :class="
                `${getCustomActiveLink(navigationItem.url) ? 'router-custom-active-link' : ''}`
              "
              rel="follow"
            >
              <div v-html="navigationItem.header" />
              <span :data-content="strippedHtml(navigationItem.title)" />
            </router-link>
          </li>
        </ul>
      </div>
      <div class="feedback-wrapper">
        <div class="social-wrapper">
          <div class="social-title">
            {{ leftNavigationSocialHeader }}
          </div>
          <div
            v-if="leftNavigationSocial.length > 0"
            class="social-block-links small">
            <div
              v-for="item in leftNavigationSocial"
              :key="item.platform"
              class="social-block-link">
              <a :href="item.url" target="_blank" rel="noopener">
                <span :class="`glyphicon glyphicon-${item.platform}`" />
              </a>
            </div>
          </div>
        </div>
        <div v-if="showFeedback" class="left-navigation-feedback" @click="onClickFeedback()">
          <img
            src="@/assets/images/video-overlay-feedback/feedback-teaser.svg"
            alt="left-navigation-feedback"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
* `
* Options:
*  Navigation type: 'transparent' | false
* `
*/
export default {
  name: 'left-navigation',
  props: {
    /**
		 * Navigation type
		 * Options: 'transparent'
		 */
    navigationType: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: () => ({
    leftNavigationFull: false,
    showFeedback: false,
  }),
  computed: {
    leftNavigation() {
      return this.$store.state.siteSettings.leftNavigation;
    },
    leftNavigationSocialHeader() {
      return this.$store.state.siteSettings.leftNavigationSocialHeader || 'JETZT FAN WERDEN:';
    },
    leftNavigationSocial() {
      return this.$store.state.siteSettings.leftNavigationSocial || [];
    },
    firstJourneyPageItem() {
      return this.$store.state.siteSettings.leftNavigation.find((el) => this.isJourneyPageItem(el));
    },
  },
  methods: {
    removeFlagCameBackFromRedirect() {
      this.$store.commit('setCameBackFromRedirect', false);
    },
    getCustomActiveLink(link) {
      return link === this.$route.path;
    },
    strippedHtml(val) {
      const regex = /(<([^>]+)>)/ig;
      return val.replace(regex, '');
    },
    onClickFeedback() {
      if (window.KAMPYLE_ONSITE_SDK && this.isUserAcceptCookies()) {
        window.KAMPYLE_ONSITE_SDK.showForm(16401);
      } else {
        window.open(
          'https://assets.kampyle.com/resources/direct/form.html?region=prodEuIrland&websiteId=330018&formId=16401',
          '_blank',
        );
      }
    },
    onResizeLeftNavigation() {
      this.leftNavigationFull = false;
      this.$nextTick(() => {
        const leftNavHeight = this.$refs.linksElement.offsetHeight + 300;
        this.leftNavigationFull = window.innerHeight < leftNavHeight;
      });
    },
    isJourneyPageItem(item) {
      // except /journeys/about/
      return item.url.indexOf('/journeys/about') === 0
        ? false : item.url.indexOf('/journeys/') === 0;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResizeLeftNavigation);
    });
    this.showFeedback = true;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResizeLeftNavigation);
  },
};
</script>
<style lang="scss" scoped>
  .social-wrapper {
    text-align: center;
    font-family: "OnAirBold";
    font-size: 10px;
    color: $blue-01;
    text-transform: uppercase;
  }
  .logo-link {
    display: block;
    text-align: center;
  }
  .left-navigation-logo {
    height: 50px;
    width: calc(100% - 50px);
    margin: 0 auto;
    padding-top: 60px;
    position: relative;
  }
  .left-navigation-full {
    li {
      padding: 17.5px 0;
    }
    .left-navigation-feedback {
      display: none;
    }
  }
  .feedback-wrapper {
    position: relative;
    margin-top: auto;
    margin-bottom: 25px;
  }
  .social-block-links {
    display: flex;
    flex-direction: row;
    width: auto;
    justify-content: center;
    &.small {
      margin-top: 0;
      width: auto;
    }
    .social-block-link {
      padding: 6px;

      a {
        display: block;
      }
      span {
        font-size: 24px;
        color: $blue-01;
        &.glyphicon-youtube {
          margin-top: 5px;
        }
        @include xl {
          font-size: 19px;
          &.glyphicon-youtube {
            margin-top: 3px;
          }
        }
        @include lg {
          &.glyphicon-youtube {
            margin-top: 5px;
          }
        }
      }
    }
    @include xl {
      width: 100%;
      justify-content: center;
    }
    @include lg {
      width: auto;
      justify-content: flex-start;
    }
    @include sm {
      width: 100%;
      justify-content: center;
    }
  }
  .left-navigation-feedback {
    max-width: 63px;
    margin: auto;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;

    img {
      height: 100%;
    }
  }
</style>
