<template>
  <div class="eventim-overlay">
    <div class="eventim-overlay-content">
      <div class="overlay-icon" />
      <div class="overlay-text">
        <p>Einen Moment bitte.</p>
        <p>Wir leiten dich zu unserem Partner CTS EVENTIM AG & Co. KGaA weiter, damit du dort deinen Ticket-Kauf fortsetzen kannst. </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EventimOverlay',
};
</script>
<style lang="scss" scoped>
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .eventim-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-content: center;
    &::before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.8;
      background-color: #040422;
      z-index: -1;
    }
  }
  .eventim-overlay-content {
    width: 480px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    line-height: normal;
    align-self: center;

    @include lg {
      width: calc(100% - 38px);
      padding-left: 19px;
      padding-right: 19px;
      font-size: 18px;
    }
  }
  .overlay-text {
    margin-top: 21px;
    font-family: 'OnAirBold';
    font-size: 24px;
    line-height: 1.38;
    color: $white;
  }
  .overlay-icon {
    width: 49px;
    height: 49px;
    background-image: url('~@/assets/icons/loader.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0 auto;
    animation: rotate 1.5s infinite;
    animation-timing-function: linear;
  }
</style>
