<template>
  <div class="quote" :class="{ 'left-column': placedInLeftColumn }" :style="gridStyles">
    <TeaserItem
      :header="copyWithLinebreaks"
      :buttonLink="null"
      :showSpacer="false"
      :class-list="{ 'no-outer-margins': true, 'no-borders': true }"
    />
  </div>
</template>

<script>
import GridItemMixin from '@/mixins/grid-item-mixin';
import TeaserItem from '@/views/common/components/media/TeaserItem.vue';

export default {
  name: 'QuoteComponent',
  mixins: [GridItemMixin],
  components: {
    TeaserItem,
  },
  props: {
    copy: {
      type: String,
      required: true,
    },
  },
  computed: {
    copyWithLinebreaks() {
      return this.copy.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
    placedInLeftColumn() {
      return this.grid_column === 'center';
    },
  },
};
</script>

<style lang="scss" scoped>
.quote {
  margin: 0 80px 80px 0;

  ::v-deep .header {
    margin-left: -3px;
  }
}
.left-column {
  margin-left: 40px;
  margin-right: 40px;
}
</style>
